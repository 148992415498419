module.exports = {
  cantUpdateTimezoneForAllAlarms:
    'Non è possibile aggiornare il fuso orario degli allarmi esistenti.',
  cantUpdateRingtonesForAllAlarms:
    'Non è possibile aggiornare le impostazioni di suoni e vibrazioni per gli allarmi esistenti.',
  preReminderWillRingAt: {
    zero: "Suona un promemoria per questo allarme \nall'indirizzo {{timeString}}.",
    one: "Una promemoria per questo allarme suona all'indirizzo \nall'indirizzo {{timeString}} del giorno precedente.",
    other:
      "Per questo allarme suona una promemoria \nall'indirizzo {{timeString}} {{count}} giorni prima."
  },
  verficationCodeInfo:
    'Riceverete un messaggio di testo o una telefonata con un codice di 4 cifre.',
  verficationCodeInfoWhatsApp:
    'Riceverete un messaggio WhatsApp con un codice di 4 cifre.',
  deletePastAlarmsNotificationMessage:
    'Sono presenti diversi allarmi inattivi. Cancellare gli allarmi non più necessari.',
  confirmCountryCodeAndEnterNumber:
    'Confermare il codice del paese e inserire il numero di telefono.',
  sendCodeOnSms: 'Inviare il codice via SMS',
  sendCodeOnWhatsApp: 'Inviare il codice via WhatsApp',
  verifyNumberWhatsApp:
    'Invieremo un messaggio WhatsApp con il codice di verifica a:\n\n{{formattedMobileNumber}}',
  verifyNumberCall:
    'Riceverete una telefonata con il codice di verifica a:\n    \n{{formattedMobileNumber}}',
  defaultTimezoneForAlarm: 'Fuso orario predefinito per gli allarmi',
  changeDefaultTimezoneForAllExistingAlarms: {
    zero: 'Si desidera reimpostare il fuso orario per tutti gli allarmi esistenti?',
    one: "Si desidera modificare il fuso orario di tutti gli allarmi esistenti in questo fuso orario? Gli allarmi suoneranno all'ora configurata nel nuovo fuso orario."
  },
  changeDefaultRingerSettingsForAllExistingAlarms:
    'Si desidera modificare le impostazioni di suono e vibrazione degli allarmi esistenti?\n  \n  Se si sceglie Sì, gli allarmi esistenti suoneranno con le nuove impostazioni. Se si sceglie No, gli allarmi esistenti continueranno a suonare con le vecchie impostazioni.',
  defaultTimezoneChangedForAllAlarms:
    'Il fuso orario è cambiato per tutti gli allarmi.',
  ringerSettingsChangedForAllAlarms:
    'Modifica delle impostazioni di suono e vibrazione per gli allarmi esistenti.',
  unlimitedAlarmsFeatureDescription:
    'Creare più allarmi per gruppi e compagni.',
  instantAlarmsPremiumFeature: 'Invio di avvisi istantanei',
  instantAlarmsFeatureDescription:
    'Inviate più avvisi istantanei ai vostri contatti e gruppi.',
  duplicateAlarmPremiumFeature: 'Allarme duplicato',
  duplicateAlarmFeatureDescription:
    'Creare rapidamente copie di un allarme con le stesse impostazioni e apportare le modifiche prima di salvare.',
  showingAlarmsForDate: 'Visualizzazione degli allarmi per {{date}}',
  shareAlarm: "Condividi il link dell'allarme",
  shareAlarmDescription:
    "È possibile condividere questo allarme con chiunque utilizzando un link senza aggiungerlo come contatto. Quando qualcuno tocca il link sul suo telefono, viene creata una copia dell'allarme.",
  sendCodeAgain: 'Inviare di nuovo il codice',
  selectDefaultTimezoneForAlarmDefaultTooltipMessage:
    'Scegliere un fuso orario predefinito per gli allarmi. È possibile modificare il fuso orario degli allarmi al momento della loro creazione.',
  alarmLinkRelativeToTimezone: '{{timeString}} nel loro fuso orario.',
  alarmLinkNotRelativeToTimezone:
    "L'allarme di tutti suonerà alle ore {{timeString}}",
  alarmShouldRingAt: "L'allarme di tutti suona alle ore:",
  showHourlyAlarms: 'Mostra gli allarmi orari',
  critical: 'Critico',
  alarmsWillBeAutomaticallyDeleted:
    'Gli allarmi saranno rimossi definitivamente 30 giorni dopo la loro cancellazione.',
  unlimitedAlarmsPremiumFeature: 'Allarmi illimitati per gruppi e compagni',
  notAcknowledged: 'Non riconosciuto',
  closed: 'Chiuso',
  showFullScreenIntents: "Mostra l'allarme su schermo intero",
  showFullScreenIntentsDescription:
    "L'allarme viene visualizzato su schermo intero quando il telefono è bloccato.",
  percentVolume: '{{volume}}% volume',
  selectADate: 'Scegliere una data per visualizzare gli allarmi.',
  affiliates: 'Affiliati',
  referralCount: 'Conteggio dei referral',
  addAffiliate: 'Aggiungi Affiliato',
  creatingAffiliate: "Creare un'affiliazione",
  affiliateLink: 'Link di affiliazione',
  upgradeRequiredForWeb: 'Aggiornamento richiesto',
  upgradeToPremiumForWeb:
    "Questa funzione richiede un abbonamento premium. Per utilizzare questa funzione, acquistare un abbonamento premium dall'applicazione mobile.",
  accountType: 'Tipo di conto',
  profileAndSettings: 'Profilo e impostazioni',
  confirmIncidents: 'Confermare gli incidenti multipli',
  removeIncidents: 'Rimuovere gli incidenti multipli',
  incidents: 'Incidenti',
  closeSelectedIncidents: 'Chiudere gli incidenti selezionati',
  viewIncidentNotes: "Vista le note sull'incidente",
  incidentNotes: "Note sull'incidente",
  addNote: 'Aggiungi nota',
  noAdditionalNotesForIncident:
    'Non ci sono note aggiuntive per questo incidente.',
  fromIncident: "Dall'incidente:",
  fromUsers: 'Da parte degli utenti:',
  database: 'Database',
  chooseFirstDayOfWeek: 'Scegliere il primo giorno della settimana',
  firstDayOfWeek: 'Primo giorno della settimana',
  changePlan: 'Piano di cambiamento',
  currentPremiumSubscriptionStatus:
    'Siete attualmente abbonati al piano {{planName}}.',
  currentProSubscriptionStatus: "Siete membri dell'organizzazione {{orgName}}.",
  changeToAnnualPlan: 'Modifica del piano annuale',
  unableToChangePlan:
    'Impossibile passare al piano annuale. Contattare l\'assistenza tecnica tramite "Impostazioni > Feedback".',
  confirmChangeToAnnualPlan: 'Volete passare al piano annuale?',
  changeToAnnualPlanDescription:
    'Per il piano annuale verrà addebitato il costo di {{price}}. Tutti i giorni rimanenti nel piano attuale saranno adattati al piano annuale.',
  alarmTimezoneChanged:
    'Alcuni allarmi sono stati aggiornati per utilizzare il fuso orario del dispositivo perché il fuso orario utilizzato per tali allarmi non è supportato da questo dispositivo.',
  defaultTimezoneChanged:
    'Il fuso orario predefinito per gli allarmi viene modificato in quello del dispositivo perché il fuso orario di {{defaultTimezoneForAlarm}} non è supportato da questo dispositivo.',
  invalidEmail: 'Indirizzo e-mail non valido.',
  unableToValidateEmail: "Impossibile convalidare l'e-mail. {{error}}",
  havingProblems: 'Avete problemi?',
  alertData: 'Dati di allarme',
  notificationActionBehavior: 'Notifica Azione Comportamento',
  foreground: 'Primo piano',
  background: 'Sfondo',
  notificationActionBehaviorTooltipMessage:
    'Quando è impostato su "In primo piano", toccando un\'azione di notifica si aprirà l\'app in primo piano. Quando è impostata su "Sfondo", toccando un\'azione di notifica l\'app rimarrà in secondo piano.',
  getCodeOnWhatsApp: 'Ricevi il codice su WhatsApp',
  getCode: 'Ottenere il codice',
  dontHaveWhatsApp: 'Non avete WhatsApp?',
  unableToSendCodeViaWhatsAppTitle:
    'Impossibile inviare un codice via WhatsApp',
  unableToSendCodeViaWhatsAppMessage:
    '{{error}}\n      \nSi prega di richiedere nuovamente il codice su un altro canale.',
  dataSecurity: 'Sicurezza dei dati',
  alarms: 'Allarmi',
  contacts: 'Contatti',
  groups: 'Gruppi',
  settings: 'Impostazioni',
  notificationsTab: 'Notifiche',
  specifyTimeInFutureForAlarm: "L'allarme non può essere nel passato.",
  specifyTimeInFutureForPreReminder:
    "La promemoria per l'allarme fa parte del passato.",
  specifyNameForGroup: 'Il nome del gruppo è obbligatorio.',
  updatingGroupName: 'Aggiornamento delle informazioni sul gruppo.',
  unableToEditGroupName:
    'Impossibile aggiornare le informazioni sul gruppo. Riprovare dopo qualche orario.',
  selectSmallerImage:
    "L'immagine è più grande di 10 MB. Selezionare un'immagine più piccola.",
  sendingVerificationCode:
    'Invio del codice di verifica a {{formattedMobileNumber}}.',
  verifyingCode: 'Verifica del codice.',
  creatingUserProfile: 'Impostazione...',
  unabeToCreateUserProfile:
    'In questo orario non è possibile impostare un account. Riprovare tra qualche istante.',
  unableToSendVerificationCode:
    'Impossibile inviare il codice di verifica. {{error}}',
  unableToVerifyCode: 'Impossibile verificare il codice. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'Impossibile eseguire la configurazione. {{error}}',
  permissionDeniedToAccessCameraIos:
    "Galarm non ha il permesso di accedere alla telecamera. \n\nAndate in Impostazioni > Galarm e consentite l'accesso alla fotocamera.",
  permissionDeniedToAccessCameraAndroid:
    "Galarm non ha il permesso di accedere alla telecamera.\n\nAndate in Impostazioni > Applicazioni > Galarm > Autorizzazioni e consentite l'accesso alla fotocamera.",
  permissionDeniedToAccessPhotosIos:
    "Galarm non ha il permesso di accedere alle foto.\n\nAndate in Impostazioni > Galarm e consentite l'accesso alle foto.",
  permissionDeniedToAccessPhotosAndroid:
    "Galarm non ha il permesso di accedere alle foto.\n\nAndate in Impostazioni > Applicazioni > Galarm > Autorizzazioni e consentite l'accesso all'archiviazione.",
  permissionDeniedToAccessCalendarIos:
    "Galarm non ha il permesso di accedere al calendario.\nAndate in Impostazioni > Calendari e consentite l'accesso a Galarm.",
  permissionDeniedToAccessCalendarAndroid:
    "Galarm non ha il permesso di accedere al calendario.\nAndate in Impostazioni > Applicazioni > Galarm > Autorizzazioni e consentite l'accesso al Calendario.",
  verificationCodeSent:
    'Codice di verifica inviato a {{formattedMobileNumber}}.',
  rateTheApp:
    'Ci auguriamo che Galarm vi sia utile. Vi invitiamo a dare un voto su Play Store. Grazie per il vostro supporto.',
  backgroundAppRefreshDisabledPermission:
    'Gli allarmi potrebbero non suonare perché Galarm non ha il permesso di eseguire l\'aggiornamento delle app in background.\n\nToccare "Abilita" e abilitare "Aggiornamento app in background".',
  selectedGroupUnselected:
    'È possibile selezionare un gruppo o un insieme di contatti come partecipanti. Il gruppo precedentemente selezionato sarà deselezionato. Procedere?',
  selectedContactsUnselected:
    'È possibile selezionare un gruppo o un insieme di contatti come partecipanti. I contatti precedentemente selezionati saranno deselezionati. Procedere?',
  notificationsDisabledIos:
    'Gli allarmi non suonano perché Galarm non ha il permesso di mostrare le notifiche.\n\nAndate su Impostazioni > Galarm > Notifiche e attivate "Avvisi", "Suoni" e "Banner".',
  notificationsDisabledAndroid:
    'Le notifiche di allarme non vengono visualizzate su schermo.\n\nToccare "Abilita", selezionare "Notifiche" e consentire le notifiche.',
  alarmNotificationChannelDisabled:
    'Le notifiche di allarme non vengono visualizzate su schermo. \n\nToccare "Abilita" per consentire la visualizzazione delle notifiche di allarme su schermo.',
  alarmLockScreenNotificationlDisabled:
    'Le notifiche degli allarmi non vengono visualizzate su una schermata di blocco. \n\nToccare "Abilita" per consentire la visualizzazione delle notifiche di allarme su questa schermata.',
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Gli allarmi non suonano perché le notifiche sono disabilitate.\n\nAndate in Impostazioni > Galarm > Notifiche e attivate "Avvisi", "Suoni" e "Banner".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Gli allarmi non suonano quando il telefono è silenziato perché gli avvisi critici sono disabilitati.\n\nAndate in Impostazioni > Galarm > Notifiche e attivate "Avvisi critici".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'L\'allarme non appare sullo schermo perché le notifiche sono disabilitate.\n\nToccare "Abilita", selezionare "Notifiche" e consentire le notifiche.',
  noNotificationsScheduledAlarmInPast:
    "L'allarme è passato. Non sono previste notifiche.",
  alarmOverAllowedLimit: {
    zero: '\nÈ stato raggiunto il limite di allarme di {{limit}}.\n\nSi prega di cancellare o riutilizzare un allarme esistente.',
    one: 'È stato raggiunto il limite di allarme di {{limit}}.\n\nSi dispone di 1 allarme inattivo che può essere eliminato o riutilizzato.',
    other:
      'È stato raggiunto il limite di allarme di {{limit}}.\n\nSono presenti {{count}} allarmi inattivi che possono essere eliminati o riutilizzati.'
  },
  alarmAlreadyConfirmed: 'Hai già confermato.',
  alarmAlreadyDeclined: 'Hai già rifiutato.',
  participantMovedToPosition:
    '{{name}} si è spostato nella posizione {{count}}.',
  memberIsFirstParticpant: '{{name}} è il primo partecipante.',
  memberIsLastParticpant: "{{name}} è l'ultimo partecipante.",
  cantContinueWithRegistrationWhenOffline:
    'Non è possibile continuare la registrazione perché il dispositivo è offline.',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'Non è possibile continuare la registrazione perché il dispositivo è offline. Assicurarsi di essere connessi a Internet.',
  confirmBlockContact:
    'Non riceverete più alcun allarme da {{name}}. Procedere?',
  confirmUnblockContact:
    'Lo sblocco di {{name}} ripristina anche gli allarmi bloccati. Procedere?',
  unblockingContact: 'Sblocco di {{name}} e ripristino degli allarmi.',
  unableToUnblockContact: 'Impossibile sbloccare il contatto. {{error}}',
  blockingContact: 'Blocco {{name}}.',
  unableToBlockContact: 'Impossibile bloccare il contatto. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    "Avete bloccato {{name}}. Sbloccateli per aggiungerli come partecipanti all'allarme.",
  cantAddContactToGroupBecauseBlocked:
    'Hai bloccato {{name}}. Sbloccateli per aggiungerli come membri del gruppo.',
  sendMessageNotAllowedForPastAlarms:
    'Non è possibile inviare messaggi per gli allarmi inattivi.',
  nameIsRequired: 'Il nome è obbligatorio.',
  confirmDisableGroupAlarm:
    "Disattivando l'allarme del gruppo, si disattiva anche quello degli altri partecipanti. Procedere?",
  removeMemberFromGroup: 'Vuoi rimuovere {{name}} da questo gruppo?',
  leaveGroupConfirm: 'Vuoi lasciare questo gruppo?',
  deviceOffline:
    'Il dispositivo è offline. Assicurarsi di essere connessi a Internet e riprovare.',
  cantUpdateGroupDefaulConfigWhenOffline:
    'La configurazione del gruppo modificata non verrà applicata agli allarmi futuri perché il dispositivo è offline.',
  specifyFeedback: 'È necessario un feedback.',
  specifyEmailId: "L'indirizzo e-mail è obbligatorio.",
  specifyValidEmailId: "L'indirizzo e-mail non è valido.",
  specifyProblem: 'È richiesta la descrizione del problema.',
  appInstalledUsingSameNumberOnDifferentPhone:
    "Avete installato Galarm su un altro dispositivo utilizzando lo stesso numero. L'app su questo dispositivo è stata ripristinata.",
  invalidUid:
    "Questo account utente non esiste più. L'applicazione Galarm su questo dispositivo è stata ripristinata.",
  accountDeleted: 'Il suo account è stato cancellato.',
  deleteAccount: 'Cancellare il mio account',
  deletingAccount: "Eliminazione dell'account",
  howCanWeImprove: 'Ci dispiace vederla andare via. Come possiamo migliorare?',
  noMusicAlert: 'Nessun file musicale trovato.',
  noLongerAvailableMusic:
    '{{fileName}} non è più disponibile. La suoneria è stata cambiata in {{defaultRingtoneName}}',
  userInactiveTitle: 'Bentornati!',
  userInactiveMessage:
    'Create degli allarmi per le cose importanti e non perdetele mai.',
  createAlarm: 'Crea allarme',
  updatedAppAvailable: "È disponibile una nuova versione dell'applicazione.",
  alarmOccurrenceDisabled: 'Disattivato per questo evento',
  ownAlarmOccurrenceDone: {
    zero: 'Fatto a {{timeString}}',
    one: 'Fatto su {{timeString}}. {{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Salta a {{timeString}}',
    one: 'Salta su {{timeString}}. {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 allarme attivato.',
    other: '{{count}} allarmi abilitati.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 allarme attivato. Gli allarmi che non si verificano in futuro rimangono disabilitati.',
    other:
      '{{count}} allarmi abilitati. Gli allarmi che non si verificano in futuro rimangono disabilitati.'
  },
  disabledAlarmsCount: {
    one: '1 allarme disattivato.',
    other: '{{count}} allarmi disattivati.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Non ha risposto',
  participantAlarmOccurrenceDone: {
    zero: 'Marcato fatto da {{participantName}} a {{timeString}}',
    one: 'Marcato fatto da {{participantName}} su {{timeString}} il {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Salta da {{participantName}} a {{timeString}}',
    one: 'Salta da {{participantName}} a {{timeString}} su {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged:
    '{{participantName}} non ha risposto',
  groupAlarmOccurrenceConfirmed: {
    zero: 'Confermato a {{timeString}}',
    one: 'Confermato su {{timeString}} il giorno {{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Declino a {{timeString}}',
    one: 'Declino su {{timeString}} su {{dateString}}'
  },
  warning: 'Avvertenze',
  info: 'Info',
  confirm: 'Confermare',
  error: 'Errore',
  cantUploadImage: "Impossibile caricare l'immagine",
  cantDeleteImage: "Impossibile eliminare l'immagine",
  cantEditGroup: 'Impossibile modificare il gruppo',
  cantAddNewContact: 'Impossibile aggiungere un nuovo contatto',
  cantDeleteContact: 'Impossibile eliminare un contatto',
  cantEditContact: 'Impossibile modificare il contatto',
  cantSaveAlarm: "Non si può salvare l'allarme",
  cantSaveGroup: 'Impossibile salvare il gruppo',
  cantReloadContacts: 'Impossibile ricaricare i contatti',
  cantTakePhoto: 'Non è possibile scattare foto',
  cantExportToCalendar: 'Non è possibile esportare in un calendario',
  cantOpenPhotoLibrary: 'Impossibile aprire la libreria di foto',
  cantDeleteAlarmHistory: 'Impossibile eliminare la cronologia degli allarmi',
  cannotDuplicateAlarm: "Non è possibile duplicare l'allarme",
  upgradeToPremiumToDuplicateAlarm:
    'Aggiornamento a Premium per creare altri allarmi per gruppi e compagni.',
  confirmAcceptAlarm: "Accettare l'allarme?",
  confirmRejectAlarm: 'Allarme Declino?',
  deleteAlarm: "Cancellare l'allarme?",
  restoreAlarmConfirmation: "Ripristinare l'allarme?",
  respondToRepeatingAlarm: {
    zero: "Siete stati aggiunti come destinatari all'allarme {{alarmName}} da {{alarmCreatorName}}. Sarete avvisati da {{alarmTimeString}} {{alarmRepetitionString}} a partire da {{alarmDateString}}",
    one: "Lei è aggiunto come partecipante all'allarme {{alarmName}} da {{alarmCreatorName}}. Sarete allertati da {{alarmTimeString}} {{alarmRepetitionString}} a partire da {{alarmDateString}}"
  },
  respondToOneTimeAlarm: {
    zero: "Lei è stato aggiunto come destinatario all'allarme {{alarmName}} da {{alarmCreatorName}}. Sarete avvisati da {{alarmTimeString}} {{alarmDateString}}",
    one: "Sei stato aggiunto come partecipante all'allarme {{alarmName}} da {{alarmCreatorName}}. Sarete avvisati da {{alarmTimeString}} {{alarmDateString}}"
  },
  cantMoveMemberUp: 'Non è possibile spostare {{name}} in alto',
  cantMoveMemberDown: "Can't Move {{name}} Down",
  decline: 'Declino',
  cantEditProfile: 'Impossibile modificare il profilo',
  cantSelectContact: 'Impossibile selezionare il contatto',
  cantSendMessage: 'Impossibile inviare il messaggio',
  cantSendVerificationCode: 'Impossibile inviare il codice di verifica',
  confirmBlockContactTitle: 'Confermare il contatto del blocco',
  confirmUnblockContactTitle: 'Confermare lo sblocco del contatto',
  cantSaveProfile: 'Impossibile salvare il profilo',
  cantBlockContact: 'Impossibile bloccare il contatto',
  cantUnblockContact: 'Impossibile sbloccare il contatto',
  cantLoadEarlierMessages: 'Impossibile caricare i messaggi precedenti',
  cantRestoreAlarm: "Impossibile ripristinare l'allarme",
  cantSendFeedback: 'Impossibile inviare il feedback',
  cantSubmitProblem: 'Problema di impossibilità di invio',
  cantDeletePastAlarmsInBulk:
    'Impossibile eliminare gli allarmi inattivi in blocco',
  cantAddNewGroup: 'Impossibile aggiungere un nuovo gruppo',
  alreadySnoozing: 'Già Rimandare',
  markAlarmUndone:
    'Hai contrassegnato questo allarme come "Fatto" {{durationString}} fa. Annullare?',
  markAlarmUnskipped:
    'Hai saltato questo allarme {{durationString}} fa. Annullare?',
  cantUndo: 'Non si può annullare',
  fetchMusicFromPhone: 'Trovare la musica disponibile.',
  enableAllAlarmsInCategory: 'Attivare tutti gli allarmi di questa categoria?',
  disableAllAlarmsInCategory:
    'Disattivare tutti gli allarmi di questa categoria?',
  allAlarmsAlreadyEnabled:
    'Tutti gli allarmi di questa categoria sono già abilitati.',
  allAlarmsAlreadyDisabled:
    'Tutti gli allarmi di questa categoria sono già disabilitati.',
  time: 'Orario',
  timeForBuddy: 'Orario per il compagno',
  timeForYou: 'Promemoria per',
  name: 'Nome',
  title: 'Titolo',
  notes: 'Note',
  repeat: 'Ripeti',
  preReminder: 'Promemoria',
  remove: 'Rimuovere',
  preReminderShortAlert: '{{duration}} Promemoria',
  preReminderTitle: '{{alarmName}} a {{date}}',
  preReminderGroupHelperText:
    "Questa promemoria è solo per voi. Gli altri partecipanti all'allarme possono impostare i propri preavvisi una volta ricevuto l'allarme.",
  addPreReminder: 'Aggiungi una promemoria',
  helperTextForPreReminder:
    'Toccare il pulsante di ripristino su per rimuovere il preavviso.',
  edit: 'Modifica',
  delete: 'Cancellare',
  creator: 'Creatore',
  useAsDefaultGroupConfiguration: 'Applicare agli allarmi futuri',
  done: 'Fatto',
  skipped: 'Salta',
  save: 'Salva',
  members: 'Membri',
  mobileNumber: 'Numero di telefono',
  enter: 'Entrare',
  addMembers: 'Aggiungi membri',
  respond: 'Rispondere',
  yes: 'Sì',
  no: 'No',
  cancel: 'Annullamento',
  participants: 'Partecipanti',
  takePhoto: 'Scattare una foto',
  chooseFromLibrary: 'Scegliere dalla biblioteca',
  resendCode: 'Reinvio del codice',
  register: 'Registro',
  inviteFriends: 'Condividi questa applicazione',
  invite: 'Invito',
  incorrectNumber: 'Numero errato?',
  date: 'Data',
  cascadingAlarmInterval: 'Orario tra gli avvisi ai partecipanti',
  ringParticipantAlarmsByDefault: 'Suona gli allarmi senza Accettare',
  alarmRingtone: 'Suoneria di allarme',
  pickASong: 'Scegliere la musica dal telefono',
  privacyPolicy: 'Informativa sulla privacy',
  sendFeedback: 'Feedback',
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm Admin',
  troubleshooting: 'Risoluzione dei problemi',
  alarmDidntRingHelp: 'Aiuto! Il mio allarme non suonava',
  autoStartSettingHelperText:
    "Se il telefono ha un'impostazione per l'avvio automatico, assicurarsi che l'avvio automatico sia abilitato per Galarm.",
  batteryOptimizationHelperText:
    "Disattivare l'ottimizzazione della batteria per Galarm",
  troubleshootingHelperText:
    'Le seguenti impostazioni potrebbero impedire agli allarmi di suonare come previsto. Rivedere queste impostazioni.',
  getMoreHelpText: 'Avete ancora problemi?',
  autoStartSetting: 'Avvio automatico',
  batteryOptimization: 'Ottimizzazione della batteria',
  battery: 'Batteria',
  rateTheAppSettings: 'Vota questa applicazione',
  about: 'Informazioni e aiuto',
  leaveGroup: 'Gruppo di congedo',
  removeGroup: 'Rimuovi gruppo',
  viewMember: 'Vista {{memberName}}',
  removeMember: 'Rimuovere {{memberName}}',
  changeMemberState: 'Modificare chi può avvisare {{memberName}}',
  changeYourGroupState: 'Cambiare chi può avvisare',
  numMembersInGroup: 'Membri: {{count}}',
  notifications: "Le notifiche dell'app sono disabilitate",
  backgroundAppRefresh: "L'aggiornamento dell'app in background è disabilitato",
  groupsInCommon: 'Gruppi in comune',
  alarmsInCommon: 'Allarmi in comune',
  close: 'Chiudere',
  responsePending: 'La vostra risposta?',
  later: 'Più tardi',
  install: 'Installare',
  all: 'Tutti',
  numYears: { one: '1 anno', other: '{{count}} anni' },
  numMonths: { one: '1 mese', other: '{{count}} mesi' },
  numDays: { one: '1 giorno', other: '{{count}} giorni' },
  hours: { one: '1 ora', other: '{{count}} ore' },
  minutes: { one: '1 minuto', other: '{{count}} minuti' },
  seconds: { one: '1 secondo', other: '{{count}} secondi' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: { one: '1 ora dopo', other: '{{count}} ore dopo' },
  minutesAfter: { one: '1 minuto dopo', other: '{{count}} minuti dopo' },
  hoursAfterBuddy: {
    one: '1 ora dopo il compagno',
    other: '{{count}} ore dopo il compagno'
  },
  minutesAfterBuddy: {
    one: '1 minuto dopo il compagno',
    other: '{{count}} minuti dopo il compagno'
  },
  longerThanAnHour: '> 1 ora',
  licenses: 'Licenze di terze parti',
  version: 'Versione',
  termsOfService: 'Termini di servizio',
  showPrivacyPolicy: '> Informativa sulla privacy',
  showTermsOfService: '> Termini di servizio',
  showLicenses: '> Licenze di terze parti',
  change: 'Cambiamento',
  snooze: 'Rimandare',
  ringOnVibrate: 'Suona su silenzioso',
  vibrate: 'Vibrazione',
  ringOnEarphoneOnly: "Suona l'allarme solo sugli auricolari",
  ringOnSpeakerAndEarphone: "Suona l'allarme su altoparlante e auricolari",
  whenEarphoneIsConnected: 'Quando sono collegati gli auricolari',
  tapGesture: 'Rubinetto',
  slideGesture: 'Scivolare',
  silent: 'Silenzioso',
  volume: 'Volume',
  soundAndVibration: 'Suoni e vibrazioni',
  advancedSettings: 'Impostazioni avanzate',
  gestureToDismissAnAlarm: 'Gesto per ignorare un allarme',
  someNotificationsAreMuted: 'Alcune notifiche sono disattivate.',
  allNotificationsEnabled: 'Tutte le notifiche sono attive',
  notificationSettings: 'Impostazioni di notifica',
  galarmWebNotifications: 'Notifiche web Galarm',
  alarmsfromOthersNotifications: 'Allarmi partecipante Notifiche',
  sharedAlarmNotifications: 'Notifiche di allarme condivise',
  alarmChatNotifictions: 'Notifiche della chat di allarme',
  groupNotifications: 'Notifiche di gruppo',
  alarmAcknowledgementNotifications: 'Notifiche di conferma degli allarmi',
  send: 'Inviare',
  prev: 'Prev',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Specificare la durata di Rimandare',
  chooseDifferentDuration: 'Scegliere una durata diversa',
  noCalendarsFound: 'Non sono stati trovati calendari su questo dispositivo.',
  eventWillEndAt: "L'evento terminerà su {{timeString}}.",
  selectDurationWithinTheSameDay:
    "L'evento non può superare il limite del giorno.",
  cannotMapRepeatTypeInTheDeviceCalendar:
    'Non è possibile "Esportare nel calendario" perché l\'impostazione "Ripeti" dell\'allarme non è supportata dal calendario.',
  alarmSavedToCalendar: "L'allarme è stato salvato nel calendario.",
  faq: 'Domande frequenti',
  openSettings: 'Impostazioni aperte',
  openAppSettings: "Aprire le impostazioni dell'app",
  alreadyDone: 'Fatto',
  enableAutoStart: "Abilita l'avvio automatico",
  restrictedApps: 'Applicazioni con restrizioni',
  unmonitoredApps: 'Applicazioni non monitorate',
  enableProtected: 'Applicazioni protette',
  manageAppLaunch: "Gestire l'avvio dell'app",
  unblockContact: 'Sbloccare il contatto',
  blockContact: 'Contatto di blocco',
  call: 'Chiamata {{name}}',
  text: 'Testo {{name}}',
  chat: 'Chat',
  ok: 'OK',
  stopSnooze: 'Interrompere lo Rimandare',
  emailId: 'Indirizzo e-mail',
  feedback: 'Feedback',
  submit: 'Invia',
  reportAProblem: 'Segnalare un problema',
  enableAlarm: 'Abilita allarme',
  restoreAlarm: "Ripristino dell'allarme",
  deletePastAlarms: 'Cancellare gli allarmi inattivi',
  accept: 'Accettare',
  defaultPersonalAlarmName: 'Promemoria personale',
  defaultGroupAlarmName: 'Evento di gruppo',
  defaultRecipientAlarmName: 'Allarme del compagno',
  search: 'Ricerca',
  recent: 'Recente',
  upcoming: 'Prossimamente',
  missed: 'Mancato',
  expired: 'Scaduto',
  past: 'Il passato',
  active: 'Attivo',
  inactive: 'Inattivo',
  allAlarms: 'Tutti gli allarmi',
  myAlarms: 'I miei allarmi',
  participantAlarms: 'Allarmi per i partecipanti',
  pendingParticipantAlarms: 'Nuovi allarmi da parte di terzi',
  alarmsWithUnreadChatMessages: 'Allarmi con la nuova chat',
  systemNotifications: 'Messaggi di sistema',
  missedAlarms: 'Allarmi mancati',
  recentlyDeletedAlarms: 'Allarmi eliminati di recente',
  searchAlarms: 'Ricerca Allarmi',
  participant: 'Partecipante',
  you: 'Tu',
  none: 'Nessuno',
  onceOnly: 'Una volta sola',
  weekdays: 'Giorni feriali',
  weekends: 'Fine settimana',
  everyDayString: 'Giornaliero',
  everySunday: 'Ogni domenica',
  everyMonday: 'Ogni Lunedì',
  everyTuesday: 'Ogni Martedì',
  everyWednesday: 'Ogni Mercoledì',
  everyThursday: 'Ogni Giovedì',
  everyFriday: 'Ogni Venerdì',
  everySaturday: 'Ogni Sabato',
  sunday: 'Domenica',
  monday: 'Lunedì',
  tuesday: 'Martedì',
  wednesday: 'Mercoledì',
  thursday: 'Giovedì',
  friday: 'Venerdì',
  saturday: 'Sabato',
  january: 'Gennaio',
  february: 'Febbraio',
  march: 'Marzo',
  april: 'Aprile',
  may: 'Maggio',
  june: 'Giugno',
  july: 'Luglio',
  august: 'Agosto',
  september: 'Settembre',
  october: 'Ottobre',
  november: 'Novembre',
  december: 'Dicembre',
  jan: 'Gen',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  shortMay: 'Mag',
  jun: 'Giu',
  jul: 'Lug',
  aug: 'Ago',
  sep: 'Set',
  oct: 'Ott',
  nov: 'Nov',
  dec: 'Dic',
  sun: 'Dom',
  mon: 'Lun',
  tue: 'Mar',
  wed: 'Mer',
  thu: 'Gio',
  fri: 'Ven',
  sat: 'Sab',
  first: '1a',
  second: '2a',
  third: '3a',
  fourth: '4a',
  fifth: '5a',
  every: 'Ogni',
  oddNumberedDays: 'Date dispari',
  evenNumberedDays: 'Date con numero pari',
  noParticipants: 'Nessun partecipante',
  typeYourMessage: 'Digitare il messaggio',
  cantPostMessageToPastAlarm:
    'Impossibile inviare un messaggio ad un allarme inattivo',
  groupName: 'Gruppo: {{name}}',
  alarm: 'Allarme',
  confirmed: 'Confermato',
  declined: 'declinato',
  Done: 'Fatto',
  Skip: 'Salta',
  Confirm: 'Confermare',
  Decline: 'Declino',
  Accept: 'Accettare',
  today: 'oggi',
  on: 'su',
  recipientAlarm: 'Allarme compagno',
  recipient: 'Destinatario',
  remindMeAfter: 'Promemoria',
  remindMeIn: 'Promemoria in',
  newRecipientAlarm: 'Nuovo allarme per i compagni',
  editRecipientAlarm: "Modifica dell'allarme del compagno",
  newInstantAlarm: 'Nuovo allarme istantaneo',
  instantAlarms: 'Allarmi istantanei',
  alarmDone: 'Allarme contrassegnato come Fatto',
  alarmSkipped: 'Allarme Salta',
  undo: 'Annullamento',
  enterVerificationCode: 'Immettere il codice a 4 cifre riportato di seguito:',
  initializing: 'Inizializzazione',
  tryAgain: 'Riprova',
  nMore: '+ {{count}} altro',
  enableNotification: 'Abilitazione',
  enableAlarmNotificationChannel: 'Abilitazione',
  enableBackgroundAppRefresh: 'Abilitazione',
  viewImpactedAlarms: 'Allarmi',
  viewImpactedGroups: 'Gruppi',
  dismiss: 'Ignora',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Ore',
  snoozeUnitMinutes: 'Minuti',
  snoozeUnitDays: 'Giorni',
  selectMinutes: 'Seleziona i minuti',
  selectHours: 'Seleziona le ore',
  selectDays: 'Seleziona i giorni',
  selectHoursAndMinutes: 'Selezionare ore e minuti',
  enterDuration: 'Durata',
  snoozeUnit: 'Unità',
  specifySnoozeUnit: "Specificare l'unità di Rimandare",
  specifySnoozeDuration: 'Specificare la durata di Rimandare',
  duration: 'Durata',
  quickAlarmName: {
    zero: '{{minutes}} promemoria min.',
    one: "{{hours}} promemoria dell'ora",
    other: '{{hours}} ora {{minutes}} min promemoria'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} promemoria',
  addedAlarmFromVoiceCommand:
    'È stato aggiunto un allarme per il sito {{alarmName}}. {{dateString}}',
  addedTimerFromVoiceCommand: 'Aggiunto un timer per {{duration}}',
  clear: 'Libero',
  ringtoneDuration: 'Durata della suoneria',
  chooseDuration: 'Toccare una durata. È possibile toccare più volte.',
  newAlarm: 'Nuovo allarme',
  quickAlarm: 'Promemoria veloce',
  quickAlarmWillRingAt: "L'allarme suona all'indirizzo {{timeString}}.",
  random: 'Casuale',
  enableAllAlarms: 'Abilita tutti gli allarmi',
  disableAllAlarms: 'Disattivare tutti gli allarmi',
  deleteAllAlarms: 'Cancellare tutti gli allarmi',
  hideCategory: 'Nascondi categoria',
  unhideCategory: 'Nascondere la categoria',
  dontShowHiddenCategories: 'Non mostrare le categorie nascoste',
  showHiddenCategories: 'Mostra le categorie nascoste',
  welcomeAlarmsTitle: 'Cominciamo!',
  welcomeAlarmsMessage: 'Ecco alcuni allarmi comunemente utilizzati:',
  onboardingAlarms: "Allarmi per l'imbarco",
  hiddenAlarmCategories: 'Categorie nascoste',
  restorePurchases: 'Ripristino degli acquisti',
  noAvailablePurchasesFound:
    'Non sono stati trovati acquisti disponibili per il suo account.\n\nSe ha un acquisto valido che non è in grado di ripristinare, la preghiamo di inviarci un feedback tramite "Impostazioni > Feedback".',
  restoringPurchases: 'Ripristino degli acquisti',
  fetchingContacts: 'Controllo dei contatti che utilizzano Galarm',
  enterNameAndUploadPhotoForUser:
    'Inserite il vostro nome qui sotto e caricate facoltativamente la foto del vostro profilo.',
  enterNameAndUploadPhotoForGroup:
    'Inserire un nome qui sotto e caricare una foto del gruppo.',
  noContactsWithAppInstalled: 'Nessuno dei vostri contatti utilizza Galarm.',
  galarmContacts: {
    one: '1 contatto Galarm',
    other: '{{count}} Contatti Galarm'
  },
  contactsPendingInvitation: {
    one: '1 invito in sospeso',
    other: '{{count}} inviti in sospeso'
  },
  otherContacts: { one: '1 altro contatto', other: '{{count}} altri contatti' },
  over100Contacts: 'Oltre 100 altri contatti',
  noAlarms: 'Non ci sono allarmi. Toccare + per aggiungere un nuovo allarme.',
  noContactsWeb: {
    one: "Non abbiamo trovato nessun contatto che utilizza Galarm. O non abbiamo il permesso di accedere ai vostri contatti o nessuno dei vostri contatti sta usando Galarm. È possibile sincronizzare i contatti dall'applicazione mobile.\n\nÈ possibile aggiungere i contatti che utilizzano Galarm agli allarmi in diversi modi per trarre vantaggio dall'applicazione.",
    other:
      "Registratevi sull'app mobile per iniziare ad aggiungere contatti ai vostri allarmi."
  },
  viewingAlarmsInCommon: 'Allarmi in comune con {{name}}',
  viewingGroupsInCommon: 'Gruppi in comune con {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    "I partecipanti attivi saranno avvisati nell'ordine visualizzato.",
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Solo i partecipanti attivi saranno avvisati.',
  changeGroupMemberStateText: {
    zero: 'Modificare i membri che possono avvisare quando viene creato un allarme per il gruppo "{{groupName}}".\n\nL\'utente sarà avvisato solo se l\'allarme è stato creato da membri autorizzati.',
    one: 'Modificare i membri che possono avvisare {{memberName}} quando viene creato un allarme per il gruppo "{{groupName}}".\n\n{{memberName}} sarà avvisato solo se l\'allarme è stato creato da membri autorizzati.'
  },
  allowed: 'Consentito',
  notAllowed: 'Non consentito',
  changeStatus: 'Membri ammessi',
  noUpcomingAlarms: 'Nessun allarme in arrivo',
  showMore: 'Mostra di più',
  nMorePastAlarms: {
    one: '1 altro allarme inattivo',
    other: '{{count}} altri allarmi inattivi'
  },
  shareAppMessage:
    "Faccio in modo che tutte le mie cose vengano fatte in orario creando promemoria per loro con l'app Galarm. Grazie a questa app, possiamo anche includere gli altri nei nostri promemoria. Consiglio di scaricare questa innovativa app di allarme e promemoria. {{downloadLink}}",
  addingMembers: 'Aggiunta di membri.',
  unableToAddMembers: 'Impossibile aggiungere membri. {{error}}',
  removingMember: 'Rimozione di un membro.',
  unableToRemoveMember: 'Impossibile rimuovere il membro. {{error}}',
  leavingGroup: 'Gruppo di partenza.',
  unableToLeaveGroup: 'Impossibile lasciare il gruppo. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Questo allarme è scaduto da {{durationString}}. Toccare "Fatto" per completare l\'allarme.',
    one: 'Questo allarme è scaduto da {{durationString}}. Toccare "Fatto" per completare l\'allarme e notificarlo ai partecipanti.',
    other:
      'Questo allarme è scaduto da {{durationString}}. Toccare "Fatto" per completare l\'allarme e notificarlo ai partecipanti.'
  },
  donePersonalAlarmForCreator:
    "Questo allarme è scaduto {{durationString}} fa. L'allarme è contrassegnato come fatto.",
  skippedPersonalAlarmForCreator:
    "Questo allarme è scaduto {{durationString}}. L'allarme è saltato.",
  expiredGroupAlarm:
    'Questo allarme è scaduto {{durationString}} fa. Confermare la propria partecipazione.',
  expiredPersonalAlarmForParticipant:
    'Questo allarme è scaduto {{durationString}} fa. Si prega di promemoria {{creatorName}}.',
  alarmDurationAndroid:
    "Impostazione dell'allarme su {{durationString}} da ora.",
  alarmDurationIos: "Impostazione dell'allarme su {{durationString}} da ora.",
  alarmFeedbackHeader:
    "L'utente invierà parte delle informazioni di questo allarme a scopo di debug. Tutti i dati personali, come il nome dell'allarme, le note o i partecipanti, non saranno condivisi.",
  privacyPolicyHeader:
    "Il rispetto per la vostra privacy è sempre presente nei nostri orologi. Proprio come voi, anche noi teniamo alla privacy, quindi vogliamo che sappiate che non condividiamo le vostre informazioni personali con nessuno, a meno che non sia necessario per l'installazione dell'applicazione o quando richiesto dalla legge. In ogni caso, non vendiamo mai le vostre informazioni. È così semplice!\n \nGli allarmi creati dall'utente sono visualizzabili solo su un dispositivo dell'utente e, se un allarme include altri partecipanti, sui dispositivi di questi ultimi.\n \nAccediamo alla rubrica del telefono/all'elenco dei contatti solo per riempire la scheda dei contatti in Galarm. Non memorizziamo tale elenco per nessun altro scopo. Ci teniamo che sia la vostra rubrica!\n \nAccediamo alla vostra fotocamera e alle vostre foto solo per consentirvi di impostare una buona immagine del profilo. Questa immagine viene visualizzata come avatar o come immagine del profilo di un gruppo che si modifica. Gli altri utenti di Galarm che sono membri degli allarmi da voi impostati potranno vedere questa foto.\n\nInviamo l'IP pubblico del vostro dispositivo e il suo ID univoco con tutte le richieste, ad esempio durante la richiesta e la verifica del codice durante il processo di registrazione. Queste informazioni non sono collegate all'account dell'utente e vengono utilizzate solo per determinare eventuali attività dannose.\n \nPer ulteriori dettagli su quali informazioni raccogliamo e come le utilizziamo, consultare l'informativa sulla privacy completa.",
  termsOfServiceHeader:
    "Utilizzando l'applicazione Galarm, l'utente accetta i nostri Termini di servizio, che regolano l'accesso e l'utilizzo di questa applicazione mobile e delle funzionalità e dei servizi che offre. \n\nIl motto di Galarm è semplice: aiutarvi a non perdere mai nessun evento!  Sia che si tratti di impostare allarmi personali per svegliarsi, essere puntuali agli appuntamenti e impostare promemoria per attività come l'assunzione tempestiva di farmaci, sia che si tratti di creare allarmi di gruppo per organizzare attività di gruppo come uscite al cinema, eventi sportivi e feste, Galarm consente di avere una sveglia sociale per ricordarsi a vicenda, seguire lo stato degli altri e comunicare.\n\nUtilizzate Galarm solo se accettate questi termini. Di seguito è riportato il link.",
  acknowledgedPersonalAlarm:
    'Avete segnato l\'allarme "Fatto" {{durationString}} fa.',
  skippedPersonalAlarm: "Hai saltato l'allarme {{durationString}} fa.",
  authenticatingWithServer: 'Collegamento...',
  selectParticipantsForPersonalAlarm:
    'Selezionare un gruppo o una serie di persone che fungano da backup per il vostro allarme personale.',
  selectParticipantsForGroupAlarm:
    'Selezionate un gruppo o un insieme di persone per la vostra attività di gruppo.',
  acknowledgedGroupAlarm:
    '{{acknowledgedAsString}} questo allarme {{durationString}} fa.',
  acknowledgedExpiredGroupAlarm:
    'Questo allarme è scaduto {{durationString}} fa. {{acknowledgedAsString}} questo allarme.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} ha segnato l\'allarme "Fatto" {{durationString}} fa.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Questo allarme è scaduto {{durationString}} fa. {{creatorName}} ha contrassegnato l\'allarme come "Fatto".',
  entityNotFound:
    'Oops! Non siamo riusciti a trovare il sito {{entityType}} selezionato. È possibile che lo stiamo ancora recuperando o che sia stato rimosso.',
  goBack: 'Torna indietro',
  uploadingImageToServer: "Caricamento dell'immagine sul server.",
  deletingImageFromServer: "Eliminazione dell'immagine dal server",
  errorUploadingImageToServer:
    "Errore nel caricamento dell'immagine sul server. Riprovare più tardi.",
  unableToRemoveGroup: 'Impossibile rimuovere il gruppo. {{error}}',
  addingGroup: 'Aggiunta di un gruppo.',
  editingGroup: 'Gruppo di redazione.',
  unableToAddGroup: 'Impossibile aggiungere un gruppo. {{error}}',
  unableToEditGroup: 'Impossibile modificare il gruppo. {{error}}',
  thanksForFeedback: 'Grazie per averci aiutato a migliorare.',
  licensesHeader:
    "Galarm include software concesso in licenza da terzi. Si prega di consultare la pagina delle licenze di terze parti per le informazioni relative all'attribuzione e alla licenza.",
  alarmAlreadyInSnooze:
    "La promemoria è disponibile all'indirizzo {{durationString}}.",
  carrierChargesMayApply: 'Potrebbero essere applicati i costi del vettore.',
  selectCascadingInterval:
    "Il primo partecipante verrà avvisato {{firstParticipantDurationString}} dopo l'orario dell'allarme, il secondo verrà avvisato {{secondParticipantDurationString}} dopo l'orario dell'allarme e così via se non si segna l'allarme come fatto.",
  reorderParticipantsScreenDescription:
    "I partecipanti saranno avvisati nell'ordine visualizzato.",
  noMembers: 'Nessun membro',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} partecipanti',
    one: '{{numActiveMembers}} attivo e {{numInactiveMembers}} partecipante inattivo',
    other:
      '{{numActiveMembers}} partecipanti attivi e {{numInactiveMembers}} inattivi'
  },
  groupAlarmWillRingAtSameTime:
    "Tutti i partecipanti saranno avvisati all'indirizzo {{timeString}}.",
  welcomeToGalarm: 'Benvenuti a Galarm',
  checkingConnection: 'Verifica della connessione',
  deletePastAlarmsTitle: 'Eliminare gli allarmi inattivi più vecchi di',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 allarmi)',
    one: '{{option}} (1 allarme)',
    other: '{{option}} ({{count}} allarmi)'
  },
  chooseSnoozeInterval: 'Promemoria in',
  personalAlarmDescription:
    "Utilizzare un allarme personale per svegliarsi, prendere le medicine o per qualsiasi altra attività. È possibile aggiungere dei partecipanti che vengono avvisati se si salta un allarme, in modo che possano promemoria. I partecipanti ricevono anche una notifica quando l'allarme è stato fatto.",
  groupAlarmDescription:
    "Utilizzate un allarme di gruppo per attività di gruppo come gite, feste o qualsiasi altro evento. L'allarme scatta allo stesso orario per tutti i partecipanti, che possono Confermare la loro partecipazione.",
  recipientAlarmDescription:
    "Create degli allarmi per un'altra persona (\"compagno\") per promemoria delle cose da fare. L'utente viene avvisato di promemoria per il compagno, nel caso in cui non riesca a rispondere all'allarme. Verrete anche avvisati quando il compagno avrà fatto l'allarme.\n  \nSi noti che gli allarmi vengono creati in base al proprio fuso orario.",
  whatsThis: "Che cos'è questo?",
  alarmCurrentlyDisabled: 'Questo allarme è attualmente disattivato',
  alarmCurrentlyRejected: 'Questo allarme è attualmente Rifiutato',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} sta digitando...',
    other: '{{currentlyTypingUsers}} stanno digitando...'
  },
  noSearchResultsFound: 'Nessun {{type}} trovato',
  noGroupsCreated: 'Non avete creato alcun gruppo.',
  noGroupsWeb: {
    one: 'Non avete creato alcun gruppo.',
    other:
      "Registratevi sull'app mobile per iniziare a creare gruppi e aggiungerli agli allarmi."
  },
  internalErrorDuringRegistration:
    "Si è verificato un errore interno durante la registrazione dell'utente. Riprovare dopo qualche minuto",
  selectRecipientAlarmInterval: {
    zero: "L'utente verrà avvisato nello stesso orario del destinatario.",
    one: 'Sarete avvisati da {{durationString}} dopo l\'orario dell\'allarme se il destinatario non lo segna come "Fatto".',
    other:
      'Sarete avvisati da {{durationString}} dopo l\'orario dell\'allarme se il destinatario non lo segna come "Fatto".'
  },
  selectRecipient: 'Selezionare il destinatario',
  acknowledgedExpiredRecipientAlarm:
    'Questo allarme è scaduto {{durationString}} fa. L\'allarme è stato contrassegnato come "Fatto".',
  skippedExpiredRecipientAlarm:
    "Questo allarme è scaduto {{durationString}}. L'allarme è stato salta.",
  expiredRecipientAlarm:
    'Questo allarme è scaduto {{durationString}} fa. Toccare "Fatto" per completare l\'allarme e notificarlo a {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'Hai segnato questo allarme come "Fatto" {{durationString}} fa.',
  skippedRecipientAlarm: 'Hai saltato questo allarme {{durationString}} fa.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Questo allarme è scaduto {{durationString}} fa. {{recipientName}} ha contrassegnato l\'allarme come "Fatto".',
  skippedExpiredRecipientAlarmForCreator:
    "Questo allarme è scaduto {{durationString}} fa. {{recipientName}} ha saltato l'allarme.",
  expiredRecipientAlarmForCreator:
    'Questo allarme è scaduto {{durationString}} fa. Si prega di promemoria {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} ha segnato l\'allarme "Fatto" {{durationString}} fa.',
  selectRecipientForRecipientAlarm: "Selezionare un destinatario dell'allarme.",
  selectAtLeastOneParticipantForGroupAlarm:
    "Aggiungere almeno un partecipante all'allarme",
  addAtLeastOneMemberToGroup: 'Aggiungere almeno un membro al gruppo',
  atTheSameTime: 'Allo stesso orario',
  myself: 'Io stesso',
  group: 'Gruppo',
  someoneElse: 'Qualcun altro',
  participantsAreNotified: 'I partecipanti vengono informati.',
  creatorIsNotified: '{{creatorName}} viene notificato.',
  membersAddedToGroup: {
    one: '{{memberNames}} viene aggiunto al gruppo {{groupName}}.',
    other: '{{memberNames}} sono aggiunti al gruppo {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} è stato rimosso dal gruppo {{groupName}}, il che potrebbe aver influito su alcuni degli allarmi.',
  youRemovedFromGroup:
    'Siete stati rimossi dal gruppo {{groupName}}, il che potrebbe aver influito su alcuni dei vostri allarmi.',
  memberLeftGroup:
    '{{memberName}} gruppo di sinistra {{groupName}} che potrebbe aver influito su alcuni degli allarmi.',
  youLeftGroup:
    'Avete lasciato il gruppo {{groupName}} che potrebbe aver influito su alcuni degli allarmi.',
  groupLeft: 'Hai lasciato il gruppo {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} si è trasferita in un altro fuso orario, il che potrebbe aver influito su alcuni degli allarmi.',
  memberDstChanged: {
    zero: "{{memberName}} ha smesso di osservare l'ora legale. Gli allarmi pertinenti sono stati aggiornati.",
    one: "{{memberName}} ha iniziato a osservare l'ora legale. Gli allarmi pertinenti sono stati aggiornati."
  },
  impactedAlarms: 'Allarmi colpiti:',
  alwaysRingParticipantAlarmsOn:
    "Gli allarmi degli altri suoneranno a meno che l'utente non li rifiuti.",
  alwaysRingParticipantAlarmsOff:
    "Gli allarmi degli altri non suonano se non vengono accettati dall'utente.",
  agreeToTermsOfServiceAndPrivacyPolicy:
    "Continuando, l'utente accetta i Termini di servizio e l'Informativa sulla privacy di Galarm.",
  deleteAccountWarningHeader:
    "Attenzione: Questa azione è irreversibile. L'eliminazione dell'account comporta:",
  deleteAccountDeleteAlarms: '- Cancellare tutti gli allarmi creati',
  deleteAccountDeleteRecipientAlarms:
    '- Cancellare tutti gli allarmi dei compagni di cui siete destinatari',
  deleteAccountRemoveFromGroups: '- Rimuovervi da tutti i gruppi',
  deleteAccountWarningFooter: {
    zero: "Se si cambia telefono, è sufficiente installare l'applicazione su quello nuovo e registrarsi con lo stesso numero. È possibile rimuovere l'app su questo telefono.\n\nUna volta eliminato l'account, non sarà possibile ripristinarlo.",
    one: "Se si cambia telefono, è sufficiente installare l'applicazione su quello nuovo e registrarsi con lo stesso numero. È possibile rimuovere l'app su questo telefono.\n\nUna volta eliminato l'account, non sarà possibile ripristinarlo. È possibile gestire l'abbonamento attivo a Galarm su Play Store.",
    other:
      "Se si cambia telefono, è sufficiente installare l'applicazione sul nuovo telefono ed eliminare quella su questo.\n\nUna volta eliminato l'account, non sarà possibile ripristinarlo. È possibile gestire l'abbonamento attivo a Galarm su App Store."
  },
  typeConfirmationStringToDeleteAccount:
    'Digitare {{confirmationString}} per cancellare il proprio account',
  unableToDeleteAccount: "Impossibile eliminare l'account. {{error}}",
  impactedGroups: 'Gruppi colpiti:',
  memberDeletedAccount:
    '{{memberName}} ha cancellato il proprio account, il che potrebbe avere un impatto su alcuni degli allarmi e dei gruppi.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    'Confermare il codice del Paese e inserire il numero di telefono utilizzato per la registrazione.',
  requiredPermissionsDisabledWarningIos:
    'Le notifiche sono disattivate. Gli allarmi non suonano. Toccare per risolvere il problema.',
  requiredPermissionsDisabledWarningAndroid:
    'Galarm non ha i permessi necessari per suonare gli allarmi. Toccare per risolvere il problema.',
  notificationChannelPopupDisabledWarningAndroid:
    'Le notifiche di allarme non appaiono su schermo. Toccare per risolvere il problema.',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'Le notifiche di allarme non vengono visualizzate su una schermata di blocco. Toccare per risolvere il problema.',
  why: 'Perché',
  how: 'Come',
  registrationRequired: 'Registrazione richiesta',
  phoneNumberRequired: 'Numero di telefono richiesto',
  fewSeconds: 'pochi secondi',
  lessThanMinute: 'meno di un minuto',
  updateUnsupportedVersion:
    "Per continuare a utilizzare Galarm è necessaria una versione aggiornata dell'app.",
  update: 'Aggiornamento',
  back: 'Indietro',
  unableToMarkAlarmDone:
    "In questo orario non è possibile contrassegnare l'allarme come fatto. Riprovare tra qualche istante.",
  unableToMarkAlarmUndone:
    "In questo orario non è possibile contrassegnare l'allarme come annullato. Riprovare tra qualche istante.",
  unableToMarkAlarmUnskipped:
    "In questo orario non è possibile contrassegnare l'allarme come non selezionato. Riprovare tra qualche istante.",
  unableToSkipAlarm:
    "In questo orario non è possibile Salta l'allarme. Riprovare tra qualche istante.",
  unableToSetGroupAlarmResponse:
    "Impossibile {{response}} l'allarme del gruppo in questo orario. Riprovare tra qualche istante.",
  unableToDeleteAlarm:
    "In questo orario non è possibile eliminare l'allarme. Riprovare tra qualche istante.",
  unableToSetAlarmResponse:
    'In questo orario non è possibile impostare la risposta. Riprovare tra qualche istante.',
  unableToSnoozeAlarm: "Impossibile Rimandare l'allarme.",
  unableToStopSnoozeForAlarm:
    "Impossibile interrompere lo Rimandare dell'allarme.",
  unableToUpdateParticipantAlarmRingerSettings:
    'Impossibile aggiornare le impostazioni della suoneria in questo orario. Riprovare tra qualche istante.',
  updateParticipantPreReminderDuration:
    'In questo orario non è possibile aggiornare la durata del preavviso. Riprovare tra qualche istante.',
  unableToUpdateAlarmRingerSettings:
    'Impossibile aggiornare le impostazioni della suoneria in questo orario. Riprovare tra qualche istante.',
  unableToUpdateAlarmPreReminderDuration:
    'In questo orario non è possibile aggiornare la durata della promemoria. Riprovare tra qualche istante.',
  retrievingAlarmsFromServer: 'Recupero degli allarmi dal server...',
  startApp: 'Iniziare >',
  phoneNumberRequiredGroupListHeaderText:
    'Il gruppo è una raccolta di contatti Galarm che consente di creare rapidamente degli allarmi con essi.\n\nRegistratevi utilizzando il vostro numero di telefono per trovare i vostri contatti che utilizzano Galarm. È possibile aggiungerli ai gruppi.',
  phoneNumberRequiredBuddyAlarmHeaderText:
    "Compagno alarm può essere utilizzato per impostare degli allarmi per amici e familiari, in modo da promemoria per le cose da fare.\n\nPer utilizzare questa e molte altre interessanti funzioni dell'applicazione, è necessario registrarsi utilizzando il proprio numero di telefono.",
  phoneNumberRequiredContactListHeaderText:
    'I contatti sono persone che possono essere aggiunte come partecipanti agli allarmi.\n\nPer visualizzare i contatti che utilizzano Galarm, registrarsi utilizzando il proprio numero di telefono. È possibile aggiungerli come partecipanti agli allarmi.',
  phoneNumberRequiredGroupAlarmHeaderText:
    "L'allarme di gruppo può essere utilizzato per impostare una promemoria per un gruppo di persone.\n\nPer utilizzare questa e molte altre interessanti funzioni dell'applicazione, registratevi utilizzando il vostro numero di telefono.",
  phoneNumberRequiredParticipantsHeaderText:
    "I partecipanti fungono da backup a questo allarme. Vengono promemoria in caso di mancato allarme.\n\nPer utilizzare questa e molte altre interessanti funzioni dell'applicazione, registratevi utilizzando il vostro numero di telefono.",
  youHaveNotRegistered:
    'Al momento non è stato eseguito il backup degli allarmi.\n\nRegistratevi utilizzando il vostro numero di telefono. Una volta effettuata la registrazione, il backup degli allarmi viene eseguito su cloud. Ciò consente di ripristinare gli allarmi e le altre impostazioni quando si cambia telefono.\n',
  registerNow: 'Registrati ora',
  hoursAndMinutes: 'Ore e minuti',
  days: 'Giorni',
  weeks: 'Settimane',
  months: 'Mesi',
  years: 'Anni',
  daysOfWeek: 'Settimanale (giorni selezionati)',
  monthly: 'Mensile',
  yearly: 'Annuale',
  everyDay: 'Giornaliero',
  selectDaysOfWeek: 'Selezionare i giorni della settimana',
  alarmRingMessageForSpecificDates:
    'Questo allarme suona solo nei mesi che hanno almeno {{dayOfMonth}} giorni. Volete continuare?',
  alarmRingMessageForSpecificYear:
    'Questo allarme suona solo negli anni bisestili. Volete continuare?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Questo allarme suona solo su mesi che hanno {{weekNumberOfDayInMonth}} {{dayName}} s. Si desidera continuare? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    "La ripetizione dell'allarme è stata aggiornata su questa nuova data.",
  inviteFriendsToInstallGalarm:
    'I contatti sono persone che possono essere aggiunte come partecipanti ai vostri allarmi. È possibile invitare i contatti:\n\n1. Dalla rubrica\n\n2. Fornendo manualmente il nome e il numero di telefono.\n\nToccare Aggiorna in alto a sinistra per ricaricare i contatti Galarm dopo la registrazione.',
  whyCreateNewGroup:
    'I gruppi sono gruppi di contatti che possono essere aggiunti come partecipanti agli allarmi.\n  \nNon è stato creato alcun gruppo.',
  noNotifications: 'Non ci sono nuove notifiche.',
  createGroup: 'Crea gruppo',
  galarmRequiresAccessToContactsPermission:
    "Galarm richiede l'autorizzazione ad accedere ai vostri contatti. Queste informazioni vengono inviate ai suoi server per identificare i contatti che utilizzano Galarm. Questi utenti possono essere aggiunti come partecipanti ai vostri allarmi.",
  galarmRequiresAccessToAudioFilesPermission:
    "Alcuni allarmi utilizzano la musica del telefono come suoneria. Si prega di fornire l'autorizzazione ad accedere ai file audio su un dispositivo per far suonare gli allarmi con la suoneria scelta.",
  galarmRequiresAccessToContactsSelectScreen:
    "Galarm non ha il permesso di accedere ai contatti. Consentire l'accesso ai contatti se si desidera utilizzare questa funzione.",
  allow: 'Consentire',
  deny: 'Negare',
  galarmRequiresAccessToContacts:
    'I contatti sono persone che possono essere aggiunte come partecipanti ai vostri allarmi. I contatti possono essere aggiunti da:\n\n1. Importazione automatica dai contatti del telefono. Toccare "Consenti accesso" e abilitare "Contatti".\n\n2. Aggiungendo manualmente il nome e il numero di telefono. Toccare "Aggiungi contatto" per iniziare.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarm richiede l\'autorizzazione "Allarmi e promemoria" per poter suonare gli allarmi esattamente agli orari specificati. Abilitatela su una schermata successiva.',
  accessToContactsPermissionRequiredAndroid:
    'Galarm non ha il permesso di accedere ai contatti del telefono.\n\nQuando si consente l\'accesso, le persone dei contatti del telefono che utilizzano Galarm appariranno come contatti di Galarm. Queste persone possono essere aggiunte come partecipanti agli allarmi. Toccare "Consenti accesso", selezionare "Autorizzazione" e abilitare "Contatti".\n\nÈ ancora possibile aggiungere manualmente i contatti a Galarm.',
  accessToContactsPermissionRequiredIos:
    'Galarm non ha il permesso di accedere ai contatti del telefono.\n\nQuando si consente l\'accesso, le persone dei contatti del telefono che utilizzano Galarm appariranno come contatti di Galarm. Queste persone possono essere aggiunte come partecipanti agli allarmi. Toccare "Consenti accesso" e abilitare "Contatti".\n\nÈ ancora possibile aggiungere manualmente i contatti a Galarm.',
  giveAccess: "Consentire l'accesso",
  enterValidMobileNumber:
    'Per registrarsi, inserire un numero di telefono valido.',
  proceedAnyway: 'Procedere comunque',
  checkInvalidPhoneNumber:
    'Pensiamo che sia stato inserito un numero non valido come {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Codice Paese non valido specificato come {{countryCode}}. Se è presente più di un codice paese, modificare il campo per mantenere il codice paese applicabile.',
  editNumber: 'Modifica numero',
  editCountryCode: 'Modifica del codice paese',
  sendingCodeOnCall: 'Ti chiamo con il codice.',
  sendCodeOnCall: 'Chiamatemi con il codice',
  registrationLandingScreenRegisterText:
    'Toccare Continua per registrarsi utilizzando il proprio numero di telefono.\n\nCon la registrazione:\n1) sarà possibile aggiungere partecipanti ai propri allarmi e chattare con loro.\n2) I vostri allarmi saranno salvati su cloud, in modo che quando cambierete telefono, i vostri allarmi appariranno sul nuovo telefono.',
  continue: 'Continua',
  registerLater: 'Salta la registrazione',
  cancelRegistration: 'Annullamento della registrazione',
  welcome: 'Benvenuti',
  haventReceivedCodeYet: {
    one: 'Non avete ancora ricevuto il codice? Potete richiedere nuovamente il codice in 1 secondo.',
    other:
      'Non avete ancora ricevuto il codice? Potete richiedere nuovamente il codice su {{count}} secondi.'
  },
  storagePermissionDenied:
    "Non è possibile accedere ai file musicali perché l'autorizzazione alla memorizzazione è negata.",
  havingTroubleRegisteringMessage:
    "Galarm non è in grado di verificare il codice dopo vari tentativi.\n\nVolete saltare la registrazione e iniziare a usare l'app?",
  autoDetectVerificationCode:
    'Per semplificare la registrazione, Galarm può rilevare automaticamente il codice di verifica se si consente a Galarm di visualizzare i messaggi SMS.',
  notNow: 'Non ora',
  verifyNumberText:
    'Invieremo un SMS con il codice di verifica a:\n\n{{formattedMobileNumber}}',
  addToContacts: 'Aggiungi ai contatti',
  todayString: 'Oggi',
  tomorrowString: 'Domani',
  backups: 'Backup',
  acintyoTrademark:
    '© 2024 Acintyo, Inc. Tutti i diritti riservati.\nGalarm è un marchio registrato di Acintyo, Inc.\nProtetto dai brevetti statunitensi 10382613, 10867286.\nAltri brevetti in corso di registrazione.',
  acintyoTrademarkWeb:
    '© 2024 Acintyo, Inc. Tutti i diritti riservati. Galarm è un marchio registrato di Acintyo, Inc.\nProtetto dai brevetti statunitensi 10382613, 10867286. Altri brevetti in corso di registrazione.',
  acintyoTrademarkEnterprise:
    '© 2024 Acintyo, Inc.\nTutti i diritti riservati.',
  markAlarmDone: "Contrassegnare l'allarme come Fatto",
  skipAlarm: 'Salta allarme',
  confirmGroupAlarm: 'Confermare la partecipazione',
  declineGroupAlarm: 'Declino della partecipazione',
  snoozeAlarm: "Rimandare l'allarme",
  deleteAlarmQuickAction: "Cancellare l'allarme",
  disableAlarm: "Disattivare l'allarme",
  confirmDisableAlarm: "Disattivare l'allarme?",
  saveToCalendar: 'Esportazione in un calendario',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    "Confermate in anticipo l'allarme del gruppo.  Volete ancora la promemoria all'orario dell'allarme?",
  updateNow: 'Aggiornare ora',
  nParticipants: {
    one: '1 altro partecipante',
    other: '{{count}} altri partecipanti'
  },
  details: 'Dettagli',
  changeParanthesis: '(modifica)',
  loadMoreHistory: 'Vedi altro',
  alarmSummaryForHistory:
    'Risposte precedenti per {{alarmName}}.\nRipetizioni {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Risposte passate per {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Ripetizioni {{alarmRepetitionString}}',
  dontSeeCompleteHistory: 'Non vedete la storia completa?',
  dontSeeCompleteHistoryExplanation:
    "La cronologia degli allarmi viene registrata a partire dall'orario di creazione dell'allarme. Per ogni allarme viene mantenuta una cronologia limitata, a seconda della ripetizione dell'allarme. Ad esempio, se l'allarme si ripete ogni giorno, viene mantenuta una cronologia di 30 giorni.\n\nModificando l'orario o la ripetizione dell'allarme, la cronologia viene ripristinata.",
  noActionAvailableForAlarmOccurrence:
    'Non sono disponibili azioni per questo evento.',
  enterEmailIdForFeedback:
    'Utilizzato per rispondere e condividere gli aggiornamenti',
  noPreviousOccurrences: "Non c'è ancora una cronologia degli allarmi",
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: "Non c'è ancora una cronologia degli allarmi",
    one: "{{missedAlarmOccurrences}} non è riuscito ad arrivare all'ultimo {{count}}",
    other:
      "{{missedAlarmOccurrences}} non è riuscito ad arrivare all'ultimo {{count}}"
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% volume, {{ringtoneName}}',
    one: "{{volume}}% volume, {{ringtoneName}}, Titolo dell'annuncio"
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Critico, {{volume}}% di volume, {{ringtoneName}}'
  },
  previousOccurrences: "Storia dell'allarme",
  retrieving: 'Recupero...',
  startTimeOfDay: 'Inizia a',
  endTimeOfDay: 'Termina a',
  ringEveryHours: 'Suona ogni',
  everyNHours: 'Orario',
  everyMHoursNMinutes: 'Ore e minuti',
  selectHourlyRepetition: 'Selezionare la ripetizione oraria',
  everyNHoursRepetitionString: {
    one: 'Ogni ora da {{startTimeString}} a {{endTimeString}} ogni {{daysString}}',
    other:
      'Ogni {{count}} ore da {{startTimeString}} a {{endTimeString}} ogni {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Ogni {{hoursAndMinutes}} da {{startTimeString}} a {{endTimeString}} ogni {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Ogni {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'Ogni ora da {{startTimeString}} a {{endTimeString}}',
    other: 'Ogni {{count}} ore da {{startTimeString}} a {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Ogni {{hoursAndMinutes}} da {{startTimeString}} a {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    'Ogni {{hoursAndMinutes}} fino a {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'Ogni ora fino a {{endTimeString}}',
    other: 'Ogni {{count}} ore fino a {{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'Tutte le date dispari',
    one: 'Date dispari ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Tutte le date con numero pari',
    one: 'Date pari ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: "L'allarme suona nelle date dispari che cadono su {{daysString}} all'orario specificato.",
    other: "L'allarme suona in tutte le date dispari all'orario specificato."
  },
  evenNumberedRepetitionPreviewString: {
    one: "L'allarme suona nelle date pari che cadono su {{daysString}} all'orario specificato.",
    other: "L'allarme suona in tutte le date pari all'orario specificato."
  },
  hourlyRepetitionPreviewString: {
    one: 'Questo allarme suona una volta ogni {{daysString}} alle ore:',
    other: 'Questo allarme suona {{count}} volte ogni {{daysString}} alle ore:'
  },
  daysRepetitionPreviewString: {
    one: 'Questo allarme suona ogni giorno.',
    other: 'Questo allarme suona ogni {{count}} giorni.'
  },
  weeksRepetitionPreviewString: {
    one: 'Questo allarme suona ogni settimana.',
    other: 'Questo allarme suona ogni {{count}} settimane.'
  },
  everyNDays: { one: 'Ogni giorno', other: 'Ogni {{count}} giorni' },
  everyNWeeks: { one: 'Ogni settimana', other: 'Ogni {{count}} settimane' },
  everyNMonths: { one: 'Ogni mese', other: 'Ogni {{count}} mesi' },
  everyNYears: { one: 'Ogni anno', other: 'Ogni {{count}} anni' },
  cantSetEndTime: "Non è possibile impostare l'orario di fine",
  endTimeShouldBeGreater:
    "L'orario di fine deve essere maggiore dell'orario di inizio.",
  cantSetStartTime: "Impossibile impostare l'orario d'inizio",
  startTimeShouldBeLess:
    "L'orario di inizio deve essere inferiore a quello di fine.",
  everyDaySmall: 'giorno',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Abbiamo rilevato che avete cambiato fuso orario. Stiamo regolando gli allarmi.',
  ringOnDays: 'Su suona',
  yourAlarmsMayNotRing: 'Gli allarmi possono non suonare',
  yourAlarmsMayNotRingTapToFix:
    'Gli allarmi potrebbero non suonare. Toccare per risolvere il problema.',
  xiaomiSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e consentire "Galarm" in modo che gli allarmi suonino sempre. \n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e abilitare "Consenti avvio automatico".\n\n - Toccare il pulsante "Applicazioni recenti" sul telefono, scorrere l\'icona dell\'app Galarm verso il basso e toccare "Blocca".  \n\nPer saperne di più, visitate www.galarmapp.com/setup-oppo',
  oppo8AndLowerSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e abilitare "Consenti avvio automatico".\n\n - Toccare il pulsante "Applicazioni recenti" sul telefono, scorrere l\'icona dell\'app Galarm verso il basso e toccare "Blocca".\n\nPer saperne di più, visitate www.galarmapp.com/setup-oppo-8-and-lower',
  vivo9And10SetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e consentire "Galarm".\n\n - Toccare "Uso della batteria", selezionare "Consumo energetico in background elevato" e consentire "Galarm".\n\nPer saperne di più, visitate www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n- Toccare "Abilita avvio automatico" e consentire "Galarm".\n\n- Toccare "Uso della batteria", selezionare "Consumo energetico in background elevato" e consentire "Galarm".\n\nPer saperne di più, visitate www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Seguite queste istruzioni per far sì che gli allarmi suonino sempre:\n\n- Aprite "Impostazioni del telefono > Applicazioni > Accesso alle applicazioni speciali > Avvio automatico" e attivate l\'avvio automatico per Galarm.\n\n- Aprite "Impostazioni telefono > Batteria > Gestione consumo energetico in background > Galarm" e scegliete "Non limitare il consumo energetico in background".\n\nPer saperne di più, visitate www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    'Seguite queste istruzioni per far sì che gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e consentire "Galarm".\n\n - Toccare "Uso della batteria", selezionare "Consumo energetico in background elevato" e consentire "Galarm".\n\nPer saperne di più, visitate www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Applicazioni protette" e consentire "Galarm".\n\nPer saperne di più, visitate www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Gestisci avvio app" e disattivare "Galarm" per "Gestisci manualmente". Nella richiesta che segue, assicurarsi che "Avvio automatico" e "Esegui in background" siano abilitati.\n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    'Seguite le istruzioni su questo link in modo che gli allarmi suonino sempre: https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e fare clic su "Gestione avvio automatico". Attivare "Galarm" in "Gestione avvio automatico".\n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Abilita avvio automatico" e consentire "Galarm".\n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Applicazioni riservate" e disabilitare "Galarm".\n\nPer saperne di più, visitate www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare il pulsante "Applicazioni recenti" sul telefono, individuare l\'applicazione Galarm e toccare l\'icona "Blocca" nell\'angolo in alto a destra.\n  \nPer saperne di più, visitate il sito https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Seguite queste istruzioni affinché gli allarmi suonino sempre:\n\n - Toccare "Applicazioni non monitorate" e consentire "Galarm".\n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Seguite le istruzioni su questo link in modo che gli allarmi suonino sempre: https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Seguite le istruzioni su questo link in modo che gli allarmi suonino sempre: https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Per impostazione predefinita, Samsung mostra notifiche "brevi" che scompaiono rapidamente. Seguite queste istruzioni per visualizzare le notifiche di allarme "dettagliate".\n\n - Aprite le impostazioni del telefono e scegliete "Notifiche".\n - Scegliete lo stile di notifica "Dettagliato".\n  \nPer saperne di più, visitate www.galarmapp.com/setup-samsung-android11-and-above',
  iosSetupInstructions:
    'Per impostazione predefinita, lo stile del banner di iOS per le notifiche è impostato su "Temporaneo", che fa scomparire rapidamente le notifiche. Seguite queste istruzioni per impostare lo stile del banner su "Persistente".\n\n- Aprire le impostazioni del telefono e scorrere fino a Galarm\n- Scegliere Notifiche e impostare lo stile del banner su Persistente.\n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    'Per impostazione predefinita, lo stile del banner di iOS per le notifiche è impostato su "Temporaneo", che fa scomparire rapidamente le notifiche. Seguite queste istruzioni per impostare lo stile del banner su "Persistente".\n\n- Aprire le impostazioni del telefono e scorrere fino a Galarm\n- Scegliere Notifiche e impostare lo stile del banner su Persistente.\n\nPer saperne di più, visitate il sito www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: 'Impostazione dello stile delle notifiche',
  setNotificationsStyleToDetailed:
    'Impostare lo stile delle notifiche su "Dettagliato".',
  setBannerStyle: 'Impostazione dello stile del banner',
  setBannerStyleToPersistent: 'Impostare lo stile del banner su "Persistente".',
  selectAtleastOneDay: "Selezionare i giorni in cui l'allarme deve suonare.",
  selectAtLeastOneHourOrMinute:
    'Le ore o i minuti devono essere un valore non nullo.',
  hourlyAlarmDefaultStartTimeSetAlert:
    "L'orario di inizio dell'allarme è stato impostato alle 8 del mattino.",
  batteryUsage: 'Utilizzo della batteria',
  dontShowAgain: 'Non presentarsi più',
  alarmWillRingEveryHourTillSeen:
    "L'allarme si sonnecchia ogni ora fino a quando non si compie un'azione.",
  unknown: 'Sconosciuto',
  alarmMarkedDone: '{{name}} ha contrassegnato l\'allarme come "Fatto".',
  alarmMarkedSkipped: "{{name}} ha saltato l'allarme.",
  alarmIsMarkedDone: '{{alarmName}} è contrassegnato come fatto.',
  alarmIsSkipped: '{{alarmName}} viene salta.',
  alarmIsConfirmed: '{{alarmName}} Confermato.',
  alarmIsDeclined: '{{alarmName}} viene rifiutato.',
  alarmIsSnoozed: '{{alarmName}} viene Rimandare.',
  alarmIsAccepted: '{{alarmName}} viene Accettato.',
  alarmIsRejected: '{{alarmName}} viene Rifiutato.',
  newContactInfo:
    'Indicare nome e numero di telefono del contatto.\n\nSolo gli utenti registrati possono essere aggiunti come contatti. Se l\'utente non è un utente registrato di Galarm, è possibile invitarlo utilizzando "Impostazioni > Condividi questa app". È possibile aggiungerlo come contatto dopo la registrazione utilizzando il suo numero di telefono. ',
  cantEditContactMobileNumber:
    'Non è possibile modificare il numero di telefono. Eliminare il contatto e aggiungerlo di nuovo se si desidera modificare il numero.',
  phoneContactsNotSyncedPermissionDenied:
    'Galarm non ha il permesso di accedere ai contatti del telefono. I contatti del telefono non verranno sincronizzati.',
  inviteContactMessage:
    "Ciao {{contactName}}, voglio includerti nei miei allarmi e promemoria usando Galarm. Scarica l'applicazione da {{downloadLink}}. Registratevi utilizzando questo numero e iniziate a condividere gli allarmi.",
  noRepeat: 'Non si ripete',
  deepLinkNoLongerValidTitle: 'Ops!',
  deepLinkNoLongerValidMessage:
    "L'allarme non è stato creato. Potrebbe trattarsi di un vecchio collegamento o di un collegamento non valido.",
  inviteContact: 'Invita il contatto',
  fromPhoneBook: 'Dalla rubrica',
  addFromPhoneBook: 'Invito dalla rubrica',
  manually: 'Manualmente',
  addManualContact: 'Invito manuale',
  noOtherContactsFound: 'Tutti i contatti della rubrica utilizzano Galarm.',
  cantAddPhoneContactPermissionDenied:
    "Galarm non ha il permesso di accedere ai contatti del telefono. Consentire l'accesso ai contatti.",
  alarmWillRingWithRandomRingtone:
    "Per l'allarme verrà scelta una suoneria casuale.",
  alarmWillRingWithRandomRingtoneGlobal:
    'Per gli allarmi verrà scelta una suoneria casuale.',
  createdAlarmFromDeepLinkTitle: "L'impostazione è perfetta!",
  createdAlarmFromDeepLinkMessage:
    'La promemoria di "{{alarmName}}" si trova su {{alarmTime}}. {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Vi sarà promemoria di "{{alarmName}}". {{repetitionString}}',
    one: 'Vi sarà promemoria di "{{alarmName}}" {{repetitionString}} a {{alarmTime}} a partire da {{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'Cancellare la categoria?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: "1 allarme appartiene a questa categoria. L'eliminazione di questa categoria comporta il ripristino della categoria su quell'allarme.",
    other:
      "{{count}} gli allarmi che appartengono a questa categoria. L'eliminazione di questa categoria comporta il ripristino della categoria su questi allarmi."
  },
  updateReadyToInstallAlertTitle: "Installare l'aggiornamento?",
  updateReadyToInstallAlertMessage:
    "Gli aggiornamenti sono pronti per essere installati. Volete installare subito l'aggiornamento? ",
  hideCategoryAlertTitle: 'Nascondere la categoria?',
  hideCategoryAlertMessage: {
    zero: 'Non sarà possibile utilizzare questa categoria in futuro.',
    one: "1 allarme appartiene a questa categoria. Se si nasconde questa categoria, si ripristina la categoria su quell'allarme.\n  \nNon sarà possibile utilizzare questa categoria in futuro.",
    other:
      '{{count}} Gli allarmi appartengono a questa categoria. Se si nasconde questa categoria, si ripristina la categoria su questi allarmi.\n  \nIn futuro non sarà più possibile utilizzare questa categoria.'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 allarme appartiene a questa categoria. Volete eliminarlo?\n\nSi noti che gli allarmi dei partecipanti non vengono eliminati.',
    other:
      '{{count}} Gli allarmi appartengono a questa categoria. Volete eliminarli?\n\nSi noti che gli allarmi dei partecipanti non vengono eliminati.'
  },
  enterNameForGroup: 'Immettere un nome per il gruppo.',
  enterMobileNumber: 'Inserire il numero di telefono',
  enterNotesForAlarm: "Inserire le note per l'allarme",
  enterUserName: 'Inserire il proprio nome',
  enterEmailId: 'Opzionale',
  enterFeedback: 'Come possiamo migliorare?',
  describeProblem: 'Descrivere il problema',
  invited: 'Invitato',
  resend: 'Reinvio',
  addContact: 'Aggiungi contatto',
  inviteAgain: 'Invitare di nuovo',
  contactNotRegistered:
    '{{contactName}} non ha ancora installato Galarm. Sarà possibile aggiungerli agli allarmi una volta installati e registrati con il loro numero di telefono.',
  registration: 'Registrazione',
  verify: 'Verifica',
  profile: 'Profilo',
  profileImage: 'Immagine del profilo',
  addProfileImage: "Aggiungere l'immagine del profilo",
  groupDetails: 'Dettagli del gruppo',
  newGroup: 'Nuovo gruppo',
  uploadImage: 'Cambia immagine',
  removeImage: 'Rimuovi immagine',
  selectParticipants: 'Seleziona i partecipanti',
  selectMembers: 'Seleziona i membri',
  selectTimezone: 'Selezionare il fuso orario',
  searchForATimezone: 'Ricerca di un fuso orario',
  timezone: 'Fuso orario',
  deviceTimezone: 'Fuso orario del dispositivo',
  specifyTimezoneForAlarm: 'Specificare il fuso orario per gli allarmi',
  editName: 'Modifica nome',
  groupImage: 'Immagine di gruppo',
  addGroupImage: "Aggiungere l'immagine del gruppo",
  selectAlarmType: 'Creare un allarme per',
  contactDetails: 'Dettagli del contatto',
  commonAlarms: 'Allarmi',
  commonGroups: 'Gruppi',
  groupAlarmsTitle: 'Allarmi',
  selectRingtone: 'Selezionare la suoneria',
  selectMusic: 'Seleziona la musica',
  select: 'Selezionare',
  personalAlarm: 'Allarme personale',
  groupAlarm: 'Allarme di gruppo',
  newPersonalAlarm: 'Nuovo allarme personale',
  newGroupAlarm: 'Nuovo allarme di gruppo',
  editPersonalAlarm: "Modifica dell'allarme personale",
  editGroupAlarm: 'Modifica allarme gruppo',
  rearrangeParticipants: 'Riorganizzazione dei partecipanti',
  alarmConversation: 'Chat di allarme',
  timezoneMismatchDetected:
    "Qualcosa ci dice che vi trovate in un fuso orario diverso rispetto all'ultima volta che avete usato Galarm.\n\nRiavviate Galarm in modo che possa adattarsi al nuovo fuso orario.",
  deviceRestartRequired: "Richiesto il riavvio dell'app",
  disabledAlarm: 'Allarme disabilitato {{name}}',
  newContact: 'Nuovo contatto',
  contactName: 'Nome del contatto',
  contactMobileNumber: 'Numero di telefono del contatto',
  enterNameForContact: 'Inserire il nome del contatto',
  pickCountryForContact: 'Paese del contatto',
  enterMobileNumberForContact: 'Inserire il numero di telefono del contatto',
  specifyNameForContact: 'Il nome del contatto è obbligatorio',
  specifyValidPhoneNumberForContact:
    'È necessario un numero di telefono valido',
  addingContact: 'Aggiunta di un contatto',
  deletingContact: 'Rimozione del contatto',
  editingContact: 'Modifica del contatto',
  pending: 'In attesa',
  deleteContact: 'Rimuovi contatto',
  galarmContact: 'Aggiunto manualmente',
  phoneContact: 'Aggiunti dai contatti del telefono',
  confirmDeleteContactTitle: 'Rimuovere {{name}}?',
  confirmDeleteContact: 'Non sarà possibile aggiungerli agli allarmi.',
  contactAdded:
    'Ottimo! Ora potete aggiungere {{name}} come partecipante ai vostri allarmi.',
  contactInvited:
    "All'indirizzo {{name}} è stato inviato un messaggio di testo che invita a utilizzare Galarm.",
  changeSnoozeDuration: 'Modifica Durata',
  durationOfEvent: "Durata dell'evento",
  upgradeToPremium: 'Aggiornamento a Premium',
  premium: 'Premium',
  alarmLimitReached: 'Limite di allarme raggiunto',
  unableToConnectToGooglePlay:
    "Impossibile connettersi a Google Play. Assicuratevi di aver effettuato l'accesso al vostro account Google.",
  cantLoadVideo: 'Impossibile caricare il video',
  cantUpgradeToPremium: "Impossibile effettuare l'aggiornamento a Premium",
  unableToConnectToIapAndroid:
    "Impossibile connettersi al Play Store. Aprire l'app Play Store e assicurarsi di aver effettuato l'accesso.",
  unableToConnectToIapIos:
    "Impossibile connettersi all'App Store. Aprire l'applicazione App Store e assicurarsi di aver effettuato l'accesso.",
  premiumFeaturesIos:
    'Come utente premium, si ottiene un limite maggiore di {{maxLimit}} allarmi simultanei.\n\nIl vostro acquisto ci aiuta inoltre a sviluppare ulteriori funzioni.',
  premiumFeaturesAndroid:
    "Creare un numero illimitato di allarmi con l'aggiornamento a premium.\n\nIl vostro acquisto ci aiuta anche a sviluppare ulteriori funzioni.",
  upgrade: 'Aggiornamento',
  rvInternalError:
    'Non siamo riusciti a caricare il video a causa di un errore interno. Tuttavia, abbiamo aumentato di 1 unità il limite di allarme.',
  rvNetworkError:
    'Assicurarsi di avere una buona connessione a Internet e riprovare.',
  rvNotFoundError:
    'In questo orario non siamo riusciti a trovare un video adatto. Tuttavia, abbiamo aumentato di 1 unità il limite di allarme.',
  dontRemindMe: 'Non me lo promemoria',
  sameTimeAsBuddy: 'Lo stesso orario del compagno',
  noAlertForBuddyAlarm:
    'Avete scelto di non essere avvisati per questo allarme.',
  noAlertRecipientIntervalDescription: 'Non sarete avvisati.',
  chooseHours: 'Scegliere le ore',
  chooseMins: 'Scegliere i minuti',
  changeHours: 'Modifica delle ore',
  moreOptions: 'Altre opzioni...',
  set: 'Impostazione',
  chooseNumberOfDays: 'Inserire il numero di giorni',
  chooseNumberOfWeeks: 'Inserire il numero di settimane',
  numberOfDaysShouldBeAtLeastOne: 'Il numero di giorni deve essere almeno 1',
  numberOfWeeksShouldBeAtLeastOne:
    'Il numero di settimane deve essere almeno 1',
  numberOfMonthsShouldBeAtLeastOne: 'Il numero di mesi deve essere almeno 1',
  numberOfYearsShouldBeAtLeastOne: 'Il numero di anni deve essere almeno 1',
  frequency: 'Frequenza',
  configuration: 'Configurazione',
  endDate: 'Data di fine allarme',
  noEndDate: 'Nessuna data di scadenza',
  alarmEndDateSetOnAlarm:
    'La data di fine allarme è impostata su {{endDateString}}.',
  noAlarmDateSetForAlarm:
    'Per questo allarme non è stata impostata alcuna data di fine.',
  noOccurrenceForThisAlarmAsPerEndDate:
    "La data di fine non consente alcuna occorrenza dell'allarme.",
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'La data di fine allarme deve essere successiva alla data di inizio allarme.',
  aWeek: 'Una settimana',
  aMonth: 'Un mese',
  allInactiveAlarms: 'Tutti gli allarmi inattivi',
  chooseAlarmEndDate: "Scegliere la data di fine dell'allarme",
  next: 'Il prossimo',
  next7Days: '7 giorni da oggi',
  next30Days: '30 giorni da oggi',
  next12Months: '12 mesi da oggi',
  next10Years: '10 anni da oggi',
  chooseDate: 'Scegli una data...',
  learnMore: 'Per saperne di più',
  repeatEvery: 'Si ripete ogni volta che',
  repeatEveryDays: 'giorno/i',
  repeatEveryWeeks: 'settimana/e',
  repeatEveryMonths: 'mese/i',
  repeatEveryYears: 'anno/i',
  autoSnooze: 'Rimandare automaticamente gli allarmi',
  autoSnoozePerAlarm: "Rimandare automaticamente l'allarme",
  unableToUploadGroupAvatar:
    "Non è possibile caricare l'immagine del gruppo. Riprovare più tardi",
  addNewMembersToExistingAlarms:
    'Con questo gruppo si hanno i seguenti allarmi:\n\n{{alarmNames}}\n    \nSi desidera aggiungere i nuovi membri a questi allarmi?',
  removeGroupMemberAlert:
    'La rimozione del membro avrà un impatto sui seguenti allarmi:\n\n{{alarmNames}}\n\nSi desidera ancora rimuovere il membro?',
  leaveGroupAlert:
    "L'uscita dal gruppo avrà un impatto sui seguenti allarmi:\n\n{{alarmNames}}\n\nVolete ancora lasciare il gruppo?",
  updateAppAfterFeedback:
    "Abbiamo notato che si sta utilizzando una versione precedente dell'applicazione. La invitiamo ad aggiornarla per avere un'esperienza migliore.",
  changesAreLost:
    'Le modifiche non sono state salvate.\n\nVolete salvare prima di partire?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    'L\'intervallo di "promemoria" deve essere inferiore all\'intervallo di ripetizione.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    'L\'intervallo "Tempo tra gli avvisi ai partecipanti" deve essere inferiore all\'intervallo di ripetizione.',
  subscriptionCanceled:
    "L'abbonamento premium è stato annullato. Le funzioni premium non saranno disponibili fino al rinnovo dell'abbonamento.",
  unableToRenewSubscription:
    "Si è verificato un problema nel rinnovo dell'abbonamento premium. Assicurarsi che i dati di pagamento siano aggiornati.",
  notice: 'Avviso',
  ringerSettingsWillApplyToNewAlarms:
    "Le modifiche apportate alle impostazioni dei suoni e delle vibrazioni si applicano solo ai nuovi allarmi.\n\nPer gli allarmi esistenti, è possibile modificare l'allarme per cambiare le impostazioni di suono e vibrazione.",
  renew: 'Rinnovare',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Pagamento mensile a {{price}} al mese',
  payYearly: "Pagamento annuale a {{price}} all'anno",
  free: 'Gratuito',
  unlimited: 'Illimitato',
  personalAlarms: 'Allarmi personali',
  groupAlarms: 'Allarmi di gruppo',
  buddyAlarms: 'Allarmi per i compagni',
  alarmRepetitions: 'Ripetizioni',
  ringtones: 'Suonerie',
  alarmHistory: "Storia dell'allarme",
  base: 'Base',
  advanced: 'Avanzato',
  complete: 'Completo',
  alarmHistoryFree: 'Limitato',
  phoneMusicAsRingtone: 'Musica del telefono come suoneria',
  startFreeTrial: 'Iniziare la prova gratuita di 14 giorni',
  chargedAtEndOfFreeTrial:
    "L'abbonamento verrà addebitato al termine dei 14 giorni di prova gratuita, a meno che non venga annullato.",
  monthlySubscription: 'Mensile',
  yearlySubscription: 'Annuale',
  premiumRingtoneRequiresUpgrade:
    'Questa suoneria richiede un abbonamento Premium.',
  defaultPremiumFeaturesDescription:
    "Effettuate l'aggiornamento a Premium per abilitare queste interessanti funzioni.",
  groupAlarmsPremiumFeaturesDescription:
    'Per creare più di un allarme di gruppo è necessario un abbonamento premium.',
  buddyAlarmsPremiumFeaturesDescription:
    'È necessario un abbonamento premium per creare più di un allarme per qualcun altro ("compagno").',
  shareableAlarmLinksPremiumFeaturesDescription:
    'È necessario un abbonamento premium per creare link di allarme più condivisibili.',
  viewPremiumFeaturesDescription:
    'Grazie per essere un utente premium. Come abbonato premium puoi usufruire di queste interessanti funzioni.',
  accessToPremiumFeatures:
    "L'accesso a queste funzioni è parte integrante dell'abbonamento:",
  alarmCategoryPremiumFeaturesDescription:
    'Per creare nuove categorie è necessario un abbonamento premium.',
  galarmWebPremiumFeaturesDescription:
    'Per utilizzare Galarm su un browser web è necessario un abbonamento premium.',
  instantAlarmPremiumFeaturesDescription:
    'Per creare altri allarmi istantanei è necessario un abbonamento premium.',
  personalAlarmsFeatureDescription:
    "Utilizzare un allarme personale per svegliarsi, prendere le medicine o per qualsiasi altra attività. È possibile aggiungere dei partecipanti che vengono avvisati se si salta un allarme, in modo che possano promemoria. I partecipanti ricevono anche una notifica quando l'allarme è stato fatto.",
  groupAlarmsFeatureDescription:
    "Utilizzate un allarme di gruppo per attività di gruppo come gite, feste o qualsiasi altro evento. L'allarme scatta allo stesso orario per tutti i partecipanti, che possono Confermare la loro partecipazione.",
  buddyAlarmsFeatureDescription:
    "Create degli allarmi per un'altra persona (\"compagno\") per promemoria delle cose da fare. L'utente viene avvisato di promemoria per il compagno, nel caso in cui non riesca a rispondere all'allarme. Verrete anche avvisati quando il compagno avrà fatto l'allarme.",
  alarmRepetitionsFeatureDescription:
    'Creare allarmi con ripetizioni avanzate come ogni 30 minuti, ogni 2 ore e 45 minuti, a giorni alterni, bisettimanali e molti altri.',
  ringtonesFeatureDescription:
    "Scegliete tra un'ampia varietà di suonerie premium per i vostri allarmi. Aggiungiamo continuamente altre suonerie all'offerta premium.",
  phoneMusicAsRingtoneFeatureDescription:
    'Scegliere qualsiasi canzone/musica su telefono come suoneria di allarme.',
  shareableAlarmLinksFeatureDescription:
    "È possibile condividere gli allarmi con altri utilizzando un link.\n\nAd esempio, un insegnante può condividere il link dell'allarme di classe con gli studenti tramite e-mail, WhatsApp o qualsiasi altra app di messaggistica. Quando gli studenti toccano il link, una copia dell'allarme della classe viene creata sui loro telefoni. Tutti gli studenti saranno avvisati all'orario specificato per unirsi alla classe.",
  instantAlarmFeatureDescription:
    "Avvisate istantaneamente un gruppo di persone con un semplice clic.\n\nUtilizzate questo allarme per avvisare di un'esercitazione di emergenza, dell'inizio di una partita, di un compito/riunione urgente e di altri eventi simili che richiedono un'attenzione immediata.",
  alarmHistoryFeatureDescription:
    'Vista tutte le risposte passate per ogni allarme ripetuto. In questo modo è possibile scoprire facilmente quando si è persa la lezione di ginnastica, quando la madre non ha preso le medicine, ecc.',
  announceAlarmTitleFeatureDescription:
    "Annuncio del titolo dell'allarme quando suona.",
  exportToCalendarPremiumFeaturesDescription:
    'È necessario un abbonamento premium per esportare altri allarmi nel calendario.',
  exportToCalendarFeatureDescription:
    'È possibile esportare gli allarmi nel calendario. In questo modo è possibile visualizzare facilmente gli allarmi sul calendario del dispositivo e riflettere meglio la propria disponibilità.',
  galarmWebFeatureDescription:
    'Utilizzare Galarm su un browser web per creare, modificare ed eliminare gli allarmi.',
  preReminderFeatureDescription:
    "Impostazione di promemoria per essere avvisati degli allarmi imminenti. È possibile configurare la durata relativa per essere avvisati prima dell'orario effettivo dell'allarme, come 30 volte prima, 1 giorno prima, ecc.",
  calendarViewFeatureDescription:
    'Vista degli allarmi in un calendario per visualizzare facilmente gli allarmi di ogni giorno.',
  updatePhoneNumberFeatureDescription:
    'È possibile cambiare il numero di telefono utilizzato per questo account. Tutti gli allarmi, i contatti, i gruppi e le altre impostazioni verranno migrati al nuovo numero di telefono.',
  alarmPhotoFeatureDescription:
    "Aggiungere una foto a un allarme per ottenere rapidamente indicazioni visive, come ad esempio la foto di un farmaco, le istruzioni per un compito, un oggetto, ecc. La foto viene visualizzata quando suona l'allarme.",
  taskListFeatureDescription:
    'Creare elenchi di attività per tenere insieme le attività correlate.',
  calendarViewPremiumFeature: 'Vista Calendario',
  updatePhoneNumberPremiumFeature: 'Modifica del numero di telefono',
  alarmNotFound: 'Allarme non trovato',
  alarmNotFoundMessage:
    'Questo allarme potrebbe essere stato eliminato di recente. Toccare "Rimuovi" per disattivare ulteriori notifiche per questo allarme.',
  alarmExistsWithDeepLinkTitle: 'Allarme duplicato',
  alarmExistsWithDeepLinkMessage:
    'Un allarme creato con questo link esiste già su questo telefono.',
  alarmDoesntHaveNextDate: "L'allarme non ha una data prossima valida.",
  sharePersonalAlarmDeepLinkDescription:
    'Toccare questo link per creare un allarme per questo evento su {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    'Toccate questo link per unirvi a questo evento di gruppo su {{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} ha aggiornato {{alarmName}}. Volete aggiornare il vostro allarme?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} ha cancellato {{alarmName}}. Vuoi cancellare il tuo allarme?',
  responseReceivedForTicket:
    'Abbiamo risposto al suo ticket #{{ticketId}} a: {{email}}. Se non è presente nella posta in arrivo, controllare la cartella spam per verificare la presenza di un messaggio di posta elettronica da support@galarm.zendesk.com.',
  newTicketReceived:
    "Grazie per averci scritto. Abbiamo creato il ticket #{{ticketId}} per seguire la sua richiesta.\n\nRisponderemo all'indirizzo e-mail fornito: {{email}}. Se questo non è corretto, la preghiamo di inviare nuovamente il suo feedback con l'indirizzo e-mail corretto.\n\nAggiungete anche support@galarm.zendesk.com alla vostra rubrica di posta elettronica per garantire la corretta consegna delle nostre risposte. Invieremo anche una notifica qui quando avremo risposto alla vostra richiesta.",
  sentAccountBackup:
    "Abbiamo inviato i dati dell'allarme a {{email}}. Se non è presente nella casella di posta elettronica, controllare le cartelle spam per verificare la presenza di un'e-mail da noreply@acintyo.com.",
  alarmDoesntExist: "L'allarme non esiste.",
  shareLink: 'Condividi link',
  joinedGroupAlarmFromDeepLinkMessage:
    'La promemoria di "{{alarmName}}" si trova su {{alarmTime}}. {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Vi sarà promemoria di "{{alarmName}}". {{repetitionString}}',
    one: 'Vi sarà promemoria di "{{alarmName}}" {{repetitionString}} a {{alarmTime}} a partire da {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'Stai seguendo questo allarme da {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: 'Non hai follower per questo allarme.\n\nÈ possibile continuare a condividere questo allarme con altre persone utilizzando un link. Quando queste persone toccano il link, viene creata una copia di questo allarme sul loro telefono.',
    one: '{{count}} persona sta seguendo questo allarme.\n\nÈ possibile continuare a condividere questo allarme con altre persone utilizzando un link. Quando le persone toccano il link, viene creata una copia di questo allarme sul loro telefono.',
    other:
      '{{count}} persone stanno seguendo questo allarme.\n\nÈ possibile continuare a condividere questo allarme con altre persone utilizzando un link. Quando i partecipanti toccano il link, viene creata una copia di questo allarme sul loro telefono.'
  },
  shareParentAlarmDescription:
    "Stai seguendo questo allarme da {{creatorName}}.\n\nÈ anche possibile condividere questo allarme con altri utilizzando un link. Quando questi ultimi toccano il link, viene creata una copia dell'allarme sul loro telefono e anche loro possono iniziare a seguirlo.",
  shareableAlarmLinks: 'Link condivisibili',
  nPeopleFollowingAlarm: {
    zero: 'Non hai follower per questo allarme.',
    one: '{{count}} persona sta seguendo questo allarme.',
    other: '{{count}} persone stanno seguendo questo allarme.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blog su Medium',
  howtoVideos: 'Video di approfondimento',
  followGalarmOnSocialMedia: 'Seguite Galarm su Social Media',
  galarmOnSocialMedia: 'Galarm su social media',
  followGalarmOnSocialMediaDescription:
    "Viste le notizie interessanti su aggiornamenti dell'app, utilizzo delle funzioni, funzioni in arrivo e recensioni chiave dell'app, è possibile interagire con il team di Galarm. È inoltre possibile interagire con il team di Galarm.",
  sharedAlarm: "Modifica dell'allarme condiviso",
  editingPublishedAlarm: {
    one: 'Si sta modificando un allarme condiviso in precedenza utilizzando un link. {{count}} persona sta seguendo questo allarme.\n\nQuando si modificano gli allarmi, la persona viene informata delle modifiche.',
    other:
      'Si sta modificando un allarme condiviso in precedenza utilizzando un link. {{count}} persone stanno seguendo questo allarme.\n\nQuando si modificano gli allarmi, queste persone riceveranno una notifica delle modifiche.'
  },
  editingSubscribedAlarm:
    'Si sta seguendo questo allarme da {{creatorName}}. Non si riceveranno aggiornamenti per questo allarme dopo averlo modificato.',
  viewAlarm: "Vista L'allarme",
  view: 'Vista',
  editAlarm: 'Modifica allarme',
  thanksForTryingPremiumTitle: "L'impostazione è perfetta!",
  thanksForTryingPremiumMessage:
    'Ci auguriamo che le funzioni premium siano di vostro gradimento.\n\nPer qualsiasi domanda, contattare l\'assistenza utilizzando "Impostazioni > Feedback" o inviando un\'e-mail a galarm@acintyo.com.',
  subscribedAlarmEditedInAlarmDetails:
    "{{creatorName}} ha cambiato il proprio allarme.\nAggiornare l'allarme?",
  subscribedAlarmDeletedInAlarmDetails:
    "{{creatorName}} ha cancellato il proprio allarme.\nCancellare l'allarme?",
  updateSubscribedAlarm: 'Sì, aggiornamento',
  deleteSubscribedAlarm: 'Sì, eliminare',
  ignoreSubscribedAlarm: 'No, ignorare',
  customRepetitionPremiumScreenDescription:
    'La ripetizione personalizzata è una funzione premium.\n\nÈ possibile utilizzare questa funzione per creare allarmi con ripetizioni avanzate. Gli esempi includono:\n',
  repeatAlternateDate: 'Ripetere a giorni alterni',
  repeatEveryNDay: 'Ripetere ogni 3 giorni.',
  repeatEveryNHoursAndMMinutes: 'Ripetere ogni 2 ore e 30 minuti.',
  repeatEveryNMinutes: 'Ripetere ogni 45 minuti.',
  repeatBiweekly: 'Ripetere ogni 2 settimane',
  repeatEveryNWeeks: 'Ripetere ogni 6 settimane',
  repeatEveryNMonths: '✅ Ripetere ogni 3 mesi (trimestrale)',
  repeatEveryLastDayOfMonth: "Ripeti l'ultimo giorno di ogni mese.",
  repeatDayOfWeekOfMonth: 'Ripeti il 2° sabato di ogni mese.',
  repeatLastDayOfWeekOfMonth: "Ripeti l'ultimo Venerdì di ogni mese.",
  customRepetition: 'Ripetizione personalizzata',
  lastDayOfMonth: 'Ultimo giorno di ogni mese',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} di ogni mese',
  lastDayOfWeekInMonth: 'Ultimo {{dayName}} di ogni mese',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} di {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Ultimo {{dayName}} di {{monthName}}',
  lastDayOfMonthInYearSummary: 'Ultimo giorno di {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} di {{monthName}} ogni anno',
  lastDayOfWeekInMonthInYear: 'Ultimo {{dayName}} di {{monthName}} ogni anno',
  lastDayOfMonthInYear: 'Ultimo giorno di {{monthName}} ogni anno',
  chooseAMonthsConfiguration: 'Scegliere una configurazione.',
  chooseADaysConfiguration: 'Scegliere una configurazione',
  chooseAYearsConfiguration: 'Scegliere una configurazione.',
  didntGetCodeNewUser:
    'Non avete ricevuto il codice?\n\nPotete richiedere di nuovo il codice o Salta la registrazione per il momento. Potrete registrarvi in seguito.',
  didntGetCodeExistingUser:
    'Non avete ricevuto il codice?\n\nÈ possibile richiedere nuovamente il codice o annullare la registrazione. È possibile registrarsi in un secondo momento.',
  didntGetCodeUpdatePhoneNumber:
    'Non avete ricevuto il codice?\n\nPotete richiedere nuovamente il codice o riprovare più tardi.',
  alreadySentCodeAgain:
    'Abbiamo inviato nuovamente il codice. Se avete difficoltà a ottenere il codice, vi invitiamo a Saltare la registrazione per ora e a registrarvi più tardi.',
  theme: 'Tema',
  announceAlarmName: "Annuncio del titolo dell'allarme",
  ttsHelperText:
    "Il titolo dell'allarme viene annunciato quando suona. È possibile toccare il pulsante di riproduzione davanti al titolo dell'allarme per ascoltarne un'anteprima.",
  initializingTts: 'Inizializzazione della sintesi vocale',
  errorSpeakingAlarmName:
    'Impossibile pronunciare il titolo dell\'allarme. Contattare l\'assistenza da "Impostazioni > Feedback".',
  entityAlarm: 'allarme',
  entityGroup: 'gruppo',
  entityContact: 'contatto',
  admin: 'Admin',
  specifyAlarmTitleToSpeak: "Specificare il titolo dell'allarme da pronunciare",
  wakeup: 'Svegliarsi',
  ttsPremiumScreenDescription:
    "L'annuncio del titolo di allarme è una funzione premium.\n\nSe attivata, il titolo dell'allarme viene annunciato quando suona.\n\nPer visualizzare l'anteprima, fornire un titolo di allarme e toccare play.",
  unknownAlarmCategory: 'Categoria di allarme sconosciuta',
  cantAnnounceAlarmTitle: "Il titolo dell'allarme non è stato annunciato.",
  ringtoneDurationIsSmall:
    "La durata della suoneria deve essere di almeno 5 secondi per l'annuncio del titolo dell'allarme.\n\nVolete continuare con queste impostazioni?",
  noActionItems: "Nessun punto d'azione.",
  personNotInContacts: 'Questa persona non è tra i contatti di Galarm.',
  light: 'Chiaro',
  dark: 'Scuro',
  systemDefault: "Utilizzare l'impostazione del dispositivo",
  chooseTheme: 'Scegliere il tema',
  dontSeeAllYourContacts: 'Non vedete tutti i vostri contatti?',
  lastContactsSyncedAt: 'Ultima sincronizzazione a {{dateString}}',
  refresh: 'Aggiornare',
  forever: 'Per sempre',
  autoSnoozeConfiguration: 'Configurare Rimandare',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Non classificato',
  uncategorizedCategoryScreenTitle: 'Senza categoria',
  setAlarmCategory: 'Impostazione della categoria di allarme',
  changeAlarmCategory: 'Modifica della categoria di allarme',
  viewAlarmHistory: 'Vista la cronologia degli allarmi',
  computingAlarmHistory:
    'Riepilogo informatico per la cronologia degli allarmi...',
  alarmByCategories: '{{name}} Allarmi',
  alarmCategories: 'Categorie',
  editAlarmCategory: 'Modifica della categoria',
  deleteAlarmCategory: 'Eliminare la categoria',
  newAlarmCategoryTitle: 'Nuova categoria',
  newAlarmCategoryNamePlaceholder: 'Specificare un nome',
  editAlarmCategoryTitle: 'Modifica della categoria',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} mancato',
    other: '{{alarmsSummary}}, {{count}} mancato'
  },
  numAlarms: {
    zero: 'Nessun allarme',
    one: '{{count}} allarme',
    other: '{{count}} Allarmi'
  },
  numIncidents: {
    zero: 'Nessun incidente',
    one: '{{count}} incidente',
    other: '{{count}} incidenti'
  },
  openedIncidents: 'Incidenti aperti',
  closedIncidents: 'Incidenti chiusi',
  incidentChart: 'Grafico degli incidenti (ultimi 30 giorni)',
  incidentCalendar: 'Calendario degli incidenti',
  incidentDistribution: 'Distribuzione degli incidenti (ultimi 30 giorni)',
  noIncidentsOpenedClosedInLast30Days:
    'Nessun incidente aperto o chiuso negli ultimi 30 giorni',
  noIncidentsOpenedInLast30Days:
    'Nessun incidente aperto negli ultimi 30 giorni',
  color: 'Colore',
  createNewAlarmCategory:
    'Creare una nuova categoria toccando il pulsante + in basso a destra.',
  setValidName: 'Impostazione di un nome valido',
  specifyTimezoneForAlarmMessage:
    'È possibile specificare un fuso orario quando si creano gli allarmi. Gli allarmi suoneranno in base al fuso orario specificato. \n\nSe si sceglie "Fuso orario del dispositivo", gli allarmi suoneranno in base al fuso orario del dispositivo.',
  alarmCategoryFeatureDescription:
    'Creare categorie personalizzate per gli allarmi. Le categorie permettono di organizzare facilmente gli allarmi.',
  markAlarmDoneInAdvance: 'Segnare l\'allarme "Fatto" in anticipo?',
  skipAlarmInAdvance: '"Salta l\'allarme in anticipo?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'L\'allarme non suonerà all\'indirizzo {{dateTimeString}} se lo si contrassegna ora come "Fatto".',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    "L'allarme non suonerà su {{dateTimeString}} se lo si salta ora.",
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'L\'allarme non suonerà all\'indirizzo {{dateTimeString}} se lo si contrassegna ora come "Fatto".',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    "L'allarme non suonerà su {{dateTimeString}} se lo si salta ora.",
  alarmsByCategories: 'Allarmi per categoria',
  workAlarmCategory: 'Lavoro',
  homeAlarmCategory: 'Casa',
  birthdaysAlarmCategory: 'Compleanni',
  more: 'Di più',
  alarmCategory: 'Categoria di allarme',
  editAlarmCategoryColor: 'Modifica del colore',
  editAlarmCategoryColorTitle: 'Modifica del colore per {{name}}',
  allUserCategoriesDeleted:
    'Tutte le categorie di allarme create dall\'utente sono state rimosse perché l\'abbonamento premium è stato annullato. Gli allarmi di queste categorie appariranno in "Non categorizzati".',
  calendar: 'Calendario',
  eventExportedFromGalarm:
    '"Esportato da Galarm su {{dateString}} a {{timeString}}".',
  unableToExportEventToCalendar:
    'Impossibile esportare un evento nel calendario. Assicurarsi di aver dato l\'autorizzazione ad accedere al calendario su un dispositivo. Se il problema persiste, inviateci un feedback tramite "Impostazioni > Feedback".',
  scanQrCode:
    'Per accedere a Galarm su un browser, andare su web.galarm.app e scansionare il codice QR.',
  logOut: 'Disconnettersi',
  useHere: 'Utilizzare qui',
  appRunningElsewhere:
    'Siete stati disconnessi da questa sessione. Desidera riprendere questa sessione?',
  dateTime: 'Data e orario',
  selectAtLeastOneDayForRepetition:
    "Scegliere almeno un giorno su cui ripetere l'allarme.",
  chooseNumberOfDaysForAlarmRepetition:
    "Inserire il numero di giorni dopo i quali l'allarme deve ripetersi.",
  chooseNumberOfWeeksForAlarmRepetition:
    "Inserire il numero di settimane dopo le quali l'allarme deve ripetersi.",
  chooseNumberOfMonthsForAlarmRepetition:
    "Inserire il numero di mesi dopo i quali l'allarme deve ripetersi.",
  chooseNumberOfYearsForAlarmRepetition:
    "Inserire il numero di anni dopo i quali l'allarme deve ripetersi.",
  previouslySelectedContactsAreUnselected:
    'I contatti selezionati in precedenza sono deselezionati.',
  previouslySelectedGroupIsUnselected:
    'Il gruppo selezionato in precedenza è deselezionato.',
  galarmWebInstructions:
    '1. Aprire l\'applicazione Galarm su telefono.\n2. Andare alla scheda "Impostazioni" e toccare "Galarm Web".\n3. Se l\'opzione non viene visualizzata, assicurarsi di eseguire l\'app più recente.\n4. Eseguire la scansione del codice QR su destra',
  likeToCreateAlarm: 'Creare un allarme per...',
  payUtilityBills: 'Pagare le bollette',
  wakeupAlarmName: 'Svegliarsi',
  exerciseAlarmName: 'Esercizio',
  drinkWaterAlarmName: 'Bere acqua',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} a {{endTimeString}} Ogni ora',
    other: '{{startTimeString}} a {{endTimeString}} Ogni {{count}} ore'
  },
  firstTimeManagementQuote:
    '"La cattiva notizia è che l\'orario vola. La buona notizia è che tu sei il pilota".',
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote:
    '"La gestione dell\'orario inizia con la pianificazione".',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Non rimandare mai a domani ciò che puoi fare oggi".',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"L\'orario è quello che desideriamo di più, ma che usiamo peggio".',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"Fra un anno potreste desiderare di aver iniziato oggi".',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote: '"Potete ritardare, ma l\'orario non lo farà".',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Toccare "+" per iniziare.',
  allCategories: 'Tutte le categorie',
  createAlarmWithContact: 'Creare un allarme con {{name}}',
  createAlarmWithGroup: 'Creare un allarme con {{name}}',
  editGroup: 'Gruppo di modifica',
  discardChanges: 'Le modifiche attuali saranno scartate. Continuare?',
  alarmWillRingEveryNDays: {
    zero: "L'allarme suona ogni giorno.",
    one: "L'allarme suona a giorni alterni.",
    other: "L'allarme suona ogni giorno {{dayWithOrdinal}}."
  },
  hoursString: { zero: '', one: '1 ora', other: '{{count}} ore' },
  minutesString: { zero: '', one: '1 minuto', other: '{{count}} minuti' },
  alarmWillRingEveryMHoursAndNMinutes:
    "L'allarme suona dopo ogni {{repeatString}} a partire da {{dayString}}. Successivamente, {{otherDaysString}} e così via.",
  alarmWillRingEveryNWeeks: {
    zero: "L'allarme suona ogni settimana.",
    one: "L'allarme suona ogni due settimane.",
    other: "L'allarme suona ogni {{weekWithOrdinal}} settimana."
  },
  alarmWillRingEveryNMonths: {
    zero: "L'allarme suona ogni mese.",
    one: "L'allarme suona ogni due mesi.",
    other: "L'allarme suona ogni {{monthWithOrdinal}} mese."
  },
  alarmWillRingEveryNYears: {
    zero: "L'allarme suona ogni anno.",
    one: "L'allarme suona ogni due anni.",
    other: "L'allarme suona ogni {{yearWithOrdinal}} anno."
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    "Quando questa preferenza è attivata, gli allarmi degli altri suoneranno senza che vengano accettati. Si deve comunque accettare l'allarme per far sapere al creatore che si intende partecipare all'allarme.\n\nQuando questa preferenza è disattivata, gli allarmi aggiunti per voi da altri non suoneranno su di voi a meno che non li accettiate esplicitamente.",
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Quando questa preferenza è attivata, è possibile specificare un fuso orario quando si creano gli allarmi. Gli allarmi suoneranno in base al fuso orario specificato.\n\nQuando questa preferenza è disattivata, gli allarmi suoneranno in base al fuso orario del dispositivo.',
  ringOnEarphoneOnlyTooltipTitle:
    "Comportamento dell'allarme quando sono collegati gli auricolari",
  ringOnEarphoneOnlyTooltipMessage:
    "Quando gli auricolari sono collegati, è possibile configurare gli allarmi in modo che suonino solo sugli auricolari o sia sull'altoparlante del telefono che sugli auricolari.\n\nQuando gli auricolari non sono collegati, gli allarmi suonano su altoparlante del telefono.",
  gestureOptionsTooltipTitle: 'Gesto per ignorare un allarme',
  gestureOptionsTooltipMessage:
    'Quando un allarme suona su una schermata di blocco, è possibile ignorarlo toccando un pulsante o facendo scorrere un pulsante.\n\nQuesta impostazione consente di scegliere come ignorare un allarme.',
  notificationSettingsTooltipTitle: 'Impostazioni di notifica',
  notificationSettingsTooltipMessage:
    'Abilitate le notifiche che desiderate ricevere e disabilitate quelle che non volete ricevere.',
  messagesForAlarm: 'Messaggi per {{name}}',
  systemNotificationsTooltipMessage:
    "Notifiche generate dall'app per eventi quali:\n  - quando gli allarmi vengono modificati a causa del cambio di fuso orario del contatto\n  - quando un contatto invitato si registra su Galarm\n  - quando gli allarmi sono influenzati dalla cancellazione dell'account di un contatto",
  galarmWebNotificationsTooltipMessage: 'Notifiche inviate da Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Notifiche che vengono inviate quando si viene aggiunti a un allarme come partecipante.',
  alarmChatNotifictionsTooltipMessage:
    'Notifiche inviate per i messaggi di chat in un allarme.',
  groupNotificationsTooltipMessage:
    'Notifiche che vengono inviate quando si viene aggiunti a un gruppo, rimossi da un gruppo o quando qualcun altro viene aggiunto/rimosso da un gruppo.',
  alarmAcknowledgementNotificationsTooltipMessage:
    'Notifiche che vengono inviate quando un partecipante riconosce un allarme a cui anche voi partecipate.',
  sharedAlarmNotificationsTooltipMessage:
    "Notifiche che vengono inviate:\n  - quando qualcuno si iscrive a un allarme condiviso dall'utente tramite un link\n  - quando un allarme a cui vi siete iscritti è stato modificato",
  sleepTimer: 'Musica del sonno',
  sleepTimerTitle: 'Riprodurre musica per',
  ongoingSleepTimer: 'Musica per dormire in corso',
  stopSleepTimer: 'Volete interrompere la musica attuale?',
  restartSleepTimer:
    'Volete interrompere la musica attuale e iniziarne una nuova?',
  play: 'Gioco',
  youAreOffline:
    'Siete attualmente offline. Andare online per eseguire qualsiasi operazione.',
  sleepMusicTooltip:
    'Riproduce suoni naturali per rilassare il corpo e la mente. La musica cessa di essere riprodotta dopo la durata specificata.',
  alarmLinkCopiedToClipboard:
    "Il link dell'allarme è stato copiato negli appunti.",
  confirmLogOut: 'Vuoi disconnetterti?',
  appNotRunningMessage:
    'Abbiamo rilevato che Galarm non è in esecuzione sul vostro telefono. Toccate per avviare Galarm in modo da non perdere nessun allarme importante.',
  tapToEnterNotesForAlarm: 'Toccare per inserire le note',
  accountAlreadyExists: 'Conto esistente trovato',
  accountsMerged:
    'Abbiamo trovato un account esistente associato a questo numero di cellulare. Abbiamo unito i due account.',
  upgradeToPremiumToAssignUserDefinedCategory:
    "Aggiornamento a Premium per selezionare la categoria definita dall'utente.",
  userDefinedCategoryNotSetForAlarm:
    "Aggiornamento a Premium per assegnare automaticamente la categoria definita dall'utente. La categoria non è impostata per l'allarme.",
  alarmDetails: "Dettagli sull'allarme",
  profileDetails: 'Profilo',
  activeWebSession:
    "È già attiva una sessione web. È possibile disconnettersi da questa o avviare una nuova sessione.\n\nL'avvio di una nuova sessione comporta la disconnessione automatica dalla sessione web attiva.",
  startNewSession: 'Avviare una nuova sessione',
  confirmWebSessionLogout:
    'In questo modo si esce dalla sessione Web corrente. È possibile avviare una nuova sessione scansionando nuovamente il codice.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Allarme da {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Allarme da {{creatorName}}',
  atleastOneMemberShouldBeActive: 'Almeno un partecipante deve essere attivo.',
  activeParticipants: 'Partecipanti attivi',
  acceptAlarm: "Accettare l'allarme",
  rejectAlarm: "Rifiutare l'allarme",
  nConfirmed: '{{count}} Confermato',
  nDeclined: '{{count}} declinato',
  nPending: '{{count}} in attesa',
  everyoneConfirmed: 'Tutti hanno Confermato',
  everyoneDeclined: "Tutti hanno declinato l'offerta",
  appTagline: 'Non perdetevi mai nulla',
  usePreviousBackup: 'Sono un utente registrato >',
  viewAlarmsByCategories: 'Vista degli allarmi per categoria',
  viewAllAlarms: 'Vista tutti gli allarmi',
  switchToSummaryView: 'Passare alla Vista di riepilogo',
  switchToListView: 'Passare alla Vista elenco',
  hideHourlyAlarms: 'Nascondere gli allarmi orari',
  calendarView: 'Calendario Galarm',
  viewRecentlyDeletedAlarms: 'Vista degli allarmi eliminati di recente',
  deletedOnDate: 'Soppresso su {{alarmDate}}',
  recentlyDeletedAlarmFooterText:
    'Questo allarme è stato cancellato su {{alarmDate}}',
  noRecentlyDeletedAlarms: 'Nessun allarme cancellato di recente',
  restoreAlarmsFromMobileApp:
    'Questi allarmi saranno eliminati automaticamente dopo 30 giorni dalla loro cancellazione. Se si desidera ripristinare uno di essi, accedere alla schermata "Allarmi eliminati di recente" dell\'applicazione mobile e toccare l\'icona di ripristino.',
  deleteInactiveAlarms: 'Cancellare gli allarmi inattivi',
  defaultConfigurationUpdated:
    'La configurazione predefinita è stata aggiornata.',
  notAlerted: '(escluso)',
  alarmKeepsRingingHelp: "Aiuto! L'allarme continua a suonare",
  alarmKeepsRingingHelpMessage:
    'Abbiamo aggiornato gli allarmi e inviato il rapporto sul problema.',
  alarmKeepsRingingHelpAlert:
    'Aggiorneremo gli allarmi e il problema non si presenterà più. Invieremo inoltre una segnalazione del problema al nostro team di assistenza per ulteriori indagini.\n\n  Se il problema persiste, vi preghiamo di contattarci tramite "Impostazioni > Feedback".',
  sendingFeedback: 'Invio di feedback',
  sendingProblemReport: 'Invio della segnalazione del problema',
  fixingAlarms: 'Aggiornare gli allarmi',
  existingAccountFound: 'Backup ripristinato',
  existingAccountDataRestored: {
    zero: 'Congratulazioni, tutti i dati sono stati ripristinati dal backup.',
    one: 'Congratulazioni, tutti i dati sono stati ripristinati dal backup.',
    other: 'Congratulazioni, tutti i dati sono stati ripristinati dal backup.'
  },
  noExistingAccountFound: 'Nessun backup trovato',
  createdNewAccount:
    'Questo numero di telefono non è stato registrato presso Galarm. Abbiamo creato un nuovo account utilizzando questo numero di telefono.',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: "iOS consente a un'app di programmare fino a 64 notifiche. Le notifiche sono state esaurite. È possibile che si perda qualche allarme.",
    one: "iOS consente a un'app di programmare fino a 64 notifiche. Avete utilizzato {{numNotifications}} di queste notifiche."
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    "Questa è l'ultima notifica per questo allarme. Premere a lungo su questa notifica ed eseguire un'azione per programmare il prossimo allarme.",
  lastNotificationForOneTimeAlarm:
    "Questa è l'ultima notifica per questo allarme.",
  criticalAlertsMessageTitle:
    'Suonano gli allarmi quando il telefono è silenzioso',
  criticalAlertsMessageDescription:
    '\nÈ possibile configurare Galarm in modo che utilizzi gli avvisi critici di iOS per far suonare gli allarmi quando il telefono è silenzioso. Volete abilitarlo per tutti gli allarmi?\n\nPotete anche abilitarlo selettivamente per alcuni allarmi modificando l\'impostazione "Suoni e vibrazioni > Avvisi critici".',
  allowCriticalAlerts: 'Consentire gli avvisi critici',
  criticalAlertsPermissionDenied:
    "Galarm richiede l'autorizzazione del telefono per mostrare gli avvisi critici. Attivare questa autorizzazione nelle impostazioni del telefono.",
  enableCriticalAlertsForAllAlarms: 'Sì, abilitato per tutti gli allarmi',
  selectivelyEnableCriticalAlerts: 'No, abiliterò selettivamente',
  firstName: 'Nome',
  lastName: 'Cognome',
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confermare la password',
  signup: 'Iscriviti',
  login: 'Accesso',
  alreadySignedUp: 'Siete già iscritti?',
  notAUser: 'Non sei un utente?',
  subscribe: 'Abbonarsi',
  choosePlan: 'Configurare il piano',
  billingCycle: 'Ciclo di fatturazione',
  needHelpToGetStarted: 'Avete bisogno di aiuto per iniziare?',
  browser: 'Browser: {{name}}',
  deviceOs: 'Dispositivo: {{name}}',
  lastActive: 'Ultimo attivo: {{timestamp}}',
  signUp: 'Iscriviti',
  useGalarmOnWeb: 'Utilizzare Galarm su web',
  welcomeUser: 'Benvenuti {{name}}',
  changePassword: 'Modifica della password',
  saveAlarm: 'Salva allarme',
  galarmUserTip: 'Lo sapevate?',
  sendAlarm: 'Invia allarme',
  saveGroup: 'Salva gruppo',
  webAppBestViewedOnDesktop:
    "L'URL (web.galarm.app) serve per lanciare l'interfaccia di Galarm su un browser web del computer portatile o del desktop.\n\nPer utilizzare Galarm su un dispositivo mobile, installare l'applicazione Android da Google Play o l'applicazione iOS dall'App Store utilizzando uno dei link seguenti:",
  scanQrCodeMessage: 'Scansione del codice QR',
  reloadQrCode: 'Fare clic per ricaricare il codice QR',
  invalidQrCodeScanned: 'Scansione di un codice QR non valido.',
  unableToScanCode: 'Impossibile scansionare il codice',
  unableToScanCodeMessage:
    'Si è verificato un problema nella scansione del codice. Si prega di riprovare. In caso di problemi, inviateci un feedback dall\'applicazione mobile utilizzando "Impostazioni > Feedback".',
  problemWithEnterpriseAccountSetup:
    "Si è verificato un problema nell'impostazione dell'account Galarm Pro. Si prega di contattare il supporto tecnico.",
  problemWithCreatingCheckoutSession:
    'Si è verificato un problema nella creazione di una sessione di checkout. Si prega di contattare il supporto tecnico.',
  problemWithCreatingCustomerPortalSession:
    'Si è verificato un problema nella creazione di una sessione del portale clienti. Si prega di contattare il supporto tecnico.',
  problemWithCreatingApiKey:
    'Si è verificato un problema nella creazione di una chiave API. Si prega di contattare il supporto tecnico.',
  creatingAnAccount: 'Creare un account',
  currentPlan: 'Piano attuale: {{name}} ({{quantity}} posti)',
  myAccount: 'Informazioni sul conto',
  new: 'Nuovo',
  deleteAllAlarmHistoryAlertTitle: 'Cancellare la cronologia degli allarmi',
  deleteAllAlarmHistoryAlertMessage:
    'Toccare "Continua" per eliminare la cronologia di questo allarme. Questa azione non può essere annullata.',
  ageOutInactiveAlarmsMessage:
    "Allarmi inattivi da oltre 60 giorni sono presenti su {{count}}. Un numero elevato di allarmi inattivi può influire sulle prestazioni dell'applicazione.\n\nSi desidera eliminare questi allarmi?",
  duplicateAlarm: 'Allarme duplicato',
  graduallyIncreaseVolume: 'Aumentare gradualmente il volume',
  graduallyIncreaseVolumeTooltipMessage:
    "Quando questa preferenza è attivata, il volume dell'allarme aumenta gradualmente fino a raggiungere il volume della suoneria configurato. In questo modo è possibile interrompere l'allarme prima che diventi troppo forte.\n\nQuando questa preferenza è disattivata, gli allarmi suonano al volume della suoneria configurata per tutta la durata della suoneria.",
  teams: 'Squadre',
  alerts: 'Avvisi',
  listeners: 'Ascoltatori',
  noMembersConfiguredInEnterpriseAccount:
    "Non è stato ancora aggiunto alcun membro. I membri sono utenti che possono essere aggiunti alla politica di escalation di un ascoltatore e vengono avvisati degli incidenti ricevuti dall'ascoltatore. Queste persone dovrebbero essere già registrate in Galarm.",
  noTeamsConfiguredInEnterpriseAccount:
    "Non è stato ancora creato alcun team. I team sono gruppi di membri che possono essere aggiunti ai criteri di escalation di un ascoltatore e vengono avvisati degli incidenti ricevuti dall'ascoltatore.",
  noAlertsConfiguredInEnterpriseAccount:
    "Non è stato ancora creato alcun ascoltatore. È possibile configurare gli ascoltatori per ascoltare gli incidenti segnalati dagli strumenti di monitoraggio. Una volta creato un ascoltatore, si possono vedere le istruzioni su come integrare l'ascoltatore con vari strumenti di monitoraggio.\n\nÈ possibile configurare più livelli di escalation per ogni ascoltatore.",
  noShiftsConfiguredInEnterpriseAccount:
    'Non è stata ancora creata alcuna configurazione di turno.',
  noRotationsConfiguredInEnterpriseAccount:
    'Non è stata ancora creata alcuna rotazione.',
  rotations: 'Rotazioni',
  addRotation: 'Aggiungi rotazione',
  viewRotation: 'Vista Rotazione',
  enterRotationName: 'Inserire il nome della rotazione',
  rotationType: 'Tipo di rotazione',
  numberOfHoursInRotation: 'Numero di ore a rotazione',
  numberOfDaysInRotation: 'Numero di giorni a rotazione',
  numberOfWeeksInRotation: 'Numero di settimane a rotazione',
  specify: 'Specificare...',
  noIntegrationsConfiguredInEnterpriseAccount:
    'Non è stata ancora configurata alcuna configurazione di integrazione.',
  loadingUser: "Caricamento delle informazioni sull'utente",
  addMember: 'Aggiungi membro',
  add: 'Aggiungi',
  noUserFoundWithMobileNumber:
    'Non è stato trovato nessun utente Galarm registrato con questo numero di telefono. Assicuratevi di inserire il numero di telefono con il prefisso nazionale senza caratteri speciali come parentesi, trattini, spazi bianchi ecc.',
  memberWithPhoneNumberAlreadyAdded:
    'Il membro con questo numero di telefono è già stato aggiunto.',
  memberWithEmailAlreadyAdded:
    'Il membro con questo indirizzo e-mail è già stato aggiunto.',
  assignKeyToMember: 'Assegnazione della chiave di licenza',
  revokeKeyFromMember: 'Revoca della chiave di licenza',
  cantAssignKeyToMember: 'Impossibile assegnare la chiave al membro',
  noEnterpriseLicenseKeyAvailable:
    'Non sono disponibili chiavi di licenza. Si prega di aggiornare il proprio piano.',
  addTeam: 'Aggiungi squadra',
  enterTeamName: 'Inserire il nome di una squadra',
  addAlert: 'Aggiungi ascoltatore',
  low: 'Basso',
  medium: 'Medio',
  high: 'Alto',
  severity: 'Gravità',
  enterAlertName: "Inserire il nome dell'ascoltatore",
  organization: 'Organizzazione',
  startTime: "Orario d'inizio",
  endTime: 'Orario finale',
  shiftName: 'Turno {{index}}',
  addShift: 'Aggiungi turno',
  assignees: 'Assegnatari',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Cessionario',
  specifyAssigneeForShift: "Specificare l'assegnatario del turno",
  selectAtLeastOneMember: 'Selezionare almeno un membro',
  editMember: 'Modifica membro',
  editTeam: 'Squadra di modifica',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'Il membro fa parte di un team o è assegnato a un ascoltatore. Si prega di rimuovere il membro da questi team/ascoltatori prima di eliminarlo.\nSquadre: {{teams}}\nAscoltatori: {{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'Il membro fa parte di un team o è assegnato a un ascoltatore. Rimuovere il membro da questi team/ascoltatori prima di revocare la chiave.\nSquadre: {{teams}}\nAscoltatori: {{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    "Il team fa parte di un ascoltatore. Rimuovere il team dall'ascoltatore prima di eliminarlo.\nAscoltatori: {{alerts}}",
  cantDeleteRotationAssignedToAlerts:
    "La rotazione fa parte di un ascoltatore. Rimuovere la squadra dall'ascoltatore prima di eliminarla.\nAscoltatori: {{alerts}}",
  editAlert: "Modifica dell'ascoltatore",
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  apiKey: 'Chiave API',
  viewApiKey: 'Vista la chiave API',
  apiKeyWarningMessage:
    'Tenete sempre al sicuro la vostra chiave API. Non condividetela con nessuno. Se si pensa che la propria chiave API sia stata compromessa, toccare il pulsante "Cambia" in alto per cambiarla. Dopo aver modificato la chiave, è necessario specificare la nuova chiave in tutti i punti in cui si attivano gli incidenti utilizzando l\'API REST.',
  integrationConfigs: 'Configurazioni di integrazione',
  addIntegrationConfig: "Aggiungere la configurazione dell'integrazione",
  editIntegrationConfig: "Modifica della configurazione dell'integrazione",
  integrationSource: 'Fonte di integrazione',
  apiToken: 'Token API',
  integrations: 'Integrazioni',
  selectPlaceholder: 'Selezionare...',
  zendeskSubdomain: 'Sottodominio Zendesk',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'I prossimi 5 allarmi',
  categories: 'Categorie',
  viewingAlarmsDueToday: 'Allarmi per la Vista in scadenza oggi',
  viewingAlarmsDueTomorrow: 'Allarmi per la vista previsti per domani',
  expiredAlarms: 'Allarmi scaduti',
  viewingExpiredAlarms: 'Vista degli allarmi scaduti',
  noAlarmsDueToday: 'Nessun allarme previsto per oggi',
  noAlarmsDueTomorrow: 'Nessun allarme per domani',
  noExpiredAlarms: 'Nessun allarme scaduto',
  viewingAllAlarms: 'Vista di tutti gli allarmi',
  viewingRecentlyDeletedAlarms: 'Vista degli allarmi eliminati di recente',
  summaryView: 'Provare la Vista di riepilogo?',
  summaryViewDescription:
    'Vedere gli allarmi organizzati per date di scadenza e categorie.\n\nÈ possibile tornare alla vista elenco utilizzando il pulsante ⋮ in alto a destra.',
  disableBatteryOptimizationMessageOnStartup:
    "Per far suonare gli allarmi in modo affidabile, Galarm deve essere eseguito in background. Consentite l'autorizzazione su una schermata successiva.",
  disableBatteryOptimizationMessage:
    'Galarm deve essere eseguito in background affinché gli allarmi suonino in modo affidabile.',
  allowNotificationsToDisplayAlarms:
    'Galarm ha bisogno dei permessi per mostrare le notifiche quando suonano gli allarmi.',
  scheduleExactAlarmsMessage:
    "Galarm ha bisogno di un'autorizzazione per programmare gli allarmi esatti.",
  alarmCategoryWithSameNameExists:
    'Esiste già una categoria con questo nome. Si prega di specificare un nome diverso.',
  alarmRepetitionsPremiumFeature: 'Opzioni di ripetizione avanzate',
  ringtonesPremiumFeature: 'Altre suonerie',
  galarmWebPremiumFeature: 'Utilizzare Galarm su web',
  taskListPremiumFeature: 'Creare più elenchi di attività',
  preReminderPremiumFeature: 'Promemoria per gli allarmi',
  alarmCategoriesPremiumFeature: 'Categorie di allarme personalizzate',
  shareableAlarmLinksPremiumFeature: 'Link di allarme condivisibili',
  announceAlarmNamePremiumFeature: "Annuncio del titolo dell'allarme",
  manageSubscription: "Gestire l'abbonamento",
  purchaseRestored: 'Acquisto restaurato.',
  unableToRetrieveSubscriptionPlansAndroid:
    "Non siamo in grado di connetterci a Google Play per eseguire l'aggiornamento. Assicurarsi di essere connessi a Internet e riprovare. \n\nSe il problema persiste, segnalatelo al nostro team di assistenza.",
  unableToRetrieveSubscriptionPlansIos:
    "Non siamo in grado di connetterci all'App Store per eseguire l'aggiornamento. Assicurarsi di essere connessi a Internet e riprovare. \n\nSe il problema persiste, segnalatelo al nostro team di assistenza.",
  drawOverOtherApps: 'Visualizzazione sopra le altre app',
  drawOverOtherAppsHelperText:
    'Consentite a Galarm di mostrare gli allarmi in alto',
  nTimes: { one: 'Un solo orario', other: '{{count}} volte' },
  learnHowToUseFocus: 'Imparare a concentrarsi',
  enhancedNotificationsDeprecated: 'Notifiche avanzate deprecate',
  enhancedNotificationsDeprecatedMessage:
    'Abbiamo scoperto che le notifiche avanzate non sono affidabili e causano un eccessivo consumo di batteria. Utilizzare iOS Focus per consentire a Galarm di suonare gli allarmi quando il telefono è su Non disturbare.',
  usingIosFocus: 'Utilizzo di iOS Focus',
  iosFocusModePara1:
    'Il Galarm non suona quando l\'interruttore della suoneria del telefono è spento o quando il volume della suoneria è pari a 0.\n\nApple consiglia di utilizzare "Focus" quando si desidera mantenere il telefono silenzioso ma consentire ad alcune applicazioni di inviare notifiche. Utilizzare le seguenti istruzioni per impostare Focus su un telefono.',
  iosFocusModePara2:
    'È possibile visualizzare istruzioni più dettagliate con schermate su https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS.',
  settingFocus: 'Impostazione della messa a fuoco:',
  settingFocusFootNotes:
    'Si noti che Focus è disponibile su iOS 15 e versioni successive.\n\nPer domande o feedback, contattateci tramite "Impostazioni > Feedback".',
  enablingFocus: 'Focus abilitante:',
  settingFocusInstruction1:
    'Accedere a "Impostazioni del telefono > Messa a fuoco".',
  settingFocusInstruction2:
    'Toccare il profilo di messa a fuoco "Non disturbare".',
  settingFocusInstruction3: 'Toccare "Applicazioni" e aggiungere Galarm.',
  settingFocusInstruction4:
    'Procedere allo stesso modo per gli altri profili Focus impostati.',
  enablingFocusInstruction1:
    'Passare il dito verso il basso dal bordo superiore destro per visualizzare il Centro di controllo.',
  enablingFocusInstruction2: 'Toccare su Focus.',
  enablingFocusInstruction3:
    'Toccare Non disturbare o qualsiasi altro profilo Focus che si desidera abilitare.',
  enablingFocusInstruction4:
    'La messa a fuoco attualmente attivata appare selezionata. È possibile toccare di nuovo su di esso per disabilitarlo.',
  startDateForRotation: 'Data di inizio della rotazione',
  startTimeForRotation: 'Orario di inizio della rotazione',
  member: 'Membro',
  multipleShiftsInADay: 'Più turni in un giorno',
  escalationPolicy: 'Politica di escalation',
  atTimeOfEvent: "Al momento dell'evento",
  afterNMinutes: 'Dopo {{count}} minuti',
  afterNHours: { one: 'Dopo 1 ora', other: 'Dopo {{count}} ore' },
  afterDuration: 'Dopo {{durationString}}',
  addTrigger: 'Aggiungi trigger',
  viewCode: 'Codice Vista',
  apiCodes: 'Codici API',
  viewIncidents: 'Vista degli incidenti',
  incidentsForAlert: "Incidenti per l'ascoltatore {{alertName}}",
  timestamp: 'Timestamp',
  acknowledged: 'Riconosciuto',
  noIncidentsForAlert: 'Non ci sono ancora incidenti per questo ascoltatore',
  apiCodeCopiedToClipboard: 'Il codice API è stato copiato negli appunti.',
  triggerName: 'Innesco {{index}}',
  delay: 'Ritardo',
  chooseAssigneePlaceholder: 'Scegliere Assegnatario...',
  delayForTrigger: "Ritardo per l'attivazione",
  assigneeWillBeAlertedAfterDelay:
    "L'assegnatario sarà avvisato dopo {{durationString}}.",
  escalationForIncidentsForAlert:
    "Escalation per l'incidente a {{incidentDate}} per l'ascoltatore {{alertName}}",
  alertIncidentAcknowledged: 'Riconosciuto da {{memberName}} a {{dateString}}',
  alertIncidentRecovered: 'Recuperato presso {{dateString}}',
  alertIncidents: 'Incidenti',
  viewingAlertIncidents: 'Vista degli incidenti',
  createOrganization: "Creare un'organizzazione",
  youAreNotPartOfAnyOrganization:
    "Non fate parte di alcuna organizzazione. Creare un'organizzazione per iniziare.",
  tellUsAboutYourOrganization: 'Parlaci della tua organizzazione',
  noActiveSubscription: 'Non avete un abbonamento attivo.',
  chooseOrganization: "Scegliere un'organizzazione per continuare.",
  noTriggersForIncident:
    'Non sono stati trovati inneschi per questo incidente.',
  role: 'Ruolo',
  viewer: 'Visualizzatore',
  newOrganization: 'Nuova organizzazione',
  noOrganizations: 'Non fate parte di alcuna organizzazione.',
  maxOrganizationsReached:
    "L'utente può far parte di un massimo di 10 organizzazioni.",
  yourOrganizations: 'Organizzazioni',
  urlNotFound: "L'URL non corrisponde a nessun percorso supportato.",
  triggerAlert: 'Incidente scatenante',
  recaptchaFailed: 'Verifica reCAPTCHA fallita. {{error}}',
  loadingAccountInformation: 'Caricamento delle informazioni sul conto',
  loadingAuthInfo: 'Caricamento delle informazioni di autenticazione',
  allAlerts: 'Tutti gli ascoltatori',
  recentlyTriggeredAlerts: 'Ascoltatori attivati di recente',
  recentlyClosedIncidents: 'Incidenti chiusi di recente',
  noRecentlyTriggeredAlerts: 'Non sono stati attivati ascoltatori di recente.',
  noRecentlyClosedIncidents: 'Nessun incidente è stato chiuso di recente.',
  closedAt: 'Chiuso a {{dateTimeString}}',
  dashboard: 'Cruscotto',
  loggedIntoOrganizationAccount:
    '(organizzazione: {{organizationName}}, ruolo: {{role}})',
  viewIncidentEscalation: "Vista l'escalation degli incidenti",
  closeIncident: 'Incidente ravvicinato',
  closingIncident: 'Incidente di chiusura',
  unableToRefreshEnterpriseAccount: "Impossibile aggiornare l'account.",
  viewAuthToken: 'Vista il gettone di autorizzazione',
  problemWithCreatingAuthToken:
    'Si è verificato un problema nella creazione di un token di autenticazione. Si prega di contattare il supporto tecnico.',
  authToken: 'Token di autorizzazione',
  authTokenWarningMessage:
    'Mantenete il token di autenticazione sempre al sicuro. Non condividetelo con nessuno. Se si pensa che la chiave API sia stata compromessa, toccare il pulsante "Cambia" in alto per cambiarla. Dopo aver modificato il token, sarà necessario specificare il nuovo token in tutti i punti in cui si attivano gli incidenti utilizzando l\'API REST.',
  authTokenCopiedToClipboard:
    'Il token di autorizzazione è stato copiato negli appunti.',
  resendVerificationEmail: "Reinvio dell'e-mail di verifica",
  loggedInUsingEmail: 'Accesso tramite e-mail {{email}}.',
  checkingIfMemberIsAppUser: "Verifica se il membro è un utente dell'app.",
  settingMemberDetails: 'Impostazione dei dettagli del membro.',
  addingTeam: 'Aggiunta di una squadra.',
  editingTeam: 'Team di redazione.',
  addingRotation: 'Aggiunta della rotazione.',
  editingRotation: "Rotazione dell'editing.",
  assigningKeyToMember: 'Assegnazione della chiave al membro.',
  revokingKeyFromMember: 'Revoca della chiave al membro.',
  deletingTeam: 'Eliminazione della squadra.',
  deletingRotation: 'Eliminazione della rotazione.',
  addingAlert: 'Aggiunta di un ascoltatore.',
  editingAlert: 'Ascoltatore di editing.',
  deletingAlert: "Eliminazione dell'ascoltatore.",
  triggeringAlert: 'Incidente scatenante.',
  creatingOrganizationAccount: "Creazione dell'account dell'organizzazione.",
  creatingCheckoutSession: 'Avvio della sessione di checkout.',
  creatingAnAccountForMember: 'Creare un account per i membri.',
  passwordResetEmailSent:
    "Le abbiamo inviato un'e-mail per reimpostare la password. Modificate la password ed effettuate nuovamente il login.",
  changeOwner: 'Cambia proprietario',
  changeOrganizationOwner: "Modifica del proprietario dell'organizzazione",
  newOwnerEmail: 'Email del nuovo proprietario',
  saveOnYearlySubscription: 'Salva {{percent}}%',
  annual: 'Annuale',
  resetAlarms: 'Aggiorna gli allarmi',
  resetAlarmsAlert:
    'Tutti gli allarmi sono stati aggiornati. Se si continuano a riscontrare problemi con gli allarmi, si prega di inviare un ticket utilizzando l\'opzione "Segnala un problema".',
  resetAlarmsTooltipMessage:
    'Se si verificano problemi con gli allarmi, ad esempio quelli cancellati che suonano sul telefono, aggiornare gli allarmi utilizzando questa opzione. Se il problema persiste, inviare un ticket utilizzando l\'opzione "Segnala un problema".',
  resetTempPassword: 'Ripristino della password temporanea',
  currPassword: 'Password corrente',
  tempPassword: 'Password temporanea',
  newPassword: 'Nuova password',
  reset: 'Reset',
  loggingIn: 'Accesso',
  resettingTempPassword: 'Ripristino della password temporanea',
  changingPassword: 'Modifica della password',
  numSeats: 'Numero di posti a sedere',
  helpMeDecide: 'Aiutami a decidere',
  numEnterpriseSeatsHelper:
    "Il numero di posti deve corrispondere al numero di persone dell'organizzazione che utilizzeranno l'applicazione per ricevere gli incidenti. Durante la configurazione di un ascoltatore, è possibile specificare la sua politica di escalation. È possibile avere più livelli in un criterio di escalation. Ad esempio, è possibile specificare che il primo livello di escalation è quello di un team di 3 persone. Se il primo livello di escalation non risponde entro 5 minuti, il secondo livello di escalation viene assegnato a un team di 5 persone. In questo caso, è possibile specificare il numero di posti a sedere (8).",
  numBusinessSeatsHelper:
    'Scegliete il numero di posti in base al numero di persone che useranno Galarm per ricevere gli allarmi e le promemoria.',
  perSeatPrice: '{{price}} per {{interval}} per posto a sedere',
  doneForToday: 'Riconosciuto',
  getStarted: 'Iniziare',
  firstAlertInstructions: 'Istruzioni per il primo ascoltatore',
  learnMoreAboutGalarmEnterprise: 'Per saperne di più su Galarm Pro',
  home: 'Casa',
  team: 'Squadra',
  alert: 'Allarme',
  listener: 'Ascoltatore',
  forgotPassword: 'Hai dimenticato la password?',
  forgotPasswordTitle: 'Password dimenticata',
  sendPasswordResetEmail: "Inviare l'e-mail di reimpostazione della password",
  sendingPasswordResetEmail:
    "Invio dell'e-mail di reimpostazione della password",
  field: 'Campo',
  description: 'Descrizione',
  requestIntegration: "Richiedere un'altra integrazione",
  integrationDetails: "Dettagli sull'integrazione",
  enterIntegrationDetails: "Fornire i dettagli dell'integrazione",
  galarmEnterpriseFreeTrial: 'Iscriviti per una prova gratuita di 14 giorni',
  galarmEnterpriseFreeTrialDescription:
    'Automatizzare la gestione degli incidenti',
  bySigningUpYouAgreeToOur: 'Iscrivendosi, si accetta la nostra',
  and: 'e',
  alarmSubscribers: "Seguaci dell'allarme",
  otherSubscribersCount: {
    one: '1 altro follower non presente nei tuoi contatti',
    other: '{{count}} più seguaci non presenti nei tuoi contatti'
  },
  getAccountData: 'Esportazione dei dati di allarme',
  getAccountDataPrompt:
    'Fornite un indirizzo e-mail al quale invieremo un file di testo contenente tutti gli allarmi.',
  getAccountDataConfirmation:
    "Si noti che verrà inviato un file di testo contenente tutti gli allarmi all'indirizzo {{email}}.",
  sendingAccountData: 'Esportazione dei dati di allarme...',
  sentAccountData: 'Dati di allarme inviati a {{email}}.',
  errorSendingAccountData:
    "Errore nell'esportazione dei dati di allarme: {{error}}",
  sendingEmailVerificationLink: 'Invio del link di verifica via e-mail',
  viewLogs: 'Visualizza i registri',
  message: 'Messaggio',
  billing: 'Fatturazione',
  newIncidentForAlert: "Nuovo incidente per l'ascoltatore {{alertName}}",
  clickNextAfterAddingMembers:
    'Fare clic su "Prossimo" dopo aver aggiunto i membri.',
  clickDoneAfterAddingAlerts:
    'Fare clic su "Fatto" per completare la configurazione e passare alla Dashboard.',
  alertCreatedSuccessfully:
    "L'ascoltatore è stato creato con successo. È possibile attivare questo ascoltatore dall'icona del menu a tre puntini davanti al nome dell'ascoltatore.",
  fixSubscription: "Riparare l'abbonamento",
  fixSubscriptionDescription:
    "Abbiamo rilevato un problema con il vostro abbonamento. L'abbonamento contiene {{numSeats}} posti, ma sono state assegnate chiavi a {{numSeatsAssigned}} membri. È possibile assegnare al massimo {{numSeats}} chiavi.",
  fixSubscriptionChooseOption:
    "Si prega di correggere l'abbonamento utilizzando una delle opzioni riportate di seguito.",
  fixSubscriptionContactAdmin:
    "Contattare l'amministratore dell'organizzazione.",
  unassignKeysFromMembers:
    'Disassegnare almeno le chiavi {{extraKeysAssigned}} dai membri.',
  addMoreSeatsToSubscription:
    "Aggiungete almeno {{extraKeysAssigned}} posti all'abbonamento",
  revokeMembersKey: 'Revoca della chiave dei membri',
  revokeMembersKeyDescription:
    'Scegliere i membri di cui si desidera revocare le chiavi.',
  revoke: 'Revocare',
  unableToRevokeMembersKey:
    "Si è verificato un errore nella revoca della chiave dei membri. Si prega di contattare l'assistenza.",
  problemWithLoadingProducts: 'Problema di caricamento dei prodotti. {{error}}',
  verificationCompleted: 'Verifica completata',
  deviceAlreadyRegistered:
    'Questo numero di telefono è stato precedentemente verificato su questo dispositivo.',
  phoneNumberAutoFilled:
    'Galarm utilizzerà il numero di telefono selezionato dal vostro account Google.',
  unableToRegisterPreVerifiedUser:
    "Impossibile registrare l'utente pre-verificato.",
  allowNotifications: 'Consentire le notifiche',
  allowNotificationsDescription: 'Per visualizzare gli allarmi quando suonano.',
  scheduleExactAlarms: 'Programmare gli allarmi',
  scheduleExactAlarmsHelperText:
    "Consentite a Galarm di suonare gli allarmi all'orario esatto.",
  scheduleExactAlarmsDescription:
    "Per far suonare gli allarmi all'orario esatto.",
  disableBatteryOptimization: "Gestire l'ottimizzazione della batteria",
  disableBatteryOptimizationDescription:
    "Per consentire l'esecuzione di Galarm in background.",
  requestRequiredPermissions:
    'Galarm richiede le seguenti autorizzazioni per suonare gli allarmi',
  allowDisallowedPermissions: 'Toccare per consentire',
  notificationsAreEnabled:
    "L'autorizzazione a mostrare le notifiche è già consentita.",
  connectingToStore: 'Collegamento al negozio...',
  restartApp: "Riavviare l'applicazione",
  updateAvailable: 'Aggiornamento disponibile',
  english: 'Inglese',
  russian: 'Russo',
  german: 'Tedesco',
  french: 'Francese',
  spanish: 'Spagnolo',
  italian: 'Italiano',
  portuguese: 'Portoghese',
  japanese: 'Giapponese',
  korean: 'Coreano',
  indonesian: 'Indonesiano',
  changeAppLanguage: "Cambiare la lingua dell'app",
  appLanguage: "Lingua dell'applicazione",
  iosChangeAppLanguagePara1:
    "Galarm sceglie la lingua dell'app dalle impostazioni del telefono. Seguite questi passaggi per cambiare la lingua dell'app per Galarm:",
  iosChangeAppLanguageInstruction1:
    'Aprire "Impostazioni del telefono > Generali > Lingua e regione". Assicurarsi che la lingua che si desidera utilizzare per Galarm sia presente nell\'elenco delle lingue preferite.',
  iosChangeAppLanguageInstruction2:
    'Tornate alle "Impostazioni del telefono" e toccate "Galarm" nell\'elenco delle app in basso.',
  iosChangeAppLanguageInstruction3:
    'Toccare "Lingua" e selezionare la lingua che si desidera impostare per Galarm. Tornare all\'applicazione dopo aver scelto la lingua',
  androidChangeAppLanguagePara1:
    "Galarm sceglie la lingua dell'app dalle impostazioni del telefono. Seguite questi passaggi per cambiare la lingua dell'app per Galarm:",
  androidChangeAppLanguageInstruction1:
    'Toccare il pulsante "Apri le impostazioni dell\'app" in basso.',
  androidChangeAppLanguageInstruction2:
    'Toccare "Lingua" e selezionare la lingua che si desidera utilizzare per Galarm.',
  androidChangeAppLanguageInstruction3:
    'Dopo aver scelto la lingua, tornate qui e fate clic su "Riavvia l\'app".',
  problemRetrievingSubscriptionData:
    "Si è verificato un problema nel recupero delle informazioni sull'abbonamento. La stiamo reindirizzando alla pagina dell'account. Controllare lo stato dell'abbonamento e contattare l'assistenza tecnica in caso di problemi.",
  deletingOrganization: "Eliminazione dell'organizzazione...",
  deleteOrganization: "Cancellare l'organizzazione",
  deleteOrganizationDescription:
    "Siete sicuri di voler cancellare la vostra organizzazione?\n  \n  Si tratta di un'operazione irreversibile. Tutti i dati dell'organizzazione verranno cancellati.\n  \n  Assicurarsi di aver annullato l'abbonamento prima di cancellare l'organizzazione.",
  galarmEnterpriseTrialDescription:
    "È prevista una prova gratuita di 14 giorni durante la quale è possibile annullare l'abbonamento senza alcun costo.",
  galarmEnterpriseTrialAlreadyAvailed:
    'Avete già usufruito della prova gratuita di 14 giorni. Contattate il supporto se avete bisogno di più orario per valutare il prodotto.',
  signinWithGoogle: 'Accedi con Google',
  signinWithGithub: 'Accedi a GitHub',
  verifyAccount: 'Verifica del conto',
  verifyAccountMessage:
    'Dovreste aver ricevuto un\'e-mail con un codice di verifica. Immettere il codice sottostante per verificare il proprio account. Se non avete ricevuto il codice, potete richiederne uno nuovo facendo clic su "Reinvia codice".',
  verificationCode: 'Codice di verifica',
  optionalString: '{{string}}',
  quickReminderTitle: 'Titolo (facoltativo)',
  accountVerified: 'Account verificato',
  accountVerifiedMessage: 'Accedere utilizzando le proprie credenziali.',
  multipleUsersWithSameEmail:
    "Ci sono più utenti con lo stesso indirizzo e-mail. Si prega di contattare l'assistenza.",
  multipleUsersWithSameMobileNumber:
    "Ci sono più utenti con lo stesso numero di cellulare. Si prega di contattare l'assistenza.",
  startTrial: 'Avviare la prova',
  nextSteps: 'Prossimi passi',
  firstTimeSetupComplete:
    'È stata completata la configurazione iniziale di Galarm Enterprise e creato il primo ascoltatore. Di seguito sono riportati i prossimi passi da compiere.',
  testTheAlertInstruction:
    "Per sapere come testare l'ascoltatore appena creato, consultare le istruzioni all'indirizzo {{link}}.",
  integrateTheAlertInstruction:
    "Consultare le istruzioni all'indirizzo {{link}} per integrare l'ascoltatore con vari strumenti di monitoraggio.",
  createMoreAlertsInstruction:
    'È possibile aggiungere altri ascoltatori accedendo alla scheda "Ascoltatori" su sinistra.',
  addMoreMembersInstruction:
    'È possibile aggiungere altri membri accedendo alla scheda "Membri" su sinistra.',
  createTeamsInstruction:
    'È possibile raggruppare i membri in "gruppi" e avvisare un intero gruppo quando si verifica un incidente. Creare i team accedendo alla scheda "Teams" su sinistra e utilizzarli nella configurazione dell\'ascoltatore.',
  testTheAlert: "Test dell'ascoltatore",
  integrateTheAlert: "Integrare l'ascoltatore",
  createMoreAlerts: 'Creare più ascoltatori',
  addMoreMembers: 'Aggiungere altri membri',
  createTeams: 'Creare squadre',
  docs: 'Documenti',
  contactSupport: 'Contatto',
  pastDue: 'Scaduto',
  canceled: 'Annullato',
  unpaid: 'Non pagato',
  incomplete: 'Incompleto',
  incomplete_expired: 'Incompleto Scaduto',
  trialing: 'Prova gratuita',
  paused: 'In pausa',
  plan: 'Piano',
  status: 'Stato',
  renewsOn: 'Rinnova su',
  yourRoles: 'I vostri ruoli',
  enterprisePlan: '{{quantity}} posti a sedere, si rinnova ogni {{interval}}',
  currentOrganization: 'Organizzazione attuale',
  useThisOrganization: 'Utilizzare questa organizzazione',
  fixPayment: 'Riparare il pagamento',
  fixingPayment: 'Fissare il pagamento',
  addPaymentInformation: 'Aggiungere informazioni sul pagamento',
  noPaymentInformationAvailable:
    'Non ci sono informazioni di pagamento disponibili. Si prega di aggiornare la pagina se le informazioni di pagamento sono state aggiunte di recente.',
  openBillingPortal: 'Portale di fatturazione aperto',
  startupWizardDescription:
    'Questa procedura guidata vi aiuterà a iniziare e a creare il vostro primo ascoltatore.',
  userNotFound: 'Utente non trovato',
  wrongPassword: 'Password sbagliata',
  emailAlreadyInUse: 'Email già in uso',
  restoreDeletedAlarms: 'Ripristino degli allarmi cancellati',
  deleteAlarmAlert:
    'Gli allarmi eliminati possono essere ripristinati da "Allarmi eliminati di recente". È possibile visualizzarli toccando il menu a tre punti (⋮) in alto a destra della scheda "Allarmi".\n\nGli allarmi cancellati rimangono lì per 30 giorni, dopodiché vengono eliminati definitivamente.',
  slackVerificationCode:
    'Il tuo codice di verifica Slack è {{verificationCode}}.',
  dndAccess: 'Accesso non disturbare',
  dndHelperText:
    'Consentire a Galarm di suonare gli allarmi quando il telefono è su Non disturbare.',
  cantRestoreInstantAlarmMessage:
    'Gli allarmi istantanei non possono essere ripristinati. Se necessario, è possibile visualizzare la chat e altre informazioni relative a questo allarme. Questo allarme verrà rimosso definitivamente dopo 30 giorni dalla sua eliminazione.',
  upgradeToUnlockAllFeatures: 'Aggiornamento per sbloccare tutte le funzioni',
  otherSignupOptions: 'Altre opzioni di iscrizione:',
  renewSubscription: "Rinnovo dell'abbonamento",
  subscriptionOnHold:
    'In attesa (Il piano consente {{subscriptionQty}} posti a sedere, ma sono stati assegnati posti a sedere.\nma sono stati assegnati {{numSubscriptionKeys}} posti.',
  checklists: 'Compiti',
  checklist: 'Elenco dei compiti',
  typeToAddTask: "Tipo per aggiungere l'attività",
  specifyTaskName: "Specificare il nome dell'attività",
  otherChecklists: 'Elenchi di attività',
  defaultChecklistName: 'senza titolo',
  addTasksToChecklist:
    'Aggiungete le attività utilizzando la casella di input in basso',
  addTasksToDefaultChecklist:
    'Aggiungere attività utilizzando la casella di input in basso... o iniziare un nuovo elenco di attività facendo clic sul pulsante + in alto a destra.',
  createNewChecklist:
    'È possibile creare nuovi elenchi di attività per tenere traccia delle attività correlate in un unico punto.',
  addNewChecklist: 'Nuovo elenco di attività',
  alarmPhoto: 'Foto di allarme',
  alarmPhotoFeatureTitle: 'Foto di allarme',
  alarmPhotoFeatureDescriptionPremiumUser:
    "Questa funzione consente di aggiungere una foto a un allarme per ottenere rapidamente indicazioni visive, come ad esempio la foto di un farmaco, le istruzioni per un compito, un oggetto, ecc. La foto viene visualizzata quando suona l'allarme.",
  alarmPhotoFeatureDescriptionFreeUser:
    "Questa funzione consente di aggiungere una foto a un allarme per ottenere rapidamente indicazioni visive, come ad esempio la foto di un farmaco, le istruzioni per un compito, un oggetto, ecc. La foto viene visualizzata quando suona l'allarme.\n  \nSi tratta di una funzione premium. Per utilizzare questa funzione è necessario effettuare l'aggiornamento a premium.",
  alarmPhotoPremiumFeature: 'Aggiungi una foto alle note di allarme',
  installPendingAppUpdate:
    "L'ultimo aggiornamento dell'app è stato scaricato ed è pronto per essere installato.",
  numTasks: {
    zero: 'Nessun compito',
    one: '1 compito',
    other: '{{count}} compiti'
  },
  numTasksWithCompleted: {
    one: '1 compito, {{completed}} completato',
    other: '{{count}} compiti, {{completed}} completati'
  },
  muteAlarms: 'Silenziamento degli allarmi',
  unmuteAlarms: 'Disattivare gli allarmi',
  alarmsAreMuted:
    "Tutti gli allarmi sono attualmente disattivati. Toccare per disattivare l'audio.",
  alarmsHaveBeenMuted:
    'Gli allarmi sono stati disattivati. Non suoneranno fino a quando non li avrete disattivati.',
  alarmsHaveBeenUnmuted:
    'Gli allarmi sono stati disattivati. Dovrebbero suonare come configurato.',
  deleteChecklist: "Cancellare l'elenco delle attività",
  confirmDeleteChecklist: "Cancellare l'elenco delle attività?",
  confirmDeleteCompletedTasks: 'Cancellare le attività completate?',
  confirmResetCompletedTasks: 'Azzerare le attività completate?',
  confirmDeleteTask: 'Cancellare {{taskName}}?',
  showCompletedTasks: 'Mostra attività completate',
  hideCompletedTasks: 'Nascondere le attività completate',
  deleteCompletedTasks: 'Eliminare le attività completate',
  resetCompletedTasks: 'Azzeramento delle attività completate',
  yourGroups: 'I vostri gruppi',
  newAlarmWith: 'Nuovo allarme con {{name}}',
  newGroupWith: 'Nuovo gruppo con {{name}}',
  alertContact: 'Allarme {{name}}',
  alertGroup: 'Allarme {{name}}',
  instantAlarmMovedToFabNotice:
    'La funzione di allarme immediato è ora disponibile dal pulsante "+" in basso a destra di questa schermata. L\'icona "altoparlante" sarà rimossa dall\'alto a destra in una prossima versione.',
  tasksIntroTitle: 'Compiti ed elenchi di compiti',
  tasksIntroDescription:
    'I compiti sono le cose da fare che non hanno una scadenza. Qui è possibile aggiungerli e gestirli rapidamente, spuntandoli man mano che si gestisce ogni attività.\n\nÈ anche possibile creare elenchi di attività per raggruppare attività simili. Ad esempio, si può creare un elenco di attività per la spesa, un altro per le attività di lavoro e così via.',
  groupsMovedToContactsScreen:
    'I "Gruppi" sono stati riuniti in questa scheda. I gruppi appaiono in alto, su "I tuoi gruppi". È possibile creare nuovi gruppi toccando il pulsante "+" in basso a destra.',
  loadingTasks: 'Caricamento attività...',
  tryPremium: 'Prova Premium',
  buyPremiumToUnlockFeature:
    'Si tratta di una funzione premium. Sbloccate questa e molte altre funzioni interessanti con il nostro piano premium che prevede una prova gratuita di 14 giorni.',
  newTask: 'Nuovo compito',
  newTaskList: 'Nuovo elenco di attività',
  enterTaskName: "Immettere il nome dell'attività",
  enterTaskListName: "Immettere il nome dell'elenco di attività",
  taskName: "Nome dell'attività",
  taskListName: "Nome dell'elenco di attività",
  checklistTasks: '{{name}}',
  editTaskListName: "Modifica del nome dell'elenco di attività",
  editTask: 'Modifica attività',
  releaseNotes: 'Note di rilascio',
  currentRelease: '(attualmente installato)',
  loadingReleaseNotes:
    'Caricamento delle note di rilascio. Assicuratevi di essere connessi a Internet.',
  id: 'ID',
  subscriptionData: 'Dati di abbonamento',
  organizations: 'Organizzazioni',
  showRotationCalendar: 'Calendario della rotazione degli spettacoli',
  rotationCalendar: 'Calendario di rotazione',
  editShift: 'Modifica del turno',
  startDateForShift: 'Data di inizio del turno',
  startTimeForShift: 'Orario di inizio del turno',
  endDateForShift: 'Data di fine turno',
  endTimeForShift: 'Orario di fine del turno',
  editingShift: 'Turno di montaggio',
  addingShift: 'Aggiunta del turno',
  products: 'Prodotti',
  prices: 'Piani',
  planType: 'Tipo di piano',
  business: 'Affari',
  enterprise: 'Impresa',
  compare: 'Confronto',
  apiSupport: 'Supporto API per il monitoraggio dei servizi',
  accessToConsoleToViewAndCloseIncidents:
    'Dashboard per la gestione degli incidenti',
  triggerInstantAlertsFromTheConsole:
    'Attivare gli incidenti da Enterprise Console',
  coreFeatures: 'Caratteristiche principali:',
  everythingInBusiness: 'Tutto in Business plan e:',
  updatePlanInstructions:
    'Puoi aggiornare il tuo piano facendo clic sul pulsante "Apri portale di fatturazione" a destra e scegliendo il pulsante "Aggiorna piano" sul portale di fatturazione.',
  membersDescription:
    'Sono stati acquistati {{subscriptionKeysCount}} posti - {{assignedSubscriptionKeysCount}} assegnati e {{unassignedSubscriptionKeysCount}} disponibili. Il segno di spunta davanti al nome di un membro indica un posto assegnato.',
  centralBilling: 'Fatturazione centrale',
  saveTwoMonths: 'Salva 2 mesi',
  owner: 'Proprietario',
  enterpriseAccountSummary:
    "Fate parte dell'organizzazione {{organizationName}} iscritta al piano Galarm Pro {{planType}}.",
  perYearPerUser: 'per utente/anno',
  perMonthPerUser: 'per utente/mese',
  creationDate: 'Data di creazione',
  updatePhoneNumber: 'Modifica del numero di telefono',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    'Confermare il codice paese e inserire il nuovo numero di telefono. Il vostro account sarà collegato a questo numero di telefono al termine della verifica.',
  unableToUpdatePhoneNumber:
    "Impossibile modificare il numero di telefono: {{error}}. Si prega di contattare l'assistenza.",
  phoneNumberUpdated: 'Il numero di telefono è stato cambiato con successo.',
  phoneNumberUpdatedMessage:
    "È possibile iniziare a utilizzare l'applicazione normalmente. Chiedete ai vostri contatti di aggiornare il vostro numero di telefono nella loro rubrica.",
  memberChangedPhoneNumber:
    '{{memberName}} ha cambiato numero di telefono. Aggiornare la rubrica per continuare ad aggiungerli agli allarmi.',
  preferredSourceForIncidents: 'Fonte privilegiata per gli incidenti',
  apiCodesDescription:
    "Utilizzate le seguenti istruzioni per integrare l'ascoltatore con una sorgente",
  rotation: 'Rotazione',
  noAlarmsForThisDay: 'Nessun allarme per questo giorno',
  markAlarmsAsComplete: 'Contrassegnare gli allarmi come completi',
  markAlarmsAsCompleteExplanation:
    'Gli allarmi di un solo orario saranno contrassegnati come completi e appariranno nella sezione Allarmi inattivi.\n\nGli allarmi ripetivi saranno contrassegnati come completi e verranno spostati alla prossima occorrenza.',
  enterIncidentPrefix: 'Massimo 5 caratteri',
  incidentPrefix: "Prefisso dell'incidente",
  incidentPrefixAlreadyExists: "Il prefisso dell'incidente esiste già",
  removeTask: "Rimuovere l'attività?",
  incidentPrefixLengthExceeded:
    "Il prefisso dell'incidente è più lungo di 5 caratteri",
  criticalAlerts: 'Avvisi critici',
  criticalAlertsConfiguration:
    "L'allarme suona al volume di {{criticalAlertsVolume}}% anche quando il telefono è silenzioso o il Focus è attivo.",
  criticalAlertsDisabledShort:
    "L'allarme non suona quando il telefono è silenziato.",
  criticalAlertsConfigurationGlobal:
    'Gli allarmi suonano al volume di {{criticalAlertsVolume}}% anche quando il telefono è silenzioso o il Focus è attivo.',
  criticalAlertsDisabledShortGlobal:
    'Gli allarmi non suonano quando il telefono è silenziato.',
  criticalAlertsEnabled:
    'Gli avvisi critici sono abilitati. Gli allarmi suonano anche quando il telefono è silenzioso o la funzione Focus è attiva.',
  criticalAlertsDisabled:
    'Gli avvisi critici sono disattivati. Gli allarmi non suonano quando il telefono è silenziato.',
  enableCriticalAlerts: 'Abilitazione degli avvisi critici',
  summer_soft: 'Estate morbida',
  beeps: 'Segnali acustici',
  bollywood_nights: 'Notti di Bollywood',
  gentle_reminder: 'Promemoria delicato',
  himalayan_piper: "Piper dell'Himalaya",
  holiday_joy: 'Gioia delle vacanze',
  hollywood_adventure: 'Avventura a Hollywood',
  melody: 'Melodia',
  morning_rooster: 'Gallo del mattino',
  morning_sunshine: 'Sole del mattino',
  sweet_sea: 'Mare dolce',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: "{{alarmName}} - È l'orario.",
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - È orario di promemoria per {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotification:
    "{{alarmName}}. È l'ora di confermare la propria partecipazione.",
  InstantAlarmNotification:
    '{{alarmName}}. Si richiede la vostra immediata attenzione.',
  MyAlarmNotificationShort: "È l'orario.",
  ParticipantCascadingAlarmNotificationShort:
    'È orario di promemoria per {{alarmCreatorName}}.',
  ParticipantSimultaneousAlarmNotificationShort:
    "È l'ora di Confermare la propria partecipazione.",
  InstantAlarmNotificationShort: 'Si richiede la vostra immediata attenzione.',
  MyRecipientAlarmNotification:
    '{{alarmName}} - È orario di promemoria per {{alarmRecipientName}}.',
  MyRecipientAlarmNotificationShort:
    'È orario di promemoria per {{alarmRecipientName}}.',
  RecipientAlarmNotification:
    "{{alarmName}} (da {{alarmCreatorName}}) - È l'orario.",
  RecipientAlarmNotificationShort: "È l'orario - da {{alarmCreatorName}}."
}
