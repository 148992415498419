module.exports = {
  cantUpdateTimezoneForAllAlarms: '既存のアラームの時間帯を更新できない',
  cantUpdateRingtonesForAllAlarms:
    '既存のアラームの音と振動の設定を更新できない',
  preReminderWillRingAt: {
    zero: 'このアラームの事前リマインダーが鳴ります。 \n{{timeString}} 。',
    one: 'このアラームの事前リマインダーは前日 \n{{timeString}} 。',
    other:
      'このアラームの事前リマインダーが鳴ります。 \n{{timeString}} {{count}} 日前に鳴ります。'
  },
  verficationCodeInfo:
    '4桁のコードが記載されたテキストメッセージまたは電話が届きます。',
  verficationCodeInfoWhatsApp:
    '4桁のコードが記載されたWhatsAppメッセージが届きます。',
  deletePastAlarmsNotificationMessage:
    '非アクティブなアラームが複数あります。不要になったアラームを削除してください。',
  confirmCountryCodeAndEnterNumber:
    '国番号を確認し、電話番号を入力してください。',
  sendCodeOnSms: 'SMSでコードを送信',
  sendCodeOnWhatsApp: 'WhatsAppでコードを送信',
  verifyNumberWhatsApp:
    '認証コードを記載したWhatsAppメッセージをお送りします：\n\n{{formattedMobileNumber}}',
  verifyNumberCall:
    'に認証コードが記載された電話がかかってきます：\n    \n{{formattedMobileNumber}}',
  defaultTimezoneForAlarm: 'アラームのデフォルト時間帯',
  changeDefaultTimezoneForAllExistingAlarms: {
    zero: '既存のすべてのアラームの時間帯をリセットしますか？',
    one: '既存のすべてのアラームの時間帯をこの時間帯に変更しますか？アラームは新しいタイムゾーンで設定された時間にリングします。'
  },
  changeDefaultRingerSettingsForAllExistingAlarms:
    '既存のアラームの音と振動の設定を変更しますか？\n  \n  はい]を選択した場合、既存のアラームは新しい設定でリングします。いいえ]を選択した場合、既存のアラームは古い設定のままリングされます。',
  defaultTimezoneChangedForAllAlarms:
    'すべてのアラームの時間帯が変更されました。',
  ringerSettingsChangedForAllAlarms: '既存のアラームの音と振動設定を変更。',
  unlimitedAlarmsFeatureDescription: 'グループや仲間のアラームを増やす。',
  instantAlarmsPremiumFeature: 'インスタントアラートの送信',
  instantAlarmsFeatureDescription:
    '連絡先やグループに複数のインスタントアラートを送信できます。',
  duplicateAlarmPremiumFeature: '重複アラーム',
  duplicateAlarmFeatureDescription:
    '同じ設定のアラームのコピーを素早く作成し、保存前に変更を加えることができます。',
  showingAlarmsForDate: 'アラームの表示{{date}}',
  shareAlarm: 'アラームリンクを共有する',
  shareAlarmDescription:
    '連絡先に追加しなくても、リンクを使用してこのアラームを誰とでも共有できます。誰かが携帯電話でリンクをタップすると、アラームのコピーが作成されます。',
  sendCodeAgain: '再度コードを送信',
  autoSnoozeConfiguration: 'スヌーズの設定',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}},{{autoSnoozeCountLabel}}',
  selectDefaultTimezoneForAlarmDefaultTooltipMessage:
    'アラームのデフォルトの時間帯を選択します。アラーム作成時にタイムゾーンを変更できます。',
  alarmLinkRelativeToTimezone: '{{timeString}} 彼らの時間帯で。',
  alarmLinkNotRelativeToTimezone: 'でアラームがリングになる。{{timeString}}',
  alarmShouldRingAt: 'みんなのアラームがリングになる：',
  showHourlyAlarms: '毎時アラームを表示する',
  critical: 'クリティカル',
  alarmsWillBeAutomaticallyDeleted:
    'アラームは削除から30日後に永久に削除されます。',
  unlimitedAlarmsPremiumFeature: '無制限のグループおよび仲間アラーム',
  notAcknowledged: '未承認',
  closed: 'クローズド',
  showFullScreenIntents: 'アラームをフルスクリーンで表示する',
  showFullScreenIntentsDescription:
    '電話がロックされている場合、アラームはフルスクリーンに表示されます。',
  percentVolume: '{{volume}}% 音量',
  selectADate: 'アラームを表示する日付を選択してください。',
  affiliates: 'アフィリエイト',
  referralCount: '紹介者数',
  addAffiliate: 'アフィリエイトの追加',
  creatingAffiliate: 'アフィリエイトの作成',
  affiliateLink: 'アフィリエイト・リンク',
  upgradeRequiredForWeb: 'アップグレードが必要',
  upgradeToPremiumForWeb:
    'この機能をご利用いただくにはプレミアム会員登録が必要です。この機能を使用するには、モバイルアプリからプレミアムサブスクリプションを購入してください。',
  accountType: '口座種別',
  profileAndSettings: 'プロフィールと設定',
  confirmIncidents: '複数のインシデントの確認',
  removeIncidents: '複数のインシデントを削除する',
  incidents: '事件',
  closeSelectedIncidents: 'インシデントを閉じる',
  viewIncidentNotes: 'インシデントノート表示',
  incidentNotes: 'インシデントノート',
  addNote: 'ノート',
  noAdditionalNotesForIncident: 'この件に関する追加ノートはない。',
  fromIncident: '事件から',
  fromUsers: 'ユーザーから',
  database: 'データベース',
  chooseFirstDayOfWeek: '週の初めの曜日を選ぶ',
  firstDayOfWeek: '週の初日',
  changePlan: '変更計画',
  currentPremiumSubscriptionStatus:
    'あなたは現在、{{planName}} プランに加入しています。',
  currentProSubscriptionStatus: 'あなたは{{orgName}} のメンバーです。',
  changeToAnnualPlan: '年間プランの変更',
  unableToChangePlan:
    '年間プランに変更できません。設定 > フィードバック」からテクニカルサポートに連絡先連絡してください。',
  confirmChangeToAnnualPlan: '年間プランに変更しますか？',
  changeToAnnualPlanDescription:
    '年間プランの場合、{{price}} 。現在のプランの残りの日数は、年間プランに調整されます。',
  alarmTimezoneChanged:
    '一部のアラームは、デバイスのタイムゾーンを使用するように更新されましたが、これはこれらのアラームに使用されているタイムゾーンがこのデバイスでサポートされていないためです。',
  defaultTimezoneChanged:
    '{{defaultTimezoneForAlarm}} タイムゾーンはこのデバイスではサポートされていないため、アラームのデフォルトタイムゾーンはデバイスタイムゾーンに変更されます。',
  invalidEmail: 'メールアドレスが無効です。',
  unableToValidateEmail: '電子メールを検証できません。{{error}}',
  havingProblems: '問題がありますか？',
  alertData: 'アラートデータ',
  notificationActionBehavior: '通知アクションの動作',
  foreground: '前景',
  background: '背景',
  notificationActionBehaviorTooltipMessage:
    'フォアグラウンド "に設定すると、通知アクションをタップするとアプリがフォアグラウンドで開きます。バックグラウンド "に設定すると、通知アクションをタップすると、アプリがバックグラウンドで表示されます。',
  getCodeOnWhatsApp: 'WhatsAppでコードを取得',
  getCode: 'コードを取得',
  dontHaveWhatsApp: 'WhatsAppをお持ちでないですか？',
  unableToSendCodeViaWhatsAppTitle: 'WhatsAppでコードを送信できない',
  unableToSendCodeViaWhatsAppMessage:
    '{{error}}\n      \n別のチャンネルで再度コードをリクエストしてください。',
  dataSecurity: 'データ・セキュリティ',
  alarms: 'アラーム',
  contacts: '連絡先',
  groups: 'グループ',
  settings: '設定',
  notificationsTab: '通知',
  specifyTimeInFutureForAlarm: 'アラームを過去にすることはできない。',
  specifyTimeInFutureForPreReminder: 'アラームの事前リマインダーは過去のもの。',
  specifyNameForGroup: 'グループ名は必須。',
  updatingGroupName: 'グループ情報の更新。',
  unableToEditGroupName:
    'グループ情報を更新できません。時間をおいて再度お試しください。',
  selectSmallerImage:
    '画像が10MBを超えています。小さい画像を選択してください。',
  sendingVerificationCode: '{{formattedMobileNumber}} に認証コードを送信する。',
  verifyingCode: 'コードを検証する',
  creatingUserProfile: '設定...',
  unabeToCreateUserProfile:
    '現在、アカウントの設定ができません。しばらくしてからもう一度お試しください。',
  unableToSendVerificationCode: '認証コードを送信できません。{{error}}',
  unableToVerifyCode: 'コードを確認できません。{{error}}',
  unableToRegisterUserWithoutMobileNumber:
    'セットアップを実行できません。{{error}}',
  permissionDeniedToAccessCameraIos:
    'Galarmはカメラにアクセスする権限がありません。 \n\n設定＞Galarmでカメラへのアクセスを許可してください。',
  permissionDeniedToAccessCameraAndroid:
    'Galarmはカメラにアクセスする権限がありません。\n\n設定 > アプリ > Galarm > アクセス許可でカメラへのアクセスを許可してください。',
  permissionDeniedToAccessPhotosIos:
    'Galarmには写真にアクセスする権限がありません。\n\n設定＞Galarmで写真へのアクセスを許可してください。',
  permissionDeniedToAccessPhotosAndroid:
    'Galarmには写真にアクセスする権限がありません。\n\n設定 > アプリ > Galarm > アクセス許可を開き、ストレージへのアクセスを許可してください。',
  permissionDeniedToAccessCalendarIos:
    'Galarmにカレンダーへのアクセス権限がありません。\n設定＞カレンダーでGalarmへのアクセスを許可してください。',
  permissionDeniedToAccessCalendarAndroid:
    'Galarmにカレンダーへのアクセス権限がありません。\n設定 > アプリ > Galarm > アクセス許可でカレンダーへのアクセスを許可してください。',
  verificationCodeSent: '認証コードが{{formattedMobileNumber}} に送信された。',
  rateTheApp:
    'Galarmをお役立てください。Playストアでの評価をお願いいたします。ご協力ありがとうございました。',
  backgroundAppRefreshDisabledPermission:
    'Galarmがバックグラウンドアプリの更新を実行する権限を持っていないため、アラームがリングしない場合があります。\n\n有効」をタップし、「バックグラウンドアプリ更新」を有効にしてください。',
  selectedGroupUnselected:
    '参加者としてグループまたは連絡先セットを選択できます。以前に選択したグループは選択解除されます。続行しますか？',
  selectedContactsUnselected:
    'グループまたは連絡先セットを参加者として選択できます。以前に選択した連絡先は選択解除されます。続行しますか？',
  notificationsDisabledIos:
    'Galarmには通知を表示する権限がないため、アラームはリングしません。\n\n設定」>「Galarm」>「通知」を開き、「アラート」、「音」、「バナー」を有効にしてください。',
  notificationsDisabledAndroid:
    'アラーム通知は画面に表示されません。\n\n有効にする」をタップし、「通知」を選択して通知を許可してください。',
  alarmNotificationChannelDisabled:
    'アラーム通知は画面にポップアップ表示されません。 \n\n有効にする」をタップし、アラーム通知が画面に表示されるようにします。',
  alarmLockScreenNotificationlDisabled:
    'アラーム通知はロック画面に表示されません。 \n\n有効にする」をタップし、ロック画面にアラーム通知が表示されるようにします。',
  alarmWillNotRingDueToNotificationsDisabledIos:
    '通知が無効のため、アラームはリングしません。\n\n設定」>「Galarm」>「通知」と進み、「アラート」、「音」、「バナー」を有効にしてください。',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'クリティカルアラートが無効になっているため、電話がミュート状態でもアラームはリングしません。\n\n設定 > Galarm > 通知に移動し、「重要な警告」を有効にします。',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    '通知が無効になっているため、アラームは画面に表示されません。\n\n有効にする」をタップし、「通知」を選択して通知を許可してください。',
  noNotificationsScheduledAlarmInPast:
    'アラームは過去のものです。通知は予定されていません。',
  alarmOverAllowedLimit: {
    zero: '\nアラームリミット（{{limit}} ）に達しました。\n\n既存のアラームを削除または再利用してください。',
    one: 'アラームリミット（{{limit}} ）に達しました。\n\n非アクティブなアラームが1つあり、削除または再利用できます。',
    other:
      'アラームリミット（{{limit}} ）に達しました。\n\n削除または再利用できる非アクティブなアラームが{{count}} あります。'
  },
  alarmAlreadyConfirmed: 'もう確認しただろう。',
  alarmAlreadyDeclined: 'あなたはすでに拒否している。',
  participantMovedToPosition: '{{name}} {{count}} に移動した。',
  memberIsFirstParticpant: '{{name}} が最初の参加者である。',
  memberIsLastParticpant: '{{name}} が最後の参加者である。',
  cantContinueWithRegistrationWhenOffline:
    'デバイスがオフラインのため、登録を続行できません。',
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    'デバイスがオフラインのため、登録を続行できません。インターネットに接続されていることを確認してください。',
  confirmBlockContact:
    '{{name}} からのアラームは受信されなくなります。続行しますか？',
  confirmUnblockContact:
    '{{name}} のブロックを解除すると、ブロックされたアラームも復元されます。続行しますか？',
  unblockingContact: '{{name}} のブロックを解除し、アラームを復旧する。',
  unableToUnblockContact: '連絡先のブロックが解除できない。{{error}}',
  blockingContact: 'ブロッキング{{name}}.',
  unableToBlockContact: '連絡先をブロックできない。{{error}}',
  cantAddContactToAlarmBecauseBlocked:
    '{{name}} をブロックしました。アラームの参加者として追加するには、ブロックを解除してください。',
  cantAddContactToGroupBecauseBlocked:
    'あなたは{{name}} をブロックしました。グループのメンバーとして追加するには、ブロックを解除してください。',
  sendMessageNotAllowedForPastAlarms:
    '非アクティブなアラームにはメッセージを送信できません。',
  nameIsRequired: '名前は必須。',
  confirmDisableGroupAlarm:
    'グループアラームを無効にすると、他の参加者のアラームも無効になります。続行しますか？',
  removeMemberFromGroup: 'このグループから{{name}} 。',
  leaveGroupConfirm: 'このグループを離れたいですか？',
  deviceOffline:
    'デバイスがオフラインです。インターネットに接続されていることを確認し、再度お試しください。',
  cantUpdateGroupDefaulConfigWhenOffline:
    'デバイスがオフラインであるため、変更されたグループ設定は今後のアラームには適用されません。',
  specifyFeedback: 'フィードバックが必要だ。',
  specifyEmailId: 'メールアドレスは必須です。',
  specifyValidEmailId: 'メールアドレスが無効です。',
  specifyProblem: '問題の説明が必要。',
  appInstalledUsingSameNumberOnDifferentPhone:
    '同じ番号を使用して別の端末にGalarmをインストールした。この端末のアプリはリセットされます。',
  invalidUid:
    'このユーザーアカウントはもう存在しません。この端末のGalarmアプリはリセットされています。',
  accountDeleted: 'あなたのアカウントは削除されました。',
  deleteAccount: 'アカウントの削除',
  deletingAccount: 'アカウントの削除',
  howCanWeImprove: 'お別れするのは残念です。どうすれば改善できるか？',
  noMusicAlert: '音楽ファイルが見つかりません。',
  noLongerAvailableMusic:
    '{{fileName}} は使用できなくなりました。着信音は以下のものに変更されました。{{defaultRingtoneName}}',
  userInactiveTitle: 'お帰りなさい！',
  userInactiveMessage: '重要な用事のアラームを作成し、見逃すことはありません。',
  createAlarm: 'アラームの作成',
  updatedAppAvailable: 'アプリの新バージョンが登場。',
  alarmOccurrenceDisabled: 'この事象は無効',
  ownAlarmOccurrenceDone: {
    zero: 'でマーク完了。{{timeString}}',
    one: '{{timeString}} 、完了のマーク。{{dateString}}'
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'でスキップ{{timeString}}',
    one: '{{timeString}} でスキップ。{{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1アラーム有効。',
    other: '{{count}} アラームが有効。'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 アラーム有効。今後発生しないアラームは無効のままです。',
    other: '{{count}} アラームは有効。将来発生しないアラームは無効のままです。'
  },
  disabledAlarmsCount: {
    one: '1 アラーム無効。',
    other: '{{count}} アラームは無効。'
  },
  ownAlarmOccurrenceUnacknowledged: '未回答',
  participantAlarmOccurrenceDone: {
    zero: '{{participantName}} でマーク完了。{{timeString}}',
    one: '{{participantName}} {{timeString}} でマーク完了。{{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: '{{participantName}} でスキップ。{{timeString}}',
    one: 'スキップ by{{participantName}} at{{timeString}} on{{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged: '{{participantName}} 反応なし',
  groupAlarmOccurrenceConfirmed: {
    zero: 'で確認{{timeString}}',
    one: '{{timeString}} にて確認。{{dateString}}'
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'で拒否。{{timeString}}',
    one: '{{timeString}} 、拒否された。{{dateString}}'
  },
  warning: '警告',
  info: 'インフォメーション',
  confirm: '確認',
  error: 'エラー',
  cantUploadImage: '画像をアップロードできない',
  cantDeleteImage: '画像を削除できない',
  cantEditGroup: 'グループを編集できない',
  cantAddNewContact: '新しい連絡先を追加できない',
  cantDeleteContact: '連絡先が削除できない',
  cantEditContact: '連絡先の編集ができない',
  cantSaveAlarm: '保存できないアラーム',
  cantSaveGroup: 'グループを保存できない',
  cantReloadContacts: '連絡先のリロードができない',
  cantTakePhoto: '写真が撮れない',
  cantExportToCalendar: 'カレンダーにエクスポートできない',
  cantOpenPhotoLibrary: 'フォトライブラリが開けない',
  cantDeleteAlarmHistory: 'アラーム履歴が削除できない',
  cannotDuplicateAlarm: 'アラームの複製不可',
  upgradeToPremiumToDuplicateAlarm:
    'プレミアムにアップグレードすると、さらに多くのグループアラームや仲間アラームを作成できます。',
  confirmAcceptAlarm: '承諾アラーム？',
  confirmRejectAlarm: '拒否アラーム？',
  deleteAlarm: 'アラームを削除しますか？',
  restoreAlarmConfirmation: 'アラームの復旧？',
  respondToRepeatingAlarm: {
    zero: 'あなたは{{alarmCreatorName}} によってアラーム{{alarmName}} の受信者として追加されます。あなたは{{alarmTimeString}} {{alarmRepetitionString}} で警告を受けます。{{alarmDateString}}',
    one: 'あなたは{{alarmCreatorName}} によってアラーム{{alarmName}} の参加者として追加されます。あなたは{{alarmTimeString}} {{alarmRepetitionString}} で警告を受けます。{{alarmDateString}}'
  },
  respondToOneTimeAlarm: {
    zero: 'あなたは{{alarmCreatorName}} によってアラーム{{alarmName}} の受信者として追加されます。あなたは{{alarmTimeString}} で警告されます。{{alarmDateString}}',
    one: 'あなたは{{alarmCreatorName}} によってアラーム{{alarmName}} の参加者として追加されます。警報は{{alarmTimeString}} で通知されます。{{alarmDateString}}'
  },
  cantMoveMemberUp: '移動できない{{name}} アップ',
  cantMoveMemberDown: '動かせない{{name}} ダウン',
  decline: '拒否',
  cantEditProfile: 'プロフィールが編集できない',
  cantSelectContact: '連絡先を選択できない',
  cantSendMessage: 'メッセージを送信できない',
  cantSendVerificationCode: '認証コードを送信できない',
  confirmBlockContactTitle: 'ブロック連絡先の確認',
  confirmUnblockContactTitle: 'ブロック解除連絡先の確認',
  cantSaveProfile: 'プロフィールを保存できない',
  cantBlockContact: '連絡先をブロックできない',
  cantUnblockContact: '連絡先のブロックが解除できない',
  cantLoadEarlierMessages: '以前のメッセージが読み込めない',
  cantRestoreAlarm: 'アラームを復元できない',
  cantSendFeedback: 'フィードバックが送信できない',
  cantSubmitProblem: '投稿できない問題',
  cantDeletePastAlarmsInBulk: '非アクティブアラームを一括削除できない',
  cantAddNewGroup: '新しいグループを追加できない',
  alreadySnoozing: 'すでにスヌーズ',
  markAlarmUndone:
    "You marked this alarm 'Done'{{durationString}} ago.元に戻す？",
  markAlarmUnskipped:
    'このアラームはスキップされました{{durationString}} ago.元に戻す？',
  cantUndo: '元に戻せない',
  fetchMusicFromPhone: '利用可能な音楽を探す',
  enableAllAlarmsInCategory: 'このカテゴリのすべてのアラームを有効にしますか？',
  disableAllAlarmsInCategory: 'このカテゴリのアラームをすべて無効にしますか？',
  allAlarmsAlreadyEnabled: 'このカテゴリのアラームはすべて有効になっています。',
  allAlarmsAlreadyDisabled:
    'このカテゴリのアラームはすべて無効になっています。',
  time: '時間',
  timeForBuddy: '仲間の時間',
  timeForYou: 'でリマインダーを。',
  name: '名称',
  title: 'タイトル',
  notes: 'ノート',
  repeat: '繰り返し',
  preReminder: '事前リマインダー',
  remove: '削除',
  preReminderShortAlert: '{{duration}} 事前リマインダー',
  preReminderTitle: '{{alarmName}} で{{date}}',
  preReminderGroupHelperText:
    'この事前リマインダーはあなた専用です。他のアラーム参加者は、アラームを受信したら、自分で事前リマインダーを設定できます。',
  addPreReminder: '事前リマインダーの追加',
  helperTextForPreReminder:
    '上部のリセットボタンをタップすると、事前リマインダーが解除されます。',
  edit: '編集',
  delete: '削除',
  creator: 'クリエイター',
  useAsDefaultGroupConfiguration: '将来のアラームに適用する',
  done: '完了',
  skipped: 'スキップ',
  save: '保存',
  members: 'メンバー',
  mobileNumber: '電話番号',
  enter: '入る',
  addMembers: 'メンバー追加',
  respond: '応答する',
  yes: 'はい',
  no: 'いいえ',
  cancel: 'キャンセル',
  participants: '参加者',
  takePhoto: '写真を撮る',
  chooseFromLibrary: 'ライブラリから選択',
  resendCode: '再送コード',
  register: '登録',
  inviteFriends: 'このアプリを共有する',
  invite: '招待',
  incorrectNumber: '番号間違い？',
  date: '日付',
  cascadingAlarmInterval: '参加者が警告を発するまでの時間',
  ringParticipantAlarmsByDefault: '承諾なしでアラームをリングする',
  alarmRingtone: 'アラーム着信音',
  pickASong: '携帯電話から音楽を選ぶ',
  privacyPolicy: 'プライバシーポリシー',
  sendFeedback: 'フィードバック',
  galarmWeb: 'ギャラーム・ウェブ',
  galarmEnterprise: 'ガラーム・プロ',
  galarmAdmin: 'Galarm管理者',
  troubleshooting: 'トラブルシューティング',
  alarmDidntRingHelp: 'ヘルプアラームが鳴らないんです。',
  autoStartSettingHelperText:
    '自動起動の設定がある場合は、Galarmの自動起動が有効になっていることをご確認ください。',
  batteryOptimizationHelperText: 'Galarmのバッテリー最適化を無効にする',
  troubleshootingHelperText:
    '以下の設定がアラームの鳴動を妨げている可能性があります。これらの設定を見直してください。',
  getMoreHelpText: 'まだ問題がありますか？',
  autoStartSetting: '自動スタート',
  batteryOptimization: 'バッテリーの最適化',
  battery: 'バッテリー',
  rateTheAppSettings: 'このアプリを評価する',
  about: '情報 & ヘルプ',
  leaveGroup: '休暇グループ',
  removeGroup: 'グループの削除',
  viewMember: '表示{{memberName}}',
  removeMember: '削除{{memberName}}',
  changeMemberState: '警告できる人を変更する{{memberName}}',
  changeYourGroupState: '注意喚起の対象者を変更する',
  numMembersInGroup: 'メンバー{{count}}',
  notifications: 'アプリ通知が無効になっている',
  backgroundAppRefresh: 'アプリのバックグラウンド更新が無効',
  groupsInCommon: '共通のグループ',
  alarmsInCommon: '共通アラーム',
  close: '閉じる',
  responsePending: 'あなたの反応は？',
  later: '後日',
  install: 'インストール',
  all: 'すべて',
  numYears: { one: '1年', other: '{{count}} 年' },
  numMonths: { one: '1ヶ月', other: '{{count}} 月' },
  numDays: { one: '1日', other: '{{count}} 日' },
  hours: { one: '1時間', other: '{{count}} 時間' },
  minutes: { one: '1分', other: '{{count}} 分' },
  seconds: { one: '1秒', other: '{{count}} 秒' },
  minutesSmall: '{{count}} 分',
  secondsSmall: '{{count}} 秒',
  hoursAfter: { one: '1時間後', other: '{{count}} 時間後' },
  minutesAfter: { one: '1分後', other: '{{count}} 分後' },
  hoursAfterBuddy: {
    one: '仲間の1時間後',
    other: '{{count}} 仲間から数時間後'
  },
  minutesAfterBuddy: { one: '仲間の1分後', other: '{{count}} 仲間から数分後' },
  longerThanAnHour: '> 1時間以上',
  licenses: 'サードパーティーライセンス',
  version: 'バージョン',
  termsOfService: '利用規約',
  showPrivacyPolicy: '> プライバシーポリシー',
  showTermsOfService: '> 利用規約',
  showLicenses: '> サードパーティーライセンス',
  change: '変更',
  snooze: 'スヌーズ',
  ringOnVibrate: 'サイレントリング',
  vibrate: '振動',
  ringOnEarphoneOnly: 'イヤホンのみのリングアラーム',
  ringOnSpeakerAndEarphone: 'スピーカーとイヤホンでリングアラーム',
  whenEarphoneIsConnected: 'イヤホン接続時',
  tapGesture: 'タップ',
  slideGesture: 'スライド',
  silent: '静か',
  volume: '音量',
  soundAndVibration: '音と振動',
  advancedSettings: '詳細設定',
  gestureToDismissAnAlarm: 'アラームを閉じるジェスチャー',
  someNotificationsAreMuted: '一部の通知がミュートされる',
  allNotificationsEnabled: 'すべての通知を有効にする',
  notificationSettings: '通知設定',
  galarmWebNotifications: 'Galarmウェブ通知',
  alarmsfromOthersNotifications: '参加者アラーム通知',
  sharedAlarmNotifications: '共有アラーム通知',
  alarmChatNotifictions: 'アラームチャット通知',
  groupNotifications: 'グループ通知',
  alarmAcknowledgementNotifications: 'アラーム確認通知',
  send: '送信',
  prev: 'Prev',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'スヌーズ期間の指定',
  chooseDifferentDuration: '異なる期間を選択する',
  noCalendarsFound: 'カレンダーが見つかりません。',
  eventWillEndAt: 'イベントは{{timeString}} で終了する。',
  selectDurationWithinTheSameDay: 'イベントは日の境を越えることはできない。',
  cannotMapRepeatTypeInTheDeviceCalendar:
    'アラームの繰り返し設定がカレンダーに対応していないため、「カレンダーにエクスポート」できません。',
  alarmSavedToCalendar: 'アラームがカレンダーに保存されました。',
  faq: 'よくある質問',
  openSettings: '設定を開く',
  openAppSettings: 'アプリの設定を開く',
  alreadyDone: '完了',
  enableAutoStart: 'オートスタートを有効にする',
  restrictedApps: '制限アプリ',
  unmonitoredApps: '監視されていないアプリ',
  enableProtected: '保護されたアプリ',
  manageAppLaunch: 'アプリの起動を管理する',
  unblockContact: '連絡先のブロック解除',
  blockContact: 'ブロック連絡先',
  call: '電話{{name}}',
  text: 'テキスト{{name}}',
  chat: 'チャット',
  ok: 'OK',
  stopSnooze: 'スヌーズを止める',
  emailId: 'メールアドレス',
  feedback: 'フィードバック',
  submit: '投稿する',
  reportAProblem: '問題を報告する',
  enableAlarm: 'アラームを有効にする',
  restoreAlarm: 'アラームの復元',
  deletePastAlarms: '非アクティブアラームの削除',
  accept: '承諾',
  defaultPersonalAlarmName: 'リマインダー',
  defaultGroupAlarmName: 'グループイベント',
  defaultRecipientAlarmName: '仲間アラーム',
  search: '検索',
  recent: '最近',
  upcoming: '今後の予定',
  missed: '欠場',
  expired: '期限切れ',
  past: '過去',
  active: 'アクティブ',
  inactive: '非アクティブ',
  allAlarms: 'すべてのアラーム',
  myAlarms: '私のアラーム',
  participantAlarms: '参加者アラーム',
  pendingParticipantAlarms: '他からの新しいアラーム',
  alarmsWithUnreadChatMessages: '新しいチャットによるアラーム',
  systemNotifications: 'システムメッセージ',
  missedAlarms: 'アラームの見逃し',
  recentlyDeletedAlarms: '最近削除されたアラーム',
  searchAlarms: 'アラーム検索',
  participant: '参加者',
  you: 'あなた',
  none: 'なし',
  onceOnly: '一度だけ',
  weekdays: '平日',
  weekends: '週末',
  everyDayString: '毎日',
  everySunday: '毎週日曜日',
  everyMonday: '毎週月曜日',
  everyTuesday: '毎週火曜日',
  everyWednesday: '毎週水曜日',
  everyThursday: '毎週木曜日',
  everyFriday: '毎週金曜日',
  everySaturday: '毎週土曜日',
  sunday: '日曜日',
  monday: '月曜日',
  tuesday: '火曜日',
  wednesday: '水曜日',
  thursday: '木曜日',
  friday: '金曜日',
  saturday: '土曜日',
  january: '1月',
  february: '2月',
  march: '3月',
  april: '4月',
  may: '5月',
  june: '6月',
  july: '7月',
  august: '8月',
  september: '9月',
  october: '10月',
  november: '11月',
  december: '12月',
  jan: '1月',
  feb: '2月',
  mar: '3月',
  apr: '4月',
  shortMay: '5月',
  jun: '6月',
  jul: '7月',
  aug: '8月',
  sep: '9月',
  oct: '10月',
  nov: '11月',
  dec: '12月',
  sun: '日',
  mon: '月',
  tue: '火',
  wed: '水',
  thu: '木',
  fri: '金',
  sat: '土',
  first: '第1回',
  second: '2位',
  third: '3位',
  fourth: '第4回',
  fifth: '5位',
  every: 'すべての',
  oddNumberedDays: '奇数日付',
  evenNumberedDays: '偶数日付',
  noParticipants: '参加者なし',
  typeYourMessage: 'メッセージを入力する',
  cantPostMessageToPastAlarm:
    '非アクティブなアラームにメッセージを投稿できない',
  groupName: 'グループだ：{{name}}',
  alarm: 'アラーム',
  confirmed: '確認',
  declined: '拒否',
  Done: '完了',
  Skip: 'スキップ',
  Confirm: '確認',
  Decline: '拒否',
  Accept: '承諾',
  today: '今日',
  on: 'オン',
  recipientAlarm: '仲間アラーム',
  recipient: '受信者',
  remindMeAfter: 'リマインダー',
  remindMeIn: 'でリマインダー。',
  newRecipientAlarm: '新しい仲間アラーム',
  editRecipientAlarm: '仲間アラームの編集',
  newInstantAlarm: '新しいインスタントアラーム',
  instantAlarms: 'インスタントアラーム',
  alarmDone: '完了マーク付きアラーム',
  alarmSkipped: 'アラームスキップ',
  undo: '元に戻す',
  enterVerificationCode: '以下の4桁のコードを入力してください：',
  initializing: '初期化',
  tryAgain: '再挑戦',
  nMore: '+{{count}} more',
  enableNotification: '有効にする',
  enableAlarmNotificationChannel: '有効にする',
  enableBackgroundAppRefresh: '有効にする',
  viewImpactedAlarms: 'アラーム',
  viewImpactedGroups: 'グループ',
  dismiss: '閉じる',
  minsInterval: '+{{count}}m',
  hoursInterval: '+{{count}}h',
  daysInterval: '+{{count}}d',
  snoozeUnitHours: '時間',
  snoozeUnitMinutes: '分',
  snoozeUnitDays: '日数',
  selectMinutes: '分数を選択',
  selectHours: '時間を選択',
  selectDays: '日数を選択',
  selectHoursAndMinutes: '時間と分を選択',
  enterDuration: '期間',
  snoozeUnit: '単位',
  specifySnoozeUnit: 'スヌーズ単位の指定',
  specifySnoozeDuration: 'スヌーズ期間の指定',
  duration: '期間',
  quickAlarmName: {
    zero: '{{minutes}} ミニリマインダー',
    one: '{{hours}} アワーリマインダー',
    other: '{{hours}} 時間 分 リマインダー{{minutes}} '
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} リマインダー',
  addedAlarmFromVoiceCommand: '{{alarmName}} 、アラームを追加。{{dateString}}',
  addedTimerFromVoiceCommand: 'のタイマーを追加。{{duration}}',
  clear: 'クリア',
  ringtoneDuration: '着信音期間',
  chooseDuration: '期間をタップします。複数回タップできます。',
  newAlarm: '新しいアラーム',
  quickAlarm: 'リマインダー',
  quickAlarmWillRingAt: 'アラームは{{timeString}} でリングします。',
  random: 'ランダム',
  enableAllAlarms: 'すべてのアラームを有効にする',
  disableAllAlarms: 'すべてのアラームを無効にする',
  deleteAllAlarms: 'すべてのアラームを削除する',
  hideCategory: 'カテゴリを隠す',
  unhideCategory: 'カテゴリを解除する',
  dontShowHiddenCategories: '隠しカテゴリを表示しない',
  showHiddenCategories: '隠しカテゴリを表示する',
  welcomeAlarmsTitle: '始めよう！',
  welcomeAlarmsMessage: 'よく使われるアラームをいくつか紹介しよう：',
  onboardingAlarms: 'オンボーディング・アラーム',
  hiddenAlarmCategories: '隠しカテゴリ',
  restorePurchases: '購入した商品の復元',
  noAvailablePurchasesFound:
    'あなたのアカウントで利用可能な購入は見つかりませんでした。\n\n復元できない有効な購入がある場合は、「設定 > フィードバック」を使用してフィードバックをお送りください。',
  restoringPurchases: '購入品の復元',
  fetchingContacts: 'Galarmを使用している連絡先の確認',
  enterNameAndUploadPhotoForUser:
    '以下にお名前を入力し、オプションでプロフィール写真をアップロードしてください。',
  enterNameAndUploadPhotoForGroup:
    '以下に名前を入力し、オプションでグループ写真をアップロードしてください。',
  noContactsWithAppInstalled: '連絡先にGalarmを使っている人がいない。',
  galarmContacts: {
    one: '1 ガラーム連絡先',
    other: '{{count}} Galarmの連絡先'
  },
  contactsPendingInvitation: {
    one: '招待保留1件',
    other: '{{count}} 招待保留'
  },
  otherContacts: { one: 'その他連絡先1件', other: '{{count}} その他の連絡先' },
  over100Contacts: 'その他100以上の連絡先',
  noAlarms: 'アラームがありません。をタップして新しいアラームを追加します。',
  noContactsWeb: {
    one: 'Galarmを使用している連絡先が見つかりませんでした。連絡先へのアクセス権限がないか、連絡先にGalarmを使っている人がいないかのどちらかです。連絡先はモバイルアプリから同期できます。\n\nGalarmを使用している連絡先を様々な方法でアラームに追加し、アプリの恩恵を受けることができます。',
    other: 'アラームに連絡先を追加するには、モバイルアプリに登録してください。'
  },
  viewingAlarmsInCommon: '共通アラーム{{name}}',
  viewingGroupsInCommon: '共通のグループ{{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    'アクティブな参加者には、表示された順番でアラートが送られる。',
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'アクティブな参加者にのみアラートが通知されます。',
  changeGroupMemberStateText: {
    zero: '{{groupName}}" グループにアラームが作成されたときに警告できるメンバーを変更する。\n\n許可されたメンバーによってアラームが作成された場合のみ、アラートが表示されます。',
    one: '{{groupName}}" グループにアラームが作成された場合に、{{memberName}} にアラートできるメンバーを変更する。\n\n{{memberName}} は、許可されたメンバーによってアラームが作成された場合のみアラートされます。'
  },
  allowed: '可',
  notAllowed: '不可',
  changeStatus: 'メンバー',
  noUpcomingAlarms: '今後のアラームはなし',
  showMore: 'もっと見る',
  nMorePastAlarms: {
    one: '非アクティブアラーム1個追加',
    other: '{{count}} より非アクティブなアラーム'
  },
  shareAppMessage:
    '私はGalarmアプリを使ってリマインダーを作成することで、すべての回をタイムリーに完了させています。このアプリを通して、リマインダーにお互いを含めることもできます。この革新的なアラームとリマインダーアプリをダウンロードすることをお勧めします。{{downloadLink}}',
  addingMembers: 'メンバーを追加する。',
  unableToAddMembers: 'メンバーを追加できません。{{error}}',
  removingMember: 'メンバーを外す。',
  unableToRemoveMember: 'メンバーを削除できません。{{error}}',
  leavingGroup: 'グループを離れる。',
  unableToLeaveGroup: 'グループから出られない。{{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'このアラームの有効期限は{{durationString}} 。完了」をタップするとアラームが完了します。',
    one: 'このアラームの有効期限は{{durationString}} 前です。完了」をタップするとアラームが完了し、参加者に通知されます。',
    other:
      'このアラームの有効期限は{{durationString}} 前です。完了」をタップするとアラームが完了し、参加者に通知されます。'
  },
  donePersonalAlarmForCreator:
    'このアラームの有効期限は{{durationString}} 。アラームは完了としてマークされています。',
  skippedPersonalAlarmForCreator:
    'このアラームの有効期限は{{durationString}} 。アラームをスキップしました。',
  expiredGroupAlarm:
    'このアラームの有効期限は{{durationString}} 前です。参加者の確認をお願いします。',
  expiredPersonalAlarmForParticipant:
    'このアラームの有効期限は{{durationString}} 前です。{{creatorName}} までリマインダーをお願いします。',
  alarmDurationAndroid: 'アラームは今から{{durationString}} に設定。',
  alarmDurationIos: 'アラームは今から{{durationString}} に設定。',
  alarmFeedbackHeader:
    'このアラームの情報の一部をデバッグ目的で送信します。アラーム名、ノート、参加者などの個人データは共有されません。',
  privacyPolicyHeader:
    'お客様のプライバシーの尊重は、私たちの時計の中で常に刻まれています。そのため、アプリケーションのインストールに必要な場合、または法律によって要求された場合を除き、お客様の個人情報を誰とも共有することはありません。いかなる場合でも、お客様の情報を販売することはありません。とてもシンプルなことです！\n \nあなたが作成したアラームは、あなたのデバイス上でのみ閲覧可能であり、アラームに他の参加者が含まれている場合は、それらの参加者のデバイス上でも閲覧可能です。\n \n弊社は、Galarm の連絡先タブに入力するためにのみ、お客様の携帯電話のアドレス帳/連絡先リストにアクセスします。他の目的でそのリストを保存することはありません。お客様のアドレス帳であることを尊重します！\n \nカメラや写真へのアクセスは、プロフィール画像を設定するためにのみ行います。この写真は、あなたのアバターや、あなたが編集したグループのプロフィール写真として表示されます。設定したアラームのメンバーである他のGalarmユーザーもこの写真を見ることができます。\n\n弊社は、登録プロセス中のコードの要求と検証中など、すべての要求でお客様のデバイスのパブリックIPと固有のIDを送信します。この情報はお客様のアカウントにリンクされることはなく、潜在的な悪意のある活動を判断するためにのみ使用されます。\n \n当社が収集する情報とその使用方法の詳細については、完全なプライバシーポリシーをご確認ください。',
  termsOfServiceHeader:
    'Galarmアプリを使用することにより、お客様は、このモバイルアプリへのアクセスおよびその使用、ならびにGalarmアプリが提供する機能およびサービスについて規定する利用規約に同意したものとみなされます。 \n\nGalarmのモットーはシンプルです！  目覚ましアラーム、約束の時間厳守、服薬時間などのリマインダーを設定するパーソナルアラームや、映画やスポーツ、パーティーなどのグループ活動のリマインダーを設定するグループアラームなど、Galarmを使えば、ソーシャル目覚まし時計でお互いのリマインダーを設定したり、お互いの状況を把握したり、コミュニケーションをとることができます。\n\n本規約に同意された場合のみ、Galarmをご利用ください。下記はそのリンクです。',
  acknowledgedPersonalAlarm:
    '{{durationString}} 、アラームに "完了 "とマークした。',
  skippedPersonalAlarm: '{{durationString}} 、アラームをスキップした。',
  authenticatingWithServer: '接続...',
  selectParticipantsForPersonalAlarm:
    '個人のアラームのバックアップとして機能するグループまたは人のセットを選択します。',
  selectParticipantsForGroupAlarm:
    'グループ活動に参加するグループまたは人数を設定します。',
  acknowledgedGroupAlarm:
    '{{acknowledgedAsString}} このアラームは{{durationString}} 。',
  acknowledgedExpiredGroupAlarm:
    'このアラームの有効期限は{{durationString}} 。このアラームは{{acknowledgedAsString}} 。',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} {{durationString}} 、アラームに "完了 "のマークをつけた。',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'このアラームの期限は{{durationString}} 。{{creatorName}} 、アラームに「完了」のマークがついた。',
  entityNotFound:
    'おっと！選択された{{entityType}} が見つかりませんでした。まだ検索中か、削除された可能性があります。',
  goBack: '戻る',
  uploadingImageToServer: '画像をサーバーにアップロードする',
  deletingImageFromServer: 'サーバーから画像を削除する',
  errorUploadingImageToServer:
    '画像をサーバーにアップロードする際にエラーが発生しました。後で再試行してください。',
  unableToRemoveGroup: 'グループを削除できません。{{error}}',
  addingGroup: 'グループを追加する。',
  editingGroup: '編集グループ。',
  unableToAddGroup: 'グループを追加できません。{{error}}',
  unableToEditGroup: 'グループを編集できません。{{error}}',
  thanksForFeedback: '改善に協力してくれてありがとう。',
  licensesHeader:
    'Galarmには、サードパーティからライセンスされたソフトウェアが含まれています。対応する帰属およびライセンス情報については、サードパーティライセンスのページをご覧ください。',
  alarmAlreadyInSnooze: '{{durationString}} でリマインダーが表示される。',
  carrierChargesMayApply: 'キャリア料金が適用される場合があります。',
  selectCascadingInterval:
    'アラームを完了とマークしなければ、最初の参加者にはアラーム時間後{{firstParticipantDurationString}} 、2人目の参加者にはアラーム時間後{{secondParticipantDurationString}} 。',
  reorderParticipantsScreenDescription:
    '参加者には、表示された順番でアラートが送られる。',
  noMembers: 'メンバーなし',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} 参加者',
    one: '{{numActiveMembers}} アクティブおよび 非アクティブ参加者{{numInactiveMembers}} ',
    other:
      '{{numActiveMembers}} アクティブおよび 非アクティブ参加者{{numInactiveMembers}} '
  },
  groupAlarmWillRingAtSameTime:
    '参加者全員に{{timeString}} にアラートが送られる。',
  welcomeToGalarm: 'Galarmへようこそ',
  checkingConnection: '接続の確認',
  deletePastAlarmsTitle: 'より古い非アクティブアラームの削除',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 アラーム)',
    one: '{{option}} (1アラーム)',
    other: '{{option}} ( アラーム){{count}} '
  },
  chooseSnoozeInterval: 'でもう一度リマインダーを。',
  personalAlarmDescription:
    '起床、服薬、その他自分のためのタスクにパーソナルアラームを使用します。アラームを聞き逃した場合にリマインダーとして通知する参加者を追加することもできます。アラームが完了すると、参加者にも通知されます。',
  groupAlarmDescription:
    'お出かけやパーティーなど、グループでのアクティビティにグループアラームをご利用ください。参加者全員が同じ時間にアラームが鳴り、参加者の確認ができます。',
  recipientAlarmDescription:
    '他の誰か（「仲間」）のためにアラームを作成し、その人がやるべきことをリマインドします。仲間がアラームを聞き逃すと、あなたにリマインダーが通知されます。仲間がアラームを完了すると、あなたにも通知が届きます。\n  \nアラームはあなたのタイムゾーンで作成されることに注意してください。',
  whatsThis: 'これは何だ？',
  alarmCurrentlyDisabled: 'このアラームは現在無効です。',
  alarmCurrentlyRejected: 'このアラームは現在拒否されています。',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} がタイプしている...',
    other: '{{currentlyTypingUsers}} を入力している...'
  },
  noSearchResultsFound: '{{type}} が見つかりません。',
  noGroupsCreated: 'グループを作成していない。',
  noGroupsWeb: {
    one: 'グループを作成していない。',
    other:
      'グループの作成とアラームへの追加を開始するには、モバイルアプリに登録してください。'
  },
  internalErrorDuringRegistration:
    'ユーザー登録中に内部エラーが発生しました。数分後に再度お試しください。',
  selectRecipientAlarmInterval: {
    zero: '受信者と同じ時間帯に通知されます。',
    one: '受信者がアラームに「完了」マークを付けなかった場合、アラーム時間後に{{durationString}} 。',
    other:
      '受信者がアラームに「完了」マークを付けなかった場合、アラーム時間後に{{durationString}} 。'
  },
  selectRecipient: '受信者の選択',
  acknowledgedExpiredRecipientAlarm:
    'このアラームの有効期限は{{durationString}} 前です。このアラームを「完了」にしました。',
  skippedExpiredRecipientAlarm:
    'このアラームの有効期限は{{durationString}} 。このアラームはスキップされました。',
  expiredRecipientAlarm:
    'このアラームの有効期限は{{durationString}} 。完了」をタップするとアラームが完了し、{{creatorName}} に通知されます。',
  acknowledgedRecipientAlarm:
    'あなたはこのアラームに "完了 "の印をつけた{{durationString}} 。',
  skippedRecipientAlarm:
    'あなたはこのアラームをスキップした{{durationString}} 。',
  acknowledgedExpiredRecipientAlarmForCreator:
    'このアラームの期限は{{durationString}} 。{{recipientName}} 、アラームに「完了」のマークがついた。',
  skippedExpiredRecipientAlarmForCreator:
    'このアラームの期限は{{durationString}} 。{{recipientName}} 、アラームをスキップした。',
  expiredRecipientAlarmForCreator:
    'このアラームの有効期限は{{durationString}} 前です。{{recipientName}} までリマインダーをお願いします。',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} {{durationString}} 、アラームに "完了 "のマークをつけた。',
  selectRecipientForRecipientAlarm: 'アラーム受信者の選択',
  selectAtLeastOneParticipantForGroupAlarm:
    'アラームに参加者を最低1人追加する。',
  addAtLeastOneMemberToGroup: 'グループにメンバーを1人以上追加する。',
  atTheSameTime: 'それと同時に',
  myself: '私自身',
  group: 'グループ',
  someoneElse: '他の誰か',
  participantsAreNotified: '参加者に通知。',
  creatorIsNotified: '{{creatorName}} が通知される。',
  membersAddedToGroup: {
    one: '{{memberNames}} がグループ に追加される。{{groupName}}',
    other: '{{memberNames}} がグループ に追加される。{{groupName}}'
  },
  memberRemovedFromGroup:
    '{{memberName}} がグループ から削除されたため、一部のアラームに影響が出た可能性があります。{{groupName}} ',
  youRemovedFromGroup:
    '{{groupName}} グループから外され、アラームの一部に影響を与えた可能性があります。',
  memberLeftGroup:
    '{{memberName}} グループ 、一部のアラームに影響を与えた可能性があります。{{groupName}} ',
  youLeftGroup:
    'あなたはグループ{{groupName}} 、アラームの一部に影響を与えた可能性があります。',
  groupLeft: '{{groupName}} グループから離脱しました。',
  memberMovedToADifferentTimezone:
    '{{memberName}} 時間帯が変わったため、アラームに影響が出たかもしれません。',
  memberDstChanged: {
    zero: '{{memberName}} サマータイムが終了しました。関連アラームが更新されました。',
    one: '{{memberName}} サマータイムを開始しました。関連アラームを更新しました。'
  },
  impactedAlarms: '影響を受けるアラーム：',
  alwaysRingParticipantAlarmsOn:
    'あなたが拒否しない限り、他の人からのアラームはリングします。',
  alwaysRingParticipantAlarmsOff:
    '他者からのアラームは承諾しない限りリングしません。',
  agreeToTermsOfServiceAndPrivacyPolicy:
    '続行することで、Galarmの利用規約とプライバシーポリシーに承諾したことになります。',
  deleteAccountWarningHeader:
    '警告この操作は元に戻せません。アカウントを削除すると',
  deleteAccountDeleteAlarms: '- 作成したアラームをすべて削除する。',
  deleteAccountDeleteRecipientAlarms:
    '- 自分が受信者になっている仲間アラームをすべて削除する。',
  deleteAccountRemoveFromGroups: '- すべてのグループからあなたを外す',
  deleteAccountWarningFooter: {
    zero: '機種変更する場合は、新しい携帯電話にアプリをインストールし、同じ番号で登録すればよい。この携帯電話ではアプリを削除できます。\n\n一度アカウントを削除すると、復元することはできません。',
    one: '機種変更する場合は、新しい携帯電話にアプリをインストールし、同じ番号で登録すればよい。この携帯電話ではアプリを削除できます。\n\n一度アカウントを削除すると、復元することはできません。アクティブなGalarm契約はPlayストアで管理できます。',
    other:
      '機種変更する場合は、新しい携帯電話にアプリをインストールし、この携帯電話のアプリを削除すればいい。\n\n一度アカウントを削除すると、復元することはできません。アクティブなGalarm契約はApp Storeで管理することができます。'
  },
  typeConfirmationStringToDeleteAccount:
    '{{confirmationString}} と入力してアカウントを削除する',
  unableToDeleteAccount: 'アカウントを削除できません。{{error}}',
  impactedGroups: '影響を受けるグループ：',
  memberDeletedAccount:
    '{{memberName}} がアカウントを削除したため、一部のアラームやグループに影響が出た可能性があります。',
  confirmCountryCodeAndEnterNumberForExistingUser:
    '国番号を確認し、登録時に使用した電話番号を入力してください。',
  requiredPermissionsDisabledWarningIos:
    '通知は無効です。アラームがリングしません。をタップして修正します。',
  requiredPermissionsDisabledWarningAndroid:
    'Galarmはアラームをリングするために必要な権限を持っていません。タップして修正してください。',
  notificationChannelPopupDisabledWarningAndroid:
    'アラーム通知が画面にポップアップ表示されません。をタップして修正します。',
  alarmLockScreenNotificationDisabledWarningAndroid:
    'アラーム通知がロック画面に表示されない。をタップして修正します。',
  why: 'なぜ',
  how: 'どのように',
  registrationRequired: '要登録',
  phoneNumberRequired: '電話番号必須',
  fewSeconds: '数秒',
  lessThanMinute: '分未満',
  updateUnsupportedVersion:
    'Galarmを使い続けるには、アプリのアップデート版が必要です。',
  update: '更新',
  back: 'バック',
  unableToMarkAlarmDone:
    '現在、アラームを完了にすることができません。しばらくしてからもう一度お試しください。',
  unableToMarkAlarmUndone:
    '現在、アラームを解除することができません。しばらくしてからもう一度お試しください。',
  unableToMarkAlarmUnskipped:
    '現在、アラームをスキップ解除としてマークすることができません。しばらくしてからもう一度お試しください。',
  unableToSkipAlarm:
    '現在、アラームをスキップすることができません。しばらくしてからもう一度お試しください。',
  unableToSetGroupAlarmResponse:
    '現在、グループアラームを{{response}} 。しばらくしてからもう一度お試しください。',
  unableToDeleteAlarm:
    '現在アラームを削除できません。しばらくしてからもう一度お試しください。',
  unableToSetAlarmResponse:
    '現在、回答を設定できません。しばらくしてからもう一度お試しください。',
  unableToSnoozeAlarm: 'アラームをスヌーズできない。',
  unableToStopSnoozeForAlarm: 'アラームのスヌーズが止められない。',
  unableToUpdateParticipantAlarmRingerSettings:
    '現在、着信音設定を更新できません。しばらくしてからもう一度お試しください。',
  updateParticipantPreReminderDuration:
    '現在、事前リマインダーの期間を更新できません。しばらくしてからもう一度お試しください。',
  unableToUpdateAlarmRingerSettings:
    '現在、着信音設定を更新できません。しばらくしてからもう一度お試しください。',
  unableToUpdateAlarmPreReminderDuration:
    '現在、事前リマインダー時間を更新できません。しばらくしてからもう一度お試しください。',
  retrievingAlarmsFromServer: 'サーバーからアラームを取得...',
  startApp: 'スタート',
  phoneNumberRequiredGroupListHeaderText:
    'グループはGalarmの連絡先の集まりで、アラームを素早く作成することができます。\n\n電話番号で登録すると、Galarmを利用している連絡先を検索できます。グループに追加することができます。',
  phoneNumberRequiredBuddyAlarmHeaderText:
    '仲間アラームは、友達や家族にアラームを設定して、やるべきことをリマインドするのに使えます。\n\nこのアプリと他の多くのクールなアプリの機能を使用するには、あなたの電話番号を使用して登録してください。',
  phoneNumberRequiredContactListHeaderText:
    '連絡先とは、アラームの参加者として追加できる人のことです。\n\nGalarmを利用している連絡先を表示するには、電話番号で登録してください。アラームの参加者として追加することができます。',
  phoneNumberRequiredGroupAlarmHeaderText:
    'グループアラームは、グループのリマインダー設定に使用できます。\n\nこの機能や他の多くのクールなアプリの機能を使用するには、電話番号を使用して登録してください。',
  phoneNumberRequiredParticipantsHeaderText:
    '参加者はこのアラームのバックアップとして機能する。アラームを聞き逃すと、参加者にリマインダーが送られます。\n\nこの機能および他の多くのクールなアプリ機能を使用するには、電話番号を使用して登録してください。',
  youHaveNotRegistered:
    'アラームは現在バックアップされていません。\n\n電話番号を使って登録してください。登録すると、アラームがクラウド上にバックアップされます。これにより、機種変更時にアラームやその他の設定を復元することができます。\n',
  registerNow: '今すぐ登録',
  hoursAndMinutes: '時間と分',
  days: '日数',
  weeks: '週間',
  months: 'ヶ月',
  years: '年',
  daysOfWeek: '毎週（曜日を選択）',
  monthly: '毎月',
  yearly: '年間',
  everyDay: '毎日',
  selectDaysOfWeek: '曜日を選択',
  alarmRingMessageForSpecificDates:
    'このアラームがリングするのは、少なくとも{{dayOfMonth}} 日がある月だけです。続けますか？',
  alarmRingMessageForSpecificYear:
    'このアラームはうるう年にのみリングします。続けますか？',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'このアラームは{{weekNumberOfDayInMonth}} {{dayName}} 秒の月のみリングします。 ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'アラームの繰り返しが新しい日付に基づいて更新されました。',
  inviteFriendsToInstallGalarm:
    '連絡先とは、アラームの参加者として追加できる人のことです。連絡先を招待することができます：\n\n1.アドレス帳から\n\n2.手動で名前と電話番号を入力する。\n\n左上の「更新」をタップすると、登録後にGalarmの連絡先が再読み込みされます。',
  whyCreateNewGroup:
    'グループは、アラームの参加者として追加できる連絡先の集まりです。\n  \nグループはまだ作成されていません。',
  noNotifications: '新しい通知はありません。',
  createGroup: 'グループ作成',
  galarmRequiresAccessToContactsPermission:
    'Galarmは、連絡先へのアクセス許可を要求します。この情報はGalarmを使用している連絡先を識別するためにサーバーに送信されます。これらのユーザーをアラームの参加者として追加できます。',
  galarmRequiresAccessToAudioFilesPermission:
    '一部のアラームは着信音として電話の音楽を使用しています。選択した着信音でこれらのアラームをリングするために、デバイス上のオーディオファイルへのアクセスを許可してください。',
  galarmRequiresAccessToContactsSelectScreen:
    'Galarmには連絡先へのアクセス権限がありません。連絡先へのアクセスを許可してください。',
  allow: '許可する',
  deny: '拒否する',
  galarmRequiresAccessToContacts:
    '連絡先とは、アラームの参加者として追加できる人のことです。連絡先は以下の方法で追加できます：\n\n1.電話の連絡先から自動的にインポートする。アクセスを許可」をタップし、「連絡先」を有効にします。\n\n2.手動で名前と電話番号を追加する。連絡先を追加」をタップしてください。',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    'Galarmは、指定した時間に正確にアラームを鳴らすために、「アラームとリマインダー」の権限が必要です。次画面で有効にしてください。',
  accessToContactsPermissionRequiredAndroid:
    'Galarmはあなたの連絡先にアクセスする権限を持っていません。\n\nアクセスを許可すると、Galarmを使用している電話連絡先の人がGalarmの連絡先として表示されます。その人をアラーム参加者として追加できます。アクセスを許可」をタップし、「許可」を選択して「連絡先」を有効にします。\n\n手動でGalarmに連絡先を追加することもできます。',
  accessToContactsPermissionRequiredIos:
    'Galarmはあなたの連絡先にアクセスする権限を持っていません。\n\nアクセスを許可すると、Galarmを使用している電話連絡先の人がGalarmの連絡先として表示されます。その人をアラーム参加者として追加できます。アクセスを許可」をタップし、「連絡先」を有効にします。\n\n連絡先を手動でGalarmに追加することもできます。',
  giveAccess: 'アクセスを許可する',
  enterValidMobileNumber: '登録には有効な電話番号を入力してください。',
  proceedAnyway: 'とにかく進め',
  checkInvalidPhoneNumber:
    '{{mobileNumber}} に無効な番号が入力されたようです。',
  checkInvalidCountryCode:
    '{{countryCode}} として指定された国コードが無効です。複数の国コードが存在する場合は、該当する国コードを保持するようにフィールドを編集してください。',
  editNumber: '編集番号',
  editCountryCode: '国コードの編集',
  sendingCodeOnCall: 'コードで連絡する。',
  sendCodeOnCall: 'コードで連絡',
  registrationLandingScreenRegisterText:
    '続行」をタップして、電話番号を使用して登録します。\n\n登録することで\n1) アラームに参加者を追加したり、参加者とチャットができるようになります。\n2)アラームがクラウド上にバックアップされ、機種変更しても新しい携帯電話にアラームが表示されます。',
  continue: '続ける',
  registerLater: 'スキップ登録',
  cancelRegistration: '登録キャンセル',
  welcome: 'ようこそ',
  haventReceivedCodeYet: {
    one: 'まだコードを受け取っていませんか？1秒後に再度コードをリクエストできます。',
    other:
      'まだコードを受け取っていませんか？{{count}} 秒後に再度コードをリクエストできます。'
  },
  storagePermissionDenied:
    'ストレージのパーミッションが拒否されているため、音楽ファイルにアクセスできません。',
  havingTroubleRegisteringMessage:
    'Galarmは何度試みてもコードを確認できない。\n\n登録をスキップしてアプリを使用しますか？',
  autoDetectVerificationCode:
    '登録を簡単にするために、GalarmがSMSメッセージの表示を許可している場合、Galarmは認証コードを自動的に検出することができます。',
  notNow: '今ではない',
  verifyNumberText:
    '認証コードをSMSで送信します：\n\n{{formattedMobileNumber}}',
  addToContacts: '連絡先に追加する',
  todayString: '今日',
  tomorrowString: '明日',
  backups: 'バックアップ',
  acintyoTrademark:
    '© 2024 Acintyo, Inc.All rights reserved.\nGalarmはAcintyo, Inc.の登録商標です。\n米国特許10382613、10867286で保護されています。\nその他の特許は出願中。',
  acintyoTrademarkWeb:
    '© 2024 Acintyo, Inc.All rights reserved.GalarmはAcintyo, Inc.の登録商標です。\n米国特許10382613、10867286で保護されています。その他の特許は出願中。',
  acintyoTrademarkEnterprise:
    '© 2024 Acintyo, Inc.\n無断複写・転載を禁じます。',
  markAlarmDone: 'アラームを完了にする',
  skipAlarm: 'スキップアラーム',
  confirmGroupAlarm: '参加者の確認',
  declineGroupAlarm: '参加拒否',
  snoozeAlarm: 'スヌーズアラーム',
  deleteAlarmQuickAction: 'アラーム削除',
  disableAlarm: 'アラームを無効にする',
  confirmDisableAlarm: 'アラームを無効にしますか？',
  saveToCalendar: 'カレンダーにエクスポート',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    'グループアラームを事前に確認しています。  アラーム時間のリマインダーは必要ですか？',
  updateNow: '現在更新中',
  nParticipants: { one: 'その他参加者1名', other: '{{count}} 他の参加者' },
  details: '詳細',
  changeParanthesis: '変更',
  loadMoreHistory: 'もっと表示',
  alarmSummaryForHistory:
    '{{alarmName}} の過去の回答。\n繰り返し{{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: '過去の回答{{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: '繰り返し{{alarmRepetitionString}}',
  dontSeeCompleteHistory: '完全な歴史が見えない？',
  dontSeeCompleteHistoryExplanation:
    'アラーム履歴は、アラームが作成された時間から記録されます。各アラームの履歴はアラームの繰り返しによって制限されます。例えば、アラームが毎日繰り返される場合、30日間の履歴を保持します。\n\nアラーム時間または繰り返しを変更すると、アラーム履歴はリセットされます。',
  noActionAvailableForAlarmOccurrence:
    'このハプニングに対応できるアクションはない。',
  enterEmailIdForFeedback: '最新情報の返信と共有に使用',
  noPreviousOccurrences: 'アラーム履歴なし',
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: 'アラーム履歴なし',
    one: '{{missedAlarmOccurrences}} 最後を逃した{{count}}',
    other: '{{missedAlarmOccurrences}} 最後を逃した{{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% 音量,{{ringtoneName}}',
    one: '{{volume}}音量,{{ringtoneName}}, 発表タイトル'
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'クリティカル、{{volume}}%音量、{{ringtoneName}}'
  },
  previousOccurrences: 'アラーム履歴',
  retrieving: '検索...',
  startTimeOfDay: 'でスタート',
  endTimeOfDay: 'で終了',
  ringEveryHours: 'リング',
  everyNHours: '時給',
  everyMHoursNMinutes: '時間と分',
  selectHourlyRepetition: '1時間ごとの繰り返しを選択',
  everyNHoursRepetitionString: {
    one: '毎時{{startTimeString}} から{{endTimeString}} まで{{daysString}}',
    other:
      '{{startTimeString}} から{{endTimeString}} まで、{{count}} 時間ごと。{{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    '{{startTimeString}} から{{endTimeString}} までの{{hoursAndMinutes}} ごとに。{{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'すべての{{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: '{{startTimeString}} から毎時{{endTimeString}}',
    other: '{{startTimeString}} から{{count}} 時間ごと{{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    '{{hoursAndMinutes}} {{startTimeString}} から{{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    '{{hoursAndMinutes}} {{endTimeString}}',
  everyNHoursRepetitionStringForAlarmSummary: {
    one: 'まで毎時{{endTimeString}}',
    other: '{{count}} 時間ごと{{endTimeString}}'
  },
  daysRepetitionOddString: {
    other: 'すべての奇数日付',
    one: '奇数日付 ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'すべて偶数日付',
    one: '偶数日付 ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: '{{daysString}} 、奇数日に指定した時間にアラームがリングする。',
    other: '奇数日付の指定時間にアラームがリングします。'
  },
  evenNumberedRepetitionPreviewString: {
    one: '{{daysString}} 、偶数日に指定した時間にアラームがリングする。',
    other: '偶数日付の指定時間にアラームがリングします。'
  },
  hourlyRepetitionPreviewString: {
    one: 'このアラームは{{daysString}} に1回リングする：',
    other: 'このアラームは{{daysString}} に{{count}} 回リングする：'
  },
  daysRepetitionPreviewString: {
    one: 'このアラームは毎日リングが鳴る。',
    other: 'このアラームは{{count}} 日ごとにリングします。'
  },
  weeksRepetitionPreviewString: {
    one: 'このアラームは毎週リングが鳴る。',
    other: 'このアラームは{{count}} 週間ごとにリングします。'
  },
  everyNDays: { one: '毎日', other: '{{count}} 日ごと' },
  everyNWeeks: { one: '毎週', other: '{{count}} 週ごと' },
  everyNMonths: { one: '毎月', other: '{{count}} 月ごと' },
  everyNYears: { one: '毎年', other: '{{count}} 年ごと' },
  cantSetEndTime: '終了時間を設定できない',
  endTimeShouldBeGreater: '終了時間は開始時間より大きくなければならない。',
  cantSetStartTime: '開始時間を設定できない',
  startTimeShouldBeLess: '開始時間は終了時間より短くする。',
  everyDaySmall: '日',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'お客様が異なる時間帯に移動されたことを確認しました。アラームを調整しています。',
  ringOnDays: 'リング',
  yourAlarmsMayNotRing: 'アラームが鳴らないことがある',
  yourAlarmsMayNotRingTapToFix:
    'アラームがリングしない場合があります。タップして直してください。',
  xiaomiSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「Galarm」を許可して、アラームが常にリングするようにします。 \n\n詳しくは、www.galarmapp.com/setup-mi をご覧ください。',
  oppoSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「自動起動を許可」を有効にします。\n\n - 携帯電話の「最近使ったアプリ」ボタンをタップし、Galarmアプリのアイコンを下にスワイプして「ロック」をタップします。  \n\n詳しくは、www.galarmapp.com/setup-oppo。',
  oppo8AndLowerSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「自動起動を許可」を有効にします。\n\n - 携帯電話の「最近使ったアプリ」ボタンをタップし、Galarmアプリのアイコンを下にスワイプして「ロック」をタップします。\n\n詳しくは、www.galarmapp.com/setup-oppo-8-and-lower。',
  vivo9And10SetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「Galarm」を許可します。\n\n - バッテリー使用状況」をタップし、「バックグラウンドでの消費電力が高い」を選択し、「Galarm」を許可します。\n\n詳しくは、www.galarmapp.com/setup-vivo。',
  vivo11SetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n- 自動起動を有効にする」をタップし、「Galarm」を許可します。\n\n- バッテリー使用状況」をタップし、「バックグラウンドでの消費電力が高い」を選択し、「Galarm」を許可します。\n\n詳しくは、www.galarmapp.com/setup-vivo-11。',
  vivoSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n- 電話設定 > アプリ > 特別なアプリへのアクセス > 自動起動」を開き、Galarmの自動起動を有効にします。\n\n- 電話設定 > バッテリー > バックグラウンド消費電力管理 > Galarm "を開き、"バックグラウンド消費電力を制限しない "を選択します。\n\n詳細については、www.galarmapp.com/setup-vivo-12-and-above。',
  vivo8AndLowerSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「Galarm」を許可します。\n\n - バッテリー使用状況」をタップし、「バックグラウンドでの消費電力が高い」を選択し、「Galarm」を許可します。\n\n詳しくは、www.galarmapp.com/setup-vivo-8-and-lower。',
  huawei7AndLowerAdditionalSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 保護されたアプリ」をタップし、「Galarm」を許可します。\n\n詳しくは、www.galarmapp.com/setup-huawei-7-and-lower をご覧ください。',
  huaweiAdditionalSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - アプリの起動を管理」をタップし、「Galarm」をオフにして「手動で管理」にします。次のプロンプトで、「自動起動」と「バックグラウンドで実行」が有効になっていることを確認します。\n\n詳細については、www.galarmapp.com/setup-huawei。',
  meizuSetupInstructions:
    'アラームが常にリングになるように、以下のリンクの指示に従ってください： https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「自動起動マネージャー」をクリックします。自動起動マネージャー "で "Galarm "を有効にします。\n\n詳しくは、www.galarmapp.com/setup-asus をご覧ください。',
  nokia8AndLowerAdditionalSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 自動起動を有効にする」をタップし、「Galarm」を許可します。\n\n詳しくは、www.galarmapp.com/setup-nokia-8-and-lower をご覧ください。',
  nokiaAdditionalSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 制限アプリ」をタップし、「Galarm」を無効にします。\n\n詳しくは、www.galarmapp.com/setup-nokia をご覧ください。',
  lenovoSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 携帯電話の「最近使ったアプリ」ボタンをタップし、Galarmアプリを見つけ、右上の「ロック」アイコンをタップします。\n  \n詳しくは、https://dontkillmyapp.com/lenovo をご覧ください。',
  samsungAdditionalSetupInstructions:
    'アラームが常にリングするように、以下の指示に従ってください：\n\n - 非監視アプリ」をタップし、「Galarm」を許可します。\n\n詳しくは、www.galarmapp.com/setup-samsung をご覧ください。',
  tecnoSetupInstructions:
    'アラームが常にリングになるように、以下のリンクの指示に従ってください： https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'アラームが常にリングになるように、以下のリンクの指示に従ってください： https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'デフォルトでは、サムスンはすぐに消える「簡易」通知を表示します。詳細」アラーム通知を表示するには、以下の手順に従ってください。\n\n - 電話設定を開き、"通知 "を選択します。\n - 詳細」通知のスタイルを選択します。\n  \n詳細については、www.galarmapp.com/setup-samsung-android11-and-above をご覧ください。',
  iosSetupInstructions:
    'デフォルトでは、iOSの通知バナースタイルは「一時的」に設定されており、通知はすぐに消えてしまいます。以下の手順に従って、バナースタイルを「持続的」に設定してください。\n\n- 電話設定を開き、Galarmまでスクロールします。\n- 通知]を選択し、バナースタイルを[永続]に設定します。\n\n詳細については、www.galarmapp.com/setup-ios をご覧ください。',
  iosSetupInstructions15AndBelow:
    'デフォルトでは、iOSの通知バナースタイルは「一時的」に設定されており、通知はすぐに消えてしまいます。以下の手順に従って、バナースタイルを「持続的」に設定してください。\n\n- 電話設定を開き、Galarmまでスクロールします。\n- 通知]を選択し、バナースタイルを[永続]に設定します。\n\n詳細については、www.galarmapp.com/setup-ios-15-below をご覧ください。',
  setNotificationsStyle: '通知スタイルの設定',
  setNotificationsStyleToDetailed: '通知スタイルを "詳細 "に設定',
  setBannerStyle: 'バナースタイルの設定',
  setBannerStyleToPersistent: 'バナースタイルを "Persistent "に設定する。',
  selectAtleastOneDay: 'アラームがリングする日を選択します。',
  selectAtLeastOneHourOrMinute:
    '時」または「分」のいずれかが0以外の値であること',
  hourlyAlarmDefaultStartTimeSetAlert: 'アラーム開始時間を午前8時に設定。',
  batteryUsage: 'バッテリーの使用状況',
  dontShowAgain: '二度と現れないで',
  alarmWillRingEveryHourTillSeen:
    'アラームは、あなたが何か行動を起こすまで、1時間ごとにスヌーズする。',
  unknown: '不明',
  alarmMarkedDone: '{{name}} はアラームに「完了」をマークした。',
  alarmMarkedSkipped: '{{name}} はアラームをスキップした。',
  alarmIsMarkedDone: '{{alarmName}} は完了と表示される。',
  alarmIsSkipped: '{{alarmName}} はスキップされる。',
  alarmIsConfirmed: '{{alarmName}} が確認された。',
  alarmIsDeclined: '{{alarmName}} は拒否される。',
  alarmIsSnoozed: '{{alarmName}} がスヌーズ。',
  alarmIsAccepted: '{{alarmName}} が承諾される。',
  alarmIsRejected: '{{alarmName}} は拒否される。',
  newContactInfo:
    '連絡先の氏名と電話番号をご記入ください。\n\n連絡先として追加できるのは登録ユーザーのみです。Galarmに登録されていない場合は、「設定 > このアプリを共有」から招待することができます。電話番号で登録後、連絡先として追加できます。 ',
  cantEditContactMobileNumber:
    '電話番号は編集できません。電話番号を変更したい場合は、連絡先を削除し、再度追加してください。',
  phoneContactsNotSyncedPermissionDenied:
    'Galarmは電話連絡先にアクセスする権限がありません。連絡先は同期されません。',
  inviteContactMessage:
    'こんにちは{{contactName}}, Galarmを使ってアラームやリマインダーにあなたを入れたい。{{downloadLink}} からアプリをダウンロードしてください。この番号を使って登録し、アラームの共有を開始してください。',
  noRepeat: '繰り返さない',
  deepLinkNoLongerValidTitle: 'おっと！',
  deepLinkNoLongerValidMessage:
    'アラームが作成されませんでした。古いリンクか無効なリンクの可能性があります。',
  inviteContact: '連絡先を招待する',
  fromPhoneBook: 'アドレス帳から',
  addFromPhoneBook: 'アドレス帳から招待する',
  manually: '手動',
  addManualContact: '手動で招待する',
  noOtherContactsFound: 'アドレス帳の連絡先はすべてGalarmを使用しています。',
  cantAddPhoneContactPermissionDenied:
    'Galarmに連絡先へのアクセス権限がありません。連絡先へのアクセスを許可してください。',
  alarmWillRingWithRandomRingtone: 'アラームの着信音はランダムに選択されます。',
  alarmWillRingWithRandomRingtoneGlobal:
    'アラームの着信音はランダムに選択されます。',
  createdAlarmFromDeepLinkTitle: 'これで設定完了だ！',
  createdAlarmFromDeepLinkMessage:
    '{{alarmTime}} の"{{alarmName}}"でリマインダーが表示される。{{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'あなたは "{{alarmName}}" を思い出すだろう。{{repetitionString}}',
    one: 'リマインダーは "{{alarmName}}"{{repetitionString}} {{alarmTime}} から始まる。{{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'カテゴリを削除しますか？',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 つのアラームがこのカテゴリに属しています。このカテゴリを削除すると、そのアラームのカテゴリはリセットされます。',
    other:
      '{{count}} アラームはこのカテゴリに属します。このカテゴリを削除すると、それらのアラームのカテゴリはリセットされます。'
  },
  updateReadyToInstallAlertTitle: 'アップデートをインストールしますか？',
  updateReadyToInstallAlertMessage:
    'アップデートのインストール準備が整いました。今すぐアップデートをインストールしますか？ ',
  hideCategoryAlertTitle: 'カテゴリを隠す？',
  hideCategoryAlertMessage: {
    zero: '今後、このカテゴリを使用することはできません。',
    one: '1 つのアラームはこのカテゴリに属します。このカテゴリを非表示にすると、そのアラームのカテゴリがリセットされます。\n  \n今後、このカテゴリを使用することはできません。',
    other:
      '{{count}} アラームはこのカテゴリに属します。このカテゴリを非表示にすると、それらのアラームのカテゴリがリセットされます。\n  \n今後、このカテゴリを使用することはできません。'
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 つのアラームがこのカテゴリに属しています。削除しますか？\n\n参加者アラームは削除されませんのでご注意ください。',
    other:
      '{{count}} アラームはこのカテゴリに属しています。削除しますか？\n\n参加者アラームは削除されないことに注意してください。'
  },
  enterNameForGroup: 'グループの名前を入力します。',
  enterMobileNumber: '電話番号を入力',
  enterNotesForAlarm: 'アラームのノート入力',
  enterUserName: '名前を入力',
  enterEmailId: 'オプション',
  enterFeedback: 'どうすれば改善できるか？',
  describeProblem: 'あなたの問題を説明する',
  invited: '招待',
  resend: '再送',
  addContact: '連絡先を追加',
  inviteAgain: '再び招待する',
  contactNotRegistered:
    '{{contactName}} はまだGalarmをインストールしていません。インストール後、電話番号で登録するとアラームに追加できるようになります。',
  registration: '参加登録',
  verify: '検証',
  profile: 'プロフィール',
  profileImage: 'プロフィール画像',
  addProfileImage: 'プロフィール画像の追加',
  groupDetails: 'グループ詳細',
  newGroup: '新グループ',
  uploadImage: 'イメージを変える',
  removeImage: '画像を削除する',
  selectParticipants: '参加者を選ぶ',
  selectMembers: 'メンバーを選ぶ',
  selectTimezone: '時間帯の選択',
  searchForATimezone: '時間帯の検索',
  timezone: '時間帯',
  deviceTimezone: 'デバイスの時間帯',
  specifyTimezoneForAlarm: 'アラームの時間帯指定',
  editName: '編集名',
  groupImage: 'グループ画像',
  addGroupImage: 'グループ画像を追加',
  selectAlarmType: 'のアラームを作成する。',
  contactDetails: '連絡先',
  commonAlarms: 'アラーム',
  commonGroups: 'グループ',
  groupAlarmsTitle: 'アラーム',
  selectRingtone: '着信音の選択',
  selectMusic: '音楽を選ぶ',
  select: '選択',
  personalAlarm: 'パーソナルアラーム',
  groupAlarm: 'グループアラーム',
  newPersonalAlarm: '新しいパーソナルアラーム',
  newGroupAlarm: '新しいグループアラーム',
  editPersonalAlarm: 'パーソナルアラームの編集',
  editGroupAlarm: 'グループアラームの編集',
  rearrangeParticipants: '参加者の再配置',
  alarmConversation: 'アラームチャット',
  timezoneMismatchDetected:
    '何か、前回Galarmを使った時とは違う時間帯にいるようだ。\n\n新しい時間帯に対応するため、Galarmを再起動してください。',
  deviceRestartRequired: 'アプリの再起動が必要',
  disabledAlarm: '無効アラーム{{name}}',
  newContact: '新規連絡先',
  contactName: '連絡先',
  contactMobileNumber: '連絡先電話番号',
  enterNameForContact: '連絡先の名前を入力してください。',
  pickCountryForContact: '連絡先の国',
  enterMobileNumberForContact: '連絡先の電話番号を入力してください。',
  specifyNameForContact: '連絡先は必須項目です。',
  specifyValidPhoneNumberForContact: '有効な電話番号が必要',
  addingContact: '連絡先の追加',
  deletingContact: '連絡先の削除',
  editingContact: '連絡先の編集',
  pending: '申請中',
  deleteContact: '連絡先を削除',
  galarmContact: '手動で追加',
  phoneContact: '電話連絡先から追加',
  confirmDeleteContactTitle: '{{name}} を削除しますか？',
  confirmDeleteContact: 'アラームに追加することはできません。',
  contactAdded:
    '素晴らしい！これで{{name}} をアラームの参加者として追加できます。',
  contactInvited:
    '{{name}} 、Galarmの利用を呼びかけるテキストメッセージが送信された。',
  changeSnoozeDuration: '変更期間',
  durationOfEvent: '開催期間',
  upgradeToPremium: 'プレミアムへのアップグレード',
  premium: 'プレミアム',
  alarmLimitReached: 'アラームリミット到達',
  unableToConnectToGooglePlay:
    'Google Playに接続できません。Googleアカウントにログインしていることを確認してください。',
  cantLoadVideo: 'ビデオを読み込めません',
  cantUpgradeToPremium: 'プレミアムにアップグレードできない',
  unableToConnectToIapAndroid:
    'Playストアに接続できません。Playストアアプリを開き、ログインしていることを確認してください。',
  unableToConnectToIapIos:
    'App Storeに接続できません。App Storeアプリを開き、ログインしていることを確認してください。',
  premiumFeaturesIos:
    'プレミアム・ユーザーになると、同時アラームの上限が{{maxLimit}} 。\n\nまた、より多くの機能を開発することができます。',
  premiumFeaturesAndroid:
    'プレミアムにアップグレードすれば、無制限にアラームを作成できます。\n\nまた、ご購入いただくことで、より多くの機能を開発することができます。',
  upgrade: 'アップグレード',
  rvInternalError:
    '内部エラーのため、ビデオを読み込むことができませんでした。アラームの上限を1増やしました。',
  rvNetworkError:
    'インターネット接続が良好であることをご確認の上、再度お試しください。',
  rvNotFoundError:
    '現在、適切なビデオを見つけることができませんでした。アラームの上限を1つ増やしました。',
  dontRemindMe: 'リマインダーはいらない。',
  sameTimeAsBuddy: '仲間と同じ時間',
  noAlertForBuddyAlarm: 'このアラームを通知しないことを選択しました。',
  noAlertRecipientIntervalDescription: '警告は出ない。',
  chooseHours: '時間を選択',
  chooseMins: '分数を選択',
  changeHours: '営業時間の変更',
  moreOptions: 'その他のオプション',
  set: '設定',
  chooseNumberOfDays: '日数を入力',
  chooseNumberOfWeeks: '週数を入力',
  numberOfDaysShouldBeAtLeastOne: '日数は少なくとも1日',
  numberOfWeeksShouldBeAtLeastOne: '週数は少なくとも1週',
  numberOfMonthsShouldBeAtLeastOne: '月数は少なくとも1',
  numberOfYearsShouldBeAtLeastOne: '年数は少なくとも1年',
  frequency: '頻度',
  configuration: '構成',
  endDate: 'アラーム終了日付',
  noEndDate: '終了日付なし',
  alarmEndDateSetOnAlarm: 'アラーム終了日付を{{endDateString}} に設定。',
  noAlarmDateSetForAlarm: 'このアラームには終了日付は設定されていない。',
  noOccurrenceForThisAlarmAsPerEndDate:
    '終了日付はアラームの発生を許可しません。',
  alarmDateShouldBeBiggerThanAlarmStartDate:
    'アラーム終了日付はアラーム開始日付より後でなければならない。',
  aWeek: '一週間',
  aMonth: '一ヶ月',
  allInactiveAlarms: 'すべての非アクティブアラーム',
  chooseAlarmEndDate: 'アラーム終了日付の選択',
  next: '次ページ',
  next7Days: '今日から7日間',
  next30Days: '今日から30日間',
  next12Months: '今日から12ヶ月後',
  next10Years: '今日から10年',
  chooseDate: '日付を選ぶ...',
  learnMore: 'さらに詳しく',
  repeatEvery: '繰り返し',
  repeatEveryDays: '日',
  repeatEveryWeeks: '週間',
  repeatEveryMonths: '月',
  repeatEveryYears: '年',
  autoSnooze: '自動スヌーズアラーム',
  autoSnoozePerAlarm: '自動スヌーズアラーム',
  unableToUploadGroupAvatar:
    'グループ画像をアップロードできません。後でもう一度お試しください。',
  addNewMembersToExistingAlarms:
    'このグループには以下のアラームがある：\n\n{{alarmNames}}\n    \nこれらのアラームに新しいメンバーを追加しますか？',
  removeGroupMemberAlert:
    'メンバーを削除すると、以下のアラームに影響する：\n\n{{alarmNames}}\n\nそれでもメンバーを削除しますか?',
  leaveGroupAlert:
    'グループ離脱は以下のアラームに影響する：\n\n{{alarmNames}}\n\nまだグループを離れますか？',
  updateAppAfterFeedback:
    'アプリのバージョンが古いようです。アップデートをお願いいたします。',
  changesAreLost: '保存されていない変更がある。\n\n出発前に保存しますか？',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    '「リマインダー」の間隔は、繰り返しの間隔より短くする。',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    '「参加者が警告を発するまでの時間」の間隔は、繰り返し間隔より短くする。',
  subscriptionCanceled:
    'プレミアムサブスクリプションがキャンセルされました。サブスクリプションを更新するまで、プレミアム機能はご利用いただけません。',
  unableToRenewSubscription:
    'プレミアム購読の更新に問題が発生しました。お支払い情報が最新であることをご確認ください。',
  notice: 'お知らせ',
  ringerSettingsWillApplyToNewAlarms:
    '音と振動の設定変更は、新しいアラームにのみ適用されます。\n\n既存のアラームについては、アラームを編集して音と振動の設定を変更できます。',
  renew: 'リニューアル',
  galarmPremium: 'ガラルム・プレミアム',
  payMonthly: '月払い{{price}} ',
  payYearly: '年俸制{{price}} ',
  free: '無料',
  unlimited: '無制限',
  personalAlarms: 'パーソナルアラーム',
  groupAlarms: 'グループアラーム',
  buddyAlarms: '仲間アラーム',
  alarmRepetitions: '繰り返し',
  ringtones: '着信音',
  alarmHistory: 'アラーム履歴',
  base: 'ベーシック',
  advanced: '上級',
  complete: 'フル',
  alarmHistoryFree: '限定',
  phoneMusicAsRingtone: '着信音として電話音楽',
  startFreeTrial: '14日間無料トライアル開始',
  chargedAtEndOfFreeTrial:
    '14日間の無料トライアルが終了した後は、購読をキャンセルしない限り課金されます。',
  monthlySubscription: '毎月',
  yearlySubscription: '年間',
  premiumRingtoneRequiresUpgrade: 'この着信音にはプレミアム契約が必要です。',
  defaultPremiumFeaturesDescription:
    'プレミアムにアップグレードすると、これらのクールな機能が有効になります。',
  groupAlarmsPremiumFeaturesDescription:
    '1つ以上のグループアラームを作成するには、プレミアムサブスクリプションが必要です。',
  buddyAlarmsPremiumFeaturesDescription:
    '他の人（"仲間"）のために1つ以上のアラームを作成するには、プレミアムサブスクリプションが必要です。',
  shareableAlarmLinksPremiumFeaturesDescription:
    'より多くの共有可能なアラームリンクを作成するには、プレミアム購読が必要です。',
  viewPremiumFeaturesDescription:
    'プレミアムユーザーであることに感謝します。プレミアム会員になると、これらのクールな機能をご利用いただけます。',
  accessToPremiumFeatures:
    'サブスクリプションの一部として、これらの機能にアクセスできます：',
  alarmCategoryPremiumFeaturesDescription:
    '新しいカテゴリを作成するには、プレミアムサブスクリプションが必要です。',
  galarmWebPremiumFeaturesDescription:
    'ウェブブラウザでGalarmを使用するにはプレミアム契約が必要です。',
  instantAlarmPremiumFeaturesDescription:
    'より多くのインスタントアラームを作成するには、プレミアムサブスクリプションが必要です。',
  personalAlarmsFeatureDescription:
    '起床、服薬、その他自分のためのタスクにパーソナルアラームを使用します。アラームを聞き逃した場合にリマインダーとして通知する参加者を追加することもできます。アラームが完了すると、参加者にも通知されます。',
  groupAlarmsFeatureDescription:
    'お出かけやパーティーなど、グループでのアクティビティにグループアラームをご利用ください。参加者全員が同じ時間にアラームが鳴り、参加者の確認ができます。',
  buddyAlarmsFeatureDescription:
    '他の誰か（「仲間」）のためにアラームを作成し、その人がやるべきことをリマインドします。仲間がアラームを聞き逃すと、あなたにリマインダーが通知されます。仲間がアラームを完了すると、あなたにも通知が届きます。',
  alarmRepetitionsFeatureDescription:
    '30分ごと、2時間45分ごと、隔日、隔週など、高度な繰り返しのアラームを作成できます。',
  ringtonesFeatureDescription:
    'アラーム用の着信音を、豊富なプレミアム着信音からお選びいただけます。プレミアム着信音は随時追加されます。',
  phoneMusicAsRingtoneFeatureDescription:
    '携帯電話の曲/音楽をアラーム着信音として選択します。',
  shareableAlarmLinksFeatureDescription:
    'リンクを使ってアラームを他の人と共有することができます。\n\n例えば、先生がEメールやWhatsApp、メッセージアプリを使って生徒とアラームリンクを共有することができます。生徒がリンクをタップすると、クラスアラームのコピーが生徒の携帯電話に作成されます。指定された時間になると、すべての生徒に授業への参加が通知されます。',
  instantAlarmFeatureDescription:
    'ボタンをクリックするだけで、即座にグループに警告を発します。\n\nこのアラームを使用して、緊急訓練、試合開始、緊急タスク/ミーティングなど、緊急に注意を要するイベントを知らせます。',
  alarmHistoryFeatureDescription:
    '繰り返されるアラームごとに、過去のすべての応答を表示します。これにより、体育の授業を休んだ日や、母親が薬を飲み忘れた日などを簡単に知ることができます。',
  announceAlarmTitleFeatureDescription:
    'アラームがリングしたときにアラームタイトルをアナウンスします。',
  exportToCalendarPremiumFeaturesDescription:
    'より多くのアラームをカレンダーにエクスポートするには、プレミアムサブスクリプションが必要です。',
  exportToCalendarFeatureDescription:
    'アラームをカレンダーにエクスポートできます。これにより、お使いのデバイスのカレンダーでアラームを簡単に表示でき、空き時間をより反映させることができます。',
  galarmWebFeatureDescription:
    'アラームの作成、編集、削除には、ウェブブラウザ上でGalarmを使用します。',
  preReminderFeatureDescription:
    '事前リマインダーを設定することで、次回アラームの通知を受けることができます。アラームの30分前、1日前など、実際のアラーム時間の前にアラートを受け取る相対期間を設定できます。',
  calendarViewFeatureDescription:
    'カレンダーでアラームを表示し、どの日のアラームも簡単に確認できます。',
  updatePhoneNumberFeatureDescription:
    'このアカウントで使用している電話番号を変更できます。すべてのアラーム、連絡先、グループ、その他の設定は新しい電話番号に移行されます。',
  alarmPhotoFeatureDescription:
    'アラームに写真を追加して、薬の写真、タスクの指示、アイテムの写真など、すばやく視覚的な合図を送ることができます。アラームがリングすると写真が表示されます。',
  taskListFeatureDescription:
    'タスクリストを作成し、関連するタスクをまとめる。',
  calendarViewPremiumFeature: 'カレンダー表示',
  updatePhoneNumberPremiumFeature: '電話番号の変更',
  alarmNotFound: 'アラームが見つからない',
  alarmNotFoundMessage:
    'このアラームは最近削除された可能性があります。削除」をタップすると、このアラームの通知が無効になります。',
  alarmExistsWithDeepLinkTitle: '重複アラーム',
  alarmExistsWithDeepLinkMessage:
    'このリンクを使用して作成されたアラームは、お使いの携帯電話にすでに存在しています。',
  alarmDoesntHaveNextDate: 'アラームには有効な次回の日付がない。',
  sharePersonalAlarmDeepLinkDescription:
    'このリンクをタップすると、このイベントのアラームが{{dateString}} 。',
  joinGroupAlarmDeepLinkDescription:
    'このリンクをタップすると、以下のグループイベントに参加できる。{{dateString}}',
  subscribedAlarmEdited:
    '{{alarmCreatorName}} が更新されました .アラームを更新しますか？{{alarmName}}',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} が削除されました .アラームを削除しますか？{{alarmName}}',
  responseReceivedForTicket:
    'チケット番号{{ticketId}} に対応いたしました：{{email}}.受信トレイにない場合は、support@galarm.zendesk.com からのメールが届いていないか、迷惑メールフォルダをご確認ください。',
  newTicketReceived:
    'ご連絡ありがとうございます。お客様のリクエストを追跡するためにチケット番号{{ticketId}} を作成しました。\n\nご記入いただいたメールアドレス{{email}} に返信いたします。このメールアドレスが正しくない場合は、正しいメールアドレスを入力して再度フィードバックを送信してください。\n\nまた、弊社からの回答を確実にお届けするため、support@galarm.zendesk.com をメール アドレス帳に追加してください。ご回答が完了しましたら、こちらにも通知をお送りいたします。',
  sentAccountBackup:
    'お客様のアラームデータを{{email}} に送信しました。 受信トレイにない場合は、noreply@acintyo.com からのメールが届いていないか、迷惑メールフォルダをご確認ください。',
  alarmDoesntExist: 'アラームは存在しない。',
  shareLink: 'シェアリンク',
  joinedGroupAlarmFromDeepLinkMessage:
    '{{alarmTime}} の"{{alarmName}}"でリマインダーが表示される。{{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'あなたは "{{alarmName}}" を思い出すだろう。{{repetitionString}}',
    one: 'リマインダーは "{{alarmName}}"{{repetitionString}} {{alarmTime}} から始まる。{{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'あなたは{{creatorName}} からこのアラームに従っている。',
  alreadySharedAlarmDescription: {
    zero: 'このアラームにはフォロワーがいない。\n\nリンクを使用して、このアラームをより多くの人と共有し続けることができます。リンクをタップすると、その人の携帯電話にこのアラームのコピーが作成されます。',
    one: '{{count}} がこのアラームに従っている。\n\nリンクを使用して、このアラームをさらに多くの人と共有し続けることができます。リンクをタップすると、その人の携帯電話にこのアラームのコピーが作成されます。',
    other:
      '{{count}} 人々はこのアラームに従っている。\n\nリンクを使用して、このアラームをさらに多くの人と共有し続けることができます。リンクをタップすると、その人の携帯電話にこのアラームのコピーが作成されます。'
  },
  shareParentAlarmDescription:
    'あなたは{{creatorName}} からこのアラームに従っている。\n\nリンクを使用して、このアラームを他のユーザーと共有することもできます。リンクをタップすると、相手の携帯電話にこのアラームのコピーが作成され、相手もこのアラームをフォローできるようになります。',
  shareableAlarmLinks: '共有リンク',
  nPeopleFollowingAlarm: {
    zero: 'このアラームにはフォロワーがいない。',
    one: '{{count}} がこのアラームに従っている。',
    other: '{{count}} 人々はこのアラームに従っている。'
  },
  likeUsOnFacebook: 'フェイスブック',
  followUsOnTwitter: 'ツイッター',
  followUsOnInstagram: 'インスタグラム',
  youtube: 'ユーチューブ',
  mediumBlog: 'ミディアムのブログ',
  howtoVideos: 'ハウツービデオ',
  followGalarmOnSocialMedia: 'ソーシャルメディアでGalarmをフォロー',
  galarmOnSocialMedia: 'ソーシャルメディアにおけるGalarm',
  followGalarmOnSocialMediaDescription:
    'アプリのアップデート、機能の使い方、今後の機能、主なアプリのレビューなど、エキサイティングなニュースを表示します。Galarmチームとの交流も可能です。',
  sharedAlarm: '共有アラームの編集',
  editingPublishedAlarm: {
    one: 'リンクを使用して以前に共有したアラームを編集しています。{{count}} 現在、このアラームをフォローしている人がいます。\n\nこのアラームに編集を加えると、変更が通知されます。',
    other:
      'リンクを使用して以前に共有したアラームを編集しています。{{count}} 現在、このアラームをフォローしている人がいます。\n\nこのアラームを編集すると、変更が通知されます。'
  },
  editingSubscribedAlarm:
    'このアラームは{{creatorName}} からフォローされています。 アラームを編集した後、このアラームのアップデートは受信されません。',
  viewAlarm: 'アラーム表示',
  view: '表示',
  editAlarm: 'アラームを編集する',
  thanksForTryingPremiumTitle: 'これで設定完了だ！',
  thanksForTryingPremiumMessage:
    'プレミアム機能をお楽しみください。\n\nご不明な点がございましたら、「設定 > フィードバック」からサポートにご連絡いただくか、galarm@acintyo.com までメールでお問い合わせください。',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} がアラームを変更しました。\nアラームを更新しますか？',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} アラームを削除しました。\nアラームを削除しますか？',
  updateSubscribedAlarm: 'はい、更新',
  deleteSubscribedAlarm: 'はい、削除',
  ignoreSubscribedAlarm: 'いいえ、無視します',
  customRepetitionPremiumScreenDescription:
    'カスタムリピートはプレミアム機能です。\n\nこの機能を使用すると、高度な反復でアラームを作成できます。例を以下に示します：\n',
  repeatAlternateDate: '✅ 交互に繰り返す',
  repeatEveryNDay: '3日ごとに繰り返す。',
  repeatEveryNHoursAndMMinutes: '2時間30分ごとに繰り返す。',
  repeatEveryNMinutes: '45分ごとに繰り返す。',
  repeatBiweekly: '2週間ごとに繰り返す。',
  repeatEveryNWeeks: '6週間ごとに繰り返す。',
  repeatEveryNMonths: '3カ月（四半期）ごとに繰り返す。',
  repeatEveryLastDayOfMonth: '毎月最終日を繰り返す',
  repeatDayOfWeekOfMonth: '毎月第2土曜日 ✅ 繰り返し開催',
  repeatLastDayOfWeekOfMonth: '毎月最終金曜日 ✅ 繰り返す',
  customRepetition: 'カスタム・リピート',
  lastDayOfMonth: '毎月末日',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} 毎月',
  lastDayOfWeekInMonth: '毎月最終{{dayName}} ',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} の{{monthName}}',
  lastDayOfWeekInMonthInYearSummary: '最後の{{dayName}} {{monthName}}',
  lastDayOfMonthInYearSummary: '最終日{{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} 毎年{{monthName}} ',
  lastDayOfWeekInMonthInYear: '{{dayName}} 毎年最後の{{monthName}} ',
  lastDayOfMonthInYear: '{{monthName}} 毎年最終日',
  chooseAMonthsConfiguration: 'コンフィギュレーションを選択してください。',
  chooseADaysConfiguration: '構成を選択してください',
  chooseAYearsConfiguration: 'コンフィギュレーションを選択してください。',
  didntGetCodeNewUser:
    'コードを受け取らなかった？\n\n再度コードをリクエストするか、登録をスキップしてください。登録は後からでも可能です。',
  didntGetCodeExistingUser:
    'コードを受け取らなかった？\n\n再度コードをリクエストするか、登録をキャンセルしてください。登録は後からでも可能です。',
  didntGetCodeUpdatePhoneNumber:
    'コードを受け取らなかった？\n\n再度コードをリクエストしていただくか、後でもう一度お試しください。',
  alreadySentCodeAgain:
    'コードを再送しました。コードの取得が困難な場合は、登録をスキップし、後で登録してください。',
  theme: 'テーマ',
  announceAlarmName: 'アラームタイトルの発表',
  ttsHelperText:
    'アラームが鳴ると、アラームタイトルがアナウンスされます。アラームタイトルの前にある再生ボタンをタップすると、プレビューを聞くことができます。',
  initializingTts: '音声合成の初期化',
  errorSpeakingAlarmName:
    'アラームタイトルを話すことができません。設定 > フィードバック」からサポートに連絡先連絡してください。',
  entityAlarm: 'アラーム',
  entityGroup: 'グループ',
  entityContact: '連絡先',
  admin: '管理者',
  specifyAlarmTitleToSpeak: 'アラームタイトルを指定して話す',
  wakeup: '目を覚ます',
  ttsPremiumScreenDescription:
    'アラームタイトルの発表はプレミアム機能です。\n\n有効にすると、アラームが鳴ったときにアラームタイトルがアナウンスされます。\n\nプレビューするには、アラームタイトルを入力し、再生をタップします。',
  unknownAlarmCategory: 'アラームカテゴリ不明',
  cantAnnounceAlarmTitle: 'アラームタイトルは発表されない',
  ringtoneDurationIsSmall:
    '着信音の期間は、アラームタイトルをアナウンスするために少なくとも5秒必要です。\n\nこの設定を続けますか？',
  noActionItems: 'アクション・アイテムはない。',
  personNotInContacts: 'この人物はGalarmの連絡先にいません。',
  light: '明るい',
  dark: '暗い',
  systemDefault: 'デバイス設定を使用する',
  chooseTheme: 'テーマを選ぶ',
  dontSeeAllYourContacts: 'すべての連絡先が表示されていませんか？',
  lastContactsSyncedAt: '最終同期日時{{dateString}}',
  refresh: 'リフレッシュ',
  forever: '永遠に',
  uncategorizedCategoryName: '未分類',
  uncategorizedCategoryScreenTitle: '未分類',
  setAlarmCategory: 'アラームカテゴリ設定',
  changeAlarmCategory: 'アラームカテゴリの変更',
  viewAlarmHistory: 'アラーム履歴表示',
  computingAlarmHistory: 'アラーム履歴の集計...',
  alarmByCategories: '{{name}} アラーム',
  alarmCategories: 'カテゴリ',
  editAlarmCategory: 'カテゴリを編集する',
  deleteAlarmCategory: 'カテゴリ削除',
  newAlarmCategoryTitle: '新カテゴリー',
  newAlarmCategoryNamePlaceholder: '名前を指定する',
  editAlarmCategoryTitle: 'カテゴリを編集する',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}} {{count}} を逃した。',
    other: '{{alarmsSummary}} {{count}} を逃した。'
  },
  numAlarms: {
    zero: 'アラームなし',
    one: '{{count}} アラーム',
    other: '{{count}} アラーム'
  },
  numIncidents: {
    zero: '事件なし',
    one: '{{count}} 事件',
    other: '{{count}} 事件'
  },
  openedIncidents: 'オープンしたインシデント',
  closedIncidents: '終結した事件',
  incidentChart: 'インシデントチャート（過去30日間）',
  incidentCalendar: 'インシデント・カレンダー',
  incidentDistribution: 'インシデントの分布（過去30日間）',
  noIncidentsOpenedClosedInLast30Days: '過去30日間に開店・閉店した事件はない',
  noIncidentsOpenedInLast30Days: '過去30日間に事故は発生していない',
  color: 'カラー',
  createNewAlarmCategory: '右下の＋ボタンをタップして新しいカテゴリを作成する',
  setValidName: '有効な名前を設定する',
  specifyTimezoneForAlarmMessage:
    'アラーム作成時に時間帯を指定することができます。指定した時間帯にアラームがリングします。 \n\nデバイスのタイムゾーン」を選択した場合、アラームはデバイスのタイムゾーンに従ってリングされます。',
  alarmCategoryFeatureDescription:
    'アラームに独自のカテゴリを作成します。カテゴリを使用すると、簡単にアラームを整理することができます。',
  markAlarmDoneInAdvance: 'アラームの "完了 "が早い？',
  skipAlarmInAdvance: 'アラームを "スキップ "する？',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    '今「完了」をマークすると、{{dateTimeString}} 、アラームはリングしません。',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    '今スキップすれば、{{dateTimeString}} 、アラームは鳴らない。',
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    '今「完了」をマークすると、{{dateTimeString}} 、アラームはリングしません。',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    '今スキップすれば、{{dateTimeString}} 、アラームは鳴らない。',
  alarmsByCategories: 'カテゴリ別アラーム',
  workAlarmCategory: '仕事',
  homeAlarmCategory: 'ホーム',
  birthdaysAlarmCategory: '誕生日',
  more: 'もっと見る',
  alarmCategory: 'アラーム カテゴリ',
  editAlarmCategoryColor: '編集カラー',
  editAlarmCategoryColorTitle: '編集カラー{{name}}',
  allUserCategoriesDeleted:
    'プレミアム契約がキャンセルされたため、お客様が作成したすべてのアラームカテゴリが削除されました。これらのカテゴリのアラームは「未分類」に表示されます。',
  calendar: 'カレンダー',
  eventExportedFromGalarm:
    '「{{dateString}} {{timeString}} にGalarmからエクスポートされた。',
  unableToExportEventToCalendar:
    'カレンダーにイベントをエクスポートできません。デバイスのカレンダーへのアクセス許可が与えられていることを確認してください。問題が解決しない場合は、「設定 > フィードバック」を使用してフィードバックをお送りください。',
  scanQrCode:
    'ブラウザでGalarmにアクセスするには、web.galarm.appにアクセスし、QRコードをスキャンします。',
  logOut: 'ログアウト',
  useHere: 'ここで使う',
  appRunningElsewhere:
    'このセッションからログアウトされました。このセッションを再開しますか？',
  dateTime: '日付と時間',
  selectAtLeastOneDayForRepetition:
    'アラームを繰り返す日を少なくとも1日選んでください。',
  chooseNumberOfDaysForAlarmRepetition: 'アラームを繰り返す日数を入力します。',
  chooseNumberOfWeeksForAlarmRepetition: 'アラームを繰り返す週数を入力します。',
  chooseNumberOfMonthsForAlarmRepetition:
    'アラームを繰り返す月数を入力します。',
  chooseNumberOfYearsForAlarmRepetition: 'アラームを繰り返す年数を入力します。',
  previouslySelectedContactsAreUnselected:
    '以前に選択した連絡先が非選択になります。',
  previouslySelectedGroupIsUnselected:
    '以前選択されていたグループが非選択になる。',
  galarmWebInstructions:
    '1.スマホでGalarmアプリを開く\n2.設定」タブを開き、"Galarm Web "をタップする。\n3.オプションが表示されない場合は、最新のアプリを起動していることをご確認ください。\n4.右のQRコードを読み取る',
  likeToCreateAlarm: 'アラーム...',
  payUtilityBills: '請求書の支払い',
  wakeupAlarmName: '目を覚ます',
  exerciseAlarmName: 'エクササイズ',
  drinkWaterAlarmName: '水を飲む',
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} to 1時間ごと{{endTimeString}} ',
    other: '{{startTimeString}} to  時間ごと{{endTimeString}} {{count}} '
  },
  firstTimeManagementQuote:
    '「悪い知らせは時間が過ぎるのが早いこと。良いニュースは、あなたがパイロットだということ。',
  firstTimeManagementQuoteAuthor: 'マイケル・アルトシューラー',
  secondTimeManagementQuote: '「すべての時間管理は計画から始まる。',
  secondTimeManagementQuoteAuthor: 'トム・グリーニング',
  thirdTimeManagementQuote: '"今日できることを明日まで残してはならない"',
  thirdTimeManagementQuoteAuthor: 'ベンジャミン・フランクリン',
  fourthTimeManagementQuote:
    '「時間は私たちが最も欲するものであるが、私たちが最も酷使するものである。',
  fourthTimeManagementQuoteAuthor: 'ウィリアム・ペン',
  fifthTimeManagementQuote:
    '1年後、"今日始めておけばよかった "と思うかもしれない。',
  fifthTimeManagementQuoteAuthor: 'カレン・ラム',
  sixthTimeManagementQuote: '「あなたは遅れても、時間は遅れない。',
  sixthTimeManagementQuoteAuthor: 'ベンジャミン・フランクリン',
  tapToGetStarted: 'をタップして始める。',
  allCategories: 'すべてのカテゴリ',
  createAlarmWithContact: 'でアラームを作成する。{{name}}',
  createAlarmWithGroup: 'でアラームを作成する。{{name}}',
  editGroup: '編集グループ',
  discardChanges: '現在の変更は破棄されます。続けるか？',
  alarmWillRingEveryNDays: {
    zero: 'アラームは毎日リングが鳴る。',
    one: 'アラームは交互にリングする。',
    other: 'アラームは{{dayWithOrdinal}} 日ごとにリングします。'
  },
  hoursString: { zero: '', one: '1時間', other: '{{count}} 時間' },
  minutesString: { zero: '', one: '1分', other: '{{count}} 分' },
  alarmWillRingEveryMHoursAndNMinutes:
    'アラームは、{{dayString}} から始まる{{repeatString}} ごとにリングする。その後、{{otherDaysString}} と続く。',
  alarmWillRingEveryNWeeks: {
    zero: 'アラームは毎週リングが鳴る。',
    one: 'アラームは隔週でリングする。',
    other: 'アラームは{{weekWithOrdinal}} 週間ごとにリングする。'
  },
  alarmWillRingEveryNMonths: {
    zero: 'アラームは毎月リングする。',
    one: 'アラームは2カ月に1度リングする。',
    other: 'アラームは{{monthWithOrdinal}} 月ごとにリングします。'
  },
  alarmWillRingEveryNYears: {
    zero: 'アラームは毎年リングを鳴らす。',
    one: 'アラームは2年に1度リングする。',
    other: 'アラームは{{yearWithOrdinal}} 年ごとにリングする。'
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    'この設定を有効にすると、他のユーザーからのアラームは承諾せずにリングされます。アラームに参加する意思があることを参加者に知らせるために、アラームを承諾する必要があります。\n\nこの設定を無効にすると、他人が追加したアラームは、あなたが明示的に承諾しない限り、あなたの携帯電話にリングされません。',
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'この環境設定を有効にすると、アラーム作成時に時間帯を指定できます。指定した時間帯にアラームがリングします。\n\nこの設定を無効にすると、アラームはデバイスのタイムゾーンに従ってリングされます。',
  ringOnEarphoneOnlyTooltipTitle: 'イヤホン接続時のアラーム動作',
  ringOnEarphoneOnlyTooltipMessage:
    'イヤホンが接続されている場合、アラームをイヤホンのみ、または電話のスピーカーとイヤホンの両方でリングするように設定できます。\n\nイヤホンが接続されていない場合、アラームは電話のスピーカーでリングします。',
  gestureOptionsTooltipTitle: 'アラームを閉じるジェスチャー',
  gestureOptionsTooltipMessage:
    'ロック画面でアラームが鳴った場合、ボタンをタップするかスライドさせることで閉じることができる。\n\nこの設定では、アラームを閉じる方法を選択できます。',
  notificationSettingsTooltipTitle: '通知設定',
  notificationSettingsTooltipMessage:
    '受け取りたい通知を有効にし、通知を受けたくない通知を無効にする。',
  messagesForAlarm: 'メッセージ{{name}}',
  systemNotificationsTooltipMessage:
    '以下のようなイベントのためにアプリによって生成された通知：\n  - 連絡先が時間帯を変更したためにアラームが影響を受けた場合\n  - 招待された連絡先がGalarmに登録した場合\n  - アカウント削除によりアラームに影響が出た場合',
  galarmWebNotificationsTooltipMessage: 'ギャラルムWebからの通知です。',
  alarmsfromOthersNotificationsTooltipMessage:
    'アラームに参加者として追加されたときに送信される通知。',
  alarmChatNotifictionsTooltipMessage:
    'アラーム内のチャットメッセージに通知を送信。',
  groupNotificationsTooltipMessage:
    'あなたがグループに追加されたり、グループから削除されたり、誰かがグループに追加されたり、グループから削除されたりしたときに送信される通知です。',
  alarmAcknowledgementNotificationsTooltipMessage:
    '参加者が、自分も参加者であるアラームを承認した場合に送信される通知。',
  sharedAlarmNotificationsTooltipMessage:
    '送信される通知：\n  - リンクを介して共有されたアラームに誰かが登録した場合\n  - 登録したアラームが変更された時',
  sleepTimer: 'スリープ・ミュージック',
  sleepTimerTitle: '音楽を再生する',
  ongoingSleepTimer: 'スリープ・ミュージック・オンゴーイング',
  stopSleepTimer: '今の音楽を止めますか？',
  restartSleepTimer: '今の音楽を止めて、新しい音楽を始めますか？',
  play: 'プレー',
  youAreOffline:
    'あなたは現在オフラインです。何か操作を行うにはオンラインにしてください。',
  sleepMusicTooltip:
    '自然音で心身をリラックス。指定期間終了後、音楽は停止します。',
  alarmLinkCopiedToClipboard:
    'アラームリンクがクリップボードにコピーされました。',
  confirmLogOut: 'ログアウトしますか？',
  appNotRunningMessage:
    'Galarmがお使いの携帯電話で実行されていないことを検出しました。タップしてGalarmを起動し、重要なアラームを見逃さないようにしてください。',
  tapToEnterNotesForAlarm: 'タップしてノートを入力する',
  accountAlreadyExists: '既存のアカウントが見つかりました',
  accountsMerged:
    'この携帯電話番号に関連する既存のアカウントが見つかりました。2つのアカウントを統合しました。',
  upgradeToPremiumToAssignUserDefinedCategory:
    'ユーザー定義カテゴリを選択するには、プレミアムにアップグレードしてください。',
  userDefinedCategoryNotSetForAlarm:
    'プレミアムにアップグレードして、ユーザー定義カテゴリを自動割り当てしてください。アラームにカテゴリが設定されていません。',
  alarmDetails: 'アラーム詳細',
  profileDetails: 'プロフィール',
  activeWebSession:
    'すでにアクティブなウェブ・セッションがあります。そのセッションからログアウトするか、新しいセッションを開始してください。\n\n新しいセッションを開始すると、現在アクティブなウェブセッションから自動的にログアウトします。',
  startNewSession: '新規セッションの開始',
  confirmWebSessionLogout:
    'これで現在のウェブ・セッションからログアウトします。再度コードをスキャンすると、新しいセッションを開始できます。',
  defaultInstantAlarmTitleForGroup: '{{groupName}} 警告{{creatorName}}',
  defaultInstantAlarmTitleForContact: '警告{{creatorName}}',
  atleastOneMemberShouldBeActive:
    '少なくとも1人の参加者がアクティブでなければならない。',
  activeParticipants: 'アクティブ参加者',
  acceptAlarm: '承諾アラーム',
  rejectAlarm: 'アラーム拒否',
  nConfirmed: '{{count}} 確認',
  nDeclined: '{{count}} 拒否',
  nPending: '{{count}} 保留',
  everyoneConfirmed: '全員が確認',
  everyoneDeclined: '誰もが拒否した',
  appTagline: '何事も見逃さない',
  usePreviousBackup: '私は登録ユーザーです。',
  viewAlarmsByCategories: 'カテゴリ別アラーム表示',
  viewAllAlarms: 'すべてのアラームを表示',
  switchToSummaryView: 'サマリー表示に切り替える',
  switchToListView: 'リスト表示に切り替える',
  hideHourlyAlarms: '毎時アラームを隠す',
  calendarView: 'Galarmカレンダー',
  viewRecentlyDeletedAlarms: '最近削除されたアラームの表示',
  deletedOnDate: '削除日{{alarmDate}}',
  recentlyDeletedAlarmFooterText:
    'このアラームは次の日に削除された。{{alarmDate}}',
  noRecentlyDeletedAlarms: '最近削除されたアラームがない',
  restoreAlarmsFromMobileApp:
    'これらのアラームは削除後30日経過すると自動的に削除されます。これらのアラームを復元したい場合は、モバイルアプリの「最近削除したアラーム」画面に移動し、復元アイコンをタップしてください。',
  deleteInactiveAlarms: '非アクティブアラームの削除',
  defaultConfigurationUpdated: 'デフォルト設定が更新されました。',
  notAlerted: '除く',
  alarmKeepsRingingHelp: 'ヘルプアラームが鳴り続けています。',
  alarmKeepsRingingHelpMessage:
    'アラームをリフレッシュし、問題レポートを送信しました。',
  alarmKeepsRingingHelpAlert:
    'アラームをリフレッシュし、この問題は発生しなくなります。また、さらなる調査のため、問題レポートをサポートチームに送信します。\n\n  それでも問題が解決しない場合は、「設定 > フィードバック」からご連絡ください。',
  sendingFeedback: 'フィードバックの送信',
  sendingProblemReport: '問題レポートの送信',
  fixingAlarms: 'アラームのリフレッシュ',
  existingAccountFound: 'バックアップの復元',
  existingAccountDataRestored: {
    zero: 'おめでとうございます、すべてのデータがバックアップから復元されました。',
    one: 'おめでとうございます、すべてのデータがバックアップから復元されました。',
    other:
      'おめでとうございます、すべてのデータがバックアップから復元されました。'
  },
  noExistingAccountFound: 'バックアップが見つかりません',
  createdNewAccount:
    'この電話番号はGalarmに登録されていません。この電話番号を使用して新しいアカウントを作成しました。',
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: 'iOSでは、アプリは最大64の通知をスケジュールすることができます。あなたはその通知をすべて使い切りました。アラームを見逃す可能性があります。',
    one: 'iOSでは、アプリは最大64の通知をスケジュールすることができます。そのうち{{numNotifications}} 。'
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    'これはこのアラームの最後の通知です。この通知を長押しして、このアラームの次 の発生をスケジュールするアクションを実行します。',
  lastNotificationForOneTimeAlarm: 'これはこのアラームの最後の通知である。',
  criticalAlertsMessageTitle: '電話のミュート時にアラームをリング',
  criticalAlertsMessageDescription:
    '\niOSのクリティカルアラートを使用して、電話がミュートされているときにアラームをリングするようにGalarmを設定できます。すべてのアラームで有効にしますか？\n\nまた、アラームの「音と振動 > クリティカルアラート」設定を編集することで、一部のアラームに対して選択的に有効にすることもできます。',
  allowCriticalAlerts: 'クリティカル・アラートを許可する',
  criticalAlertsPermissionDenied:
    'Galarmが重要なアラートを表示するには、携帯電話の許可が必要です。携帯電話の設定でこの許可を有効にしてください。',
  enableCriticalAlertsForAllAlarms: 'すべてのアラームで有効',
  selectivelyEnableCriticalAlerts: 'いや、選択的に有効にする',
  firstName: '名前',
  lastName: 'ラストネーム',
  email: '電子メール',
  password: 'パスワード',
  confirmPassword: 'パスワードの確認',
  signup: '会員登録',
  login: 'ログイン',
  alreadySignedUp: 'すでに登録済みですか？',
  notAUser: 'ユーザーではない？',
  subscribe: 'サブスクライブ',
  choosePlan: 'プランの設定',
  billingCycle: '請求サイクル',
  needHelpToGetStarted: 'スタートアップのサポートが必要ですか？',
  browser: 'ブラウザ：{{name}}',
  deviceOs: 'デバイス{{name}}',
  lastActive: '最後のアクティブ{{timestamp}}',
  signUp: '登録する',
  useGalarmOnWeb: 'ウェブでGalarmを使う',
  welcomeUser: 'ようこそ{{name}}',
  changePassword: 'パスワードの変更',
  saveAlarm: 'アラーム保存',
  galarmUserTip: 'ご存知でしたか？',
  sendAlarm: 'アラームを送信',
  saveGroup: '保存グループ',
  webAppBestViewedOnDesktop:
    'URL（web.galarm.app）は、ラップトップやデスクトップ上のウェブブラウザでGalarmインターフェースを起動するためのものです。\n\nモバイルデバイスでGalarmを使用するには、以下のリンクのいずれかを使用して、Google PlayからAndroidアプリ、またはApp StoreからiOSアプリをインストールしてください：',
  scanQrCodeMessage: 'QRコードをスキャン',
  reloadQrCode: 'クリックしてQRコードを再読み込み',
  invalidQrCodeScanned: '無効なQRコードが読み込まれました。',
  unableToScanCode: 'コードをスキャンできない',
  unableToScanCodeMessage:
    'コードのスキャンに問題がありました。もう一度お試しください。問題が解決しない場合は、モバイルアプリから「設定 > フィードバック」を使用してフィードバックをお送りください。',
  problemWithEnterpriseAccountSetup:
    'Galarm Proアカウントの設定に問題がありました。テクニカルサポートにご連絡ください。',
  problemWithCreatingCheckoutSession:
    'チェックアウトセッションの作成に問題が発生しました。テクニカルサポートに連絡してください。',
  problemWithCreatingCustomerPortalSession:
    'カスタマーポータルセッションの作成に問題が発生しました。テクニカルサポートに連絡してください。',
  problemWithCreatingApiKey:
    'APIキーの作成に問題が発生しました。テクニカルサポートに連絡してください。',
  creatingAnAccount: 'アカウントの作成',
  currentPlan: '現行プラン：{{name}} ({{quantity}} シート)',
  myAccount: '口座情報',
  new: '新しい',
  deleteAllAlarmHistoryAlertTitle: 'アラーム履歴の削除',
  deleteAllAlarmHistoryAlertMessage:
    '続行」をタップすると、このアラームの履歴が削除されます。この操作は元に戻せません。',
  ageOutInactiveAlarmsMessage:
    '{{count}} 、60日以上非アクティブのアラームがあります。非アクティブなアラームが多いと、アプリのパフォーマンスに影響する可能性があります。\n\nこれらのアラームを削除しますか？',
  duplicateAlarm: '重複アラーム',
  graduallyIncreaseVolume: '徐々に音量を上げる',
  graduallyIncreaseVolumeTooltipMessage:
    'この設定を有効にすると、アラーム音量が設定した着信音音量まで徐々に大きくなります。これにより、アラームが大きくなりすぎる前に止めることができます。\n\nこの設定を無効にすると、着信音の期間中、設定した音量でアラームがリングします。',
  teams: 'チーム',
  alerts: 'アラート',
  listeners: 'リスナー',
  noMembersConfiguredInEnterpriseAccount:
    'メンバーはまだ追加されていません。メンバーとは、リスナーのエスカレーションポリシーに追加することができ、リスナーが受信したインシデントに対してアラートを出すユーザーです。Galarmに登録されている必要があります。',
  noTeamsConfiguredInEnterpriseAccount:
    'チームはまだ作成されていません。チームとは、リスナーのエスカレーション・ポリシーに追加できるメンバーのグループであり、リスナーが受信したインシデントに対してアラートされます。',
  noAlertsConfiguredInEnterpriseAccount:
    'まだリスナーを作成していません。監視ツールによって報告されたインシデントをリッスンするためにリスナーを構成できます。リスナーを作成すると、そのリスナーをさまざまな監視ツールと統合する方法についての説明が表示されます。\n\nリスナーごとに複数のエスカレーション・レベルを設定できます。',
  noShiftsConfiguredInEnterpriseAccount:
    'まだシフトコンフィグを作成していない。',
  noRotationsConfiguredInEnterpriseAccount:
    'あなたはまだローテーションを作成していない。',
  rotations: 'ローテーション',
  addRotation: 'ローテーションの追加',
  viewRotation: '表示回転',
  enterRotationName: 'ローテーション名を入力',
  rotationType: '回転タイプ',
  numberOfHoursInRotation: 'ローテーション時間数',
  numberOfDaysInRotation: 'ローテーション日数',
  numberOfWeeksInRotation: 'ローテーション週数',
  specify: '指定する',
  noIntegrationsConfiguredInEnterpriseAccount: 'まだ統合設定がされていません。',
  loadingUser: 'ユーザー情報の読み込み',
  addMember: 'メンバー追加',
  add: '追加',
  noUserFoundWithMobileNumber:
    'この電話番号でGalarmの登録ユーザーは見つかりませんでした。国番号と一緒に、カッコ、ダッシュ、空白などの特殊文字を含まない電話番号を入力してください。',
  memberWithPhoneNumberAlreadyAdded:
    'この電話番号のメンバーはすでに追加されています。',
  memberWithEmailAlreadyAdded:
    'このメールアドレスを持つメンバーはすでに追加されています。',
  assignKeyToMember: 'ライセンスキーの割り当て',
  revokeKeyFromMember: 'ライセンスキーの失効',
  cantAssignKeyToMember: 'メンバーにキーを割り当てられない',
  noEnterpriseLicenseKeyAvailable:
    'ライセンスキーはありません。プランをアップグレードしてください。',
  addTeam: 'チーム追加',
  enterTeamName: 'チーム名を入力',
  addAlert: 'リスナー追加',
  low: '低い',
  medium: 'ミディアム',
  high: '高い',
  severity: '重大性',
  enterAlertName: 'リスナーの名前を入力',
  organization: '組織',
  startTime: '開始時間',
  endTime: '終了時間',
  shiftName: 'シフト{{index}}',
  addShift: 'シフトの追加',
  assignees: '割当先',
  shiftStartEndTime: '{{startTime}} -{{endTime}}',
  assignee: '譲受人',
  specifyAssigneeForShift: 'シフトの担当者を指定する',
  selectAtLeastOneMember: '少なくとも1人のメンバーを選ぶ',
  editMember: '編集メンバー',
  editTeam: '編集チーム',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    'メンバーがチームに所属しているか、リスナーに割り当てられています。削除する前に、それらのチームやリスナーからメンバーを削除してください。\nチーム{{teams}}\nリスナー{{alerts}}',
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    'メンバーがチームに所属しているか、リスナーに割り当てられています。キーを失効させる前に、それらのチームやリスナーからメンバーを削除してください。\nチーム{{teams}}\nリスナー{{alerts}}',
  cantDeleteTeamAssignedToAlerts:
    'チームはリスナーの一部です。削除する前に、リスナーからチームを削除してください。\nリスナー{{alerts}}',
  cantDeleteRotationAssignedToAlerts:
    'ローテーションはリスナーの一部です。削除する前に、リスナーからチームを削除してください。\nリスナー{{alerts}}',
  editAlert: 'リスナーの編集',
  timezoneDisplayString: '(GMT{{offset}}){{timezone}}',
  apiKey: 'APIキー',
  viewApiKey: 'APIキー表示',
  apiKeyWarningMessage:
    'APIキーは常に安全に保管してください。誰とも共有しないでください。APIキーが漏洩したと思われる場合は、上記の「変更」ボタンをタップして変更してください。キーを変更したら、REST APIを使用してインシデントをトリガーするすべての場所で新しいキーを指定する必要があります。',
  integrationConfigs: '統合コンフィグ',
  addIntegrationConfig: '統合設定の追加',
  editIntegrationConfig: '統合設定の編集',
  integrationSource: '統合ソース',
  apiToken: 'APIトークン',
  integrations: '統合',
  selectPlaceholder: '選択...',
  zendeskSubdomain: 'Zendesk サブドメイン',
  creatorTimeForBuddyAlarm: '{{timeString}} ( ){{remindMeAfter}}',
  nextFiveAlarms: '次の5つのアラーム',
  categories: 'カテゴリ',
  viewingAlarmsDueToday: '表示アラームは今日まで',
  viewingAlarmsDueTomorrow: '表示アラームは明日まで',
  expiredAlarms: '期限切れアラーム',
  viewingExpiredAlarms: '期限切れアラームの表示',
  noAlarmsDueToday: '今日はアラームなし',
  noAlarmsDueTomorrow: '明日はアラームなし',
  noExpiredAlarms: '期限切れアラームなし',
  viewingAllAlarms: 'すべてのアラームの表示',
  viewingRecentlyDeletedAlarms: '最近削除されたアラームの表示',
  summaryView: 'サマリー表示はいかがですか？',
  summaryViewDescription:
    '日付やカテゴリごとに整理されたアラームをご覧ください。\n\n右上の⋮ボタンでリスト表示に戻ることができます。',
  disableBatteryOptimizationMessageOnStartup:
    'アラームを確実にリングするためには、Galarmをバックグラウンドで実行する必要があります。次画面で許可してください。',
  disableBatteryOptimizationMessage:
    'アラームが確実にリングするためには、Galarmをバックグラウンドで実行する必要がある。',
  allowNotificationsToDisplayAlarms:
    'Galarmはアラームがリングしたときに通知を表示する権限が必要です。',
  scheduleExactAlarmsMessage:
    'Galarmは正確なアラームをスケジュールする許可を必要とする。',
  alarmCategoryWithSameNameExists:
    'この名前のカテゴリはすでに存在します。別の名前を指定してください。',
  alarmRepetitionsPremiumFeature: '高度な反復練習オプション',
  ringtonesPremiumFeature: 'その他の着信音',
  galarmWebPremiumFeature: 'ウェブでGalarmを使う',
  taskListPremiumFeature: '複数のタスクリストを作成する',
  preReminderPremiumFeature: 'アラーム用事前リマインダー',
  alarmCategoriesPremiumFeature: 'カスタムアラームカテゴリ',
  shareableAlarmLinksPremiumFeature: '共有可能なアラームリンク',
  announceAlarmNamePremiumFeature: 'アラームタイトルの発表',
  manageSubscription: 'サブスクリプションの管理',
  purchaseRestored: '購入は復活した。',
  unableToRetrieveSubscriptionPlansAndroid:
    'アップグレードを実行するためにGoogle Playに接続できません。インターネットに接続されていることを確認してから、もう一度お試しください。 \n\nこの問題が解決しない場合は、サポートチームに問題を報告してください。',
  unableToRetrieveSubscriptionPlansIos:
    'アップグレードを実行するためにApp Storeに接続できません。インターネットに接続されていることを確認してから、もう一度お試しください。 \n\nこの問題が解決しない場合は、サポートチームに問題を報告してください。',
  drawOverOtherApps: '他のアプリの上に表示',
  drawOverOtherAppsHelperText: 'Galarmがアラームを上部に表示できるようにする',
  nTimes: { one: '時間', other: '{{count}} 回' },
  learnHowToUseFocus: 'フォーカスを学ぶ',
  enhancedNotificationsDeprecated: '強化された通知 非推奨',
  enhancedNotificationsDeprecatedMessage:
    '拡張通知は信頼性が低く、バッテリーの過剰な消耗を引き起こすことがわかりました。iOS Focusを使用して、携帯電話がDo Not DisturbになっているときにGalarmがアラームを鳴らせるようにしてください。',
  usingIosFocus: 'iOS Focusの使用',
  iosFocusModePara1:
    '電話のリンガースイッチがオフの場合、またはリンガーの音量が0の場合、ガラームはリングしません。\n\nAppleは、電話を静かな状態に保ちながらも、一部のアプリによる通知を許可したい場合に「Focus」を使用することを推奨しています。お使いの携帯電話で「Focus」を設定するには、以下の手順に従ってください。',
  iosFocusModePara2:
    'スクリーンショット付きの詳しい説明は、https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS でご覧いただけます。',
  settingFocus: 'フォーカスの設定：',
  settingFocusFootNotes:
    'フォーカスはiOS 15以上でご利用いただけます。\n\nご質問やフィードバックは「設定 > フィードバック」から連絡先までご連絡ください。',
  enablingFocus: 'フォーカスを有効にする：',
  settingFocusInstruction1: '電話設定 > フォーカス」に進みます。',
  settingFocusInstruction2:
    'Do Not Disturb" フォーカスプロファイルをタップします。',
  settingFocusInstruction3: 'アプリ」をタップし、Galarmを追加する。',
  settingFocusInstruction4:
    '設定した他のフォーカスプロファイルについても同じようにします。',
  enablingFocusInstruction1:
    'コントロールセンターを表示するには、右上の端から下にスワイプします。',
  enablingFocusInstruction2: 'フォーカス」をタップする。',
  enablingFocusInstruction3:
    'Do Not Disturb（邪魔しない）または有効にしたいその他のフォーカスプロファイルをタップします。',
  enablingFocusInstruction4:
    '現在有効なフォーカスが選択されているように表示されます。もう一度タップすると無効にできます。',
  startDateForRotation: 'ローテーション開始日付',
  startTimeForRotation: 'ローテーション開始時間',
  member: 'メンバー',
  multipleShiftsInADay: '1日に複数のシフト',
  escalationPolicy: 'エスカレーション・ポリシー',
  atTimeOfEvent: 'イベント開催時間',
  afterNMinutes: '{{count}} 分後',
  afterNHours: { one: '1時間後', other: '{{count}} 時間後' },
  afterDuration: 'その後{{durationString}}',
  addTrigger: 'トリガーの追加',
  viewCode: '表示コード',
  apiCodes: 'APIコード',
  viewIncidents: 'インシデントの表示',
  incidentsForAlert: 'リスナーのための事件{{alertName}}',
  timestamp: 'タイムスタンプ',
  acknowledged: '了承済み',
  noIncidentsForAlert: 'このリスナーの事件はまだない',
  apiCodeCopiedToClipboard: 'APIコードがクリップボードにコピーされました。',
  triggerName: 'トリガー{{index}}',
  delay: '遅延',
  chooseAssigneePlaceholder: '担当者を選択...',
  delayForTrigger: 'トリガー遅延',
  assigneeWillBeAlertedAfterDelay:
    '{{durationString}} 以降に受任者に通知される。',
  escalationForIncidentsForAlert:
    '{{incidentDate}} 、リスナーに対してインシデントのエスカレーションを行う。{{alertName}}',
  alertIncidentAcknowledged: '{{memberName}} 。{{dateString}}',
  alertIncidentRecovered: 'で回収された。{{dateString}}',
  alertIncidents: '事件',
  viewingAlertIncidents: 'インシデントの表示',
  createOrganization: '組織を作る',
  youAreNotPartOfAnyOrganization:
    'あなたはどの組織にも属していません。組織を作って始めましょう。',
  tellUsAboutYourOrganization: 'あなたの組織について教えてください',
  noActiveSubscription: 'アクティブ契約を結んでいない。',
  chooseOrganization: '続行する組織を選択します。',
  noTriggersForIncident: 'この件に関するトリガーは見つかっていない。',
  role: '役割',
  viewer: 'ビューア',
  newOrganization: '新組織',
  noOrganizations: 'あなたはどの組織にも属していない。',
  maxOrganizationsReached: 'ユーザーは最大10個の組織に所属することができます。',
  yourOrganizations: '組織',
  urlNotFound: 'URL がサポートされているルートにマッチしない。',
  triggerAlert: 'トリガー事件',
  recaptchaFailed: 'reCAPTCHAの検証に失敗しました。{{error}}',
  loadingAccountInformation: 'アカウント情報の読み込み',
  loadingAuthInfo: '認証情報の読み込み',
  allAlerts: 'すべてのリスナー',
  recentlyTriggeredAlerts: '最近トリガーされたリスナー',
  recentlyClosedIncidents: '最近発生した事件',
  noRecentlyTriggeredAlerts: '最近、リスナーは発動していない。',
  noRecentlyClosedIncidents: '最近、閉鎖された事件はない。',
  closedAt: '閉店{{dateTimeString}}',
  dashboard: 'ダッシュボード',
  loggedIntoOrganizationAccount:
    '(organization:{{organizationName}}, role:{{role}})',
  viewIncidentEscalation: 'インシデント・エスカレーションの表示',
  closeIncident: 'クローズ・インシデント',
  closingIncident: 'クロージング事件',
  unableToRefreshEnterpriseAccount: 'アカウントを更新できません。',
  viewAuthToken: '認証トークンの表示',
  problemWithCreatingAuthToken:
    '認証トークンの作成に問題が発生しました。テクニカルサポートに連絡してください。',
  authToken: '認証トークン',
  authTokenWarningMessage:
    'この認証トークンは常に安全に保管してください。誰とも共有しないでください。APIキーが漏洩したと思われる場合は、上の「変更」ボタンをタップして変更してください。トークンを変更したら、REST APIを使用してインシデントをトリガーするすべての場所で新しいトークンを指定する必要があります。',
  authTokenCopiedToClipboard:
    '認証トークンがクリップボードにコピーされました。',
  resendVerificationEmail: '認証メールの再送',
  loggedInUsingEmail: '電子メールを使用してログイン{{email}}.',
  checkingIfMemberIsAppUser:
    'メンバーがアプリのユーザーかどうかをチェックします。',
  settingMemberDetails: 'メンバーの詳細設定。',
  addingTeam: 'チームの追加',
  editingTeam: '編集チーム。',
  addingRotation: '回転を加える。',
  editingRotation: '編集ローテーション。',
  assigningKeyToMember: 'メンバーにキーを割り当てる。',
  revokingKeyFromMember: 'メンバーから鍵を失効させる。',
  deletingTeam: 'チームの削除。',
  deletingRotation: 'ローテーションの削除',
  addingAlert: 'リスナーの追加',
  editingAlert: '編集リスナー。',
  deletingAlert: 'リスナーの削除',
  triggeringAlert: '事件の引き金となった。',
  creatingOrganizationAccount: '組織アカウントの作成',
  creatingCheckoutSession: 'チェックアウトセッションの開始',
  creatingAnAccountForMember: 'メンバー用のアカウントを作成する。',
  passwordResetEmailSent:
    'パスワードをリセットするためのメールを送信しました。パスワードを変更して再度ログインしてください。',
  changeOwner: 'オーナー変更',
  changeOrganizationOwner: '組織所有者の変更',
  newOwnerEmail: '新しいオーナーのEメール',
  saveOnYearlySubscription: '保存{{percent}}%.',
  annual: '年間',
  resetAlarms: 'アラームの更新',
  resetAlarmsAlert:
    'すべてのアラームがリフレッシュされました。引き続きアラームに問題がある場合は、「問題を報告する」オプションを使用してチケットを提出してください。',
  resetAlarmsTooltipMessage:
    '削除されたアラームが電話でリングするなど、アラームに問題がある場合は、このオプションを使用してアラームを更新してください。問題が解決しない場合は、「問題を報告する」オプションを使用してチケットを提出してください。',
  resetTempPassword: '一時パスワードのリセット',
  currPassword: '現在のパスワード',
  tempPassword: '一時パスワード',
  newPassword: '新しいパスワード',
  reset: 'リセット',
  loggingIn: 'ログイン',
  resettingTempPassword: '一時パスワードのリセット',
  changingPassword: 'パスワードの変更',
  numSeats: '座席数',
  helpMeDecide: '決断の手助けを',
  numEnterpriseSeatsHelper:
    'シート数は、インシデントを受信するためにアプリを使用する組織内の人の数として選択する必要があります。リスナーを構成している間、そのエスカレーション・ポリシーを指定できます。エスカレーション・ポリシーには複数のレベルを設定できます。たとえば、エスカレーションの最初のレベルを3人のチームに指定できます。エスカレーションの最初のレベルが5分以内に応答しない場合、エスカレーションの2番目のレベルは5人のチームになります。この場合、シート数を8と指定できます。',
  numBusinessSeatsHelper:
    'アラームやリマインダーを受信するためにGalarmを使用する人数に合わせてシート数を選択してください。',
  perSeatPrice: '{{price}} {{interval}} 1席につき',
  doneForToday: '了承済み',
  getStarted: 'スタート',
  firstAlertInstructions: 'ファースト・リスナーの指示',
  learnMoreAboutGalarmEnterprise: 'Galarm Proについてもっと知る',
  home: 'ホーム',
  team: 'チーム',
  alert: 'アラート',
  listener: 'リスナー',
  forgotPassword: 'パスワードをお忘れですか？',
  forgotPasswordTitle: 'パスワードを忘れた',
  sendPasswordResetEmail: 'パスワード再設定メールの送信',
  sendingPasswordResetEmail: 'パスワード再設定メールの送信',
  field: 'フィールド',
  description: '説明',
  requestIntegration: '別の統合をリクエストする',
  integrationDetails: '統合の詳細',
  enterIntegrationDetails: '統合の詳細を提供する',
  galarmEnterpriseFreeTrial: '14日間無料トライアルに申し込む',
  galarmEnterpriseFreeTrialDescription: 'インシデント管理の自動化',
  bySigningUpYouAgreeToOur: 'サインアップすることにより、あなたは私たちの',
  and: 'そして',
  alarmSubscribers: 'アラーム・フォロワー',
  otherSubscribersCount: {
    one: '連絡先にいないフォロワーを1人増やす',
    other: '{{count}} 連絡先にいないフォロワーを増やす'
  },
  getAccountData: 'アラームデータのエクスポート',
  getAccountDataPrompt:
    'すべてのアラームを含むテキストファイルを送信する電子メールアドレスを入力してください。',
  getAccountDataConfirmation:
    'すべてのアラームを含むテキストファイルを{{email}} に電子メールでお送りしますので、ご注意ください。',
  sendingAccountData: 'アラームデータのエクスポート...',
  sentAccountData: '{{email}} に送信されるアラームデータ。',
  errorSendingAccountData: 'アラームデータのエクスポートエラー：{{error}}',
  sendingEmailVerificationLink: 'メール認証リンクの送信',
  viewLogs: 'ログ表示',
  message: 'メッセージ',
  billing: '請求',
  newIncidentForAlert: 'リスナーに新たな事件{{alertName}}',
  clickNextAfterAddingMembers: 'メンバーを追加したら「次へ」をクリックする。',
  clickDoneAfterAddingAlerts:
    '完了」をクリックして設定を完了し、ダッシュボードに移動します。',
  alertCreatedSuccessfully:
    'リスナーが正常に作成されました。リスナー名の前にある3つの点のメニューアイコンから、このリスナーをトリガーすることができます。',
  fixSubscription: '定期購読の修正',
  fixSubscriptionDescription:
    'お客様のサブスクリプションに問題が検出されました。お客様のサブスクリプションには{{numSeats}} のシートが含まれていますが、{{numSeatsAssigned}} のメンバーにキーを割り当てています。割り当てられるキーは{{numSeats}} までです。',
  fixSubscriptionChooseOption:
    '以下のオプションのいずれかを使用してサブスクリプションを修正してください。',
  fixSubscriptionContactAdmin: '各組織の管理者にご連絡ください。',
  unassignKeysFromMembers:
    'メンバーから少なくとも{{extraKeysAssigned}} キーの割り当てを解除する。',
  addMoreSeatsToSubscription:
    '{{extraKeysAssigned}} 以上の座席をご契約に追加してください。',
  revokeMembersKey: 'メンバー・キーの失効',
  revokeMembersKeyDescription: '鍵を失効させたいメンバーを選択する。',
  revoke: '取り消す',
  unableToRevokeMembersKey:
    'メンバーキーの失効にエラーが発生しました。サポートまでご連絡ください。',
  problemWithLoadingProducts: '製品の読み込みに問題があります。{{error}}',
  verificationCompleted: '検証完了',
  deviceAlreadyRegistered:
    'この電話番号は以前にこのデバイスで確認されています。',
  phoneNumberAutoFilled:
    'GalarmはGoogleアカウントから選択した電話番号を使用します。',
  unableToRegisterPreVerifiedUser: '認証済みのユーザーを登録できません。',
  allowNotifications: '通知を許可する',
  allowNotificationsDescription:
    'アラームがリングしたときにアラームを表示する。',
  scheduleExactAlarms: 'スケジュールアラーム',
  scheduleExactAlarmsHelperText: 'アラームを正確な時間に鳴らせるようにする。',
  scheduleExactAlarmsDescription: '正確な時間にアラームをリングする。',
  disableBatteryOptimization: 'バッテリー最適化の管理',
  disableBatteryOptimizationDescription:
    'Galarmをバックグラウンドで実行できるようにする。',
  requestRequiredPermissions:
    'Galarmがアラームをリングするには、以下の権限が必要です。',
  allowDisallowedPermissions: 'タップして許可する',
  notificationsAreEnabled: '通知を表示する許可はすでに許可されている。',
  connectingToStore: '店舗への接続...',
  restartApp: 'アプリの再起動',
  updateAvailable: '更新あり',
  english: '英語',
  russian: 'ロシア語',
  german: 'ドイツ語',
  french: 'フランス語',
  spanish: 'スペイン語',
  italian: 'イタリア語',
  portuguese: 'ポルトガル語',
  japanese: '日本語',
  korean: '韓国語',
  indonesian: 'インドネシア語',
  changeAppLanguage: 'アプリの言語を変更する',
  appLanguage: 'アプリ言語',
  iosChangeAppLanguagePara1:
    'Galarmは携帯電話の設定からアプリの言語を選択します。Galarmのアプリ言語を変更するには、以下の手順に従ってください：',
  iosChangeAppLanguageInstruction1:
    '電話設定 > 一般 > 言語と地域」を開きます。優先言語のリストにGalarmで使用したい言語があることを確認します。',
  iosChangeAppLanguageInstruction2:
    '電話設定」に戻り、一番下のアプリ一覧から「Galarm」をタップします。',
  iosChangeAppLanguageInstruction3:
    '言語」をタップし、Galarmに設定したい言語を選択します。言語を選択した後、アプリに戻ってください。',
  androidChangeAppLanguagePara1:
    'Galarmは携帯電話の設定からアプリの言語を選択します。Galarmのアプリ言語を変更するには、以下の手順に従ってください：',
  androidChangeAppLanguageInstruction1:
    '下の「アプリ設定を開く」ボタンをタップします。',
  androidChangeAppLanguageInstruction2:
    '言語」をタップし、Galarmで使用する言語を選択します。',
  androidChangeAppLanguageInstruction3:
    '言語を選択したら、ここに戻り、下の「アプリを再起動」ボタンをクリックします。',
  problemRetrievingSubscriptionData:
    '購読情報を取得する際に問題が発生しました。アカウントページに転送しています。問題が解決しない場合は、テクニカルサポートまでご連絡ください。',
  deletingOrganization: '組織の削除',
  deleteOrganization: '組織の削除',
  deleteOrganizationDescription:
    '本当に組織を削除しますか？\n  \n  これは不可逆的な操作です。組織のデータはすべて削除されます。\n  \n  組織を削除する前に、サブスクリプションをキャンセルしたことを確認してください。',
  galarmEnterpriseTrialDescription:
    '14日間の無料トライアルがあり、その期間中であれば無料で解約することができる。',
  galarmEnterpriseTrialAlreadyAvailed:
    'あなたはすでに14日間の無料トライアルを利用しています。製品を評価するためにさらに時間が必要な場合は、サポートに連絡してください。',
  signinWithGoogle: 'Googleでログイン',
  signinWithGithub: 'GitHubでサインインする',
  verifyAccount: 'アカウントの確認',
  verifyAccountMessage:
    '認証コードが記載されたメールが届いているはずです。以下のコードを入力して、アカウントを確認してください。コードが届いていない場合は、「コードを再送する」ボタンをクリックして新しいコードをリクエストしてください。',
  verificationCode: '検証コード',
  optionalString: '{{string}}',
  quickReminderTitle: 'タイトル（任意）',
  accountVerified: 'アカウント認証済み',
  accountVerifiedMessage: '認証情報を使用してログインしてください。',
  multipleUsersWithSameEmail:
    '同じメールアドレスを持つユーザーが複数います。連絡先までご連絡ください。',
  multipleUsersWithSameMobileNumber:
    '同じ携帯電話番号のユーザーが複数います。サポートまでご連絡ください。',
  startTrial: 'トライアル開始',
  nextSteps: '次へのステップ',
  firstTimeSetupComplete:
    'Galarm Enterpriseの初期設定が完了し、最初のリスナーが作成されました。ここから次のステップに進みます。',
  testTheAlertInstruction:
    '作成したリスナーをテストする方法については、{{link}} の説明を参照してください。',
  integrateTheAlertInstruction:
    'リスナーをさまざまな監視ツールと統合するには、{{link}} の説明を参照してください。',
  createMoreAlertsInstruction:
    '左側の "Listeners "タブでリスナーを追加することができます。',
  addMoreMembersInstruction: '左側の「メンバー」タブでメンバーを追加できます。',
  createTeamsInstruction:
    'メンバーを "チーム "にグループ化し、インシデント発生時にチーム全体にアラートすることができます。左側の「チーム」タブでチームを作成し、リスナーの設定で使用します。',
  testTheAlert: 'リスナーのテスト',
  integrateTheAlert: 'リスナーの統合',
  createMoreAlerts: 'リスナーを増やす',
  addMoreMembers: 'メンバーを追加する',
  createTeams: 'チームを作る',
  docs: '資料',
  contactSupport: '連絡先',
  pastDue: '期限切れ',
  canceled: 'キャンセル',
  unpaid: '未払い',
  incomplete: '未完成',
  incomplete_expired: '不完全 賞味期限切れ',
  trialing: '無料トライアル',
  paused: '休止',
  plan: 'プラン',
  status: 'ステータス',
  renewsOn: 'ニュースオン',
  yourRoles: 'あなたの役割',
  enterprisePlan: '{{quantity}} の座席は毎回更新される。{{interval}}',
  currentOrganization: '現在の組織',
  useThisOrganization: 'この組織を利用する',
  fixPayment: 'フィックスペイメント',
  fixingPayment: '支払いの確定',
  addPaymentInformation: '支払い情報の追加',
  noPaymentInformationAvailable:
    '支払い情報がありません。最近支払い情報を追加した場合は、ページを更新してください。',
  openBillingPortal: 'オープン請求ポータル',
  startupWizardDescription:
    'このウィザードは、最初のリスナーを作成するのに役立ちます。',
  userNotFound: 'ユーザーが見つかりません',
  wrongPassword: 'パスワードの間違い',
  emailAlreadyInUse: 'すでに使用されている電子メール',
  restoreDeletedAlarms: '削除されたアラームの復元',
  deleteAlarmAlert:
    '削除したアラームは「最近削除したアラーム」から復元できます。アラーム」タブの右上にある三点メニュー（⋮）をタップすると表示できます。\n\n削除されたアラームは30日間保存され、その後永久に削除されます。',
  slackVerificationCode: 'あなたのSlack認証コードは{{verificationCode}} です。',
  dndAccess: '邪魔しないアクセス',
  dndHelperText:
    '携帯電話が「おやすみモード」になっているときにアラームを鳴らせるようにします。',
  cantRestoreInstantAlarmMessage:
    'インスタントアラームは復元できません。必要に応じて、このアラームのチャットやその他の情報を表示できます。このアラームは、削除後30日経過すると永久に削除されます。',
  upgradeToUnlockAllFeatures: 'アップグレードで全機能をアンロック',
  otherSignupOptions: 'その他の登録オプション',
  renewSubscription: '購読の更新',
  subscriptionOnHold:
    '保留中（お客様のプランでは{{subscriptionQty}} 。\n{{numSubscriptionKeys}} 。',
  checklists: 'タスク',
  checklist: 'タスクリスト',
  typeToAddTask: 'タスクを追加するタイプ',
  specifyTaskName: 'タスク名の指定',
  otherChecklists: 'タスクリスト',
  defaultChecklistName: '無題',
  addTasksToChecklist: '下部の入力ボックスを使ってタスクを追加する',
  addTasksToDefaultChecklist:
    '下部の入力ボックスを使ってタスクを追加する...または右上の+ボタンをクリックして新しいタスクリストを開始する',
  createNewChecklist:
    '新しいタスクリストを作成して、関連するタスクを一箇所で管理することができます。',
  addNewChecklist: '新しいタスクリスト',
  alarmPhoto: 'アラーム写真',
  alarmPhotoFeatureTitle: 'アラーム写真',
  alarmPhotoFeatureDescriptionPremiumUser:
    'この機能を使用すると、アラームに写真を追加して、薬、タスクの指示、アイテムなどの写真を素早く視覚的な合図にすることができます。アラームが鳴ると写真が表示されます。',
  alarmPhotoFeatureDescriptionFreeUser:
    'この機能を使用すると、アラームに写真を追加して、薬、タスクの指示、アイテムなどの写真を素早く視覚的な合図にすることができます。アラームが鳴ると写真が表示されます。\n  \nこれはプレミアム機能です。この機能を使用するには、プレミアムへのアップグレードを求められます。',
  alarmPhotoPremiumFeature: 'アラームノートに写真を追加する',
  installPendingAppUpdate:
    '最新のアプリのアップデートがダウンロードされ、インストールの準備が整いました。',
  numTasks: { zero: 'タスクなし', one: '1 課題', other: '{{count}} 業務内容' },
  numTasksWithCompleted: {
    one: '1タスク、{{completed}} 完了',
    other: '{{count}} タスク、 完了{{completed}} '
  },
  muteAlarms: 'アラームのミュート',
  unmuteAlarms: 'アラームのミュート解除',
  alarmsAreMuted:
    '現在、すべてのアラームはミュートされています。タップしてミュートを解除します。',
  alarmsHaveBeenMuted:
    'アラームがミュートされました。ミュートを解除するまでアラームは鳴りません。',
  alarmsHaveBeenUnmuted:
    'アラームのミュートが解除されました。設定どおりにリングが鳴るはずです。',
  deleteChecklist: 'タスクリストの削除',
  confirmDeleteChecklist: 'タスクリストを削除しますか？',
  confirmDeleteCompletedTasks: '完了したタスクを削除しますか？',
  confirmResetCompletedTasks: '完了したタスクをリセットしますか？',
  confirmDeleteTask: '{{taskName}} を削除しますか？',
  showCompletedTasks: '完了したタスクの表示',
  hideCompletedTasks: '完了したタスクを隠す',
  deleteCompletedTasks: '完了したタスクの削除',
  resetCompletedTasks: '完了したタスクのリセット',
  yourGroups: 'グループ',
  newAlarmWith: '新しいアラーム{{name}}',
  newGroupWith: '新しいグループ{{name}}',
  alertContact: 'アラート{{name}}',
  alertGroup: 'アラート{{name}}',
  instantAlarmMovedToFabNotice:
    'インスタントアラーム機能は、この画面の右下にある「+」ボタンから利用できるようになりました。拡声器」アイコンは将来のリリースで右上から削除される予定です。',
  tasksIntroTitle: 'タスクとタスクリスト',
  tasksIntroDescription:
    'タスクは締め切りのないToDoです。各タスクを処理するたびにチェックしながら、ここで素早く追加・管理することができます。\n\n似たようなタスクをグループ化するためにタスクリストを作成することもできます。例えば、買い物用のタスクリスト、仕事用のタスクリストといった具合です。',
  groupsMovedToContactsScreen:
    '「グループ」はこのタブに統合されました。あなたのグループは一番上の「あなたのグループ」に表示されます。右下の "+"ボタンをタップすると、新しいグループを作成できます。',
  loadingTasks: 'タスクのロード...',
  tryPremium: 'プレミアムを試す',
  buyPremiumToUnlockFeature:
    'これはプレミアム機能です。14日間の無料トライアル付きのプレミアムプランで、この機能やその他多くのクールな機能のロックを解除してください。',
  newTask: '新しい仕事',
  newTaskList: '新しいタスクリスト',
  enterTaskName: 'タスク名を入力',
  enterTaskListName: 'タスクリスト名を入力',
  taskName: 'タスク名',
  taskListName: 'タスクリスト名',
  checklistTasks: '{{name}}',
  editTaskListName: 'タスクリスト名の編集',
  editTask: '編集タスク',
  releaseNotes: 'リリースノート',
  currentRelease: '(現在インストール中）',
  loadingReleaseNotes:
    'リリースノートを読み込んでいます。インターネットに接続されていることを確認してください。',
  id: '身分証明書',
  subscriptionData: '購読データ',
  organizations: '組織',
  showRotationCalendar: 'ショー・ローテーション・カレンダー',
  rotationCalendar: 'ローテーション・カレンダー',
  editShift: '編集シフト',
  startDateForShift: 'シフト開始日付',
  startTimeForShift: 'シフト開始時間',
  endDateForShift: 'シフト終了日付',
  endTimeForShift: 'シフト終了時間',
  editingShift: '編集シフト',
  addingShift: 'シフトの追加',
  products: '製品紹介',
  prices: 'プラン',
  planType: 'プランタイプ',
  business: 'ビジネス',
  enterprise: 'エンタープライズ',
  compare: '比較する',
  apiSupport: 'サービス監視のためのAPIサポート',
  accessToConsoleToViewAndCloseIncidents:
    'インシデントを管理するダッシュボード',
  triggerInstantAlertsFromTheConsole:
    'エンタープライズコンソールからインシデントをトリガーする',
  coreFeatures: 'コアの特徴',
  everythingInBusiness: 'ビジネスプランのすべてと：',
  updatePlanInstructions:
    '右側の「請求ポータルを開く」ボタンをクリックし、請求ポータルの「プランを更新する」ボタンを選択することで、プランを更新することができます。',
  membersDescription:
    '{{subscriptionKeysCount}} {{assignedSubscriptionKeysCount}} 指定席、 空席があります。メンバー名の前のチェックマークは指定席であることを示します。{{unassignedSubscriptionKeysCount}} ',
  centralBilling: 'セントラル・ビリング',
  saveTwoMonths: '2ヶ月保存',
  owner: 'オーナー',
  enterpriseAccountSummary:
    'あなたは、Galarm Pro{{planType}} プランに登録されている組織{{organizationName}} の一員です。',
  perYearPerUser: 'ユーザーあたり/年',
  perMonthPerUser: 'ユーザー/月',
  creationDate: '日付',
  updatePhoneNumber: '電話番号の変更',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    '国番号を確認し、新しい電話番号を入力してください。認証が完了すると、この電話番号にアカウントがリンクされます。',
  unableToUpdatePhoneNumber:
    '電話番号を変更できません:{{error}}.サポートに連絡してください。',
  phoneNumberUpdated: '電話番号の変更に成功しました。',
  phoneNumberUpdatedMessage:
    'アプリを普通に使い始めることができます。連絡先にアドレス帳の電話番号を更新してもらう。',
  memberChangedPhoneNumber:
    '{{memberName}} が電話番号を変更しました。引き続きアラームに追加するには、アドレス帳を更新してください。',
  preferredSourceForIncidents: 'インシデントの優先情報源',
  apiCodesDescription:
    'リスナーをソースに統合するには、以下の手順を使用します。',
  rotation: 'ローテーション',
  noAlarmsForThisDay: 'この日はアラームなし',
  markAlarmsAsComplete: 'アラームを完了としてマークする',
  markAlarmsAsCompleteExplanation:
    '回アラームは完了としてマークされ、「非アクティブ」アラームセクションに表示されます。\n\n繰り返しのアラームは完了としてマークされ、次の発生に移動します。',
  enterIncidentPrefix: '最大5文字',
  incidentPrefix: 'インシデント・プレフィックス',
  incidentPrefixAlreadyExists: 'インシデントプレフィックスが既に存在する',
  removeTask: 'タスクを削除しますか？',
  incidentPrefixLengthExceeded: 'インシデント・プレフィックスが5文字より長い',
  criticalAlerts: 'クリティカル・アラート',
  criticalAlertsConfiguration:
    'アラームは、電話がミュートされていたり、Focusがオンになっている場合でも、{{criticalAlertsVolume}}%の音量でリングします。',
  criticalAlertsDisabledShort:
    '電話がミュートになっている場合、アラームはリングしません。',
  criticalAlertsConfigurationGlobal:
    'アラームは、電話がミュートになっていたり、Focus がオンになっていても、{{criticalAlertsVolume}}% の音量でリングします。',
  criticalAlertsDisabledShortGlobal:
    '電話がミュートになっている場合、アラームはリングしません。',
  criticalAlertsEnabled:
    'クリティカルアラートが有効になっています。電話がミュートになっていたり、Focus がオンの状態でもアラームがリングします。',
  criticalAlertsDisabled:
    'クリティカルアラートが無効になります。電話がミュートになっている場合、アラームはリングしません。',
  enableCriticalAlerts: 'クリティカル・アラートの有効化',
  summer_soft: 'サマーソフト',
  beeps: 'ビープ音',
  bollywood_nights: 'ボリウッド・ナイト',
  gentle_reminder: '優しいリマインダー',
  himalayan_piper: 'ヒマラヤ・パイパー',
  holiday_joy: 'ホリデー・ジョイ',
  hollywood_adventure: 'ハリウッド・アドベンチャー',
  melody: 'メロディ',
  morning_rooster: 'モーニング・ルースター',
  morning_sunshine: 'モーニング・サンシャイン',
  sweet_sea: '甘い海',
  old_school_ring: 'トリング・トリング',
  MyAlarmNotification: '{{alarmName}} - 時間だ。',
  ParticipantCascadingAlarmNotification:
    '{{alarmName}} - をリマインダーする時間だ。{{alarmCreatorName}}',
  ParticipantSimultaneousAlarmNotification:
    '{{alarmName}}.参加者を確認する時間だ。',
  InstantAlarmNotification: '{{alarmName}}.至急ご連絡ください。',
  MyAlarmNotificationShort: '時間だ。',
  ParticipantCascadingAlarmNotificationShort:
    '{{alarmCreatorName}} をリマインダーする時間だ。',
  ParticipantSimultaneousAlarmNotificationShort: '参加者を確認する時間だ。',
  InstantAlarmNotificationShort: '早急なご対応をお願いいたします。',
  MyRecipientAlarmNotification:
    '{{alarmName}} - をリマインダーする時間だ。{{alarmRecipientName}}',
  MyRecipientAlarmNotificationShort:
    '{{alarmRecipientName}} をリマインダーする時間だ。',
  RecipientAlarmNotification:
    '{{alarmName}} (by ) - 時間だ。{{alarmCreatorName}}',
  RecipientAlarmNotificationShort: '時間だ - by{{alarmCreatorName}}.'
}
