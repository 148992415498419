import React, { useRef, useState } from 'react'
import { Box, Form, FormField, TextInput, Button, Text, Heading } from 'grommet'
import GlobalConfig from '../GlobalConfig'
import { Constants, I18n } from 'galarm-config'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { validateEmail, validatePassword } from '../utils/validations'
import ErrorProvider from './ErrorProvider'
import ReCAPTCHA from 'react-google-recaptcha'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { SecondaryText } from 'web-components'

const Login = () => {
  const [value, setValue] = useState({ email: '', password: '' })

  const location = useLocation()
  const navigate = useNavigate()

  const [errors, setErrors] = useState([])
  const addError = error => setErrors(errors.concat([error]))
  const onClearError = errorToClear => {
    const newErrors = errors.filter(error => error !== errorToClear)
    setErrors(newErrors)
  }

  const captchaRef = useRef(null)

  const onLogin = async ({ value }) => {
    GlobalConfig.showProgress({
      state: Constants.ProgressStates.IN_PROGRESS,
      message: I18n.t('loggingIn'),
      closeable: false
    })

    try {
      if (!__DEV__) {
        const token = captchaRef.current.getValue()
        captchaRef.current.reset()

        const functions = getFunctions()
        const verifyRecaptcha = httpsCallable(functions, 'verifyRecaptcha')
        const result = await verifyRecaptcha({ token })
        const verificationResponse = result.data

        if (!verificationResponse.success) {
          addError(
            I18n.t('recaptchaFailed', {
              error: verificationResponse['error-codes'].join(', ')
            })
          )
          GlobalConfig.hideProgress()
          return
        }
      }

      const { email, password } = value
      const auth = getAuth()
      await signInWithEmailAndPassword(auth, email, password)
      const userAuthData = await auth.currentUser.getIdTokenResult()

      if (userAuthData.claims?.isTempPassword) {
        navigate('/resetTempPassword', { replace: true })
        GlobalConfig.hideProgress()
        return
      }
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
      GlobalConfig.hideProgress()
    } catch (error) {
      console.error('onLogin', error)

      switch (error.code) {
        case 'auth/user-not-found':
          addError(I18n.t('userNotFound'))
          break
        case 'auth/wrong-password':
          addError(I18n.t('wrongPassword'))
          break
        default:
          addError(error.message)
          break
      }

      GlobalConfig.hideProgress()
    }
  }

  const onForgotPassword = () => {
    navigate('/forgotPassword')
  }

  return (
    <ErrorProvider
      errors={errors}
      getErrorMessage={error => error}
      onClearError={onClearError}>
      <Box overflow={'auto'}>
        <Box flex={{ shrink: 0 }} align="center">
          <Heading level={4} margin={{ left: 'medium' }}>
            {I18n.t('login')}
          </Heading>
          <Box
            background="white"
            pad="small"
            round="small"
            margin="medium"
            width="large">
            <Form
              value={value}
              onChange={nextValue => setValue(nextValue)}
              validate="submit"
              onSubmit={onLogin}>
              <FormField
                htmlFor="email"
                name="email"
                label={
                  <SecondaryText weight={500}>{I18n.t('email')}</SecondaryText>
                }
                validate={validateEmail}>
                <TextInput id="email" name="email" />
              </FormField>
              <Box direction="row">
                <FormField
                  flex
                  htmlFor="password"
                  name="password"
                  label={
                    <SecondaryText weight={500}>
                      {I18n.t('password')}
                    </SecondaryText>
                  }
                  validate={validatePassword}>
                  <TextInput type="password" id="password" name="password" />
                </FormField>
                <Button
                  plain
                  color="primary"
                  label={I18n.t('forgotPassword')}
                  onClick={onForgotPassword}
                />
              </Box>
              <Box
                direction="row"
                gap="medium"
                justify="center"
                margin="medium">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                />
              </Box>
              <Box
                direction="row"
                gap="medium"
                justify="center"
                margin="medium">
                <Button
                  type="submit"
                  primary
                  style={{ color: 'white' }}
                  label={I18n.t('login')}
                />
              </Box>
            </Form>
          </Box>
          <Box direction="row" gap="small" justify="center" pad="medium">
            <Text>{I18n.t('notAUser')}</Text>
            <Link to="/signup">{I18n.t('signup')}</Link>
          </Box>
        </Box>
      </Box>
    </ErrorProvider>
  )
}

export default Login
