module.exports = {
  alarms: 'Alarmes',
  contacts: 'Contacts',
  groups: 'Les groupes',
  settings: 'Réglages',
  notificationsTab: 'Notifications',
  specifyTimeInFutureForAlarm: "L'alarme ne peut pas être dans le passé.",
  specifyTimeInFutureForPreReminder:
    "Le pré-rappel de l'alarme appartient au passé.",
  specifyNameForGroup: 'Le nom du groupe est obligatoire.',
  updatingGroupName: 'Mise à jour des informations sur le groupe.',
  unableToEditGroupName:
    'Impossible de mettre à jour les informations sur le groupe. Veuillez réessayer après une certaine heure.',
  selectSmallerImage:
    "L'image est supérieure à 10 Mo. Sélectionnez une image plus petite.",
  sendingVerificationCode:
    'Envoi du code de vérification à {{formattedMobileNumber}}.',
  verifyingCode: 'Vérification du code.',
  creatingUserProfile: 'Le réglage...',
  unabeToCreateUserProfile:
    'Impossible de régler un compte pour le moment. Veuillez réessayer dans quelques instants.',
  unableToSendVerificationCode:
    "Impossible d'envoyer le code de vérification. {{error}}",
  unableToVerifyCode: 'Impossible de vérifier le code. {{error}}',
  unableToRegisterUserWithoutMobileNumber:
    "Impossible d'effectuer la configuration. {{error}}",
  permissionDeniedToAccessCameraIos:
    "Galarm n'a pas la permission d'accéder à la caméra. \n\nAllez dans Réglages > Galarm et autorisez l'accès à la caméra.",
  permissionDeniedToAccessCameraAndroid:
    "Galarm n'a pas la permission d'accéder à la caméra.\n\nAllez dans Réglages > Apps > Galarm > Permissions et autorisez l'accès à la caméra.",
  permissionDeniedToAccessPhotosIos:
    "Galarm n'a pas l'autorisation d'accéder aux photos.\n\nAllez dans Réglages > Galarm et autorisez l'accès aux photos.",
  permissionDeniedToAccessPhotosAndroid:
    "Galarm n'a pas l'autorisation d'accéder aux photos.\n\nAllez dans Réglages > Apps > Galarm > Permissions et autorisez l'accès au stockage.",
  permissionDeniedToAccessCalendarIos:
    "Galarm n'a pas la permission d'accéder au calendrier.\nAllez dans Réglages > Calendriers et autorisez l'accès à Galarm.",
  permissionDeniedToAccessCalendarAndroid:
    "Galarm n'a pas la permission d'accéder au calendrier.\nAllez dans Réglages > Apps > Galarm > Permissions et autorisez l'accès au calendrier.",
  verificationCodeSent:
    'Code de vérification envoyé à {{formattedMobileNumber}}.',
  rateTheApp:
    'Nous espérons que Galarm vous sera utile. Prenez le temps de nous évaluer sur le Play Store. Nous vous remercions de votre soutien.',
  backgroundAppRefreshDisabledPermission:
    "Il se peut que les alarmes ne sonnent pas parce que Galarm n'a pas l'autorisation de procéder à l'actualisation de l'application en arrière-plan.\n\nTapez sur \"Activer\" et activez \"Rafraîchissement de l'application en arrière-plan\".",
  selectedGroupUnselected:
    'Vous pouvez sélectionner un groupe ou un ensemble de contacts comme participants. Le groupe précédemment sélectionné sera désélectionné. Poursuivre ?',
  selectedContactsUnselected:
    'Vous pouvez sélectionner un groupe ou un ensemble de contacts comme participants. Les contacts sélectionnés précédemment seront désélectionnés. Poursuivre ?',
  notificationsDisabledIos:
    'Les alarmes ne sonneront pas car Galarm n\'a pas l\'autorisation d\'afficher des notifications.\n\nAllez dans Réglages > Galarm > Notifications et activez "Alertes", "Sons" et "Bannières".',
  notificationsDisabledAndroid:
    'Les notifications d\'alarme ne s\'affichent pas à l\'écran.\n\nAppuyez sur "Activer", sélectionnez "Notifications" et autorisez les notifications.',
  alarmNotificationChannelDisabled:
    "Les notifications d'alarme ne s'affichent pas à l'écran. \n\nAppuyez sur \"Activer\" pour que les notifications d'alarme s'affichent à l'écran.",
  alarmLockScreenNotificationlDisabled:
    "Les notifications d'alarme ne s'affichent pas sur l'écran de verrouillage. \n\nAppuyez sur \"Activer\" pour permettre aux notifications d'alarme de s'afficher sur l'écran de verrouillage.",
  alarmWillNotRingDueToNotificationsDisabledIos:
    'Les alarmes ne sonneront pas car les notifications sont désactivées.\n\nAllez dans Réglages > Galarm > Notifications et activez les "Alertes", les "Sons" et les "Bannières".',
  alarmWillNotRingDueToCriticalAlertsDisabledIos:
    'Les alarmes ne sonneront pas lorsque le téléphone est en sourdine car les alertes critiques sont désactivées.\n\nAllez dans Réglages > Galarm > Notifications et activez les "Alertes critiques".',
  alarmWillNotRingDueToNotificationsDisabledAndroid:
    'L\'alarme ne s\'affiche pas à l\'écran car les notifications sont désactivées.\n\nAppuyez sur "Activer", sélectionnez "Notifications" et autorisez les notifications.',
  noNotificationsScheduledAlarmInPast:
    "L'alarme est dans le passé. Aucune notification n'est programmée.",
  alarmOverAllowedLimit: {
    zero: "\nVous avez atteint votre limite d'alarme de {{limit}} alarmes.\n\nVeuillez supprimer ou réutiliser une alarme existante.",
    one: "Vous avez atteint votre limite d'alarme de {{limit}} alarmes.\n\nVous avez 1 alarme inactive que vous pouvez supprimer ou réutiliser.",
    other:
      "Vous avez atteint votre limite d'alarme de {{limit}} alarmes.\n\nVous avez {{count}} alarmes inactives que vous pouvez supprimer ou réutiliser."
  },
  alarmAlreadyConfirmed: "Vous l'avez déjà confirmé.",
  alarmAlreadyDeclined: 'Vous avez déjà refusé.',
  participantMovedToPosition: '{{name}} a été déplacé à la position {{count}}.',
  memberIsFirstParticpant: '{{name}} est le premier participant.',
  memberIsLastParticpant: '{{name}} est le dernier participant.',
  cantContinueWithRegistrationWhenOffline:
    "Impossible de poursuivre l'enregistrement car l'appareil est hors ligne.",
  cantContinueWithRegistrationWhenOfflineWithTryAgain:
    "Impossible de poursuivre l'enregistrement car l'appareil est hors ligne. Assurez-vous que vous êtes connecté à l'internet.",
  confirmBlockContact:
    "Vous ne recevrez plus d'alarme de la part de {{name}}. Poursuivre ?",
  confirmUnblockContact:
    'Débloquer {{name}} rétablira également les alarmes bloquées de ces sites. Poursuivre ?',
  unblockingContact: 'Débloquer {{name}} et rétablir les alarmes.',
  unableToUnblockContact: 'Impossible de débloquer le contact. {{error}}',
  blockingContact: 'Blocage de {{name}}.',
  unableToBlockContact: 'Impossible de bloquer le contact. {{error}}',
  cantAddContactToAlarmBecauseBlocked:
    "Vous avez bloqué {{name}}. Débloquez-le pour l'ajouter comme participant à l'alarme.",
  cantAddContactToGroupBecauseBlocked:
    "Vous avez bloqué {{name}}. Débloquez-le pour l'ajouter comme membre du groupe.",
  sendMessageNotAllowedForPastAlarms:
    "Il n'est pas possible d'envoyer des messages pour des alarmes inactives.",
  nameIsRequired: 'Le nom est obligatoire.',
  confirmDisableGroupAlarm:
    "La désactivation de l'alarme de groupe entraîne également la désactivation de l'alarme pour les autres participants. Poursuivre ?",
  removeMemberFromGroup: 'Voulez-vous retirer {{name}} de ce groupe ?',
  leaveGroupConfirm: 'Voulez-vous quitter ce groupe ?',
  deviceOffline:
    "L'appareil est hors ligne. Assurez-vous que vous êtes connecté à Internet et réessayez.",
  cantUpdateGroupDefaulConfigWhenOffline:
    'La configuration de groupe modifiée ne sera pas appliquée aux futures alarmes car le dispositif est hors ligne.',
  specifyFeedback: "Un retour d'information est nécessaire.",
  specifyEmailId: "L'adresse électronique est requise.",
  specifyValidEmailId: "L'adresse électronique n'est pas valide.",
  specifyProblem: 'Une description du problème est requise.',
  appInstalledUsingSameNumberOnDifferentPhone:
    "Vous avez installé Galarm sur un autre appareil en utilisant le même numéro. L'application sur cet appareil est réinitialisée.",
  invalidUid:
    "Ce compte utilisateur n'existe plus. L'application Galarm sur cet appareil est réinitialisée.",
  accountDeleted: 'Votre compte a été supprimé.',
  deleteAccount: 'Supprimer mon compte',
  deletingAccount: 'Suppression de votre compte',
  howCanWeImprove:
    'Nous sommes désolés de vous voir partir. Comment pouvons-nous nous améliorer ?',
  noMusicAlert: "Aucun fichier musical n'a été trouvé.",
  noLongerAvailableMusic:
    "{{fileName}} n'est plus disponible. La sonnerie a été changée en {{defaultRingtoneName}}",
  userInactiveTitle: 'Bienvenue !',
  userInactiveMessage:
    'Créez des alarmes pour les choses importantes et ne les manquez jamais.',
  createAlarm: 'Créer une alarme',
  updatedAppAvailable: "Une nouvelle version de l'application est disponible.",
  alarmOccurrenceDisabled: 'Désactivé pour cet événement',
  ownAlarmOccurrenceDone: {
    zero: 'Marqué Terminé à {{timeString}}',
    one: "Terminé à l'adresse suivante : {{timeString}} le {{dateString}}"
  },
  ownAlarmOccurrenceSkipped: {
    zero: 'Passé à {{timeString}}',
    one: 'Passé à {{timeString}} sur {{dateString}}'
  },
  enabledAlarmsCount: {
    one: '1 alarme activée.',
    other: '{{count}} alarmes activées.'
  },
  enabledAndNotEnabledAlarmsCount: {
    one: '1 alarme activée. Les alarmes sans occurrence future restent désactivées.',
    other:
      '{{count}} alarmes activées. Les alarmes sans occurrence future restent désactivées.'
  },
  disabledAlarmsCount: {
    one: '1 alarme désactivée.',
    other: '{{count}} alarmes désactivées.'
  },
  ownAlarmOccurrenceUnacknowledged: 'Pas de réponse',
  participantAlarmOccurrenceDone: {
    zero: 'Marqué fait par {{participantName}} à {{timeString}}',
    one: 'Marqué Terminé par {{participantName}} à {{timeString}} le {{dateString}}'
  },
  participantAlarmOccurrenceSkipped: {
    zero: 'Passé par {{participantName}} à {{timeString}}',
    one: 'Passé par {{participantName}} à {{timeString}} le {{dateString}}'
  },
  participantAlarmOccurrenceUnacknowledged:
    "{{participantName}} n'a pas répondu",
  groupAlarmOccurrenceConfirmed: {
    zero: 'Confirmer à {{timeString}}',
    one: "Confirmer à l'adresse {{timeString}} le {{dateString}}"
  },
  groupAlarmOccurrenceDeclined: {
    zero: 'Refusé à {{timeString}}',
    one: 'Refusé sur {{timeString}} le {{dateString}}'
  },
  warning: 'Avertissement',
  info: 'Info',
  confirm: 'Confirmer',
  error: 'Erreur',
  cantUploadImage: 'Impossible de télécharger une image',
  cantDeleteImage: "Impossible de supprimer l'image",
  cantEditGroup: 'Impossible de modifier le groupe',
  cantAddNewContact: "Impossible d'ajouter un nouveau contact",
  cantDeleteContact: 'Impossible de supprimer un contact',
  cantEditContact: 'Impossible de modifier le contact',
  cantSaveAlarm: "L'alarme ne peut être sauvegardée",
  cantSaveGroup: 'Impossible de sauvegarder le groupe',
  cantReloadContacts: 'Impossible de recharger les contacts',
  cantTakePhoto: 'Impossible de prendre une photo',
  cantExportToCalendar: "Impossible d'exporter vers le calendrier",
  cantOpenPhotoLibrary: "Impossible d'ouvrir la photothèque",
  cantDeleteAlarmHistory: "Impossible de supprimer l'historique des alarmes",
  cannotDuplicateAlarm: "Impossible de reproduire l'alarme",
  upgradeToPremiumToDuplicateAlarm:
    "Passez à la version Premium pour créer davantage d'alarmes de groupe et d'ami.",
  confirmAcceptAlarm: "Accepter l'alarme ?",
  confirmRejectAlarm: "L'alarme Refuser ?",
  deleteAlarm: "Supprimer l'alarme ?",
  restoreAlarmConfirmation: "Rétablir l'alarme ?",
  respondToRepeatingAlarm: {
    zero: "Vous êtes ajouté en tant que destinataire de l'alarme {{alarmName}} par {{alarmCreatorName}}. Vous serez alerté à l'adresse {{alarmTimeString}} {{alarmRepetitionString}} à partir de {{alarmDateString}}",
    one: "Vous êtes ajouté en tant que participant à l'alarme {{alarmName}} par {{alarmCreatorName}}. Vous serez alerté à l'adresse {{alarmTimeString}} {{alarmRepetitionString}} à partir de {{alarmDateString}}"
  },
  respondToOneTimeAlarm: {
    zero: "Vous êtes ajouté en tant que destinataire de l'alarme {{alarmName}} par {{alarmCreatorName}}. Vous serez alerté à l'adresse {{alarmTimeString}} {{alarmDateString}}",
    one: "Vous êtes ajouté en tant que participant à l'alarme {{alarmName}} par {{alarmCreatorName}}. Vous serez alerté à l'adresse {{alarmTimeString}} {{alarmDateString}}"
  },
  cantMoveMemberUp: "Impossible d'avancer {{name}} Up",
  cantMoveMemberDown: 'Ne peut pas bouger {{name}} Vers le bas',
  decline: 'Refuser',
  cantEditProfile: 'Impossible de modifier le profil',
  cantSelectContact: 'Impossible de sélectionner un contact',
  cantSendMessage: "Impossible d'envoyer un message",
  cantSendVerificationCode: "Impossible d'envoyer le code de vérification",
  confirmBlockContactTitle: 'Confirmer le contact du bloc',
  confirmUnblockContactTitle: 'Confirmer Débloquer le contact',
  cantSaveProfile: 'Impossible de sauvegarder le profil',
  cantBlockContact: 'Impossible de bloquer le contact',
  cantUnblockContact: 'Impossible de débloquer un contact',
  cantLoadEarlierMessages: 'Impossible de charger les messages précédents',
  cantRestoreAlarm: "Impossible de rétablir l'alarme",
  cantSendFeedback: "Impossible de soumettre un retour d'information",
  cantSubmitProblem: "Problème d'impossibilité de soumission",
  cantDeletePastAlarmsInBulk:
    'Impossible de supprimer des alarmes inactives en bloc',
  cantAddNewGroup: "Impossible d'ajouter un nouveau groupe",
  alreadySnoozing: 'Déjà en train de Rétarder',
  markAlarmUndone:
    "Vous avez marqué cette alarme 'Terminé' il y a {{durationString}}. Annuler ?",
  markAlarmUnskipped:
    'Vous avez passé cette alarme il y a {{durationString}}. Annuler ?',
  cantUndo: "Impossible d'annuler",
  fetchMusicFromPhone: 'Recherche de musique disponible.',
  enableAllAlarmsInCategory: 'Activer toutes les alarmes de cette catégorie ?',
  disableAllAlarmsInCategory:
    'Désactiver toutes les alarmes de cette catégorie ?',
  allAlarmsAlreadyEnabled:
    'Toutes les alarmes de cette catégorie sont déjà activées.',
  allAlarmsAlreadyDisabled:
    'Toutes les alarmes de cette catégorie sont déjà désactivées.',
  time: "L'heure",
  timeForBuddy: "L'heure de l'ami",
  timeForYou: 'Rappelez-moi à',
  name: 'Nom',
  title: 'Titre',
  notes: 'Notes',
  repeat: 'Répéter',
  preReminder: 'Prérappel',
  remove: 'Retirer',
  preReminderShortAlert: '{{duration}} Prérappel',
  preReminderTitle: '{{alarmName}} à {{date}}',
  preReminderGroupHelperText:
    "Ce pré-rappel n'est destiné qu'à vous. Les autres participants à l'alarme peuvent régler leurs propres pré-rappels une fois qu'ils ont reçu l'alarme.",
  addPreReminder: 'Ajouter un pré-rappel',
  helperTextForPreReminder:
    'Touchez le bouton de réinitialisation situé en haut pour supprimer le pré-rappel.',
  edit: 'Editer',
  delete: 'Supprimer',
  creator: 'Créateur',
  useAsDefaultGroupConfiguration: 'Appliquer aux alarmes futures',
  done: 'Terminé',
  skipped: 'Passé',
  save: 'Sauvegarder',
  members: 'Membres',
  mobileNumber: 'Numéro de téléphone',
  enter: 'Entrer',
  addMembers: 'Ajouter des membres',
  respond: 'Répondre',
  yes: 'Oui',
  no: 'Non',
  cancel: 'Annuler',
  participants: 'Participants',
  takePhoto: 'Prendre une photo',
  chooseFromLibrary: 'Choisir dans la bibliothèque',
  resendCode: 'Code de renvoi',
  register: 'Registre',
  inviteFriends: 'Partager cette application',
  invite: 'Invitation',
  incorrectNumber: 'Numéro erroné ?',
  date: 'Date',
  cascadingAlarmInterval: 'Heure entre les alertes des participants',
  ringParticipantAlarmsByDefault: 'Sonner les alarmes sans les accepter',
  alarmRingtone: "Sonnerie d'alarme",
  pickASong: 'Choisir de la musique sur le téléphone',
  privacyPolicy: 'Politique de confidentialité',
  sendFeedback: "Retour d'information",
  galarmWeb: 'Galarm Web',
  galarmEnterprise: 'Galarm Pro',
  galarmAdmin: 'Galarm Admin',
  troubleshooting: 'Dépannage',
  alarmDidntRingHelp: "Aidez-nous ! Mon alarme n'a pas sonné",
  autoStartSettingHelperText:
    "Si votre téléphone dispose d'un réglage de démarrage automatique, assurez-vous que celui-ci est activé pour Galarm.",
  batteryOptimizationHelperText:
    "Désactiver l'optimisation de la batterie pour Galarm",
  troubleshootingHelperText:
    'Les réglages suivants peuvent empêcher les alarmes de sonner comme prévu. Veuillez revoir ces réglages.',
  getMoreHelpText: 'Toujours des problèmes ?',
  autoStartSetting: 'Démarrage automatique',
  batteryOptimization: 'Optimisation de la batterie',
  battery: 'Batterie',
  rateTheAppSettings: 'Notez cette application',
  about: 'À propos et aide',
  leaveGroup: 'Groupe de congé',
  removeGroup: 'Supprimer le groupe',
  viewMember: 'Voir {{memberName}}',
  removeMember: 'Retirer {{memberName}}',
  changeMemberState: 'Modifier qui peut alerter {{memberName}}',
  changeYourGroupState: 'Modifier les personnes autorisées à vous alerter',
  numMembersInGroup: 'Membres : {{count}}',
  notifications: "Les notifications d'application sont désactivées",
  backgroundAppRefresh:
    "L'actualisation des applications en arrière-plan est désactivée",
  groupsInCommon: 'Groupes en commun',
  alarmsInCommon: 'Alarmes en commun',
  close: 'Fermer',
  responsePending: 'Votre réponse ?',
  later: 'Plus tard',
  install: 'Installer',
  all: 'Tous',
  numYears: { one: '1 an', other: '{{count}} années' },
  numMonths: { one: '1 mois', other: '{{count}} mois' },
  numDays: { one: '1 jour', other: '{{count}} jours' },
  hours: { one: '1 heure', other: '{{count}} heures' },
  minutes: { one: '1 minute', other: '{{count}} minutes' },
  seconds: { one: '1 seconde', other: '{{count}} secondes' },
  minutesSmall: '{{count}} min',
  secondsSmall: '{{count}} sec',
  hoursAfter: { one: '1 heure après', other: '{{count}} heures après' },
  minutesAfter: { one: '1 minute après', other: '{{count}} minutes après' },
  hoursAfterBuddy: {
    one: "1 heure après l'ami",
    other: "{{count}} heures après l'ami"
  },
  minutesAfterBuddy: {
    one: "1 minute après l'ami",
    other: "{{count}} minutes après l'ami"
  },
  longerThanAnHour: '> 1 heure',
  licenses: 'Licences de tiers',
  version: 'Version',
  termsOfService: "Conditions d'utilisation",
  showPrivacyPolicy: '> Politique de confidentialité',
  showTermsOfService: "> Conditions d'utilisation",
  showLicenses: '> Licences accordées à des tiers',
  change: 'Changer',
  snooze: 'Rétarder',
  ringOnVibrate: 'Sonner en silencieux',
  vibrate: 'Vibrer',
  ringOnEarphoneOnly: "Sonner l'alarme sur les écouteurs uniquement",
  ringOnSpeakerAndEarphone:
    "Sonner l'alarme sur le haut-parleur et les écouteurs",
  whenEarphoneIsConnected: 'Lorsque des écouteurs sont branchés',
  tapGesture: 'Robinet',
  slideGesture: 'Diapositive',
  silent: 'Silencieux',
  volume: 'Volume',
  soundAndVibration: 'Son et vibrations',
  advancedSettings: 'Réglages avancés',
  gestureToDismissAnAlarm: 'Geste pour ignorer une alarme',
  someNotificationsAreMuted: 'Certaines notifications sont mises en sourdine',
  allNotificationsEnabled: 'Toutes les notifications sont activées',
  notificationSettings: 'Réglages de la notification',
  galarmWebNotifications: 'Notifications Web Galarm',
  alarmsfromOthersNotifications: 'Alarmes des participants Notifications',
  sharedAlarmNotifications: "Notifications d'alarmes partagées",
  alarmChatNotifictions: 'Notifications des alarmes discutées',
  groupNotifications: 'Notifications de groupe',
  alarmAcknowledgementNotifications: "Notifications d'acquittement d'alarme",
  send: 'Envoyer',
  prev: 'Prévenir',
  snoozing: '{{timeString}}',
  specifySnoozeDuartion: 'Spécifier la durée de la fonction "Rétarder".',
  chooseDifferentDuration: 'Choisir une autre durée',
  noCalendarsFound: "Aucun calendrier n'a été trouvé sur l'appareil.",
  eventWillEndAt: "L'événement se terminera à l'adresse {{timeString}}.",
  selectDurationWithinTheSameDay:
    "L'événement ne peut pas franchir la limite de la journée.",
  cannotMapRepeatTypeInTheDeviceCalendar:
    'Impossible d\'"Exporter vers le calendrier" car le réglage "Répéter" de votre alarme n\'est pas pris en charge par le calendrier.',
  alarmSavedToCalendar:
    "L'alarme a été sauvegardée avec succès dans le calendrier.",
  faq: 'Questions fréquemment posées',
  openSettings: 'Ouvrir les réglages',
  openAppSettings: "Ouvrir les réglages de l'application",
  alreadyDone: 'Terminé',
  enableAutoStart: 'Activer le démarrage automatique',
  restrictedApps: 'Applications restreintes',
  unmonitoredApps: 'Applications non surveillées',
  enableProtected: 'Applications protégées',
  manageAppLaunch: "Gérer le lancement de l'application",
  unblockContact: 'Débloquer le contact',
  blockContact: 'Contact en bloc',
  call: 'Appeler {{name}}',
  text: 'Texte {{name}}',
  chat: 'Discuter',
  ok: 'OK',
  stopSnooze: 'Arrêter de Rétarder',
  emailId: 'Votre adresse électronique',
  feedback: "Retour d'information",
  submit: 'Soumettre',
  reportAProblem: 'Signaler un problème',
  enableAlarm: "Activer l'alarme",
  restoreAlarm: "Rétablissement de l'alarme",
  deletePastAlarms: 'Suppression des alarmes inactives',
  accept: 'Accepter',
  defaultPersonalAlarmName: 'Rappel personnel',
  defaultGroupAlarmName: 'Événement de groupe',
  defaultRecipientAlarmName: 'Alarme ami',
  search: 'Recherche',
  recent: 'Récents',
  upcoming: 'À venir',
  missed: 'Manqué',
  expired: 'Expiré',
  past: 'Passé',
  active: 'Actif',
  inactive: 'Inactif',
  allAlarms: 'Toutes les alarmes',
  myAlarms: 'Mes alarmes',
  participantAlarms: 'Alarmes pour les participants',
  pendingParticipantAlarms: "Nouvelles alarmes d'autrui",
  alarmsWithUnreadChatMessages: 'Alarmes avec New Chat',
  systemNotifications: 'Messages du système',
  missedAlarms: 'Alarmes manquées',
  recentlyDeletedAlarms: 'Alarmes récemment supprimées',
  searchAlarms: "Recherche d'alarmes",
  participant: 'Participant',
  you: 'Vous',
  none: 'Aucun',
  onceOnly: 'Une seule fois',
  weekdays: 'En semaine',
  weekends: 'Week-end',
  everyDayString: 'Quotidiennement',
  everySunday: 'Tous les Dimanche',
  everyMonday: 'Tous les Lundi',
  everyTuesday: 'Tous les Mardi',
  everyWednesday: 'Tous les Mercredi',
  everyThursday: 'Tous les Jeudi',
  everyFriday: 'Tous les Vendredi',
  everySaturday: 'Tous les Samedi',
  sunday: 'Dimanche',
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  january: 'Janvier',
  february: 'Février',
  march: 'Mars',
  april: 'Avril',
  may: 'Mai',
  june: 'Juin',
  july: 'Juillet',
  august: 'Août',
  september: 'Septembre',
  october: 'Octobre',
  november: 'Novembre',
  december: 'Décembre',
  jan: 'Jan',
  feb: 'Fév',
  mar: 'Mar',
  apr: 'Avr',
  shortMay: 'Mai',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Août',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Déc',
  sun: 'Dim',
  mon: 'Lun',
  tue: 'Mar',
  wed: 'Mer',
  thu: 'Jeu',
  fri: 'Ven',
  sat: 'Sam',
  first: '1er',
  second: '2ème',
  third: '3ème',
  fourth: '4ème',
  fifth: '5ème',
  every: 'Chaque',
  oddNumberedDays: 'Dates impaires',
  evenNumberedDays: 'Dates paires',
  noParticipants: 'Aucun participant',
  typeYourMessage: 'Tapez votre message',
  cantPostMessageToPastAlarm:
    "Impossible d'envoyer un message à une alarme inactive",
  groupName: 'Le groupe : {{name}}',
  alarm: 'Alarme',
  confirmed: 'Confirmer',
  declined: 'Refusé',
  Done: 'Terminé',
  Skip: 'Passer',
  Confirm: 'Confirmer',
  Decline: 'Refuser',
  Accept: 'Accepter',
  today: "aujourd'hui",
  on: 'sur',
  recipientAlarm: 'Alarme ami',
  recipient: 'Destinataire',
  remindMeAfter: 'Rappelez-moi',
  remindMeIn: 'Rappelez-moi en',
  newRecipientAlarm: 'Nouvelle alarme pour les amis',
  editRecipientAlarm: "Modifier l'alarme de l'ami",
  newInstantAlarm: 'Nouvelle alarme instantanée',
  instantAlarms: 'Alarmes instantanées',
  alarmDone: 'Alarme marquée Terminé',
  alarmSkipped: 'Alarme passée',
  undo: 'Annuler',
  enterVerificationCode: 'Veuillez saisir le code à 4 chiffres ci-dessous :',
  initializing: 'Initialisation',
  tryAgain: 'Réessayer',
  nMore: '+ {{count}} plus',
  enableNotification: 'Activer',
  enableAlarmNotificationChannel: 'Activer',
  enableBackgroundAppRefresh: 'Activer',
  viewImpactedAlarms: 'Alarmes',
  viewImpactedGroups: 'Les groupes',
  dismiss: 'Ignorer',
  minsInterval: '+ {{count}}m',
  hoursInterval: '+ {{count}}h',
  daysInterval: '+ {{count}}d',
  snoozeUnitHours: 'Heures',
  snoozeUnitMinutes: 'Procès-verbal',
  snoozeUnitDays: 'Jours',
  selectMinutes: 'Sélectionner les minutes',
  selectHours: 'Sélectionner les heures',
  selectDays: 'Sélectionner les jours',
  selectHoursAndMinutes: 'Sélectionner les heures et les minutes',
  enterDuration: 'Durée',
  snoozeUnit: 'Unité',
  specifySnoozeUnit: "Spécifier l'unité Rétarder",
  specifySnoozeDuration: 'Spécifier la durée de la fonction "Rétarder".',
  duration: 'Durée',
  quickAlarmName: {
    zero: '{{minutes}} rappel min',
    one: "{{hours}} rappel de l'heure",
    other: '{{hours}} heure {{minutes}} rappel min'
  },
  alarmTitleForGoogleVoiceTimerCommand: '{{durationString}} rappel',
  addedAlarmFromVoiceCommand:
    "Ajout d'une alarme pour {{alarmName}} à l'adresse suivante {{dateString}}",
  addedTimerFromVoiceCommand: "Ajout d'une minuterie pour {{duration}}",
  clear: 'Clair',
  ringtoneDuration: 'Durée de la sonnerie',
  chooseDuration: 'Effleurez une durée. Vous pouvez appuyer plusieurs fois.',
  newAlarm: 'Nouvelle alarme',
  quickAlarm: 'Petit rappel',
  quickAlarmWillRingAt: "L'alarme sonnera à l'adresse {{timeString}}.",
  random: 'Au hasard',
  enableAllAlarms: 'Activer toutes les alarmes',
  disableAllAlarms: 'Désactiver toutes les alarmes',
  deleteAllAlarms: 'Supprimer toutes les alarmes',
  hideCategory: 'Masquer la catégorie',
  unhideCategory: 'Désactiver la catégorie',
  dontShowHiddenCategories: 'Ne pas afficher les catégories cachées',
  showHiddenCategories: 'Afficher les catégories cachées',
  welcomeAlarmsTitle: "C'est parti !",
  welcomeAlarmsMessage: 'Voici quelques alarmes couramment utilisées :',
  onboardingAlarms: "Alarmes d'accueil",
  hiddenAlarmCategories: 'Catégories cachées',
  restorePurchases: 'Restaurer les achats',
  noAvailablePurchasesFound:
    "Aucun achat disponible n'a été trouvé pour votre compte.\n\nVeuillez nous envoyer un retour d'information en utilisant \"Réglages > Retour d'information\" si vous avez un achat valide que vous ne pouvez pas restaurer.",
  restoringPurchases: 'Rétablissement des achats',
  fetchingContacts: 'Vérifier quels contacts utilisent Galarm',
  enterNameAndUploadPhotoForUser:
    'Saisissez votre nom ci-dessous et téléchargez éventuellement votre photo de profil',
  enterNameAndUploadPhotoForGroup:
    'Entrez un nom ci-dessous et téléchargez éventuellement une photo de groupe.',
  noContactsWithAppInstalled: "Personne dans vos contacts n'utilise Galarm.",
  galarmContacts: {
    one: '1 contact Galarm',
    other: '{{count}} Contacts Galarm'
  },
  contactsPendingInvitation: {
    one: '1 invitation en cours',
    other: '{{count}} invitations en cours'
  },
  otherContacts: { one: '1 autre contact', other: '{{count}} autres contacts' },
  over100Contacts: 'Plus de 100 autres contacts',
  noAlarms:
    "Vous n'avez pas d'alarme. Touchez + pour ajouter une nouvelle alarme.",
  noContactsWeb: {
    one: "Nous n'avons trouvé aucun contact utilisant Galarm. Soit nous n'avons pas l'autorisation d'accéder à vos contacts, soit personne dans vos contacts n'utilise Galarm. Vous pouvez synchroniser vos contacts depuis l'application mobile.\n\nVous pouvez ajouter des contacts utilisant Galarm à vos alarmes de différentes manières pour bénéficier de l'appli.",
    other:
      "Veuillez vous enregistrer sur l'application mobile pour commencer à ajouter des contacts à vos alarmes."
  },
  viewingAlarmsInCommon: 'Alarmes en commun avec {{name}}',
  viewingGroupsInCommon: 'Groupes en commun avec {{contactName}}',
  changeGroupMemberConfigTextForCascadingAlarm:
    "Les participants actifs seront alertés dans l'ordre affiché.",
  changeGroupMemberConfigTextForSimultaneousAlarm:
    'Seuls les participants actifs seront alertés.',
  changeGroupMemberStateText: {
    zero: 'Modifier les membres qui peuvent vous alerter lorsqu\'une alarme est créée pour le groupe "{{groupName}}".\n\nVous ne serez alerté que si l\'alarme est créée par des membres autorisés.',
    one: 'Modifier les membres qui peuvent alerter {{memberName}} lorsqu\'une alarme est créée pour le groupe "{{groupName}}".\n\n{{memberName}} ne sera alerté que si l\'alarme est créée par des membres autorisés.'
  },
  allowed: 'Autorisé',
  notAllowed: 'Non autorisé',
  changeStatus: 'Membres autorisés',
  noUpcomingAlarms: "Pas d'alarme à venir",
  showMore: 'Afficher plus',
  nMorePastAlarms: {
    one: '1 alarme inactive supplémentaire',
    other: "{{count}} plus d'alarmes inactives"
  },
  shareAppMessage:
    "Je fais en sorte que toutes mes choses soient terminées à temps en leur créant des rappels à l'aide de l'appli Galarm. Grâce à cette appli, nous pouvons également nous inclure les uns les autres dans nos rappels. Je recommande de télécharger cette appli innovante d'alarme et de rappel. {{downloadLink}}",
  addingMembers: 'Ajout de membres.',
  unableToAddMembers: "Impossible d'ajouter des membres. {{error}}",
  removingMember: "Suppression d'un membre.",
  unableToRemoveMember: 'Impossible de supprimer le membre. {{error}}',
  leavingGroup: 'Groupe de départ.',
  unableToLeaveGroup: 'Impossible de quitter le groupe. {{error}}',
  expiredPersonalAlarmForCreator: {
    zero: 'Cette alarme a expiré il y a {{durationString}}. Appuyez sur "Terminé" pour terminer l\'alarme.',
    one: 'Cette alarme a expiré il y a {{durationString}}. Appuyez sur "Terminé" pour terminer l\'alarme et en informer les participants.',
    other:
      'Cette alarme a expiré il y a {{durationString}}. Appuyez sur "Terminé" pour terminer l\'alarme et en informer les participants.'
  },
  donePersonalAlarmForCreator:
    "Cette alarme a expiré il y a {{durationString}}. L'alarme est marquée comme Terminé.",
  skippedPersonalAlarmForCreator:
    "Cette alarme a expiré il y a {{durationString}}. Vous avez passé l'alarme.",
  expiredGroupAlarm:
    'Cette alarme a expiré il y a {{durationString}}. Confirmez votre participation.',
  expiredPersonalAlarmForParticipant:
    'Cette alarme a expiré il y a {{durationString}}. Veuillez la rappeler à {{creatorName}}.',
  alarmDurationAndroid: "L'alarme est réglée sur {{durationString}}.",
  alarmDurationIos: "L'alarme est réglée sur {{durationString}}.",
  alarmFeedbackHeader:
    "Vous enverrez une partie des informations relatives à cette alarme à des fins de débogage. Les données personnelles telles que le nom de l'alarme, les notes ou les participants ne seront pas partagées.",
  privacyPolicyHeader:
    "Le respect de votre vie privée fait constamment tic-tac dans nos horloges. Tout comme vous, nous attachons de l'importance à la vie privée, c'est pourquoi nous tenons à ce que vous sachiez que nous ne partageons pas vos informations personnelles avec qui que ce soit, sauf si cela est nécessaire pour l'installation de l'application ou si la loi l'exige. Dans tous les cas, nous ne vendons jamais vos informations. C'est aussi simple que cela !\n \nLes alarmes que vous créez ne sont visibles que sur votre appareil et, si une alarme inclut d'autres participants, sur les appareils de ces derniers.\n \nNous accédons au carnet d'adresses/à la liste de contacts de votre téléphone uniquement pour remplir l'onglet des contacts dans Galarm. Nous ne stockons pas cette liste à d'autres fins. Nous respectons le fait qu'il s'agit de votre carnet d'adresses !\n \nNous accédons à votre appareil photo et à vos photos uniquement pour vous permettre de régler une bonne photo de profil. Cette photo apparaît comme votre avatar ou comme photo de profil d'un groupe que vous éditez. Les autres utilisateurs de Galarm qui sont membres des alarmes que vous avez réglées pourront voir cette photo.\n\nNous envoyons l'adresse IP publique de votre appareil et son identifiant unique avec toutes les demandes, par exemple lors de la demande et de la vérification du code au cours de la procédure d'enregistrement. Ces informations ne sont pas liées à votre compte et ne sont utilisées que pour déterminer toute activité malveillante potentielle.\n \nVeuillez consulter la politique de confidentialité complète pour plus de détails sur les informations que nous collectons et la manière dont nous les utilisons.",
  termsOfServiceHeader:
    "En utilisant l'application Galarm, vous acceptez nos conditions de service, qui régissent votre accès et votre utilisation de cette application mobile ainsi que les fonctions et services qu'elle offre. \n\nLa devise de Galarm est simple : vous aider à ne jamais manquer un événement !  Qu'il s'agisse de configurer des alarmes personnelles pour vous réveiller, être à l'heure à vos rendez-vous et fixer des rappels pour des activités telles que la prise de médicaments en temps voulu, ou de créer des alarmes de groupe pour organiser des activités de groupe telles que des sorties au cinéma, des événements sportifs et des fêtes, Galarm vous permet d'avoir un réveil social pour vous rappeler les uns aux autres, suivre l'état de chacun et communiquer.\n\nN'utilisez Galarm que si vous acceptez ces conditions. Vous trouverez ci-dessous un lien vers ces conditions.",
  acknowledgedPersonalAlarm:
    'Vous avez marqué l\'alarme "Terminé" il y a {{durationString}}.',
  skippedPersonalAlarm: "Vous avez passé l'alarme il y a {{durationString}}.",
  authenticatingWithServer: 'Connexion...',
  selectParticipantsForPersonalAlarm:
    'Sélectionnez un groupe ou un ensemble de personnes qui serviront de sauvegarde pour votre alarme personnelle.',
  selectParticipantsForGroupAlarm:
    'Sélectionnez un groupe ou un ensemble de personnes pour votre activité de groupe.',
  acknowledgedGroupAlarm:
    'Vous avez {{acknowledgedAsString}} cette alarme il y a {{durationString}}.',
  acknowledgedExpiredGroupAlarm:
    'Cette alarme a expiré il y a {{durationString}}. Vous avez {{acknowledgedAsString}} cette alarme.',
  acknowledgedPersonalAlarmForParticipant:
    '{{creatorName}} a marqué l\'alarme "Terminé" il y a {{durationString}}.',
  acknowledgedExpiredPersonalAlarmForParticipant:
    'Cette alarme a expiré depuis {{durationString}}. {{creatorName}} a marqué l\'alarme "Terminé".',
  entityNotFound:
    "Oups ! Nous n'avons pas pu trouver le site {{entityType}} sélectionné. Il se peut que nous soyons encore en train de le chercher ou qu'il ait été supprimé.",
  goBack: 'Retour',
  uploadingImageToServer: "Téléchargement de l'image sur le serveur.",
  deletingImageFromServer: "Suppression d'une image du serveur",
  errorUploadingImageToServer:
    "Erreur de téléchargement de l'image sur le serveur. Veuillez réessayer plus tard.",
  unableToRemoveGroup: 'Impossible de supprimer le groupe. {{error}}',
  addingGroup: "Ajout d'un groupe.",
  editingGroup: 'Groupe de rédaction.',
  unableToAddGroup: "Impossible d'ajouter un groupe. {{error}}",
  unableToEditGroup: 'Impossible de modifier le groupe. {{error}}',
  thanksForFeedback: 'Merci de nous aider à nous améliorer.',
  licensesHeader:
    'Galarm comprend des logiciels sous licence de tiers. Veuillez consulter notre page sur les licences de tiers pour obtenir des informations sur les attributions et les licences correspondantes.',
  alarmAlreadyInSnooze:
    "Un rappel vous sera adressé à l'adresse {{durationString}}.",
  carrierChargesMayApply: 'Des frais de transport peuvent être appliqués.',
  selectCascadingInterval:
    "Le premier participant sera alerté {{firstParticipantDurationString}} après l'heure de l'alarme, le deuxième participant sera alerté {{secondParticipantDurationString}} après l'heure de l'alarme et ainsi de suite si vous ne marquez pas l'alarme comme étant terminée.",
  reorderParticipantsScreenDescription:
    "Les participants seront alertés dans l'ordre affiché.",
  noMembers: 'Aucun membre',
  selectedGroupDescription: {
    zero: '{{numActiveMembers}} participants',
    one: '{{numActiveMembers}} participant actif et {{numInactiveMembers}} participant inactif',
    other:
      '{{numActiveMembers}} les participants actifs et {{numInactiveMembers}} les participants inactifs'
  },
  groupAlarmWillRingAtSameTime:
    "Tous les participants seront alertés à l'adresse {{timeString}}.",
  welcomeToGalarm: 'Bienvenue à Galarm',
  checkingConnection: 'Vérification de la connexion',
  deletePastAlarmsTitle: 'Supprimer les alarmes inactives plus anciennes que',
  deletePastAlarmsOption: {
    zero: '{{option}} (0 alarmes)',
    one: '{{option}} (1 alarme)',
    other: '{{option}} ({{count}} alarmes)'
  },
  chooseSnoozeInterval: 'Rappelez-moi en',
  personalAlarmDescription:
    "Utilisez une alarme personnelle pour vous réveiller, prendre vos médicaments ou effectuer toute autre tâche pour vous-même. Vous pouvez éventuellement ajouter des participants qui seront alertés si vous manquez une alarme, afin qu'ils puissent vous la rappeler. Les participants sont également avertis lorsque vous marquez l'alarme comme étant Terminée.",
  groupAlarmDescription:
    "Utilisez une alarme de groupe pour les activités de groupe telles que les sorties, les fêtes ou tout autre événement. L'alarme se déclenche à la même heure pour tous les participants, qui peuvent ainsi confirmer leur participation.",
  recipientAlarmDescription:
    "Créez des alarmes pour quelqu'un d'autre (\"ami\") afin de lui rappeler ce qu'il doit faire. Vous êtes alerté pour rappeler l'alarme à l'ami au cas où il la manquerait. Vous serez également averti lorsque l'ami marquera l'alarme comme étant Terminée.\n  \nNotez que les alarmes sont créées dans votre fuseau horaire.",
  whatsThis: "Qu'est-ce que c'est ?",
  alarmCurrentlyDisabled: 'Cette alarme est actuellement désactivée',
  alarmCurrentlyRejected: 'Cette alarme est actuellement Rejetée',
  currentlyTypingUsers: {
    one: '{{currentlyTypingUsers}} tape...',
    other: '{{currentlyTypingUsers}} tapent...'
  },
  noSearchResultsFound: 'Pas de {{type}} trouvé',
  noGroupsCreated: "Vous n'avez créé aucun groupe.",
  noGroupsWeb: {
    one: "Vous n'avez créé aucun groupe.",
    other:
      "Veuillez vous inscrire sur l'application mobile pour commencer à créer des groupes et à les ajouter aux alarmes."
  },
  internalErrorDuringRegistration:
    "Une erreur interne s'est produite lors de l'enregistrement de l'utilisateur. Veuillez réessayer après quelques minutes",
  selectRecipientAlarmInterval: {
    zero: 'Vous serez alerté en même temps que le destinataire',
    one: "Vous serez alerté {{durationString}} après l'heure de l'alarme si le destinataire ne marque pas l'alarme \"Terminé\".",
    other:
      "Vous serez alerté {{durationString}} après l'heure de l'alarme si le destinataire ne marque pas l'alarme \"Terminé\"."
  },
  selectRecipient: 'Sélectionner le destinataire',
  acknowledgedExpiredRecipientAlarm:
    'Cette alarme a expiré il y a {{durationString}}. Vous avez marqué cette alarme "Terminé".',
  skippedExpiredRecipientAlarm:
    'Cette alarme a expiré il y a {{durationString}}. Vous avez passé cette alarme.',
  expiredRecipientAlarm:
    'Cette alarme a expiré il y a {{durationString}}. Tapez sur "Terminé" pour terminer l\'alarme et avertir {{creatorName}}.',
  acknowledgedRecipientAlarm:
    'Vous avez marqué cette alarme "Terminé" il y a {{durationString}}.',
  skippedRecipientAlarm:
    'Vous avez passé cette alarme il y a {{durationString}}.',
  acknowledgedExpiredRecipientAlarmForCreator:
    'Cette alarme a expiré depuis {{durationString}}. {{recipientName}} a marqué l\'alarme "Terminé".',
  skippedExpiredRecipientAlarmForCreator:
    "Cette alarme a expiré il y a {{durationString}}. {{recipientName}} a passé l'alarme.",
  expiredRecipientAlarmForCreator:
    'Cette alarme a expiré il y a {{durationString}}. Veuillez la rappeler à {{recipientName}}.',
  acknowledgedRecipientAlarmForCreator:
    '{{recipientName}} a marqué l\'alarme "Terminé" il y a {{durationString}}.',
  selectRecipientForRecipientAlarm:
    "Sélectionner un destinataire pour l'alarme",
  selectAtLeastOneParticipantForGroupAlarm:
    "Ajouter au moins un participant à l'alarme",
  addAtLeastOneMemberToGroup: 'Ajouter au moins un membre au groupe',
  atTheSameTime: 'En même temps',
  myself: 'Moi-même',
  group: 'Groupe',
  someoneElse: "Quelqu'un d'autre",
  participantsAreNotified: 'Les participants sont informés.',
  creatorIsNotified: '{{creatorName}} est notifié.',
  membersAddedToGroup: {
    one: '{{memberNames}} est ajouté au groupe {{groupName}}.',
    other: '{{memberNames}} sont ajoutés au groupe {{groupName}}.'
  },
  memberRemovedFromGroup:
    '{{memberName}} est supprimée du groupe {{groupName}}, ce qui a pu avoir un impact sur certaines de vos alarmes.',
  youRemovedFromGroup:
    'Vous êtes retiré du groupe {{groupName}}, ce qui a pu avoir un impact sur certaines de vos alarmes.',
  memberLeftGroup:
    '{{memberName}} a laissé le groupe {{groupName}}, ce qui a pu avoir un impact sur certaines de vos alarmes.',
  youLeftGroup:
    'Vous avez quitté le groupe {{groupName}}, ce qui a pu avoir un impact sur certaines de vos alarmes.',
  groupLeft: 'Vous avez quitté le groupe {{groupName}}.',
  memberMovedToADifferentTimezone:
    '{{memberName}} a changé de fuseau horaire, ce qui peut avoir eu un impact sur certaines de vos alarmes.',
  memberDstChanged: {
    zero: "{{memberName}} a cessé de sauvegarder l'heure d'été. Les alarmes correspondantes ont été mises à jour.",
    one: "{{memberName}} a commencé à sauvegarder l'heure d'été. Les alarmes correspondantes ont été mises à jour."
  },
  impactedAlarms: 'Alarmes touchées :',
  alwaysRingParticipantAlarmsOn:
    "Les alarmes d'autres personnes sonneront à moins que vous ne les refusiez.",
  alwaysRingParticipantAlarmsOff:
    "Les alarmes provenant d'autres personnes ne sonneront pas à moins que vous ne les acceptiez.",
  agreeToTermsOfServiceAndPrivacyPolicy:
    'En continuant, vous acceptez les conditions de service et la politique de confidentialité de Galarm.',
  deleteAccountWarningHeader:
    'Avertissement : Cette action est irréversible. La suppression de votre compte entraînera :',
  deleteAccountDeleteAlarms:
    '- Supprimer toutes les alarmes que vous avez créées',
  deleteAccountDeleteRecipientAlarms:
    "- Supprimer toutes les alarmes d'amis dont vous êtes le destinataire",
  deleteAccountRemoveFromGroups: '- Vous retirer de tous les groupes',
  deleteAccountWarningFooter: {
    zero: "Si vous changez de téléphone, il vous suffit d'installer l'application sur votre nouveau téléphone et de vous enregistrer en utilisant le même numéro. Vous pouvez supprimer l'application sur ce téléphone.\n\nUne fois votre compte supprimé, vous ne pourrez plus le restaurer.",
    one: "Si vous changez de téléphone, il vous suffit d'installer l'application sur votre nouveau téléphone et de vous enregistrer en utilisant le même numéro. Vous pouvez supprimer l'application sur ce téléphone.\n\nUne fois votre compte supprimé, vous ne pourrez plus le restaurer. Vous pouvez gérer votre abonnement actif à Galarm sur le Play Store.",
    other:
      "Si vous changez de téléphone, vous pouvez simplement installer l'application sur votre nouveau téléphone et supprimer l'application sur ce téléphone.\n\nUne fois votre compte supprimé, vous ne pourrez plus le restaurer. Vous pouvez gérer votre abonnement actif à Galarm sur l'App Store."
  },
  typeConfirmationStringToDeleteAccount:
    'Tapez {{confirmationString}} pour supprimer votre compte',
  unableToDeleteAccount: 'Impossible de supprimer le compte. {{error}}',
  impactedGroups: 'Groupes concernés :',
  memberDeletedAccount:
    '{{memberName}} a supprimé son compte, ce qui peut avoir eu un impact sur certaines de vos alarmes et certains de vos groupes.',
  confirmCountryCodeAndEnterNumberForExistingUser:
    "Confirmez l'indicatif de votre pays et entrez le numéro de téléphone que vous avez utilisé lors de l'enregistrement.",
  requiredPermissionsDisabledWarningIos:
    'Les notifications sont désactivées. Les alarmes ne sonnent pas. Tapez sur pour corriger la situation.',
  requiredPermissionsDisabledWarningAndroid:
    'Galarm ne dispose pas des autorisations nécessaires pour sonner les alarmes. Tapez pour corriger.',
  notificationChannelPopupDisabledWarningAndroid:
    "Les notifications d'alarme ne s'affichent pas à l'écran. Tapez sur pour résoudre le problème.",
  alarmLockScreenNotificationDisabledWarningAndroid:
    "Les notifications d'alarme ne s'affichent pas sur l'écran de verrouillage. Tapez sur pour corriger la situation.",
  why: 'Pourquoi',
  how: 'Comment',
  registrationRequired: 'Inscription obligatoire',
  phoneNumberRequired: 'Numéro de téléphone requis',
  fewSeconds: 'quelques secondes',
  lessThanMinute: "moins d'une minute",
  updateUnsupportedVersion:
    "Une version actualisée de l'application est nécessaire pour continuer à utiliser Galarm.",
  update: 'Mise à jour',
  back: 'Retour',
  unableToMarkAlarmDone:
    "Impossible de marquer l'alarme comme Terminé pour le moment. Veuillez réessayer dans quelques instants.",
  unableToMarkAlarmUndone:
    "Impossible de marquer l'alarme comme annulée pour le moment. Veuillez réessayer dans quelques instants.",
  unableToMarkAlarmUnskipped:
    "Impossible pour l'instant de marquer l'alarme comme étant non zappée. Veuillez réessayer dans quelques instants.",
  unableToSkipAlarm:
    "Impossible de passer l'alarme pour le moment. Veuillez réessayer dans quelques instants.",
  unableToSetGroupAlarmResponse:
    "Impossible d'envoyer l'alarme du groupe à {{response}} pour l'instant. Veuillez réessayer dans quelques instants.",
  unableToDeleteAlarm:
    "Impossible de supprimer l'alarme pour le moment. Veuillez réessayer dans quelques instants.",
  unableToSetAlarmResponse:
    "Impossible de régler votre réponse pour l'instant. Veuillez réessayer dans quelques instants.",
  unableToSnoozeAlarm: "Impossible de Rétarder l'alarme.",
  unableToStopSnoozeForAlarm: "Impossible d'arrêter de Rétarder l'alarme.",
  unableToUpdateParticipantAlarmRingerSettings:
    'Impossible de mettre à jour les réglages de la sonnerie pour le moment. Veuillez réessayer dans quelques instants.',
  updateParticipantPreReminderDuration:
    'Impossible de mettre à jour la durée du pré-rappel pour le moment. Veuillez réessayer dans quelques instants.',
  unableToUpdateAlarmRingerSettings:
    'Impossible de mettre à jour les réglages de la sonnerie pour le moment. Veuillez réessayer dans quelques instants.',
  unableToUpdateAlarmPreReminderDuration:
    'Impossible de mettre à jour la durée du pré-rappel pour le moment. Veuillez réessayer dans quelques instants.',
  retrievingAlarmsFromServer: 'Récupération des alarmes sur le serveur...',
  startApp: 'Démarrer >',
  phoneNumberRequiredGroupListHeaderText:
    "Le groupe est une collection de contacts Galarm vous permettant de créer rapidement des alarmes avec eux.\n\nVeuillez vous enregistrer à l'aide de votre numéro de téléphone pour trouver vos contacts qui utilisent Galarm. Vous pouvez les ajouter à des groupes.",
  phoneNumberRequiredBuddyAlarmHeaderText:
    "L'alarme ami peut être utilisée pour régler des alarmes pour les amis et la famille afin de leur rappeler les choses qu'ils doivent faire.\n\nPour utiliser cette fonction ainsi que d'autres fonctions intéressantes de l'application, veuillez vous enregistrer en utilisant votre numéro de téléphone.",
  phoneNumberRequiredContactListHeaderText:
    "Les contacts sont des personnes qui peuvent être ajoutées en tant que participants à des alarmes.\n\nVeuillez vous enregistrer à l'aide de votre numéro de téléphone pour voir vos contacts qui utilisent Galarm. Vous pouvez les ajouter comme participants à vos alarmes.",
  phoneNumberRequiredGroupAlarmHeaderText:
    "L'alarme de groupe permet de régler un rappel pour un groupe de personnes.\n\nPour utiliser cette fonction ainsi que d'autres fonctions intéressantes de l'application, veuillez vous enregistrer en utilisant votre numéro de téléphone.",
  phoneNumberRequiredParticipantsHeaderText:
    "Les participants servent de sauvegarde à cette alarme. Ils sont rappelés si vous manquez l'alarme.\n\nPour utiliser cette fonction et bien d'autres fonctions intéressantes de l'application, veuillez vous enregistrer à l'aide de votre numéro de téléphone.",
  youHaveNotRegistered:
    'Vos alarmes ne sont actuellement pas sauvegardées.\n\nVeuillez vous enregistrer en utilisant votre numéro de téléphone. Une fois que vous vous êtes enregistré, vos alarmes sont sauvegardées sur le cloud. Cela vous permet de restaurer vos alarmes et autres réglages lorsque vous changez de téléphone.\n',
  registerNow: "S'inscrire",
  hoursAndMinutes: 'Heures et minutes',
  days: 'Jours',
  weeks: 'Semaines',
  months: 'Mois',
  years: 'Années',
  daysOfWeek: 'Hebdomadaire (certains jours)',
  monthly: 'Mensuel',
  yearly: 'Annuel',
  everyDay: 'Quotidiennement',
  selectDaysOfWeek: 'Sélectionner les jours de la semaine',
  alarmRingMessageForSpecificDates:
    'Cette alarme ne sonnera que pour les mois qui comptent au moins {{dayOfMonth}} jours. Voulez-vous continuer ?',
  alarmRingMessageForSpecificYear:
    'Cette alarme ne sonnera que lors des années bissextiles. Voulez-vous continuer ?',
  alarmRingMessageForSpecificDayOfWeekInMonth:
    'Cette alarme ne sonnera que pour les mois qui ont {{weekNumberOfDayInMonth}} {{dayName}} s. Voulez-vous continuer ? ',
  alarmRepetitionAdjustedBasedOnNewDate:
    'La répétition des alarmes a été mise à jour en fonction de la nouvelle date.',
  inviteFriendsToInstallGalarm:
    "Les contacts sont des personnes qui peuvent être ajoutées comme participants à vos alarmes. Vous pouvez inviter des contacts :\n\n1. Depuis votre carnet d'adresses\n\n2. En indiquant manuellement leur nom et leur numéro.\n\nTouchez Actualiser en haut à gauche pour recharger les contacts Galarm après leur enregistrement.",
  whyCreateNewGroup:
    "Les groupes sont des ensembles de contacts qui peuvent être ajoutés en tant que participants à vos alarmes.\n  \nVous n'avez pas créé de groupes.",
  noNotifications: "Vous n'avez pas de nouvelles notifications.",
  createGroup: 'Créer un groupe',
  galarmRequiresAccessToContactsPermission:
    "Galarm demande l'autorisation d'accéder à vos contacts. Ces informations sont envoyées à ses serveurs pour identifier vos contacts utilisant Galarm. Ces utilisateurs peuvent être ajoutés en tant que participants à vos alarmes.",
  galarmRequiresAccessToAudioFilesPermission:
    "Certaines alarmes utilisent la musique du téléphone comme sonnerie. Veuillez autoriser l'accès aux fichiers audio sur votre appareil pour faire sonner ces alarmes avec la sonnerie choisie.",
  galarmRequiresAccessToContactsSelectScreen:
    "Galarm n'a pas l'autorisation d'accéder aux contacts. Veuillez autoriser l'accès aux contacts si vous souhaitez utiliser cette fonctionnalité.",
  allow: 'Autoriser',
  deny: 'Refuser',
  galarmRequiresAccessToContacts:
    'Les contacts sont des personnes qui peuvent être ajoutées comme participants à vos alarmes. Vous pouvez ajouter des contacts par :\n\n1. Importation automatique à partir des contacts du téléphone. Appuyez sur "Autoriser l\'accès" et activez "Contacts".\n\n2. En ajoutant manuellement leur nom et leur numéro. Appuyez sur "Ajouter un contact" pour commencer.',
  galarmRequiresAccessToAlarmAndRemindersPermission:
    "Galarm a besoin de l'autorisation \"Alarmes et rappels\" pour pouvoir sonner des alarmes à des heures précises. Veuillez l'activer dans l'écran suivant.",
  accessToContactsPermissionRequiredAndroid:
    'Galarm n\'a pas l\'autorisation d\'accéder à vos contacts téléphoniques.\n\nLorsque vous autorisez l\'accès, les personnes de vos contacts téléphoniques qui utilisent Galarm apparaissent comme des contacts Galarm. Ces personnes peuvent être ajoutées en tant que participants à l\'alarme. Appuyez sur "Autoriser l\'accès", sélectionnez "Permission" et activez "Contacts".\n\nVous pouvez toujours ajouter manuellement des contacts à Galarm.',
  accessToContactsPermissionRequiredIos:
    "Galarm n'a pas l'autorisation d'accéder à vos contacts téléphoniques.\n\nLorsque vous autorisez l'accès, les personnes de vos contacts téléphoniques qui utilisent Galarm apparaissent comme des contacts Galarm. Ces personnes peuvent être ajoutées en tant que participants à l'alarme. Tapez sur \"Autoriser l'accès\" et activez \"Contacts\".\n\nVous pouvez toujours ajouter manuellement des contacts à Galarm.",
  giveAccess: "Autoriser l'accès",
  enterValidMobileNumber:
    'Veuillez saisir un numéro de téléphone valide pour vous inscrire.',
  proceedAnyway: 'Procéder quand même',
  checkInvalidPhoneNumber:
    'Nous pensons que vous avez saisi un numéro non valide comme {{mobileNumber}}.',
  checkInvalidCountryCode:
    'Code de pays non valide spécifié sous {{countryCode}}. Si plusieurs codes de pays sont présents, modifiez le champ pour conserver le code de pays applicable.',
  editNumber: 'Modifier le numéro',
  editCountryCode: 'Modifier le code pays',
  sendingCodeOnCall: 'Je vous appelle avec le code.',
  sendCodeOnCall: 'Appelez-moi avec le code',
  registrationLandingScreenRegisterText:
    "Appuyez sur Continuer pour vous enregistrer à l'aide de votre numéro de téléphone.\n\nEn vous inscrivant :\n1) Vous pourrez ajouter des participants à vos alarmes et discuter avec eux.\n2) Vos alarmes seront sauvegardées sur le cloud de sorte que lorsque vous changerez de téléphone, vos alarmes apparaîtront sur le nouveau téléphone.",
  continue: 'Continuer',
  registerLater: "Passer l'enregistrement",
  cancelRegistration: "Annuler l'inscription",
  welcome: 'Bienvenue',
  haventReceivedCodeYet: {
    one: "Vous n'avez pas encore reçu le code ? Vous pouvez redemander le code dans 1 seconde.",
    other:
      "Vous n'avez pas encore reçu le code ? Vous pouvez redemander le code dans {{count}} secondes."
  },
  storagePermissionDenied:
    "Impossible d'accéder aux fichiers musicaux car l'autorisation de stockage est refusée.",
  havingTroubleRegisteringMessage:
    "Galarm n'arrive pas à vérifier le code après plusieurs tentatives.\n\nVoulez-vous sauter l'étape de l'enregistrement et commencer à utiliser l'application ?",
  autoDetectVerificationCode:
    "Pour simplifier l'enregistrement, Galarm peut détecter automatiquement le code de vérification si vous autorisez Galarm à consulter les messages SMS.",
  notNow: 'Pas maintenant',
  verifyNumberText:
    'Nous vous enverrons un SMS avec le code de vérification à :\n\n{{formattedMobileNumber}}',
  addToContacts: 'Ajouter aux contacts',
  todayString: "Aujourd'hui",
  tomorrowString: 'Demain',
  backups: 'Sauvegardes',
  acintyoTrademark:
    "2024 Acintyo, Inc. Tous droits réservés.\nGalarm est une marque déposée d'Acintyo, Inc.\nProtégé par les brevets américains 10382613, 10867286.\nAutres brevets en cours.",
  acintyoTrademarkWeb:
    "2024 Acintyo, Inc. Tous droits réservés. Galarm est une marque déposée d'Acintyo, Inc.\nProtégé par les brevets américains 10382613, 10867286. Autres brevets en cours.",
  acintyoTrademarkEnterprise: '2024 Acintyo, Inc.\nTous droits réservés.',
  markAlarmDone: "Marquer l'alarme comme Terminé",
  skipAlarm: "Passer l'alarme",
  confirmGroupAlarm: 'Confirmer la participation',
  declineGroupAlarm: 'Refuser la participation',
  snoozeAlarm: 'Alarme Rétarder',
  deleteAlarmQuickAction: "Supprimer l'alarme",
  disableAlarm: "Désactiver l'alarme",
  confirmDisableAlarm: "Désactiver l'alarme ?",
  saveToCalendar: 'Exporter vers le calendrier',
  stillRingGroupAlarmAfterConfirmingInAdvance:
    "Vous confirmez à l'avance l'alarme du groupe.  Souhaitez-vous toujours le rappel à l'heure de l'alarme ?",
  updateNow: 'Mise à jour',
  nParticipants: {
    one: '1 autre participant',
    other: '{{count}} autres participants'
  },
  details: 'Détails',
  changeParanthesis: '(changement)',
  loadMoreHistory: 'Voir plus',
  alarmSummaryForHistory:
    'Réponses antérieures pour {{alarmName}}.\nRépéter {{alarmRepetitionString}}\n{{previousOccurrencesString}}',
  alarmSummaryForHistoryWeb: 'Réponses antérieures pour {{alarmName}}',
  alarmSummaryForHistoryRepetitionWeb: 'Répéter {{alarmRepetitionString}}',
  dontSeeCompleteHistory: "L'histoire n'est pas complète ?",
  dontSeeCompleteHistoryExplanation:
    "L'historique des alarmes est enregistré à partir de l'heure de création de l'alarme. Nous ne conservons qu'un historique limité pour chaque alarme, en fonction de la répétition de l'alarme. Par exemple, si l'alarme se répète tous les jours, nous conservons un historique de 30 jours.\n\nLa modification de l'heure de l'alarme ou de la répétition réinitialise l'historique de l'alarme.",
  noActionAvailableForAlarmOccurrence:
    "Aucune action n'est disponible pour cette occurrence.",
  enterEmailIdForFeedback: 'Utilisé pour répondre et partager des mises à jour',
  noPreviousOccurrences: "Pas encore d'historique d'alarme",
  nMissedOccurrencesOfAlarmInLastNDays: {
    zero: "Pas encore d'historique d'alarme",
    one: '{{missedAlarmOccurrences}} a manqué la dernière {{count}}',
    other: '{{missedAlarmOccurrences}} a manqué la dernière {{count}}'
  },
  soundAndVibrationSummaryString: {
    zero: '{{volume}}% volume, {{ringtoneName}}',
    one: "{{volume}}% volume, {{ringtoneName}}, Titre de l'annonce"
  },
  soundAndVibrationSummaryStringIos: {
    zero: '{{ringtoneName}}',
    one: 'Critique, {{volume}}% volume, {{ringtoneName}}'
  },
  previousOccurrences: 'Historique des alarmes',
  retrieving: 'Récupérer...',
  startTimeOfDay: 'Commence à',
  endTimeOfDay: 'Fin à',
  ringEveryHours: 'Sonne tous les',
  everyNHours: 'Horaire',
  everyMHoursNMinutes: 'Heures et minutes',
  selectHourlyRepetition: 'Sélectionner la répétition horaire',
  everyNHoursRepetitionString: {
    one: 'Toutes les heures de {{startTimeString}} à {{endTimeString}} tous les {{daysString}}',
    other:
      'Toutes les {{count}} heures de {{startTimeString}} à {{endTimeString}} toutes les {{daysString}}'
  },
  everyNHoursAndMinutesRepetitionString:
    'Tous les {{hoursAndMinutes}} de {{startTimeString}} à {{endTimeString}} tous les {{daysString}}',
  everyMHoursAndNMinutesRepetitionString: 'Chaque {{repeatString}}',
  everyNHoursRepetitionStringForCalendar: {
    one: 'Toutes les heures de {{startTimeString}} à {{endTimeString}}',
    other:
      'Toutes les {{count}} heures, de {{startTimeString}} à {{endTimeString}}'
  },
  everyNHoursAndMinutesRepetitionStringForCalendar:
    'Tous les {{hoursAndMinutes}} de {{startTimeString}} à {{endTimeString}}',
  everyNHoursAndMinutesRepetitionStringForAlarmSummary:
    "Tous les {{hoursAndMinutes}} jusqu'à {{endTimeString}}",
  everyNHoursRepetitionStringForAlarmSummary: {
    one: "Toutes les heures jusqu'à {{endTimeString}}",
    other: "Toutes les {{count}} heures jusqu'à {{endTimeString}}"
  },
  daysRepetitionOddString: {
    other: 'Toutes les dates impaires',
    one: 'Dates impaires ({{daysString}})'
  },
  daysRepetitionEvenString: {
    other: 'Toutes les dates paires',
    one: 'Dates paires ({{daysString}})'
  },
  oddNumberedRepetitionPreviewString: {
    one: "L'alarme sonnera aux dates impaires qui tombent sur {{daysString}} à l'heure spécifiée.",
    other: "L'alarme sonnera à toutes les dates impaires à l'heure spécifiée."
  },
  evenNumberedRepetitionPreviewString: {
    one: "L'alarme sonnera aux dates paires qui tombent sur {{daysString}} à l'heure spécifiée.",
    other: "L'alarme sonnera à toutes les dates paires à l'heure spécifiée."
  },
  hourlyRepetitionPreviewString: {
    one: 'Cette alarme sonnera une fois tous les {{daysString}} à :',
    other: 'Cette alarme sonnera {{count}} fois tous les {{daysString}} à :'
  },
  daysRepetitionPreviewString: {
    one: 'Cette alarme sonnera tous les jours.',
    other: 'Cette alarme sonnera tous les {{count}} jours.'
  },
  weeksRepetitionPreviewString: {
    one: 'Cette alarme sonnera chaque semaine.',
    other: 'Cette alarme sonnera toutes les {{count}} semaines.'
  },
  everyNDays: { one: 'Tous les jours', other: 'Tous les {{count}} jours' },
  everyNWeeks: {
    one: 'Chaque semaine',
    other: 'Toutes les {{count}} semaines'
  },
  everyNMonths: { one: 'Tous les mois', other: 'Tous les {{count}} mois' },
  everyNYears: { one: 'Chaque année', other: 'Tous les {{count}} ans' },
  cantSetEndTime: "Impossible de régler l'heure de fin.",
  endTimeShouldBeGreater:
    "L'heure de fin doit être supérieure à l'heure de début.",
  cantSetStartTime: "Impossible de régler l'heure de démarrage",
  startTimeShouldBeLess:
    "L'heure de début doit être inférieure à l'heure de fin.",
  everyDaySmall: 'jour',
  everyGivenDay: '{{day}}',
  everyGivenDays: '{{days}}',
  adjustingAlarmsAfterTimezoneChange:
    'Nous avons détecté que vous avez changé de fuseau horaire. Nous ajustons vos alarmes.',
  ringOnDays: 'Sonneries',
  yourAlarmsMayNotRing: 'Vos alarmes ne sonneront peut-être pas',
  yourAlarmsMayNotRingTapToFix:
    'Il se peut que vos alarmes ne sonnent pas. Tapez pour réparer.',
  xiaomiSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Activer le démarrage automatique" et autorisez "Galarm" pour que vos alarmes sonnent toujours. \n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-mi',
  oppoSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Activer le démarrage automatique" et activez "Autoriser le démarrage automatique".\n\n - Appuyez sur le bouton "Apps récentes" de votre téléphone, faites glisser l\'icône de l\'application Galarm vers le bas et appuyez sur "Verrouiller".  \n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-oppo',
  oppo8AndLowerSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Activer le démarrage automatique" et activez "Autoriser le démarrage automatique".\n\n - Appuyez sur le bouton "Apps récentes" de votre téléphone, faites glisser l\'icône de l\'application Galarm vers le bas et appuyez sur "Verrouiller".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-oppo-8-and-lower',
  vivo9And10SetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Activer le démarrage automatique" et autorisez "Galarm".\n\n - Appuyez sur "Utilisation de la batterie", sélectionnez "Consommation d\'énergie élevée en arrière-plan" et autorisez "Galarm".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-vivo',
  vivo11SetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n- Tapez sur "Activer le démarrage automatique" et autorisez "Galarm".\n\n- Appuyez sur "Utilisation de la batterie", sélectionnez "Consommation d\'énergie élevée en arrière-plan" et autorisez "Galarm".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-vivo-11',
  vivoSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n- Ouvrez "Phone Settings > Apps > Special app access > Autostart" et activez le démarrage automatique pour Galarm.\n\n- Ouvrez "Réglages du téléphone > Batterie > Gestion de la consommation d\'énergie en arrière-plan > Galarm" et choisissez "Ne pas restreindre la consommation d\'énergie en arrière-plan".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-vivo-12-and-above',
  vivo8AndLowerSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Tapez sur "Activer le démarrage automatique" et autorisez "Galarm".\n\n - Appuyez sur "Utilisation de la batterie", sélectionnez "Consommation d\'énergie élevée en arrière-plan" et autorisez "Galarm".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-vivo-8-and-lower',
  huawei7AndLowerAdditionalSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Apps protégées" et autorisez "Galarm".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-huawei-7-and-lower',
  huaweiAdditionalSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Gérer le lancement des applications" et désactivez "Galarm" pour "Gérer manuellement". Dans l\'invite qui suit, assurez-vous que les options "Lancement automatique" et "Exécuter en arrière-plan" sont activées.\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-huawei',
  meizuSetupInstructions:
    'Suivez les instructions sur le lien ci-dessous pour que vos alarmes sonnent toujours : https://dontkillmyapp.com/meizu',
  asusSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Tapez sur "Activer le démarrage automatique" et cliquez sur "Gestionnaire de démarrage automatique". Activez "Galarm" dans le "Gestionnaire de démarrage automatique".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-asus',
  nokia8AndLowerAdditionalSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Tapez sur "Activer le démarrage automatique" et autorisez "Galarm".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-nokia-8-and-lower',
  nokiaAdditionalSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Apps restreintes" et désactivez "Galarm".\n\nPour en savoir plus, consultez le site www.galarmapp.com/setup-nokia',
  lenovoSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur le bouton "Apps récentes" de votre téléphone, localisez l\'application Galarm et appuyez sur l\'icône "Verrouiller" dans le coin supérieur droit.\n  \nPour en savoir plus, visitez le site https://dontkillmyapp.com/lenovo',
  samsungAdditionalSetupInstructions:
    'Suivez ces instructions pour que vos alarmes sonnent toujours :\n\n - Appuyez sur "Applications non surveillées" et autorisez "Galarm".\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-samsung',
  tecnoSetupInstructions:
    'Suivez les instructions sur le lien ci-dessous pour que vos alarmes sonnent toujours : https://www.galarmapp.com/setup-tecno',
  infinixSetupInstructions:
    'Suivez les instructions sur le lien ci-dessous pour que vos alarmes sonnent toujours : https://www.galarmapp.com/setup-infinix',
  samsungAdditionalSetupInstructionsAndroid11AndAbove:
    'Par défaut, Samsung affiche des notifications "brèves" qui disparaissent rapidement. Suivez ces instructions pour voir les notifications d\'alarme "détaillées".\n\n - Ouvrez les réglages du téléphone et choisissez "Notifications"\n - Choisissez le style de notifications "Détaillées".\n  \nPour en savoir plus, visitez le site www.galarmapp.com/setup-samsung-android11-and-above',
  iosSetupInstructions:
    'Par défaut, le style de bannière d\'iOS pour les notifications est réglé sur "Temporaire", ce qui entraîne la disparition rapide des notifications. Suivez ces instructions pour régler le style de bannière sur "Persistant".\n\n- Ouvrez les réglages du téléphone et faites défiler jusqu\'à Galarm.\n- Choisissez Notifications et réglez le style de la bannière sur Persistant.\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-ios',
  iosSetupInstructions15AndBelow:
    'Par défaut, le style de bannière d\'iOS pour les notifications est réglé sur "Temporaire", ce qui entraîne la disparition rapide des notifications. Suivez ces instructions pour régler le style de bannière sur "Persistant".\n\n- Ouvrez les réglages du téléphone et faites défiler jusqu\'à Galarm.\n- Choisissez Notifications et réglez le style de la bannière sur Persistant.\n\nPour en savoir plus, visitez le site www.galarmapp.com/setup-ios-15-below',
  setNotificationsStyle: 'Réglage du style des notifications',
  setNotificationsStyleToDetailed:
    'Régler le style de notification sur "détaillé".',
  setBannerStyle: 'Réglage du style de la bannière',
  setBannerStyleToPersistent:
    'Régler le style de la bannière sur "Persistant".',
  selectAtleastOneDay: 'Sélectionnez les jours où cette alarme doit sonner.',
  selectAtLeastOneHourOrMinute:
    'Les heures ou les minutes doivent avoir une valeur non nulle.',
  hourlyAlarmDefaultStartTimeSetAlert:
    "L'heure de début de l'alarme a été réglée sur 8 heures du matin.",
  batteryUsage: 'Utilisation de la batterie',
  dontShowAgain: 'Ne vous montrez plus',
  alarmWillRingEveryHourTillSeen:
    "L'alarme Rétardera toutes les heures jusqu'à ce que vous agissiez.",
  unknown: 'Inconnu',
  alarmMarkedDone: '{{name}} a marqué l\'alarme "Terminé".',
  alarmMarkedSkipped: "{{name}} a passé l'alarme.",
  alarmIsMarkedDone: '{{alarmName}} est marquée Terminé.',
  alarmIsSkipped: '{{alarmName}} est passé.',
  alarmIsConfirmed: '{{alarmName}} est confirmée.',
  alarmIsDeclined: '{{alarmName}} est refusée.',
  alarmIsSnoozed: '{{alarmName}} est Rétardé.',
  alarmIsAccepted: '{{alarmName}} est acceptée.',
  alarmIsRejected: '{{alarmName}} est rejetée.',
  newContactInfo:
    "Veuillez indiquer le nom et le numéro de téléphone du contact.\n\nSeuls les utilisateurs enregistrés peuvent être ajoutés en tant que contacts. Si l'utilisateur n'est pas un utilisateur enregistré de Galarm, vous pouvez l'inviter en utilisant \"Réglages > Partager cette application\". Vous pouvez l'ajouter en tant que contact une fois qu'il s'est enregistré en utilisant son numéro de téléphone. ",
  cantEditContactMobileNumber:
    'Vous ne pouvez pas modifier le numéro de téléphone. Supprimez le contact et ajoutez-le à nouveau si vous souhaitez modifier le numéro.',
  phoneContactsNotSyncedPermissionDenied:
    "Galarm n'a pas la permission d'accéder aux contacts téléphoniques. Les contacts téléphoniques ne seront pas synchronisés.",
  inviteContactMessage:
    "Bonjour {{contactName}}, Je souhaite vous inclure dans mes alarmes et mes rappels à l'aide de Galarm. Téléchargez l'application à partir de {{downloadLink}}. Veuillez vous enregistrer en utilisant ce numéro et commencer à partager des alarmes.",
  noRepeat: 'Ne se répète pas',
  deepLinkNoLongerValidTitle: 'Oups !',
  deepLinkNoLongerValidMessage:
    "L'alarme n'a pas été créée. Il peut s'agir d'un ancien lien ou d'un lien non valide.",
  inviteContact: 'Inviter un contact',
  fromPhoneBook: "Depuis le carnet d'adresses",
  addFromPhoneBook: "Inviter à partir du carnet d'adresses",
  manually: 'Manuellement',
  addManualContact: 'Inviter manuellement',
  noOtherContactsFound:
    "Tous les contacts de votre carnet d'adresses utilisent Galarm.",
  cantAddPhoneContactPermissionDenied:
    "Galarm n'a pas l'autorisation d'accéder aux contacts du téléphone. Autoriser l'accès aux contacts.",
  alarmWillRingWithRandomRingtone:
    "Une sonnerie aléatoire est choisie pour l'alarme.",
  alarmWillRingWithRandomRingtoneGlobal:
    'Une sonnerie aléatoire est choisie pour les alarmes.',
  createdAlarmFromDeepLinkTitle: 'Tout est réglé !',
  createdAlarmFromDeepLinkMessage:
    'Le site {{alarmTime}} vous rappellera l\'existence de "{{alarmName}}". {{alarmDate}}',
  createdRepeatingAlarmFromDeepLinkMessage: {
    zero: 'Le site "{{alarmName}}" vous sera rappelé. {{repetitionString}}',
    one: 'On vous rappellera "{{alarmName}}" {{repetitionString}} à partir de {{alarmTime}} {{alarmDate}}'
  },
  deleteCategoryAlertTitle: 'Supprimer une catégorie ?',
  deleteCategoryAlertMessage: {
    zero: '',
    one: '1 alarme appartient à cette catégorie. La suppression de cette catégorie réinitialise la catégorie sur cette alarme.',
    other:
      '{{count}} Les alarmes appartiennent à cette catégorie. La suppression de cette catégorie réinitialise la catégorie sur ces alarmes.'
  },
  updateReadyToInstallAlertTitle: 'Installer la mise à jour ?',
  updateReadyToInstallAlertMessage:
    'Les mises à jour sont prêtes à être installées. Souhaitez-vous installer la mise à jour maintenant ? ',
  hideCategoryAlertTitle: 'Cacher la catégorie ?',
  hideCategoryAlertMessage: {
    zero: "Vous ne pourrez plus utiliser cette catégorie à l'avenir.",
    one: "1 alarme appartient à cette catégorie. Le fait de masquer cette catégorie réinitialise la catégorie sur cette alarme.\n  \nVous ne pourrez plus utiliser cette catégorie à l'avenir.",
    other:
      "{{count}} Les alarmes appartiennent à cette catégorie. Le fait de masquer cette catégorie réinitialise la catégorie sur ces alarmes.\n  \nVous ne pourrez plus utiliser cette catégorie à l'avenir."
  },
  alarmCategoryDeleteAllAlarmsPrompt: {
    one: '1 alarme appartient à cette catégorie. Voulez-vous la supprimer ?\n\nNotez que les alarmes de participants ne sont pas supprimées.',
    other:
      '{{count}} alarmes appartiennent à cette catégorie. Voulez-vous les supprimer ?\n\nNotez que les alarmes de participants ne sont pas supprimées.'
  },
  enterNameForGroup: 'Saisir un nom pour le groupe',
  enterMobileNumber: 'Entrez votre numéro de téléphone',
  enterNotesForAlarm: "Saisir des notes pour l'alarme",
  enterUserName: 'Entrez votre nom',
  enterEmailId: 'En option',
  enterFeedback: 'Comment pouvons-nous nous améliorer ?',
  describeProblem: 'Décrivez votre problème',
  invited: 'Sur invitation',
  resend: 'Renvoyer',
  addContact: 'Ajouter un contact',
  inviteAgain: 'Inviter à nouveau',
  contactNotRegistered:
    "{{contactName}} n'a pas encore installé Galarm. Vous pourrez les ajouter aux alarmes une fois qu'ils auront installé Galarm et se seront enregistrés à l'aide de leur numéro de téléphone.",
  registration: 'Inscription',
  verify: 'Vérification',
  profile: 'Profil',
  profileImage: 'Image de profil',
  addProfileImage: 'Ajouter une image de profil',
  groupDetails: 'Détails du groupe',
  newGroup: 'Nouveau groupe',
  uploadImage: "Changer d'image",
  removeImage: "Supprimer l'image",
  selectParticipants: 'Sélectionner les participants',
  selectMembers: 'Sélectionner les membres',
  selectTimezone: 'Sélectionner le fuseau horaire',
  searchForATimezone: "Recherche d'un fuseau horaire",
  timezone: 'Fuseau horaire',
  deviceTimezone: "Fuseau horaire de l'appareil",
  specifyTimezoneForAlarm: 'Spécifier le fuseau horaire pour les alarmes',
  editName: 'Modifier le nom',
  groupImage: 'Image du groupe',
  addGroupImage: 'Ajouter une image de groupe',
  selectAlarmType: 'Créer une alarme pour',
  contactDetails: 'Détails du contact',
  commonAlarms: 'Alarmes',
  commonGroups: 'Les groupes',
  groupAlarmsTitle: 'Alarmes',
  selectRingtone: 'Sélectionner la sonnerie',
  selectMusic: 'Sélectionner la musique',
  select: 'Sélectionner',
  personalAlarm: 'Alarme personnelle',
  groupAlarm: 'Alarme de groupe',
  newPersonalAlarm: 'Nouvelle alarme personnelle',
  newGroupAlarm: 'Nouvelle alarme de groupe',
  editPersonalAlarm: "Modifier l'alarme personnelle",
  editGroupAlarm: "Modifier l'alarme du groupe",
  rearrangeParticipants: 'Réorganiser les participants',
  alarmConversation: 'Alarme Discuter',
  timezoneMismatchDetected:
    'Quelque chose nous dit que vous êtes dans un fuseau horaire différent de la dernière fois que vous avez utilisé Galarm.\n\nVeuillez redémarrer Galarm afin que nous puissions nous adapter au nouveau fuseau horaire.',
  deviceRestartRequired: "Redémarrage de l'application nécessaire",
  disabledAlarm: 'Alarme désactivée {{name}}',
  newContact: 'Nouveau contact',
  contactName: 'Nom du contact',
  contactMobileNumber: 'Numéro de téléphone du contact',
  enterNameForContact: 'Saisir le nom du contact',
  pickCountryForContact: 'Pays du contact',
  enterMobileNumberForContact: 'Saisir le numéro de téléphone du contact',
  specifyNameForContact: 'Le nom du contact est obligatoire',
  specifyValidPhoneNumberForContact: 'Un numéro de téléphone valide est requis',
  addingContact: "Ajout d'un contact",
  deletingContact: 'Suppression du contact',
  editingContact: 'Modification du contact',
  pending: 'En attente',
  deleteContact: 'Supprimer le contact',
  galarmContact: 'Ajouté manuellement',
  phoneContact: 'Ajouté à partir des contacts téléphoniques',
  confirmDeleteContactTitle: 'Supprimer {{name}}?',
  confirmDeleteContact: 'Vous ne pourrez pas les ajouter à vos alarmes.',
  contactAdded:
    'Super ! Vous pouvez désormais ajouter {{name}} comme participant à vos alarmes.',
  contactInvited:
    'Un message texte a été envoyé à {{name}} pour les inviter à utiliser Galarm.',
  changeSnoozeDuration: 'Changement Durée',
  durationOfEvent: "Durée de l'événement",
  upgradeToPremium: 'Amélioration de la version premium',
  premium: 'Premium',
  alarmLimitReached: "Limite d'alarme atteinte",
  unableToConnectToGooglePlay:
    'Impossible de se connecter à Google Play. Vérifiez que vous êtes bien connecté à votre compte Google.',
  cantLoadVideo: 'Impossible de charger la vidéo',
  cantUpgradeToPremium: 'Impossible de passer à la version premium',
  unableToConnectToIapAndroid:
    "Impossible de se connecter au Play Store. Veuillez ouvrir l'application Play Store et vérifier que vous êtes connecté.",
  unableToConnectToIapIos:
    "Impossible de se connecter à l'App Store. Veuillez ouvrir l'application App Store et vérifier que vous êtes connecté.",
  premiumFeaturesIos:
    "En tant qu'utilisateur premium, vous bénéficiez d'une limite plus élevée de {{maxLimit}} alarmes simultanées.\n\nVotre achat nous aide également à développer de nouvelles fonctionnalités.",
  premiumFeaturesAndroid:
    "Créez un nombre illimité d'alarmes en passant à la version premium.\n\nVotre achat nous aide également à développer de nouvelles fonctionnalités.",
  upgrade: 'Amélioration',
  rvInternalError:
    "Nous n'avons pas pu charger la vidéo en raison d'une erreur interne. Cependant, nous avons augmenté votre limite d'alarme de 1.",
  rvNetworkError:
    "Assurez-vous que vous disposez d'une bonne connexion internet et réessayez.",
  rvNotFoundError:
    "Nous n'avons pas pu trouver de vidéo appropriée pour le moment. Cependant, nous avons augmenté votre limite d'alarme de 1.",
  dontRemindMe: 'Ne me le rappelez pas',
  sameTimeAsBuddy: "La même heure que l'ami",
  noAlertForBuddyAlarm:
    'Vous avez choisi de ne pas être alerté pour cette alarme.',
  noAlertRecipientIntervalDescription: 'Vous ne serez pas alerté.',
  chooseHours: 'Choisir les heures',
  chooseMins: 'Choisir les minutes',
  changeHours: 'Modifier les heures',
  moreOptions: "Plus d'options...",
  set: 'Réglage',
  chooseNumberOfDays: 'Saisir le nombre de jours',
  chooseNumberOfWeeks: 'Saisir le nombre de semaines',
  numberOfDaysShouldBeAtLeastOne:
    'Le nombre de jours doit être au moins égal à 1',
  numberOfWeeksShouldBeAtLeastOne:
    "Le nombre de semaines doit être d'au moins 1",
  numberOfMonthsShouldBeAtLeastOne:
    'Le nombre de mois doit être au moins égal à 1',
  numberOfYearsShouldBeAtLeastOne: "Le nombre d'années doit être d'au moins 1",
  frequency: 'Fréquence',
  configuration: 'Configuration',
  endDate: "Date de fin de l'alarme",
  noEndDate: 'Pas de date de fin',
  alarmEndDateSetOnAlarm:
    "La date de fin d'alarme est réglée sur {{endDateString}}.",
  noAlarmDateSetForAlarm: "Aucune date de fin n'est réglée pour cette alarme.",
  noOccurrenceForThisAlarmAsPerEndDate:
    "La date de fin n'autorise aucune occurrence pour l'alarme.",
  alarmDateShouldBeBiggerThanAlarmStartDate:
    "La date de fin de l'alarme doit être postérieure à la date de début de l'alarme.",
  aWeek: 'Une semaine',
  aMonth: 'Un mois',
  allInactiveAlarms: 'Toutes les alarmes inactives',
  chooseAlarmEndDate: "Choisir la date de fin de l'alarme",
  next: 'Suivant',
  next7Days: "7 jours à partir d'aujourd'hui",
  next30Days: "30 jours à partir d'aujourd'hui",
  next12Months: "12 mois à partir d'aujourd'hui",
  next10Years: "10 ans à partir d'aujourd'hui",
  chooseDate: 'Choisissez une date...',
  learnMore: 'En savoir plus',
  repeatEvery: 'Se répète tous les',
  repeatEveryDays: 'jour(s)',
  repeatEveryWeeks: 'semaine(s)',
  repeatEveryMonths: 'mois',
  repeatEveryYears: 'année(s)',
  autoSnooze: 'Rétardez les alarmes automatiquement',
  autoSnoozePerAlarm: "Rétarder l'alarme automatiquement",
  unableToUploadGroupAvatar:
    "Impossible de télécharger l'image du groupe maintenant. Veuillez réessayer plus tard",
  addNewMembersToExistingAlarms:
    'Vous disposez des alarmes suivantes avec ce groupe :\n\n{{alarmNames}}\n    \nVoulez-vous ajouter les nouveaux membres à ces alarmes ?',
  removeGroupMemberAlert:
    'La suppression du membre aura un impact sur les alarmes suivantes :\n\n{{alarmNames}}\n\nVoulez-vous toujours supprimer le membre ?',
  leaveGroupAlert:
    'La sortie du groupe aura un impact sur les alarmes suivantes :\n\n{{alarmNames}}\n\nVoulez-vous toujours quitter le groupe ?',
  updateAppAfterFeedback:
    "Nous avons remarqué que vous utilisez une ancienne version de l'application. Veuillez la mettre à jour pour une meilleure expérience.",
  changesAreLost:
    'Vous avez des changements non sauvés.\n\nVoulez-vous sauvegarder avant de partir ?',
  recipientAlarmIntervalGreaterThanRepetitionInterval:
    'L\'intervalle de "rappel" doit être inférieur à l\'intervalle de rappel.',
  cascadingAlarmIntervalGreaterThanRepetitionInterval:
    "\"L'intervalle entre les alertes des participants doit être inférieur à l'intervalle de répétition.",
  subscriptionCanceled:
    "Votre abonnement premium a été annulé. Les fonctionnalités premium ne seront pas disponibles tant que vous n'aurez pas renouvelé l'abonnement.",
  unableToRenewSubscription:
    'Il y a eu un problème lors du renouvellement de votre abonnement premium. Veuillez vous assurer que vos informations de paiement sont à jour.',
  notice: 'Avis',
  ringerSettingsWillApplyToNewAlarms:
    "Les modifications apportées aux réglages des sons et des vibrations ne s'appliquent qu'aux nouvelles alarmes.\n\nPour les alarmes existantes, vous pouvez modifier l'alarme pour changer ses réglages de son et de vibration.",
  renew: 'Renouveler',
  galarmPremium: 'Galarm Premium',
  payMonthly: 'Paiement mensuel à {{price}} par mois',
  payYearly: 'Payez annuellement à {{price}} par an',
  free: 'Gratuit',
  unlimited: 'Illimité',
  personalAlarms: 'Alarmes personnelles',
  groupAlarms: 'Alarmes de groupe',
  buddyAlarms: 'Alarmes pour les amis',
  alarmRepetitions: 'Répétitions',
  ringtones: 'Sonneries',
  alarmHistory: 'Historique des alarmes',
  base: 'De base',
  advanced: 'Avancé',
  complete: 'Complet',
  alarmHistoryFree: 'Limitée',
  phoneMusicAsRingtone: 'Musique du téléphone comme sonnerie',
  startFreeTrial: 'Essai gratuit de 14 jours',
  chargedAtEndOfFreeTrial:
    "Vous serez facturé après la fin de l'essai gratuit de 14 jours, à moins que vous n'annuliez votre abonnement.",
  monthlySubscription: 'Mensuel',
  yearlySubscription: 'Annuel',
  premiumRingtoneRequiresUpgrade:
    'Cette sonnerie nécessite un abonnement Premium.',
  defaultPremiumFeaturesDescription:
    'Passez à la version premium pour bénéficier de ces fonctionnalités intéressantes.',
  groupAlarmsPremiumFeaturesDescription:
    "Vous avez besoin d'un abonnement premium pour créer plus d'une alarme de groupe.",
  buddyAlarmsPremiumFeaturesDescription:
    "Vous avez besoin d'un abonnement premium pour créer plus d'une alarme pour quelqu'un d'autre (\"ami\").",
  shareableAlarmLinksPremiumFeaturesDescription:
    "Vous avez besoin d'un abonnement premium pour créer plus de liens d'alarme partageables.",
  viewPremiumFeaturesDescription:
    "Merci d'être un utilisateur premium. Vous pouvez bénéficier de ces fonctionnalités intéressantes en tant qu'abonné premium.",
  accessToPremiumFeatures:
    "Vous aurez accès à ces fonctionnalités dans le cadre de l'abonnement :",
  alarmCategoryPremiumFeaturesDescription:
    "Vous avez besoin d'un abonnement premium pour créer de nouvelles catégories.",
  galarmWebPremiumFeaturesDescription:
    "Vous avez besoin d'un abonnement premium pour utiliser Galarm sur un navigateur web.",
  instantAlarmPremiumFeaturesDescription:
    "Vous avez besoin d'un abonnement premium pour créer davantage d'alarmes instantanées.",
  personalAlarmsFeatureDescription:
    "Utilisez une alarme personnelle pour vous réveiller, prendre vos médicaments ou effectuer toute autre tâche pour vous-même. Vous pouvez éventuellement ajouter des participants qui seront alertés si vous manquez une alarme, afin qu'ils puissent vous la rappeler. Les participants sont également avertis lorsque vous marquez l'alarme comme étant Terminée.",
  groupAlarmsFeatureDescription:
    "Utilisez une alarme de groupe pour les activités de groupe telles que les sorties, les fêtes ou tout autre événement. L'alarme se déclenche à la même heure pour tous les participants, qui peuvent ainsi confirmer leur participation.",
  buddyAlarmsFeatureDescription:
    "Créez des alarmes pour quelqu'un d'autre (\"ami\") afin de lui rappeler ce qu'il doit faire. Vous êtes alerté pour rappeler l'alarme à l'ami au cas où il la manquerait. Vous serez également averti lorsque l'ami marquera l'alarme comme étant Terminée.",
  alarmRepetitionsFeatureDescription:
    "Créez des alarmes avec des répétitions avancées telles que toutes les 30 minutes, toutes les 2 heures 45 minutes, tous les deux jours, toutes les deux semaines, et bien d'autres encore.",
  ringtonesFeatureDescription:
    "Choisissez parmi une grande variété de sonneries premium pour vos alarmes. Nous ajoutons continuellement de nouvelles sonneries à l'offre premium.",
  phoneMusicAsRingtoneFeatureDescription:
    "Choisissez n'importe quelle chanson/musique de votre téléphone comme sonnerie d'alarme.",
  shareableAlarmLinksFeatureDescription:
    "Vous pouvez partager des alarmes avec d'autres personnes à l'aide d'un lien.\n\nPar exemple, un enseignant peut partager le lien de l'alarme de sa classe avec ses élèves en utilisant l'email, WhatsApp ou toute autre application de messagerie. Lorsque les élèves tapent sur le lien, une copie de l'alarme de classe sera créée sur leur téléphone. Tous les élèves seront alertés à l'heure spécifiée pour rejoindre la classe.",
  instantAlarmFeatureDescription:
    "Alerter instantanément un groupe de personnes en cliquant sur un bouton.\n\nUtilisez cette alarme pour les avertir d'un exercice d'urgence, du début d'un match, d'une tâche/réunion urgente et d'autres événements de ce type qui nécessitent une attention immédiate.",
  alarmHistoryFeatureDescription:
    'Voir toutes les réponses passées pour chaque alarme répétée. Vous pouvez ainsi facilement savoir quand vous avez manqué votre cours de gymnastique, quand votre mère a oublié de prendre ses médicaments, etc.',
  announceAlarmTitleFeatureDescription:
    "Annonce le titre de l'alarme lorsque celle-ci sonne.",
  exportToCalendarPremiumFeaturesDescription:
    "Vous devez souscrire un abonnement premium pour exporter davantage d'alarmes vers le calendrier.",
  exportToCalendarFeatureDescription:
    'Vous pouvez exporter des alarmes vers votre calendrier. Cela vous permet de voir facilement vos alarmes sur le calendrier de votre appareil et de mieux refléter votre disponibilité.',
  galarmWebFeatureDescription:
    'Utilisez Galarm sur un navigateur web pour créer, modifier et supprimer des alarmes.',
  preReminderFeatureDescription:
    "Réglez les pré-rappels pour être alerté des alarmes à venir. Vous pouvez configurer la durée relative de l'alerte avant l'heure réelle de l'alarme, par exemple 30 minutes avant, 1 jour avant, etc.",
  calendarViewFeatureDescription:
    "Voir les alarmes dans un calendrier pour voir facilement vos alarmes pour n'importe quel jour.",
  updatePhoneNumberFeatureDescription:
    'Vous pouvez modifier le numéro de téléphone utilisé pour ce compte. Toutes vos alarmes, contacts, groupes et autres réglages seront transférés vers le nouveau numéro de téléphone.',
  alarmPhotoFeatureDescription:
    "Ajoutez une photo à une alarme pour obtenir des repères visuels rapides tels que la photo d'un médicament, d'instructions pour une tâche, d'un objet, etc. La photo s'affichera lorsque l'alarme sonnera.",
  taskListFeatureDescription:
    'Créez des listes de tâches pour regrouper les tâches connexes.',
  calendarViewPremiumFeature: 'Voir le calendrier',
  updatePhoneNumberPremiumFeature: 'Modifier le numéro de téléphone',
  alarmNotFound: 'Alarme introuvable',
  alarmNotFoundMessage:
    'Il se peut que cette alarme ait été récemment supprimée. Tapez sur "Supprimer" pour désactiver les notifications ultérieures de cette alarme.',
  alarmExistsWithDeepLinkTitle: 'Alarme de doublon',
  alarmExistsWithDeepLinkMessage:
    "Une alarme créée à l'aide de ce lien existe déjà sur votre téléphone.",
  alarmDoesntHaveNextDate: "L'alarme n'a pas de date suivante valide.",
  sharePersonalAlarmDeepLinkDescription:
    'Tapez sur ce lien pour créer une alarme pour cet événement à {{dateString}}.',
  joinGroupAlarmDeepLinkDescription:
    "Cliquez sur ce lien pour participer à cet événement de groupe à l'adresse suivante {{dateString}}",
  subscribedAlarmEdited:
    '{{alarmCreatorName}} a mis à jour {{alarmName}}. Voulez-vous mettre à jour votre alarme ?',
  subscribedAlarmDeleted:
    '{{alarmCreatorName}} a supprimé {{alarmName}}. Voulez-vous supprimer votre alarme ?',
  responseReceivedForTicket:
    "Nous avons répondu à votre ticket #{{ticketId}} à : {{email}}. S'il n'est pas dans votre boîte de réception, veuillez vérifier vos dossiers de spam pour un courriel de support@galarm.zendesk.com.",
  newTicketReceived:
    "Merci de nous avoir écrit. Nous avons créé le ticket #{{ticketId}} pour suivre votre demande.\n\nNous vous répondrons à l'adresse électronique que vous avez fournie : {{email}}. Si ce n'est pas le cas, veuillez soumettre à nouveau votre retour d'information avec l'adresse électronique correcte.\n\nVeuillez également ajouter support@galarm.zendesk.com à votre carnet d'adresses électroniques afin de garantir la bonne réception de nos réponses. Nous enverrons également une notification ici lorsque nous aurons répondu à votre demande.",
  sentAccountBackup:
    "Nous avons envoyé vos données d'alarme à {{email}}. S'il n'est pas dans votre boîte de réception, veuillez vérifier vos dossiers de spam pour un e-mail de noreply@acintyo.com.",
  alarmDoesntExist: "L'alarme n'existe pas.",
  shareLink: 'Lien de partage',
  joinedGroupAlarmFromDeepLinkMessage:
    'Le site {{alarmTime}} vous rappellera l\'existence de "{{alarmName}}". {{alarmDate}}',
  joinedRepeatingGroupAlarmFromDeepLinkMessage: {
    zero: 'Le site "{{alarmName}}" vous sera rappelé. {{repetitionString}}',
    one: 'On vous rappellera "{{alarmName}}" {{repetitionString}} à partir de {{alarmTime}} {{alarmDate}}'
  },
  youHaveSubscribedToThisAlarm:
    'Vous suivez cette alarme depuis {{creatorName}}.',
  alreadySharedAlarmDescription: {
    zero: "Vous n'avez pas d'adeptes pour cette alarme.\n\nVous pouvez continuer à partager cette alarme avec d'autres personnes à l'aide d'un lien. Lorsque ces personnes cliqueront sur le lien, une copie de cette alarme sera créée sur leur téléphone.",
    one: "{{count}} suit cette alarme.\n\nVous pouvez continuer à partager cette alarme avec d'autres personnes à l'aide d'un lien. Lorsque ces personnes cliqueront sur le lien, une copie de cette alarme sera créée sur leur téléphone.",
    other:
      "{{count}} personnes suivent cette alarme.\n\nVous pouvez continuer à partager cette alarme avec d'autres personnes à l'aide d'un lien. Lorsque ces personnes cliqueront sur le lien, une copie de cette alarme sera créée sur leur téléphone."
  },
  shareParentAlarmDescription:
    "Vous suivez cette alarme depuis {{creatorName}}.\n\nVous pouvez également partager cette alarme avec d'autres personnes à l'aide d'un lien. Lorsqu'ils cliquent sur le lien, une copie de cette alarme est créée sur leur téléphone et ils peuvent également commencer à suivre cette alarme.",
  shareableAlarmLinks: 'Liens à partager',
  nPeopleFollowingAlarm: {
    zero: "Vous n'avez pas d'adeptes pour cette alarme.",
    one: '{{count}} suit cette alarme.',
    other: '{{count}} personnes suivent cette alarme.'
  },
  likeUsOnFacebook: 'Facebook',
  followUsOnTwitter: 'Twitter',
  followUsOnInstagram: 'Instagram',
  youtube: 'YouTube',
  mediumBlog: 'Blogs sur Medium',
  howtoVideos: 'Vidéos pratiques',
  followGalarmOnSocialMedia: 'Suivez Galarm sur les médias sociaux',
  galarmOnSocialMedia: 'Galarm sur les médias sociaux',
  followGalarmOnSocialMediaDescription:
    "Voir les nouvelles passionnantes sur les mises à jour de l'application, l'utilisation des fonctionnalités, les fonctionnalités à venir, ainsi que les principales critiques d'applications. Vous pouvez également interagir avec l'équipe de Galarm.",
  sharedAlarm: "Modifier l'alarme partagée",
  editingPublishedAlarm: {
    one: "Vous modifiez une alarme que vous avez partagée précédemment à l'aide d'un lien. {{count}} personne suit actuellement cette alarme.\n\nLorsque vous modifierez cette alarme, elle sera informée des changements.",
    other:
      "Vous modifiez une alarme que vous avez partagée précédemment à l'aide d'un lien. {{count}} personnes suivent actuellement cette alarme.\n\nLorsque vous modifierez cette alarme, elles seront informées des changements."
  },
  editingSubscribedAlarm:
    "Vous suivez cette alarme depuis {{creatorName}}. Vous ne recevrez plus de mises à jour pour cette alarme après l'avoir modifiée.",
  viewAlarm: "Voir l'alarme",
  view: 'Voir',
  editAlarm: "Modifier l'alarme",
  thanksForTryingPremiumTitle: 'Tout est réglé !',
  thanksForTryingPremiumMessage:
    'Nous espérons que vous apprécierez les fonctionnalités premium.\n\nSi vous avez des questions, veuillez contacter le service d\'assistance en utilisant "Réglages > Retour d\'information" ou en nous envoyant un courriel à galarm@acintyo.com.',
  subscribedAlarmEditedInAlarmDetails:
    '{{creatorName}} a modifié son alarme.\nVous avez mis à jour votre alarme ?',
  subscribedAlarmDeletedInAlarmDetails:
    '{{creatorName}} a supprimé son alarme.\nVous avez supprimé votre alarme ?',
  updateSubscribedAlarm: 'Oui, mise à jour',
  deleteSubscribedAlarm: 'Oui, supprimer',
  ignoreSubscribedAlarm: 'Non, ignorer',
  customRepetitionPremiumScreenDescription:
    'La répétition personnalisée est une fonction premium.\n\nVous pouvez utiliser cette fonctionnalité pour créer des alarmes avec des répétitions avancées. Voici quelques exemples :\n',
  repeatAlternateDate: 'Répéter un jour sur deux',
  repeatEveryNDay: 'Répéter tous les 3 jours',
  repeatEveryNHoursAndMMinutes: '✅ Répéter toutes les 2 heures 30 minutes',
  repeatEveryNMinutes: 'Répéter toutes les 45 minutes',
  repeatBiweekly: 'Répéter toutes les 2 semaines',
  repeatEveryNWeeks: 'Répéter toutes les 6 semaines',
  repeatEveryNMonths: '✅ Répéter tous les 3 mois (trimestriel)',
  repeatEveryLastDayOfMonth: '✅ Répéter le dernier jour de chaque mois',
  repeatDayOfWeekOfMonth: '✅ Répéter le 2ème Samedi de chaque mois',
  repeatLastDayOfWeekOfMonth: '✅ Répéter le dernier Vendredi de chaque mois',
  customRepetition: 'Répétition personnalisée',
  lastDayOfMonth: 'Le dernier jour de chaque mois',
  dayOfWeekInMonth: '{{weekNumberOfDayInMonth}} {{dayName}} de chaque mois',
  lastDayOfWeekInMonth: 'Dernier {{dayName}} de chaque mois',
  dayOfWeekInMonthInYearSummary:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}}',
  lastDayOfWeekInMonthInYearSummary: 'Dernière {{dayName}} de {{monthName}}',
  lastDayOfMonthInYearSummary: 'Dernier jour de {{monthName}}',
  dayOfWeekInMonthInYear:
    '{{weekNumberOfDayInMonth}} {{dayName}} de {{monthName}} chaque année',
  lastDayOfWeekInMonthInYear:
    'Dernier {{dayName}} de {{monthName}} chaque année',
  lastDayOfMonthInYear: 'Dernier jour de {{monthName}} chaque année',
  chooseAMonthsConfiguration: 'Veuillez choisir une configuration.',
  chooseADaysConfiguration: 'Veuillez choisir une configuration',
  chooseAYearsConfiguration: 'Veuillez choisir une configuration.',
  didntGetCodeNewUser:
    "Vous n'avez pas reçu le code ?\n\nVous pouvez redemander le code ou ne pas vous inscrire pour l'instant. Vous pourrez vous inscrire plus tard.",
  didntGetCodeExistingUser:
    "Vous n'avez pas reçu le code ?\n\nVous pouvez redemander le code ou annuler l'inscription. Vous pouvez vous inscrire plus tard.",
  didntGetCodeUpdatePhoneNumber:
    "Vous n'avez pas reçu le code ?\n\nVous pouvez redemander le code ou réessayer plus tard.",
  alreadySentCodeAgain:
    "Nous vous avons renvoyé le code. Si vous avez des difficultés à obtenir le code, veuillez ne pas vous inscrire pour l'instant et vous inscrire plus tard.",
  theme: 'Thème',
  announceAlarmName: "Annoncer le titre de l'alarme",
  ttsHelperText:
    "Le titre de l'alarme est annoncé lorsque l'alarme sonne. Vous pouvez appuyer sur le bouton de lecture situé devant le titre de l'alarme pour écouter un aperçu.",
  initializingTts: 'Initialisation de la synthèse vocale',
  errorSpeakingAlarmName:
    "Impossible d'énoncer le titre de l'alarme. Veuillez contacter le service d'assistance à partir de \"Réglages > Retour d'informations\".",
  entityAlarm: 'alarme',
  entityGroup: 'groupe',
  entityContact: 'contact',
  admin: 'Administrateur',
  specifyAlarmTitleToSpeak: "Spécifiez le titre de l'alarme à prononcer",
  wakeup: 'Réveillez-vous',
  ttsPremiumScreenDescription:
    "L'annonce du titre de l'alarme est une fonctionnalité premium.\n\nLorsqu'elle est activée, le titre de l'alarme est annoncé lorsque l'alarme sonne.\n\nPour prévisualiser, indiquez un titre d'alarme et appuyez sur lecture.",
  unknownAlarmCategory: "Catégorie d'alarme inconnue",
  cantAnnounceAlarmTitle: "Le titre de l'alarme ne sera pas annoncé",
  ringtoneDurationIsSmall:
    "La durée de la sonnerie doit être d'au moins 5 secondes pour que le titre de l'alarme soit annoncé.\n\nSouhaitez-vous poursuivre ces réglages ?",
  noActionItems: "Aucun point d'action.",
  personNotInContacts: 'Cette personne ne figure pas dans vos contacts Galarm.',
  light: 'Clair',
  dark: 'Sombre',
  systemDefault: "Utiliser le réglage de l'appareil",
  chooseTheme: 'Choisir un thème',
  dontSeeAllYourContacts: 'Vous ne voyez pas tous vos contacts ?',
  lastContactsSyncedAt: 'Dernière synchronisation à {{dateString}}',
  refresh: 'Rafraîchir',
  forever: 'Pour toujours',
  autoSnoozeConfiguration: 'Configurer Rétarder',
  autoSnoozeConfigurationSummary:
    '{{autoSnoozeDurationLabel}}, {{autoSnoozeCountLabel}}',
  uncategorizedCategoryName: 'Non classé',
  uncategorizedCategoryScreenTitle: 'Non classé',
  setAlarmCategory: "Réglage de la catégorie d'alarme",
  changeAlarmCategory: "Changement de catégorie d'alarme",
  viewAlarmHistory: "Voir l'historique des alarmes",
  computingAlarmHistory: "Résumé informatique pour l'historique des alarmes...",
  alarmByCategories: '{{name}} Alarmes',
  alarmCategories: 'Catégories',
  editAlarmCategory: 'Modifier la catégorie',
  deleteAlarmCategory: 'Supprimer une catégorie',
  newAlarmCategoryTitle: 'Nouvelle catégorie',
  newAlarmCategoryNamePlaceholder: 'Spécifier un nom',
  editAlarmCategoryTitle: 'Modifier la catégorie',
  alarmCategorySummary: {
    zero: '{{alarmsSummary}}',
    one: '{{alarmsSummary}}, {{count}} manqué',
    other: '{{alarmsSummary}}, {{count}} manqué'
  },
  numAlarms: {
    zero: "Pas d'alarme",
    one: '{{count}} alarme',
    other: '{{count}} alarmes'
  },
  numIncidents: {
    zero: 'Aucun incident',
    one: '{{count}} incident',
    other: '{{count}} incidents'
  },
  openedIncidents: 'Incidents ouverts',
  closedIncidents: 'Incidents clos',
  incidentChart: 'Tableau des incidents (30 derniers jours)',
  incidentCalendar: 'Calendrier des incidents',
  incidentDistribution: 'Répartition des incidents (30 derniers jours)',
  noIncidentsOpenedClosedInLast30Days:
    "Pas d'incidents ouverts ou fermés au cours des 30 derniers jours",
  noIncidentsOpenedInLast30Days:
    'Aucun incident ouvert au cours des 30 derniers jours',
  color: 'Couleur',
  createNewAlarmCategory:
    'Créez une nouvelle catégorie en appuyant sur le bouton + en bas à droite.',
  setValidName: "Réglage d'un nom valide",
  specifyTimezoneForAlarmMessage:
    'Vous pouvez spécifier un fuseau horaire lorsque vous créez des alarmes. Les alarmes sonneront en fonction du fuseau horaire spécifié. \n\nSi vous choisissez "Fuseau horaire de l\'appareil", les alarmes sonneront selon le fuseau horaire de votre appareil.',
  alarmCategoryFeatureDescription:
    "Créez vos propres catégories d'alarmes. Les catégories vous permettent d'organiser facilement vos alarmes.",
  markAlarmDoneInAdvance: 'Marquer l\'alarme "Terminé" à l\'avance ?',
  skipAlarmInAdvance: '"Passer" l\'alarme plus tôt ?',
  markAlarmDoneInAdvanceMessageRepeatingAlarm:
    'L\'alarme ne sonnera pas à l\'adresse {{dateTimeString}} si vous marquez "Terminé" maintenant.',
  skipAlarmInAdvanceMessageRepeatingAlarm:
    "L'alarme ne sonnera pas à l'adresse {{dateTimeString}} si vous la passez maintenant.",
  markAlarmDoneInAdvanceMessageOneTimeAlarm:
    'L\'alarme ne sonnera pas à l\'adresse {{dateTimeString}} si vous marquez "Terminé" maintenant.',
  skipAlarmInAdvanceMessageOneTimeAlarm:
    "L'alarme ne sonnera pas à l'adresse {{dateTimeString}} si vous la passez maintenant.",
  alarmsByCategories: 'Alarmes par catégories',
  workAlarmCategory: 'Travail',
  homeAlarmCategory: 'Accueil',
  birthdaysAlarmCategory: 'Anniversaires',
  more: "Plus d'informations",
  alarmCategory: "Catégorie d'alarme",
  editAlarmCategoryColor: 'Modifier la couleur',
  editAlarmCategoryColorTitle: 'Modifier la couleur pour {{name}}',
  allUserCategoriesDeleted:
    'Toutes les catégories d\'alarme que vous avez créées ont été supprimées parce que l\'abonnement premium a été annulé. Les alarmes de ces catégories apparaîtront dans "Non catégorisées".',
  calendar: 'Calendrier',
  eventExportedFromGalarm:
    '"Exporté de Galarm sur {{dateString}} à {{timeString}}."',
  unableToExportEventToCalendar:
    "Impossible d'exporter un événement vers le calendrier. Veuillez vous assurer que vous avez l'autorisation d'accéder au calendrier sur l'appareil. Envoyez-nous un retour d'information en utilisant \"Réglages > Retour d'information\" si le problème persiste.",
  scanQrCode:
    "Pour accéder à Galarm à partir d'un navigateur, rendez-vous sur web.galarm.app et scannez le code QR.",
  logOut: 'Se déconnecter',
  useHere: 'Utiliser ici',
  appRunningElsewhere:
    'Vous avez été déconnecté de cette session. Souhaitez-vous reprendre cette session ?',
  dateTime: 'Date et heure',
  selectAtLeastOneDayForRepetition:
    "Choisissez au moins un jour où l'alarme doit se répéter.",
  chooseNumberOfDaysForAlarmRepetition:
    "Saisir le nombre de jours après lesquels l'alarme doit se répéter.",
  chooseNumberOfWeeksForAlarmRepetition:
    "Saisir le nombre de semaines après lequel l'alarme doit se répéter.",
  chooseNumberOfMonthsForAlarmRepetition:
    "Saisir le nombre de mois après lesquels l'alarme doit se répéter.",
  chooseNumberOfYearsForAlarmRepetition:
    "Saisir le nombre d'années après lequel l'alarme doit se répéter.",
  previouslySelectedContactsAreUnselected:
    'Le(s) contact(s) précédemment sélectionné(s) est/sont désélectionné(s).',
  previouslySelectedGroupIsUnselected:
    'Le groupe précédemment sélectionné est désélectionné.',
  galarmWebInstructions:
    '1. Ouvrez l\'application Galarm sur votre téléphone\n2. Allez dans l\'onglet "Réglages" et tapez sur "Galarm Web"\n3. Assurez-vous que vous utilisez la dernière version de l\'application si vous ne voyez pas l\'option.\n4. Scannez le code QR sur la droite',
  likeToCreateAlarm: 'Créer une alarme pour...',
  payUtilityBills: 'Payer les factures',
  wakeupAlarmName: 'Réveillez-vous',
  exerciseAlarmName: 'Exercice',
  drinkWaterAlarmName: "Boire de l'eau",
  everyNHoursRepetitionStringShortSummary: {
    one: '{{startTimeString}} à {{endTimeString}} Toutes les heures',
    other: '{{startTimeString}} à {{endTimeString}} Toutes les {{count}} heures'
  },
  firstTimeManagementQuote:
    "\"La mauvaise nouvelle, c'est que l'heure passe vite. La bonne nouvelle, c'est que vous êtes le pilote.\"",
  firstTimeManagementQuoteAuthor: 'Michael Altshuler',
  secondTimeManagementQuote:
    '"Toute gestion du temps commence par la planification".',
  secondTimeManagementQuoteAuthor: 'Tom Greening',
  thirdTimeManagementQuote:
    '"Ne remettez jamais à demain ce que vous pouvez faire aujourd\'hui.',
  thirdTimeManagementQuoteAuthor: 'Benjamin Franklin',
  fourthTimeManagementQuote:
    '"L\'heure est ce que nous désirons le plus, mais ce que nous utilisons le moins bien".',
  fourthTimeManagementQuoteAuthor: 'William Penn',
  fifthTimeManagementQuote:
    '"Dans un an, vous regretterez peut-être de ne pas avoir commencé aujourd\'hui.',
  fifthTimeManagementQuoteAuthor: 'Karen Lamb',
  sixthTimeManagementQuote:
    '"Vous pouvez retarder, mais l\'heure ne le fera pas".',
  sixthTimeManagementQuoteAuthor: 'Benjamin Franklin',
  tapToGetStarted: 'Appuyez sur "+" pour commencer.',
  allCategories: 'Toutes les catégories',
  createAlarmWithContact: 'Créer une alarme avec {{name}}',
  createAlarmWithGroup: 'Créer une alarme avec {{name}}',
  editGroup: "Groupe d'édition",
  discardChanges: 'Les modifications en cours seront supprimées. Poursuivre ?',
  alarmWillRingEveryNDays: {
    zero: "L'alarme sonnera tous les jours.",
    one: "L'alarme sonnera un jour sur deux.",
    other: "L'alarme sonnera tous les jours {{dayWithOrdinal}}."
  },
  hoursString: { zero: '', one: '1 heure', other: '{{count}} heures' },
  minutesString: { zero: '', one: '1 minute', other: '{{count}} minutes' },
  alarmWillRingEveryMHoursAndNMinutes:
    "L'alarme sonnera après chaque {{repeatString}} en commençant par {{dayString}}. Ensuite, {{otherDaysString}} et ainsi de suite.",
  alarmWillRingEveryNWeeks: {
    zero: "L'alarme sonnera chaque semaine.",
    one: "L'alarme sonnera toutes les deux semaines.",
    other: "L'alarme sonnera toutes les {{weekWithOrdinal}} semaines."
  },
  alarmWillRingEveryNMonths: {
    zero: "L'alarme sonnera tous les mois.",
    one: "L'alarme sonnera tous les deux mois.",
    other: "L'alarme sonnera tous les {{monthWithOrdinal}} mois."
  },
  alarmWillRingEveryNYears: {
    zero: "L'alarme sonnera chaque année.",
    one: "L'alarme sonnera tous les deux ans.",
    other: "L'alarme sonnera tous les {{yearWithOrdinal}} ans."
  },
  ringParticipantAlarmsByDefaultTooltipMessage:
    "Lorsque cette préférence est activée, les alarmes des autres sonneront sans que vous les acceptiez. Vous devez tout de même accepter l'alarme pour faire savoir au participant que vous avez l'intention de participer à l'alarme.\n\nLorsque cette préférence est désactivée, les alarmes ajoutées pour vous par d'autres personnes ne sonneront pas sur votre téléphone à moins que vous ne les acceptiez explicitement.",
  specifyTimezoneForAlarmDefaultTooltipMessage:
    'Lorsque cette préférence est activée, vous pouvez spécifier un fuseau horaire lorsque vous créez des alarmes. Les alarmes sonneront en fonction du fuseau horaire spécifié.\n\nLorsque cette préférence est désactivée, les alarmes sonneront selon le fuseau horaire de votre appareil.',
  ringOnEarphoneOnlyTooltipTitle:
    "Comportement de l'alarme lorsque les écouteurs sont connectés",
  ringOnEarphoneOnlyTooltipMessage:
    "Lorsque des écouteurs sont connectés, vous pouvez configurer vos alarmes pour qu'elles sonnent uniquement sur les écouteurs ou à la fois sur le haut-parleur du téléphone et sur les écouteurs.\n\nLorsque les écouteurs ne sont pas connectés, les alarmes sonnent sur le haut-parleur du téléphone.",
  gestureOptionsTooltipTitle: 'Geste pour ignorer une alarme',
  gestureOptionsTooltipMessage:
    "Lorsqu'une alarme sonne sur l'écran de verrouillage, vous pouvez l'ignorer en appuyant sur un bouton ou en faisant glisser un bouton.\n\nCe réglage vous permet de choisir la manière dont vous souhaitez ignorer une alarme.",
  notificationSettingsTooltipTitle: 'Réglages de la notification',
  notificationSettingsTooltipMessage:
    'Activez les notifications que vous souhaitez recevoir et désactivez celles qui ne vous intéressent pas.',
  messagesForAlarm: 'Messages pour {{name}}',
  systemNotificationsTooltipMessage:
    "Notifications générées par l'appli pour des événements tels que :\n  - lorsque les alarmes sont affectées en raison du changement de fuseau horaire d'un contact.\n  - lorsqu'un contact invité s'inscrit sur Galarm\n  - lorsque les alarmes sont affectées en raison de la suppression du compte d'une personne.",
  galarmWebNotificationsTooltipMessage:
    'Notifications qui vous sont envoyées par Galarm Web.',
  alarmsfromOthersNotificationsTooltipMessage:
    'Notifications envoyées lorsque vous êtes ajouté à une alarme en tant que participant.',
  alarmChatNotifictionsTooltipMessage:
    'Notifications envoyées pour les messages de discussion dans une alarme.',
  groupNotificationsTooltipMessage:
    "Notifications envoyées lorsque vous êtes ajouté à un groupe, retiré d'un groupe ou que quelqu'un d'autre est ajouté/supprimé d'un groupe.",
  alarmAcknowledgementNotificationsTooltipMessage:
    "Notifications envoyées lorsqu'un participant accuse réception d'une alarme à laquelle vous participez également.",
  sharedAlarmNotificationsTooltipMessage:
    "Les notifications qui sont envoyées :\n  - lorsqu'une personne s'abonne à une alarme partagée par vous via un lien.\n  - lorsqu'une alarme à laquelle vous vous êtes abonné a été modifiée",
  sleepTimer: 'Musique du sommeil',
  sleepTimerTitle: 'Jouer de la musique pour',
  ongoingSleepTimer: 'Musique pour le sommeil en cours',
  stopSleepTimer: 'Voulez-vous arrêter la musique actuelle ?',
  restartSleepTimer:
    'Voulez-vous arrêter la musique en cours et en commencer une nouvelle ?',
  play: 'Jouer',
  youAreOffline:
    'Vous êtes actuellement hors ligne. Allez en ligne pour effectuer des opérations.',
  sleepMusicTooltip:
    "Jouez des sons naturels pour détendre votre corps et votre esprit. La musique s'arrête après la durée spécifiée.",
  alarmLinkCopiedToClipboard: "Lien d'alarme copié dans le presse-papiers.",
  confirmLogOut: 'Voulez-vous vous déconnecter ?',
  appNotRunningMessage:
    'Nous avons détecté que Galarm ne fonctionne pas sur votre téléphone. Tapez pour démarrer Galarm afin de ne manquer aucune alarme importante.',
  tapToEnterNotesForAlarm: 'Touchez pour saisir des notes',
  accountAlreadyExists: 'Compte existant trouvé',
  accountsMerged:
    'Nous avons trouvé un compte existant associé à ce numéro de GSM. Nous avons fusionné les deux comptes.',
  upgradeToPremiumToAssignUserDefinedCategory:
    "Passez à la version premium pour sélectionner une catégorie définie par l'utilisateur.",
  userDefinedCategoryNotSetForAlarm:
    "Passez à la version premium pour attribuer automatiquement une catégorie définie par l'utilisateur. La catégorie n'est pas réglée pour l'alarme.",
  alarmDetails: "Détails de l'alarme",
  profileDetails: 'Profil',
  activeWebSession:
    "Vous avez déjà une session web active. Vous pouvez vous déconnecter de celle-ci ou démarrer une nouvelle session.\n\nLe démarrage d'une nouvelle session vous déconnectera automatiquement de la session web active.",
  startNewSession: 'Démarrer une nouvelle session',
  confirmWebSessionLogout:
    'Vous serez alors déconnecté de votre session web actuelle. Vous pouvez démarrer une nouvelle session en scannant à nouveau le code.',
  defaultInstantAlarmTitleForGroup: '{{groupName}} Alerte de {{creatorName}}',
  defaultInstantAlarmTitleForContact: 'Alerte de {{creatorName}}',
  atleastOneMemberShouldBeActive: 'Au moins un participant doit être actif.',
  activeParticipants: 'Participants actifs',
  acceptAlarm: "Accepter l'alarme",
  rejectAlarm: "Rejeter l'alarme",
  nConfirmed: '{{count}} Confirmer',
  nDeclined: '{{count}} Refusé',
  nPending: '{{count}} en attente',
  everyoneConfirmed: 'Tout le monde a confirmé',
  everyoneDeclined: 'Tout le monde a refusé',
  appTagline: 'Ne jamais rien manquer',
  usePreviousBackup: 'Je suis un utilisateur enregistré >',
  viewAlarmsByCategories: 'Voir les alarmes par catégories',
  viewAllAlarms: 'Voir toutes les alarmes',
  switchToSummaryView: 'Passer à la Voir Résumé',
  switchToListView: 'Passer à la Voir',
  hideHourlyAlarms: 'Masquer les alarmes horaires',
  calendarView: 'Calendrier Galarm',
  viewRecentlyDeletedAlarms: 'Voir les alarmes récemment supprimées',
  deletedOnDate: 'Supprimé le {{alarmDate}}',
  recentlyDeletedAlarmFooterText:
    'Cette alarme a été supprimée le {{alarmDate}}',
  noRecentlyDeletedAlarms: "Pas d'alarme récemment supprimée",
  restoreAlarmsFromMobileApp:
    "Ces alarmes seront automatiquement supprimées au bout de 30 jours après leur suppression. Si vous souhaitez restaurer l'une d'entre elles, rendez-vous sur l'écran \"Alarmes récemment supprimées\" de votre application mobile, et tapez sur leur icône de restauration.",
  deleteInactiveAlarms: 'Suppression des alarmes inactives',
  defaultConfigurationUpdated: 'La configuration par défaut a été mise à jour.',
  notAlerted: '(exclus)',
  alarmKeepsRingingHelp: 'Aidez-nous ! Mon alarme ne cesse de sonner',
  alarmKeepsRingingHelpMessage:
    'Nous avons actualisé vos alarmes et envoyé le rapport de problème.',
  alarmKeepsRingingHelpAlert:
    'Nous actualiserons vos alarmes et vous ne devriez plus observer ce problème. Nous enverrons également un rapport de problème à notre équipe d\'assistance pour un examen plus approfondi.\n\n  Si le problème persiste, veuillez nous contacter en utilisant "Réglages > Retour d\'informations".',
  sendingFeedback: "Envoi du retour d'information",
  sendingProblemReport: "Envoi d'un rapport de problème",
  fixingAlarms: 'Actualisation des alarmes',
  existingAccountFound: 'Sauvegarde restaurée',
  existingAccountDataRestored: {
    zero: 'Félicitations, toutes les données ont été restaurées à partir de la sauvegarde.',
    one: 'Félicitations, toutes les données ont été restaurées à partir de la sauvegarde.',
    other:
      'Félicitations, toutes les données ont été restaurées à partir de la sauvegarde.'
  },
  noExistingAccountFound: "Aucune sauvegarde n'a été trouvée",
  createdNewAccount:
    "Ce numéro de téléphone n'a pas été enregistré auprès de Galarm. Nous avons créé un nouveau compte avec ce numéro de téléphone.",
  iosCloseToAllowedNumNotificationsScheduled: {
    zero: "iOS permet à une app de programmer jusqu'à 64 notifications. Vous avez utilisé toutes ces notifications. Il est possible que vous manquiez certaines alarmes.",
    one: "iOS permet à une app de programmer jusqu'à 64 notifications. Vous avez utilisé {{numNotifications}} de ces notifications."
  },
  acknowledgeAlarmToScheduleNextOccurrence:
    "Il s'agit de la dernière notification pour cette alarme. Appuyez longuement sur cette notification et prenez des mesures pour programmer l'alarme suivante.",
  lastNotificationForOneTimeAlarm:
    "Il s'agit de la dernière notification pour cette alarme.",
  criticalAlertsMessageTitle:
    'Sonnez des alarmes lorsque votre téléphone est en sourdine',
  criticalAlertsMessageDescription:
    "\nVous pouvez configurer Galarm pour qu'il utilise les alertes critiques d'iOS afin de faire sonner les alarmes lorsque votre téléphone est en sourdine. Vous souhaitez l'activer pour toutes les alarmes ?\n\nVous pouvez également l'activer de manière sélective pour certaines alarmes en modifiant leur réglage \"Son et vibration > Alertes critiques\".",
  allowCriticalAlerts: 'Autoriser les alertes critiques',
  criticalAlertsPermissionDenied:
    "Galarm a besoin de l'autorisation de votre téléphone pour afficher des alertes critiques. Veuillez activer cette autorisation dans les réglages de votre téléphone.",
  enableCriticalAlertsForAllAlarms: 'Oui, activer pour toutes les alarmes',
  selectivelyEnableCriticalAlerts: 'Non, je vais activer sélectivement',
  firstName: 'Prénom',
  lastName: 'Nom de famille',
  email: 'Courriel',
  password: 'Mot de passe',
  confirmPassword: 'Confirmer le mot de passe',
  signup: "S'inscrire",
  login: 'Connexion',
  alreadySignedUp: 'Déjà inscrit ?',
  notAUser: "Vous n'êtes pas utilisateur ?",
  subscribe: "S'abonner",
  choosePlan: 'Configurer le plan',
  billingCycle: 'Cycle de facturation',
  needHelpToGetStarted: "Besoin d'aide pour démarrer ?",
  browser: 'Navigateur : {{name}}',
  deviceOs: 'Dispositif : {{name}}',
  lastActive: 'Dernier actif : {{timestamp}}',
  signUp: "S'inscrire",
  useGalarmOnWeb: 'Utiliser Galarm sur le Web',
  welcomeUser: 'Bienvenue {{name}}',
  changePassword: 'Modifier le mot de passe',
  saveAlarm: "Sauvegarder l'alarme",
  galarmUserTip: 'Le saviez-vous ?',
  sendAlarm: "Envoyer l'alarme",
  saveGroup: 'Sauvegarder le groupe',
  webAppBestViewedOnDesktop:
    "L'URL (web.galarm.app) permet de lancer une interface Galarm dans un navigateur web sur votre ordinateur portable ou de bureau.\n\nPour utiliser Galarm sur votre appareil mobile, veuillez installer l'application Android à partir de Google Play ou l'application iOS à partir de l'App Store en utilisant l'un des liens ci-dessous :",
  scanQrCodeMessage: 'Scanner le code QR',
  reloadQrCode: 'Cliquez pour recharger le code QR',
  invalidQrCodeScanned: 'Code QR invalide scanné.',
  unableToScanCode: 'Impossible de scanner le code',
  unableToScanCodeMessage:
    "Il y a eu un problème lors de la numérisation du code. Veuillez réessayer. Si vous continuez à rencontrer des problèmes, veuillez nous envoyer un retour d'information depuis l'application mobile en utilisant \"Réglages > Retour d'information\".",
  problemWithEnterpriseAccountSetup:
    'Il y a eu un problème de réglage de votre compte Galarm Pro. Veuillez contacter le support technique.',
  problemWithCreatingCheckoutSession:
    "Il y a eu un problème lors de la création d'une session de paiement. Veuillez contacter le support technique.",
  problemWithCreatingCustomerPortalSession:
    "Il y a eu un problème lors de la création d'une session sur le portail client. Veuillez contacter le support technique.",
  problemWithCreatingApiKey:
    "Il y a eu un problème lors de la création d'une clé API. Veuillez contacter le support technique.",
  creatingAnAccount: "Création d'un compte",
  currentPlan: 'Plan actuel : {{name}} ({{quantity}} seats)',
  myAccount: 'Informations sur le compte',
  new: 'Nouveau',
  deleteAllAlarmHistoryAlertTitle: "Supprimer l'historique des alarmes",
  deleteAllAlarmHistoryAlertMessage:
    'Appuyez sur "Continuer" pour supprimer l\'historique de cette alarme. Cette action ne peut pas être annulée.',
  ageOutInactiveAlarmsMessage:
    "Vous avez {{count}} alarmes qui sont inactives depuis plus de 60 jours. Le fait d'avoir un grand nombre d'alarmes inactives peut nuire aux performances de l'appli.\n\nSouhaitez-vous supprimer ces alarmes ?",
  duplicateAlarm: 'Alarme de doublon',
  graduallyIncreaseVolume: 'Augmenter progressivement le volume',
  graduallyIncreaseVolumeTooltipMessage:
    "Lorsque cette préférence est activée, le volume de l'alarme augmente progressivement jusqu'au volume de la sonnerie configurée. Cela vous permet d'arrêter l'alarme avant qu'elle ne devienne trop forte.\n\nLorsque cette préférence est désactivée, les alarmes sonnent au volume de sonnerie configuré pendant toute la durée de la sonnerie.",
  teams: 'Les équipes',
  alerts: 'Alertes',
  listeners: 'Les auditeurs',
  noMembersConfiguredInEnterpriseAccount:
    "Vous n'avez pas encore ajouté de membres. Les membres sont des utilisateurs qui peuvent être ajoutés à la politique d'escalade d'un auditeur et qui sont alertés pour les incidents reçus par l'auditeur. Ces personnes doivent déjà être enregistrées dans Galarm.",
  noTeamsConfiguredInEnterpriseAccount:
    "Vous n'avez pas encore créé d'équipes. Les équipes sont des groupes de membres qui peuvent être ajoutés à la politique d'escalade d'un auditeur et qui sont alertés pour les incidents reçus par l'auditeur.",
  noAlertsConfiguredInEnterpriseAccount:
    "Vous n'avez pas encore créé d'auditeurs. Vous pouvez configurer les auditeurs pour qu'ils écoutent les incidents signalés par les outils de surveillance. Une fois que vous avez créé un auditeur, vous pouvez consulter des instructions sur la manière d'intégrer l'auditeur à divers outils de surveillance.\n\nVous pouvez configurer plusieurs niveaux d'escalade pour chaque auditeur.",
  noShiftsConfiguredInEnterpriseAccount:
    "Vous n'avez pas encore créé de configuration d'équipe.",
  noRotationsConfiguredInEnterpriseAccount:
    "Vous n'avez pas encore créé de rotations.",
  rotations: 'Rotations',
  addRotation: 'Ajouter une rotation',
  viewRotation: 'Voir la rotation',
  enterRotationName: 'Saisir le nom de la rotation',
  rotationType: 'Type de rotation',
  numberOfHoursInRotation: "Nombre d'heures de rotation",
  numberOfDaysInRotation: 'Nombre de jours de rotation',
  numberOfWeeksInRotation: 'Nombre de semaines de rotation',
  specify: 'Précisez...',
  noIntegrationsConfiguredInEnterpriseAccount:
    "Vous n'avez pas encore configuré de configuration d'intégration.",
  loadingUser: "Chargement des informations sur l'utilisateur",
  addMember: 'Ajouter un membre',
  add: 'Ajouter',
  noUserFoundWithMobileNumber:
    "Aucun utilisateur enregistré de Galarm n'a été trouvé avec ce numéro de téléphone. Veillez à saisir le numéro de téléphone avec l'indicatif du pays sans caractères spéciaux tels que parenthèses, tirets, espaces blancs, etc.",
  memberWithPhoneNumberAlreadyAdded:
    'Le membre ayant ce numéro de téléphone a déjà été ajouté.',
  memberWithEmailAlreadyAdded: 'Le membre ayant cet email a déjà été ajouté.',
  assignKeyToMember: 'Attribuer une clé de licence',
  revokeKeyFromMember: 'Révoquer la clé de licence',
  cantAssignKeyToMember: "Impossible d'attribuer une clé à un membre",
  noEnterpriseLicenseKeyAvailable:
    "Aucune clé de licence n'est disponible. Veuillez procéder à une amélioration de votre plan.",
  addTeam: 'Ajouter une équipe',
  enterTeamName: "Saisir un nom d'équipe",
  addAlert: 'Ajouter un auditeur',
  low: 'Faible',
  medium: 'Moyen',
  high: 'Haut',
  severity: 'Sévérité',
  enterAlertName: "Saisir le nom de l'auditeur",
  organization: 'Organisation',
  startTime: 'Heure de début',
  endTime: "L'heure de la fin",
  shiftName: 'Déplacement {{index}}',
  addShift: 'Ajouter un décalage',
  assignees: 'Destinataires',
  shiftStartEndTime: '{{startTime}} - {{endTime}}',
  assignee: 'Cessionnaire',
  specifyAssigneeForShift: "Spécifier l'assigné pour le quart de travail",
  selectAtLeastOneMember: 'Sélectionner au moins un membre',
  editMember: 'Modifier le membre',
  editTeam: 'Équipe de rédaction',
  cantRemoveMemberAssignedToTeamsOrAlerts:
    "Le membre fait partie d'une équipe ou est affecté à un auditeur. Veuillez retirer le membre de ces équipes/auditeurs avant de le supprimer.\nÉquipes : {{teams}}\nAuditeurs : {{alerts}}",
  cantRevokeKeyFromMemberAssignedToTeamsOrAlerts:
    "Le membre fait partie d'une équipe ou est affecté à un auditeur. Veuillez retirer le membre de ces équipes/auditeurs avant de révoquer la clé.\nÉquipes : {{teams}}\nAuditeurs : {{alerts}}",
  cantDeleteTeamAssignedToAlerts:
    "L'équipe fait partie d'un auditeur. Veuillez supprimer l'équipe de l'auditeur avant de la supprimer.\nLes auditeurs : {{alerts}}",
  cantDeleteRotationAssignedToAlerts:
    "Rotation fait partie d'un auditeur. Veuillez retirer l'équipe de l'auditeur avant de le supprimer.\nLes auditeurs : {{alerts}}",
  editAlert: "Modifier l'écouteur",
  timezoneDisplayString: '(GMT{{offset}}) {{timezone}}',
  apiKey: 'Clé API',
  viewApiKey: 'Voir la clé API',
  apiKeyWarningMessage:
    'Gardez votre clé API en sécurité à tout moment. Ne la communiquez à personne. Si vous pensez que votre clé API a été compromise, cliquez sur le bouton "Modifier" ci-dessus pour la changer. Une fois la clé modifiée, vous devrez spécifier la nouvelle clé à tous les endroits où vous déclenchez des incidents à l\'aide de l\'API REST.',
  integrationConfigs: "Configurations d'intégration",
  addIntegrationConfig: "Ajouter une configuration d'intégration",
  editIntegrationConfig: "Modifier la configuration de l'intégration",
  integrationSource: "Source d'intégration",
  apiToken: 'Jeton API',
  integrations: 'Intégrations',
  selectPlaceholder: 'Sélectionner...',
  zendeskSubdomain: 'Sous-domaine Zendesk',
  creatorTimeForBuddyAlarm: '{{timeString}} ({{remindMeAfter}})',
  nextFiveAlarms: 'Les 5 alarmes suivantes',
  categories: 'Catégories',
  viewingAlarmsDueToday: "Voir les alarmes aujourd'hui",
  viewingAlarmsDueTomorrow: 'Voir les alarmes pour demain',
  expiredAlarms: 'Alarmes périmées',
  viewingExpiredAlarms: 'Voir les alarmes expirées',
  noAlarmsDueToday: "Aucune alarme n'est due aujourd'hui",
  noAlarmsDueTomorrow: "Pas d'alarme pour demain",
  noExpiredAlarms: "Pas d'alarme expirée",
  viewingAllAlarms: 'Voir toutes les alarmes',
  viewingRecentlyDeletedAlarms: 'Voir les alarmes récemment supprimées',
  summaryView: 'Essayez la Voir Résumé ?',
  summaryViewDescription:
    "Voir les alarmes organisées par dates d'échéance et par catégories.\n\nVous pouvez revenir à la vue en liste en utilisant le bouton ⋮ en haut à droite.",
  disableBatteryOptimizationMessageOnStartup:
    "Galarm doit fonctionner en arrière-plan pour que les alarmes puissent sonner de manière fiable. Veuillez autoriser la permission sur l'écran suivant.",
  disableBatteryOptimizationMessage:
    'Galarm doit fonctionner en arrière-plan pour que les alarmes puissent sonner de manière fiable.',
  allowNotificationsToDisplayAlarms:
    "Galarm a besoin d'une autorisation pour afficher des notifications lorsque des alarmes sonnent.",
  scheduleExactAlarmsMessage:
    "Galarm a besoin d'une autorisation pour programmer des alarmes exactes.",
  alarmCategoryWithSameNameExists:
    'Une catégorie portant ce nom existe déjà. Veuillez spécifier un nom différent.',
  alarmRepetitionsPremiumFeature: 'Options de répétition avancées',
  ringtonesPremiumFeature: 'Plus de sonneries',
  galarmWebPremiumFeature: 'Utiliser Galarm sur le Web',
  taskListPremiumFeature: 'Créer des listes de tâches multiples',
  preReminderPremiumFeature: 'Prérappel pour les alarmes',
  alarmCategoriesPremiumFeature: "Catégories d'alarme personnalisées",
  shareableAlarmLinksPremiumFeature: "Liens d'alarme à partager",
  announceAlarmNamePremiumFeature: "Annoncer le titre de l'alarme",
  manageSubscription: "Gérer l'abonnement",
  purchaseRestored: 'Achat restauré.',
  unableToRetrieveSubscriptionPlansAndroid:
    "Nous ne parvenons pas à nous connecter à Google Play pour effectuer l'amélioration. Veuillez vous assurer que vous êtes connecté à Internet, puis réessayez. \n\nSi le problème persiste, veuillez le signaler à notre équipe d'assistance.",
  unableToRetrieveSubscriptionPlansIos:
    "Nous ne parvenons pas à nous connecter à l'App Store pour effectuer l'amélioration. Veuillez vous assurer que vous êtes connecté à l'internet, puis réessayez. \n\nSi le problème persiste, veuillez le signaler à notre équipe d'assistance.",
  drawOverOtherApps: 'Affichage par-dessus les autres applications',
  drawOverOtherAppsHelperText:
    "Permettre à Galarm d'afficher les alarmes en haut de page",
  nTimes: { one: 'Une fois', other: '{{count}} fois' },
  learnHowToUseFocus: 'Apprendre le Focus',
  enhancedNotificationsDeprecated:
    'Les notifications améliorées sont obsolètes',
  enhancedNotificationsDeprecatedMessage:
    'Nous avons constaté que les notifications améliorées ne sont pas fiables et provoquent une consommation excessive de la batterie. Veuillez utiliser iOS Focus pour permettre à Galarm de faire sonner les alarmes lorsque votre téléphone est en mode Ne pas déranger.',
  usingIosFocus: 'Utiliser iOS Focus',
  iosFocusModePara1:
    'Galarm ne sonnera pas lorsque le commutateur de sonnerie du téléphone est désactivé ou lorsque le volume de la sonnerie est à 0.\n\nApple recommande d\'utiliser "Focus" lorsque vous souhaitez que votre téléphone reste silencieux tout en permettant à certaines applications de vous avertir. Suivez les instructions ci-dessous pour configurer Focus sur votre téléphone.',
  iosFocusModePara2:
    "Vous pouvez voir des instructions plus détaillées avec des captures d'écran à l'adresse suivante : https://galarm.zendesk.com/hc/en-us/articles/10906890793236-Allow-alarms-to-ring-on-silent-on-iOS.",
  settingFocus: 'Réglage de Focus :',
  settingFocusFootNotes:
    'À noter que Focus est disponible sur iOS 15 et les versions ultérieures.\n\nPour toute question ou retour d\'information, veuillez nous contacter en utilisant "Réglages > Retour d\'information".',
  enablingFocus: 'Favoriser la focalisation :',
  settingFocusInstruction1:
    'Allez dans "Réglages du téléphone > Mise au point".',
  settingFocusInstruction2:
    'Tapez sur le profil de mise au point "Ne pas déranger".',
  settingFocusInstruction3: 'Tapez sur "Apps" et ajoutez Galarm.',
  settingFocusInstruction4:
    'Faites de même pour les autres profils Focus que vous avez réglés.',
  enablingFocusInstruction1:
    'Balayez vers le bas depuis le bord supérieur droit pour voir le Centre de contrôle.',
  enablingFocusInstruction2: 'Tapez sur Focus.',
  enablingFocusInstruction3:
    'Tapez sur Ne pas déranger ou sur tout autre profil Focus que vous souhaitez activer.',
  enablingFocusInstruction4:
    "L'option Focus actuellement activée est sélectionnée. Vous pouvez à nouveau taper dessus pour la désactiver.",
  startDateForRotation: 'Date de début de la rotation',
  startTimeForRotation: 'Heure de début de la rotation',
  member: 'Membre',
  multipleShiftsInADay: 'Plusieurs équipes par jour',
  escalationPolicy: "Politique d'escalade",
  atTimeOfEvent: "Au moment de l'événement",
  afterNMinutes: 'Après {{count}} minutes',
  afterNHours: { one: 'Après 1 heure', other: 'Après {{count}} heures' },
  afterDuration: 'Après {{durationString}}',
  addTrigger: 'Ajouter un déclencheur',
  viewCode: 'Voir le code',
  apiCodes: 'Codes API',
  viewIncidents: 'Voir les incidents',
  incidentsForAlert: "Incidents pour l'auditeur {{alertName}}",
  timestamp: 'Horodatage',
  acknowledged: 'Accusé de réception',
  noIncidentsForAlert: 'Aucun incident pour cet auditeur pour le moment',
  apiCodeCopiedToClipboard: 'Le code API a été copié dans le presse-papiers.',
  triggerName: 'Déclencheur {{index}}',
  delay: 'Délai',
  chooseAssigneePlaceholder: 'Sélectionnez Assignee...',
  delayForTrigger: 'Délai de déclenchement',
  assigneeWillBeAlertedAfterDelay:
    'Le cessionnaire sera alerté après {{durationString}}.',
  escalationForIncidentsForAlert:
    "Escalade en cas d'incident à l'adresse {{incidentDate}} pour l'auditeur {{alertName}}",
  alertIncidentAcknowledged:
    "Reconnu par {{memberName}} à l'adresse suivante {{dateString}}",
  alertIncidentRecovered: 'Récupéré à {{dateString}}',
  alertIncidents: 'Incidents',
  viewingAlertIncidents: 'Voir les incidents',
  createOrganization: 'Créer une organisation',
  youAreNotPartOfAnyOrganization:
    "Vous ne faites partie d'aucune organisation. Créez une organisation pour commencer.",
  tellUsAboutYourOrganization: 'Parlez-nous de votre organisation',
  noActiveSubscription: "Vous n'avez pas d'abonnement actif.",
  chooseOrganization: 'Choisissez une organisation pour continuer.',
  noTriggersForIncident:
    "Aucun élément déclencheur n'a été trouvé pour cet incident.",
  role: 'Rôle',
  viewer: 'Visionneuse',
  newOrganization: 'Nouvelle organisation',
  noOrganizations: "Vous ne faites partie d'aucune organisation.",
  maxOrganizationsReached:
    "L'utilisateur peut faire partie de 10 organisations au maximum.",
  yourOrganizations: 'Organisations',
  urlNotFound: "L'URL ne correspond à aucun itinéraire pris en charge.",
  triggerAlert: 'Incident déclencheur',
  recaptchaFailed: 'La vérification reCAPTCHA a échoué. {{error}}',
  loadingAccountInformation: 'Chargement des informations sur le compte',
  loadingAuthInfo: "Chargement des informations d'authentification",
  allAlerts: 'Tous les auditeurs',
  recentlyTriggeredAlerts: 'Écouteurs récemment déclenchés',
  recentlyClosedIncidents: 'Incidents récemment clôturés',
  noRecentlyTriggeredAlerts: "Aucun auditeur n'a été déclenché récemment.",
  noRecentlyClosedIncidents: "Aucun incident n'a été clos récemment.",
  closedAt: 'Fermé à {{dateTimeString}}',
  dashboard: 'Tableau de bord',
  loggedIntoOrganizationAccount:
    '(organisation : {{organizationName}}, rôle : {{role}})',
  viewIncidentEscalation: "Voir l'escalade des incidents",
  closeIncident: 'Incident proche',
  closingIncident: 'Incident de clôture',
  unableToRefreshEnterpriseAccount: "Impossible d'actualiser votre compte.",
  viewAuthToken: "Voir le jeton d'authentification",
  problemWithCreatingAuthToken:
    "Il y a eu un problème lors de la création d'un jeton d'authentification. Veuillez contacter le support technique.",
  authToken: "Jeton d'authentification",
  authTokenWarningMessage:
    "Gardez ce jeton d'authentification en sécurité à tout moment. Ne le partagez avec personne. Si vous pensez que votre clé API a été compromise, cliquez sur le bouton \"Modifier\" ci-dessus pour la changer. Après avoir modifié la clé, vous devrez spécifier la nouvelle clé à tous les endroits où vous déclenchez des incidents à l'aide de l'API REST.",
  authTokenCopiedToClipboard:
    "Le jeton d'authentification a été copié dans le presse-papiers.",
  resendVerificationEmail: "Renvoyer l'e-mail de vérification",
  loggedInUsingEmail: "Connecté en utilisant l'email {{email}}.",
  checkingIfMemberIsAppUser:
    "Vérifier si le membre est un utilisateur de l'application.",
  settingMemberDetails: 'Réglage des coordonnées des membres.',
  addingTeam: "Ajout d'une équipe.",
  editingTeam: 'Équipe de rédaction.',
  addingRotation: "Ajout d'une rotation.",
  editingRotation: "Rotation de l'édition.",
  assigningKeyToMember: "Attribution d'une clé à un membre.",
  revokingKeyFromMember: "Révoquer la clé d'un membre.",
  deletingTeam: "Suppression d'une équipe.",
  deletingRotation: 'Suppression de la rotation.',
  addingAlert: "Ajout d'un auditeur.",
  editingAlert: "Éditeur d'écoute.",
  deletingAlert: "Suppression d'un auditeur.",
  triggeringAlert: 'Incident déclencheur.',
  creatingOrganizationAccount: "Création d'un compte d'organisation.",
  creatingCheckoutSession: 'Lancement de la session de vérification.',
  creatingAnAccountForMember: "Création d'un compte pour un membre.",
  passwordResetEmailSent:
    'Nous vous avons envoyé un courriel pour réinitialiser votre mot de passe. Modifiez votre mot de passe et reconnectez-vous.',
  changeOwner: 'Changement de propriétaire',
  changeOrganizationOwner: "Changer le propriétaire de l'organisation",
  newOwnerEmail: 'Email du nouveau propriétaire',
  saveOnYearlySubscription: 'Sauvegarder {{percent}}%',
  annual: 'Annuel',
  resetAlarms: 'Rafraîchir les alarmes',
  resetAlarmsAlert:
    'Toutes les alarmes ont été actualisées. Si vous continuez à observer des problèmes avec les alarmes, veuillez soumettre un ticket en utilisant l\'option "Signaler un problème".',
  resetAlarmsTooltipMessage:
    'Si vous rencontrez des problèmes avec des alarmes telles que des alarmes supprimées qui sonnent sur le téléphone, veuillez rafraîchir les alarmes à l\'aide de cette option. Si le problème persiste, veuillez soumettre un ticket en utilisant l\'option "Signaler un problème".',
  resetTempPassword: 'Réinitialiser le mot de passe temporaire',
  currPassword: 'Mot de passe actuel',
  tempPassword: 'Mot de passe temporaire',
  newPassword: 'Nouveau mot de passe',
  reset: 'Remise à zéro',
  loggingIn: 'Connexion',
  resettingTempPassword: 'Réinitialisation du mot de passe temporaire',
  changingPassword: 'Modification du mot de passe',
  numSeats: 'Nombre de sièges',
  helpMeDecide: 'Aidez-moi à décider',
  numEnterpriseSeatsHelper:
    "Vous devez choisir le nombre de sièges en fonction du nombre de personnes de votre organisation qui utiliseront l'application pour recevoir des incidents. Lors de la configuration d'un auditeur, vous pouvez spécifier sa politique d'escalade. Une politique d'escalade peut comporter plusieurs niveaux. Par exemple, vous pouvez spécifier que le premier niveau d'escalade est une équipe de 3 personnes. Si le premier niveau d'escalade ne répond pas dans les 5 minutes, le deuxième niveau d'escalade s'adresse à une équipe de 5 personnes. Dans ce cas, vous pouvez spécifier que le nombre de sièges est de 8.",
  numBusinessSeatsHelper:
    'Choisissez le nombre de sièges en fonction du nombre de personnes qui utiliseront Galarm pour recevoir des alarmes et des rappels.',
  perSeatPrice: '{{price}} par {{interval}} par siège',
  doneForToday: 'Accusé de réception',
  getStarted: 'Commencer',
  firstAlertInstructions: 'Instructions pour le premier auditeur',
  learnMoreAboutGalarmEnterprise: 'En savoir plus sur Galarm Pro',
  home: 'Accueil',
  team: "L'équipe",
  alert: 'Alerte',
  listener: 'Auditeur',
  forgotPassword: 'Mot de passe oublié ?',
  forgotPasswordTitle: 'Mot de passe oublié',
  sendPasswordResetEmail: "Envoyer l'email de réinitialisation du mot de passe",
  sendingPasswordResetEmail:
    'Envoi du courriel de réinitialisation du mot de passe',
  field: "Champ d'application",
  description: 'Description',
  requestIntegration: 'Demander une autre intégration',
  integrationDetails: "Détails de l'intégration",
  enterIntegrationDetails: "Fournir les détails de l'intégration",
  galarmEnterpriseFreeTrial: "S'inscrire pour un essai gratuit de 14 jours",
  galarmEnterpriseFreeTrialDescription: 'Automatiser la gestion des incidents',
  bySigningUpYouAgreeToOur: 'En vous inscrivant, vous acceptez nos',
  and: 'et',
  alarmSubscribers: "Suiveurs de l'alarme",
  otherSubscribersCount: {
    one: '1 follower supplémentaire ne figurant pas dans vos contacts',
    other: "{{count}} plus d'adeptes ne figurant pas dans vos contacts"
  },
  getAccountData: "Exporter les données d'alarme",
  getAccountDataPrompt:
    'Indiquez une adresse électronique à laquelle nous enverrons un fichier texte contenant toutes vos alarmes.',
  getAccountDataConfirmation:
    "Veuillez noter que nous vous enverrons par courrier électronique un fichier texte contenant toutes vos alarmes à l'adresse {{email}}.",
  sendingAccountData: "Exportation des données d'alarme...",
  sentAccountData: "Données d'alarme envoyées à {{email}}.",
  errorSendingAccountData:
    "Erreur d'exportation des données d'alarme : {{error}}",
  sendingEmailVerificationLink: 'Envoi du lien de vérification par courriel',
  viewLogs: 'Voir les journaux',
  message: 'Message',
  billing: 'Facturation',
  newIncidentForAlert: "Nouvel incident pour l'auditeur {{alertName}}",
  clickNextAfterAddingMembers:
    'Cliquez sur "Suivant" après avoir ajouté des membres.',
  clickDoneAfterAddingAlerts:
    'Cliquez sur "Terminé" pour terminer la configuration et accéder au tableau de bord.',
  alertCreatedSuccessfully:
    "L'écouteur a été créé avec succès. Vous pouvez déclencher cet écouteur à partir de l'icône de menu à trois points située devant le nom de l'écouteur.",
  fixSubscription: "Fixer l'abonnement",
  fixSubscriptionDescription:
    'Nous avons détecté un problème avec votre abonnement. Votre abonnement contient {{numSeats}} siège(s) mais vous avez attribué des clés à {{numSeatsAssigned}} membre(s). Vous pouvez attribuer au maximum {{numSeats}} clé(s).',
  fixSubscriptionChooseOption:
    "Veuillez corriger votre abonnement en utilisant l'une des options ci-dessous.",
  fixSubscriptionContactAdmin:
    "Veuillez contacter l'administrateur de votre organisation.",
  unassignKeysFromMembers:
    'Désaffecter au moins {{extraKeysAssigned}} les clés des membres',
  addMoreSeatsToSubscription:
    'Ajoutez au moins {{extraKeysAssigned}} siège(s) à votre abonnement',
  revokeMembersKey: 'Révoquer la clé des membres',
  revokeMembersKeyDescription:
    'Choisissez les membres dont vous souhaitez révoquer les clés.',
  revoke: 'Révoquer',
  unableToRevokeMembersKey:
    "Une erreur s'est produite lors de la révocation de la clé des membres. Veuillez contacter le support.",
  problemWithLoadingProducts: 'Problème de chargement des produits. {{error}}',
  verificationCompleted: 'Vérification terminée',
  deviceAlreadyRegistered:
    'Ce numéro de téléphone a déjà été vérifié sur cet appareil.',
  phoneNumberAutoFilled:
    'Galarm utilisera le numéro de téléphone sélectionné dans votre compte Google.',
  unableToRegisterPreVerifiedUser:
    "Impossible d'enregistrer un utilisateur pré-vérifié.",
  allowNotifications: 'Autoriser les notifications',
  allowNotificationsDescription:
    "Pour afficher les alarmes lorsqu'elles sonnent.",
  scheduleExactAlarms: 'Programmation des alarmes',
  scheduleExactAlarmsHelperText:
    "Permettre à Galarm de faire sonner les alarmes à l'heure exacte.",
  scheduleExactAlarmsDescription:
    "Pour faire sonner les alarmes à l'heure exacte.",
  disableBatteryOptimization: "Gérer l'optimisation de la batterie",
  disableBatteryOptimizationDescription:
    'Pour permettre à Galarm de fonctionner en arrière-plan.',
  requestRequiredPermissions:
    'Galarm nécessite les autorisations suivantes pour sonner les alarmes',
  allowDisallowedPermissions: 'Tapez sur pour autoriser',
  notificationsAreEnabled:
    "L'autorisation d'afficher des notifications est déjà accordée.",
  connectingToStore: 'Connexion au magasin...',
  restartApp: "Redémarrer l'application",
  updateAvailable: 'Mise à jour disponible',
  english: 'Anglais',
  russian: 'Russie',
  german: 'Allemand',
  french: 'Français',
  spanish: 'Espagnol',
  italian: 'Italien',
  portuguese: 'Portugais',
  japanese: 'Japonais',
  korean: 'Coréen',
  indonesian: 'Indonésien',
  changeAppLanguage: "Changer la langue de l'application",
  appLanguage: "Langue de l'application",
  iosChangeAppLanguagePara1:
    "Galarm choisit la langue de l'application à partir des réglages de votre téléphone. Veuillez suivre les étapes suivantes pour changer la langue de l'application Galarm :",
  iosChangeAppLanguageInstruction1:
    'Ouvrez "Réglages du téléphone > Général > Langue et région". Assurez-vous que la langue que vous souhaitez utiliser pour Galarm est présente dans la liste des langues préférées.',
  iosChangeAppLanguageInstruction2:
    'Retournez dans les "Réglages du téléphone" et tapez sur "Galarm" dans la liste des applications en bas.',
  iosChangeAppLanguageInstruction3:
    'Tapez sur "Langue" et sélectionnez la langue que vous souhaitez régler pour Galarm. Revenez à l\'application après avoir choisi la langue.',
  androidChangeAppLanguagePara1:
    "Galarm choisit la langue de l'application à partir des réglages de votre téléphone. Veuillez suivre les étapes suivantes pour changer la langue de l'application Galarm :",
  androidChangeAppLanguageInstruction1:
    'Tapez sur le bouton "Ouvrir les réglages de l\'application" ci-dessous.',
  androidChangeAppLanguageInstruction2:
    'Tapez sur "Langue" et sélectionnez la langue que vous souhaitez utiliser pour Galarm.',
  androidChangeAppLanguageInstruction3:
    'Après avoir choisi la langue, revenez ici et cliquez sur le bouton "Redémarrer l\'application" ci-dessous.',
  problemRetrievingSubscriptionData:
    "Il y a eu un problème pour récupérer vos informations d'abonnement. Nous vous renvoyons à la page du compte. Veuillez vérifier l'état de votre abonnement et contacter le support technique si vous continuez à rencontrer des problèmes.",
  deletingOrganization: "Suppression d'une organisation...",
  deleteOrganization: "Supprimer l'organisation",
  deleteOrganizationDescription:
    "Êtes-vous sûr de vouloir supprimer votre organisation ?\n  \n  Il s'agit d'une opération irréversible. Toutes les données de votre organisation seront supprimées.\n  \n  Veuillez vous assurer que vous avez annulé votre abonnement avant de supprimer l'organisation.",
  galarmEnterpriseTrialDescription:
    "Il existe une période d'essai gratuit de 14 jours pendant laquelle vous pouvez annuler l'abonnement sans frais.",
  galarmEnterpriseTrialAlreadyAvailed:
    "Vous avez déjà bénéficié de l'essai gratuit de 14 jours. Contactez le support si vous avez besoin de plus d'heure pour évaluer le produit.",
  signinWithGoogle: 'Se connecter avec Google',
  signinWithGithub: "S'identifier avec GitHub",
  verifyAccount: 'Vérifier le compte',
  verifyAccountMessage:
    'Vous devriez avoir reçu un courriel contenant un code de vérification. Saisissez le code ci-dessous pour vérifier votre compte. Si vous n\'avez pas reçu le code, vous pouvez en demander un nouveau en cliquant sur le bouton "Renvoyer le code".',
  verificationCode: 'Code de vérification',
  optionalString: '{{string}}',
  quickReminderTitle: 'Titre (facultatif)',
  accountVerified: 'Compte vérifié',
  accountVerifiedMessage:
    'Veuillez vous connecter en utilisant vos identifiants.',
  multipleUsersWithSameEmail:
    'Il y a plusieurs utilisateurs avec le même email. Veuillez contacter le support.',
  multipleUsersWithSameMobileNumber:
    "Il y a plusieurs utilisateurs avec le même numéro de portable. Veuillez contacter le service d'assistance.",
  startTrial: "Début de l'essai",
  nextSteps: 'Étapes suivantes',
  firstTimeSetupComplete:
    'Vous avez terminé la configuration initiale de Galarm Enterprise et créé votre premier auditeur. Vous trouverez ci-dessous les étapes suivantes.',
  testTheAlertInstruction:
    "Consultez les instructions à l'adresse {{link}} pour savoir comment tester l'écouteur que vous venez de créer.",
  integrateTheAlertInstruction:
    "Consultez les instructions à l'adresse {{link}} pour intégrer l'écouteur à divers outils de surveillance.",
  createMoreAlertsInstruction:
    'Vous pouvez ajouter d\'autres auditeurs en allant dans l\'onglet "Auditeurs" sur la gauche.',
  addMoreMembersInstruction:
    'Vous pouvez ajouter d\'autres membres en allant dans l\'onglet "Membres" sur la gauche.',
  createTeamsInstruction:
    'Vous pouvez regrouper les membres dans des "équipes" et alerter toute une équipe lorsqu\'un incident se produit. Créez des équipes en allant dans l\'onglet "Équipes" à gauche et utilisez-les dans la configuration de l\'auditeur.',
  testTheAlert: "Tester l'écouteur",
  integrateTheAlert: "Intégrer l'auditeur",
  createMoreAlerts: "Créer plus d'auditeurs",
  addMoreMembers: 'Ajouter des membres',
  createTeams: 'Créer des équipes',
  docs: 'Docs',
  contactSupport: 'Contact Support',
  pastDue: 'En souffrance',
  canceled: 'Annulé',
  unpaid: 'Non payé',
  incomplete: 'Incomplet',
  incomplete_expired: 'Incomplet Expiré',
  trialing: 'Essai gratuit',
  paused: 'En pause',
  plan: 'Plan',
  status: 'Statut',
  renewsOn: 'Renouvellement le',
  yourRoles: 'Vos rôles',
  enterprisePlan: '{{quantity}} sièges, renouvelle chaque {{interval}}',
  currentOrganization: 'Organisation actuelle',
  useThisOrganization: 'Utiliser cette organisation',
  fixPayment: 'Fixer le paiement',
  fixingPayment: 'Fixation du paiement',
  addPaymentInformation: 'Ajouter des informations de paiement',
  noPaymentInformationAvailable:
    "Aucune information de paiement n'est disponible. Veuillez actualiser la page si vous avez récemment ajouté des informations de paiement.",
  openBillingPortal: 'Portail de facturation ouvert',
  startupWizardDescription:
    'Cet assistant vous aidera à démarrer et à créer votre premier auditeur.',
  userNotFound: 'Utilisateur non trouvé',
  wrongPassword: 'Mot de passe erroné',
  emailAlreadyInUse: 'Email déjà utilisé',
  restoreDeletedAlarms: 'Restauration des alarmes supprimées',
  deleteAlarmAlert:
    'Les alarmes supprimées peuvent être restaurées à partir des "Alarmes récemment supprimées". Vous pouvez les voir en appuyant sur le menu à trois points (⋮) en haut à droite de l\'onglet "Alarmes".\n\nLes alarmes supprimées y restent pendant 30 jours, après quoi elles sont définitivement supprimées.',
  slackVerificationCode:
    'Votre code de vérification Slack est {{verificationCode}}.',
  dndAccess: 'Accès à la fonction "Ne pas déranger',
  dndHelperText:
    'Autoriser Galarm à sonner les alarmes lorsque votre téléphone est en mode Ne pas déranger.',
  cantRestoreInstantAlarmMessage:
    'Les alarmes instantanées ne peuvent pas être rétablies. Vous pouvez voir les discussions et autres informations relatives à cette alarme si nécessaire. Cette alarme sera définitivement supprimée au bout de 30 jours après sa suppression.',
  upgradeToUnlockAllFeatures:
    'Amélioration pour débloquer toutes les fonctionnalités',
  otherSignupOptions: "Autres options d'inscription :",
  renewSubscription: "Renouveler l'abonnement",
  subscriptionOnHold:
    'En attente (Votre plan prévoit {{subscriptionQty}} sièges\nmais vous avez attribué {{numSubscriptionKeys}} sièges.',
  checklists: 'Tâches',
  checklist: 'Liste des tâches',
  typeToAddTask: 'Type de tâche à ajouter',
  specifyTaskName: 'Spécifier le nom de la tâche',
  otherChecklists: 'Listes de tâches',
  defaultChecklistName: 'sans titre',
  addTasksToChecklist:
    "Ajouter des tâches à l'aide de la boîte de saisie située en bas de l'écran",
  addTasksToDefaultChecklist:
    'Ajoutez des tâches en utilisant le champ de saisie en bas... ou créez une nouvelle liste de tâches en cliquant sur le bouton + en haut à droite.',
  createNewChecklist:
    'Vous pouvez créer de nouvelles listes de tâches pour assurer le suivi des tâches connexes en un seul endroit.',
  addNewChecklist: 'Nouvelle liste de tâches',
  alarmPhoto: "Photo d'alarme",
  alarmPhotoFeatureTitle: "Photo d'alarme",
  alarmPhotoFeatureDescriptionPremiumUser:
    "Grâce à cette fonction, vous pouvez ajouter une photo à une alarme pour obtenir des repères visuels rapides tels que la photo d'un médicament, d'instructions pour une tâche, d'un objet, etc. La photo s'affiche lorsque l'alarme sonne.",
  alarmPhotoFeatureDescriptionFreeUser:
    "Grâce à cette fonction, vous pouvez ajouter une photo à une alarme pour obtenir des repères visuels rapides tels que la photo d'un médicament, d'instructions pour une tâche, d'un objet, etc. La photo s'affiche lorsque l'alarme sonne.\n  \nIl s'agit d'une fonction premium. Il vous sera demandé d'effectuer une amélioration vers la version premium pour pouvoir utiliser cette fonction.",
  alarmPhotoPremiumFeature: "Ajouter une photo aux notes d'alarme",
  installPendingAppUpdate:
    "La dernière mise à jour de l'application a été téléchargée et est prête à être installée.",
  numTasks: {
    zero: 'Pas de tâches',
    one: '1 tâche',
    other: '{{count}} tâches'
  },
  numTasksWithCompleted: {
    one: '1 tâche, {{completed}} complétée',
    other: '{{count}} tâches, {{completed}} complétées'
  },
  muteAlarms: 'Mise en sourdine des alarmes',
  unmuteAlarms: 'Désactiver les alarmes',
  alarmsAreMuted:
    'Toutes vos alarmes sont actuellement en sourdine. Touchez pour rétablir le son.',
  alarmsHaveBeenMuted:
    'Vos alarmes ont été mises en sourdine. Elles ne sonneront pas tant que vous ne les aurez pas remises en sourdine.',
  alarmsHaveBeenUnmuted:
    'Vos alarmes ont été mises en sourdine. Elles devraient sonner comme configuré.',
  deleteChecklist: 'Supprimer la liste des tâches',
  confirmDeleteChecklist: 'Supprimer la liste des tâches ?',
  confirmDeleteCompletedTasks: 'Supprimer les tâches terminées ?',
  confirmResetCompletedTasks: 'Réinitialiser les tâches achevées ?',
  confirmDeleteTask: 'Supprimer {{taskName}}?',
  showCompletedTasks: 'Afficher les tâches terminées',
  hideCompletedTasks: 'Masquer les tâches terminées',
  deleteCompletedTasks: 'Supprimer les tâches terminées',
  resetCompletedTasks: 'Réinitialiser les tâches terminées',
  yourGroups: 'Vos groupes',
  newAlarmWith: 'Nouvelle alarme avec {{name}}',
  newGroupWith: 'Nouveau groupe avec {{name}}',
  alertContact: 'Alerte {{name}}',
  alertGroup: 'Alerte {{name}}',
  instantAlarmMovedToFabNotice:
    'La fonction d\'alarme instantanée est désormais disponible à partir du bouton "+" situé en bas à droite de cet écran. L\'icône "haut-parleur" sera supprimée en haut à droite dans une prochaine version.',
  tasksIntroTitle: 'Tâches et listes de tâches',
  tasksIntroDescription:
    "Les tâches sont les choses à faire qui n'ont pas de date limite. Vous pouvez rapidement les ajouter et les gérer ici, en les cochant au fur et à mesure que vous les accomplissez.\n\nVous pouvez également créer des listes de tâches pour regrouper des tâches similaires. Par exemple, vous pouvez créer une liste de tâches pour vos courses, une autre pour vos tâches professionnelles, etc.",
  groupsMovedToContactsScreen:
    'Les "groupes" ont été fusionnés dans cet onglet. Vos groupes apparaissent en haut sous "Vos groupes". Vous pouvez créer de nouveaux groupes en cliquant sur le bouton "+" en bas à droite.',
  loadingTasks: 'Chargement des tâches...',
  tryPremium: 'Essayez Premium',
  buyPremiumToUnlockFeature:
    "Il s'agit d'une fonctionnalité premium. Débloquez cette fonctionnalité et bien d'autres encore avec notre plan premium qui est accompagné d'une période d'essai gratuite de 14 jours.",
  newTask: 'Nouvelle tâche',
  newTaskList: 'Nouvelle liste de tâches',
  enterTaskName: 'Saisir le nom de la tâche',
  enterTaskListName: 'Saisir le nom de la liste de tâches',
  taskName: 'Nom de la tâche',
  taskListName: 'Nom de la liste des tâches',
  checklistTasks: '{{name}}',
  editTaskListName: 'Modifier le nom de la liste des tâches',
  editTask: 'Modifier la tâche',
  releaseNotes: 'Notes de mise à jour',
  currentRelease: '(actuellement installé)',
  loadingReleaseNotes:
    "Chargement des notes de mise à jour. Veuillez vous assurer que vous êtes connecté à l'internet.",
  id: 'ID',
  subscriptionData: "Données d'abonnement",
  organizations: 'Organisations',
  showRotationCalendar: 'Calendrier de rotation des spectacles',
  rotationCalendar: 'Calendrier de rotation',
  editShift: 'Modifier le décalage',
  startDateForShift: 'Date de début de la période de travail',
  startTimeForShift: 'Heure de début de la période de travail',
  endDateForShift: 'Date de fin de la période de travail',
  endTimeForShift: 'Heure de fin de la période de travail',
  editingShift: "Changement d'édition",
  addingShift: "Ajout d'un décalage",
  products: 'Produits',
  prices: 'Plans',
  planType: 'Type de régime',
  business: 'Entreprises',
  enterprise: 'Entreprise',
  compare: 'Comparer',
  apiSupport: "Prise en charge de l'API pour surveiller les services",
  accessToConsoleToViewAndCloseIncidents:
    'Tableau de bord pour la gestion des incidents',
  triggerInstantAlertsFromTheConsole:
    "Déclencher des incidents à partir de l'Enterprise Console",
  coreFeatures: 'Caractéristiques principales :',
  everythingInBusiness: 'Tout dans Business plan et :',
  updatePlanInstructions:
    'Vous pouvez mettre à jour votre plan en cliquant sur le bouton "Ouvrir le portail de facturation" à droite et en choisissant le bouton "Mettre à jour le plan" sur le portail de facturation.',
  membersDescription:
    "Vous avez acheté {{subscriptionKeysCount}} places -- {{assignedSubscriptionKeysCount}} assignées et {{unassignedSubscriptionKeysCount}} disponibles. Une coche devant le nom d'un membre indique qu'il s'agit d'un siège attribué.",
  centralBilling: 'Facturation centrale',
  saveTwoMonths: 'Sauvegarder 2 mois',
  owner: 'Propriétaire',
  enterpriseAccountSummary:
    "Vous faites partie de l'organisation {{organizationName}} inscrite au plan Galarm Pro {{planType}}.",
  perYearPerUser: 'par utilisateur/an',
  perMonthPerUser: 'par utilisateur/mois',
  creationDate: 'Date de création',
  updatePhoneNumber: 'Modifier le numéro de téléphone',
  confirmCountryCodeAndEnterUpdatedPhoneNumber:
    "Confirmez l'indicatif du pays et saisissez votre nouveau numéro de téléphone. Votre compte sera lié à ce numéro de téléphone une fois la vérification terminée.",
  unableToUpdatePhoneNumber:
    'Impossible de modifier le numéro de téléphone : {{error}}. Veuillez contacter le support.',
  phoneNumberUpdated: 'Le numéro de téléphone a été modifié avec succès.',
  phoneNumberUpdatedMessage:
    "Vous pouvez commencer à utiliser l'application normalement. Plesae demander à vos contacts de mettre à jour votre numéro de téléphone dans leur carnet d'adresses.",
  memberChangedPhoneNumber:
    "{{memberName}} a changé de numéro de téléphone. Veuillez mettre à jour votre carnet d'adresses pour continuer à les ajouter à vos alarmes.",
  preferredSourceForIncidents: 'Source privilégiée pour les incidents',
  apiCodesDescription:
    "Suivez les instructions suivantes pour intégrer l'auditeur à une source",
  rotation: 'Rotation',
  noAlarmsForThisDay: "Pas d'alarme pour ce jour",
  markAlarmsAsComplete: 'Marquer les alarmes comme complètes',
  markAlarmsAsCompleteExplanation:
    'Les alarmes uniques seront marquées comme terminées et apparaîtront dans la section des alarmes "inactives".\n\nLes alarmes qui se répètent sont marquées comme étant terminées et sont déplacées vers l\'événement suivant.',
  enterIncidentPrefix: 'Max 5 caractères',
  incidentPrefix: "Préfixe d'incident",
  incidentPrefixAlreadyExists: "Le préfixe d'incident existe déjà",
  removeTask: 'Supprimer la tâche ?',
  incidentPrefixLengthExceeded:
    "Le préfixe de l'incident comporte plus de 5 caractères",
  criticalAlerts: 'Alertes critiques',
  criticalAlertsConfiguration:
    "L'alarme sonnera à un volume de {{criticalAlertsVolume}}% même si votre téléphone est en sourdine ou si un Focus est activé.",
  criticalAlertsDisabledShort:
    "L'alarme ne sonnera pas lorsque votre téléphone est en sourdine.",
  criticalAlertsConfigurationGlobal:
    'Les alarmes sonneront à un volume de {{criticalAlertsVolume}}% même si votre téléphone est en sourdine ou si un Focus est activé.',
  criticalAlertsDisabledShortGlobal:
    'Les alarmes ne sonneront pas lorsque votre téléphone est en sourdine.',
  criticalAlertsEnabled:
    'Les alertes critiques sont activées. Les alarmes sonneront même si votre téléphone est en sourdine ou si un Focus est activé.',
  criticalAlertsDisabled:
    'Les alertes critiques sont désactivées. Les alarmes ne sonneront pas lorsque votre téléphone est en sourdine.',
  enableCriticalAlerts: 'Activer les alertes critiques',
  summer_soft: 'Douceur estivale',
  beeps: 'Bips',
  bollywood_nights: 'Les nuits de Bollywood',
  gentle_reminder: 'Un rappel en douceur',
  himalayan_piper: "Piper de l'Himalaya",
  holiday_joy: 'Joie des vacances',
  hollywood_adventure: "L'aventure hollywoodienne",
  melody: 'Mélodie',
  morning_rooster: 'Coq du matin',
  morning_sunshine: 'Soleil du matin',
  sweet_sea: 'Douceur de la mer',
  old_school_ring: 'Tring Tring',
  MyAlarmNotification: "{{alarmName}} - C'est l'heure.",
  ParticipantCascadingAlarmNotification:
    "{{alarmName}} - L'heure est venue de rappeler à {{alarmCreatorName}}.",
  ParticipantSimultaneousAlarmNotification:
    "{{alarmName}}. L'heure est venue de confirmer votre participation.",
  InstantAlarmNotification:
    '{{alarmName}}. Votre attention immédiate est requise.',
  MyAlarmNotificationShort: "C'est l'heure.",
  ParticipantCascadingAlarmNotificationShort:
    "L'heure est venue de rappeler à {{alarmCreatorName}}.",
  ParticipantSimultaneousAlarmNotificationShort:
    "L'heure est venue de confirmer votre participation.",
  InstantAlarmNotificationShort: 'Votre attention immédiate est requise.',
  MyRecipientAlarmNotification:
    "{{alarmName}} - L'heure est venue de rappeler à {{alarmRecipientName}}.",
  MyRecipientAlarmNotificationShort:
    "L'heure est venue de rappeler à {{alarmRecipientName}}.",
  RecipientAlarmNotification:
    "{{alarmName}} (par {{alarmCreatorName}}) - L'heure est venue.",
  RecipientAlarmNotificationShort: "C'est l'heure - par {{alarmCreatorName}}.",
  selectADate: 'Veuillez choisir une date pour voir les alarmes',

  cantUpdateTimezoneForAllAlarms:
    'Impossible de mettre à jour le fuseau horaire pour les alarmes existantes',
  cantUpdateRingtonesForAllAlarms:
    'Impossible de mettre à jour les réglages des sons et vibrations pour les alarmes existantes',
  preReminderWillRingAt: {
    zero: "Un pré-rappel de cette alarme sonnera \nà l'adresse {{timeString}}.",
    one: 'Un pré-rappel de cette alarme sonnera \nà {{timeString}} le jour précédent.',
    other:
      "Un pré-rappel de cette alarme sonnera \nà l'adresse {{timeString}} {{count}} jours avant."
  },
  verficationCodeInfo:
    'Vous recevrez un message texte ou un appel téléphonique avec un code à 4 chiffres.',
  verficationCodeInfoWhatsApp:
    'Vous recevrez un message WhatsApp avec un code à 4 chiffres.',
  deletePastAlarmsNotificationMessage:
    "Vous avez plusieurs alarmes inactives. Veuillez supprimer les alarmes dont vous n'avez plus besoin.",
  confirmCountryCodeAndEnterNumber:
    "Confirmez l'indicatif de votre pays et entrez votre numéro de téléphone.",
  sendCodeOnSms: 'Envoyer le code par SMS',
  sendCodeOnWhatsApp: 'Envoyer le code via WhatsApp',
  verifyNumberWhatsApp:
    'Nous enverrons un message WhatsApp avec le code de vérification à :\n\n{{formattedMobileNumber}}',
  verifyNumberCall:
    'Vous recevrez un appel téléphonique avec le code de vérification au :\n\n{{formattedMobileNumber}}',
  defaultTimezoneForAlarm: 'Fuseau horaire par défaut pour les alarmes',
  changeDefaultTimezoneForAllExistingAlarms: {
    zero: 'Souhaitez-vous réinitialiser le fuseau horaire pour toutes les alarmes existantes ?',
    one: "Voulez-vous changer le fuseau horaire de toutes les alarmes existantes pour ce fuseau horaire ? Les alarmes sonneront à l'heure configurée dans le nouveau fuseau horaire."
  },
  changeDefaultRingerSettingsForAllExistingAlarms:
    'Vous souhaitez modifier les réglages des sons et des vibrations pour les alarmes existantes ?\n\nSi vous choisissez Oui, les alarmes existantes sonneront avec les nouveaux réglages. Si vous choisissez Non, les alarmes existantes continueront à sonner avec les anciens réglages.',
  defaultTimezoneChangedForAllAlarms:
    'Changement de fuseau horaire pour toutes les alarmes.',
  ringerSettingsChangedForAllAlarms:
    'Les réglages du son et de la vibration ont été modifiés pour les alarmes existantes.',
  unlimitedAlarmsFeatureDescription:
    "Créer davantage d'alarmes de groupe et d'ami.",
  instantAlarmsPremiumFeature: 'Envoyer des alertes instantanées',
  instantAlarmsFeatureDescription:
    'Envoyez plusieurs alertes instantanées à vos contacts et à vos groupes.',
  duplicateAlarmPremiumFeature: 'Alarme de doublon',
  duplicateAlarmFeatureDescription:
    "Créez rapidement des copies d'une alarme avec les mêmes réglages et apportez des modifications avant de sauvegarder.",
  showingAlarmsForDate: 'Affichage des alarmes pour {{date}}',
  shareAlarm: "Partager le lien de l'alarme",
  shareAlarmDescription:
    "Vous pouvez partager cette alarme avec n'importe qui à l'aide d'un lien sans l'ajouter en tant que contact. Lorsque quelqu'un tape sur le lien sur son téléphone, une copie de l'alarme est créée.",
  sendCodeAgain: 'Renvoyer le code',
  selectDefaultTimezoneForAlarmDefaultTooltipMessage:
    'Choisissez un fuseau horaire par défaut pour vos alarmes. Vous pouvez modifier le fuseau horaire des alarmes lorsque vous les créez.',
  alarmLinkRelativeToTimezone: '{{timeString}} dans leur fuseau horaire.',
  alarmLinkNotRelativeToTimezone: "L'alarme de chacun sonnera à {{timeString}}",
  alarmShouldRingAt: "L'alarme de chacun sonnera à :",
  showHourlyAlarms: 'Afficher les alarmes horaires',
  critical: 'Critique',
  alarmsWillBeAutomaticallyDeleted:
    'Les alarmes seront définitivement supprimées 30 jours après leur suppression.',
  unlimitedAlarmsPremiumFeature: "Alarmes de groupe et d'ami illimitées",
  notAcknowledged: "Pas d'accusé de réception",
  closed: 'Fermé',
  showFullScreenIntents: "Afficher l'alarme en plein écran",
  showFullScreenIntentsDescription:
    "L'alarme s'affiche en plein écran lorsque le téléphone est verrouillé.",
  percentVolume: '{{volume}}% volume',
  affiliates: 'Affiliés',
  referralCount: 'Nombre de renvois',
  addAffiliate: 'Ajouter un affilié',
  creatingAffiliate: "Création d'un affilié",
  affiliateLink: "Lien d'affiliation",
  upgradeRequiredForWeb: 'Amélioration nécessaire',
  upgradeToPremiumForWeb:
    "Cette fonctionnalité nécessite un abonnement premium. Veuillez acheter un abonnement premium depuis l'application mobile pour utiliser cette fonctionnalité.",
  accountType: 'Type de compte',
  profileAndSettings: 'Profil et réglages',
  confirmIncidents: 'Confirmer des incidents multiples',
  removeIncidents: 'Supprimer des incidents multiples',
  incidents: 'Incidents',
  closeSelectedIncidents: 'Fermer les incidents sélectionnés',
  viewIncidentNotes: "Voir les notes d'incident",
  incidentNotes: "Notes sur l'incident",
  addNote: 'Ajouter une note',
  noAdditionalNotesForIncident:
    'Pas de notes supplémentaires pour cet incident.',
  fromIncident: "De l'incident :",
  fromUsers: 'De la part des utilisateurs :',
  database: 'Base de données',
  chooseFirstDayOfWeek: 'Choisir le premier jour de la semaine',
  firstDayOfWeek: 'Premier jour de la semaine',
  changePlan: 'Plan de changement',
  currentPremiumSubscriptionStatus:
    'Vous êtes actuellement abonné au plan {{planName}}.',
  currentProSubscriptionStatus:
    "Vous êtes membre de l'organisation {{orgName}}.",
  changeToAnnualPlan: 'Modification du plan annuel',
  unableToChangePlan:
    'Impossible de passer au plan annuel. Veuillez contacter le support technique en utilisant "Réglages > Retour d\'information".',
  confirmChangeToAnnualPlan: 'Voulez-vous passer au plan annuel ?',
  changeToAnnualPlanDescription:
    'Vous serez facturé {{price}} pour le plan annuel. Les jours restants de votre plan actuel seront adaptés au plan annuel.',
  alarmTimezoneChanged:
    "Certaines alarmes ont été mises à jour pour utiliser le fuseau horaire de l'appareil car le fuseau horaire utilisé pour ces alarmes n'est pas pris en charge par cet appareil.",
  defaultTimezoneChanged:
    "Le fuseau horaire par défaut pour les alarmes est remplacé par le fuseau horaire de l'appareil car le fuseau horaire {{defaultTimezoneForAlarm}} n'est pas pris en charge par cet appareil.",
  invalidEmail: 'Adresse électronique non valide.',
  unableToValidateEmail: "Impossible de valider l'email. {{error}}",
  havingProblems: 'Vous avez des problèmes ?',
  alertData: "Données d'alerte",
  notificationActionBehavior: "Comportement de l'action de notification",
  foreground: 'Premier plan',
  background: 'Contexte',
  notificationActionBehaviorTooltipMessage:
    "Lorsqu'il est réglé sur \"Avant-plan\", le fait d'appuyer sur une action de notification ouvre l'application au premier plan. Lorsqu'elle est réglée sur \"Arrière-plan\", l'application reste en arrière-plan lorsque l'on tape sur une action de notification.",
  getCodeOnWhatsApp: 'Obtenir le code sur WhatsApp',
  getCode: 'Obtenir le code',
  dontHaveWhatsApp: "Vous n'avez pas WhatsApp ?",
  unableToSendCodeViaWhatsAppTitle: "Impossible d'envoyer un code via WhatsApp",
  unableToSendCodeViaWhatsAppMessage:
    '{{error}}\n\nVeuillez redemander le code sur un autre canal.',
  dataSecurity: 'Sécurité des données'
}
